import React, { useState } from "react"
import TextWithInfo from "../../ReusableComponents/TextWithInfo";
import Input from "antd/es/input/Input";


export default function OptionScoreInput(props){
    const [value, setValue] = useState(props.value ? props.value : 0);
    return (
        <>              
        {props.type == "ImageRadioButtons" && props.quizTypeName === "employability_quiz" ? 
        <div className="options-vl">
        </div> :
        null
        }
        <div className="score-input"
            style={{
                width: "100px"
            }}
        >
           <div
            style={{
                display: "flex",
                justifyContent: "space-between"

            }}
           >
           <div
            className="option-input-header"
            style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "18px",
                color: "#333333"
            }}>
                Score
            </div>
            {props.index === 0 ?
                <div>
                    <TextWithInfo 
                    title={
                        <>
                        <p>The score assigned to a option</p>
                        </>
                    }
                    >
                    </TextWithInfo>
                </div> :
                null  
            }
           </div>
           <Input
                type="number"
                style={{
                background: "#E7F1F7",
                borderRadius: "4px"
                }}
                value={value}
                max={5}
                min={0}
                onInput={(e) => {
                    let val = e.target['value']
                    if(parseInt(val) > 5 || parseInt(val) < 0){
                        alert("invalid score")
                        return 
                    }
                    setValue(parseInt(val))
                    props.onInput(props.index, val, null, false, true)
                    } 
                }
            />
           {/* <div>
           <input
            style={{
            background: "#E7F1F7",
            borderRadius: "4px"
            }}
            type="number" onChange={(e)=>{
               console.log(e.target)
               setValue(parseInt(e.target.value))
               props.onInput(props.index, value, null, false, true)
           }} name="score" value={value} />
           </div> */}
        </div>
        </>
    )
}