import {  useEffect, useMemo } from "react";
import React, { useState } from 'react'
import ReactQuill, { Quill } from "react-quill";
import { PROCESSOR_S3_URL, PROCESSOR_URL, S3_URL } from "../../../../utils/constants";
import { ArrayInputMetaForm } from "./ArrayInputMetaForm";


const RiasecQuizMetaForm = (props: any) => {   
    const uploadFiles = (uploadFileObj: any, element: string, index: any = null) => {
      const formdata = new FormData()
      formdata.append("file", uploadFileObj)
      fetch(`${PROCESSOR_URL}quiz/upload`, {
        method: "POST",
        body: formdata
      })
      .then(res=>res.json())
      .then(data=>{
        if(data['data']['key']){
          const res = PROCESSOR_S3_URL + data['data']['key']
          let newFormValues = {...props.quizMeta};
          // @ts-ignore: Object is possibly 'null'.
          if(index === null){
            newFormValues[element] = {...newFormValues[element], logo: res};
          }else{
            if(Array.isArray(newFormValues[element]?.interest_areas_clusters)){
              newFormValues[element].interest_areas_clusters[index] = {...newFormValues[element].interest_areas_clusters[index], icon: res}
            }
            else{
              newFormValues[element] = {...newFormValues[element], interest_areas_clusters: [{icon: res}]}
            }
          }
          props.onChange(newFormValues);
          alert("Image uploaded successfully")
        }else{
          return  Promise.reject(500)
        }
      })
      .catch(err=>{
        console.log(err)
        alert("Failed to upload image. Please try again.")
      })
    }
    const riasecHeading = ['r', 'i', 'a', 's', 'e', 'c']
    const imageHandler = (e: any, element: string, index: any = null) => {
      const image = e.target.files[0]
      uploadFiles(image, element, index)
    };
    return (
        <div>
          <label>Quiz Meta</label>
          {riasecHeading.map((element: any, index: any) => (          
            <div className="form-inline" key={index}>
              <br />
              <div>
                For trait type {element}
              </div>
              <div style={{border: "3px solid black", padding: "10px"}}>
              <h3>Title </h3>
              <input type="text" name="title" value={props.quizMeta[element]?.title} onChange={(e: any) => {
                let newFormValues = {...props.quizMeta};
                // @ts-ignore: Object is possibly 'null'.
                newFormValues[element] = {...newFormValues[element], title: e.target.value};
                props.onChange(newFormValues);
              }}
              />
              
              <h3>Description </h3>
              <input type="text-area" name="description" value={props.quizMeta[element]?.description} onChange={(e: any) => {
                let newFormValues = {...props.quizMeta};
                // @ts-ignore: Object is possibly 'null'.
                newFormValues[element] = {...newFormValues[element], description: e.target.value};
                props.onChange(newFormValues);
              }}/>

              <h3>Someone who </h3>
               <ArrayInputMetaForm 
                name='someone_who'
                value={props.quizMeta[element]?.someone_who}
                parentCallback = {(childData) => {
                  let newFormValues = {...props.quizMeta};
                  // @ts-ignore: Object is possibly 'null'.
                  newFormValues[element] = {...newFormValues[element], someone_who: childData};
                  props.onChange(newFormValues);
                }}
              />

              <h3>Avoid </h3>
              <ArrayInputMetaForm 
                name='avoid'
                value={props.quizMeta[element]?.avoid}
                parentCallback = {(childData) => {
                  let newFormValues = {...props.quizMeta};
                  // @ts-ignore: Object is possibly 'null'.
                  newFormValues[element] = {...newFormValues[element], avoid: childData};
                  props.onChange(newFormValues);
                }}
              />

              <h3>Values </h3>
              <ArrayInputMetaForm 
                name='values'
                value={props.quizMeta[element]?.values}
                parentCallback = {(childData) => {
                  let newFormValues = {...props.quizMeta};
                  // @ts-ignore: Object is possibly 'null'.
                  newFormValues[element] = {...newFormValues[element], values: childData};
                  props.onChange(newFormValues);
                }}
              />

              <h3>Icon </h3>
              <input type="file" name="icon"  onChange={(e: any) => {
                imageHandler(e, element)
              }}/>
              <br/>
              <br/>
              
              <h2>Interest areas: </h2>
              <h3>Interest areas 1 icon</h3>
              <input type="file" name="intereset_areas_1_icon"  onChange={(e: any) => {
                imageHandler(e, element, 0)
              }}/>

              <h3>Interest areas 1 description</h3>
  
              <input type="text-area" name="intereset_areas_1_description"
                value={props.quizMeta[element]?.interest_areas_clusters ? props.quizMeta[element]?.interest_areas_clusters[0]?.description : ""}
                onChange={(e: any) => {
                  let newFormValues = {...props.quizMeta};
                  // @ts-ignore: Object is possibly 'null'.
                  if(Array.isArray(newFormValues[element]?.interest_areas_clusters)){
                    newFormValues[element].interest_areas_clusters[0] = {...newFormValues[element].interest_areas_clusters[0], description: e.target.value}
                  }
                  else{
                    newFormValues[element] = {...newFormValues[element], interest_areas_clusters: [{description: e.target.value}]}
                  }
                  props.onChange(newFormValues);
                }
              }/>

              <br/>
              <br/>

              <h3>Interest areas 2 icon</h3>
              <input type="file" name="intereset_areas_2_icon"  onChange={(e: any) => {
                imageHandler(e, element, 1)
              }}/>

              <h3>Interest areas 2 description</h3>
              <input type="text-area" name="intereset_areas_2_description"
                value={props.quizMeta[element]?.interest_areas_clusters ? props.quizMeta[element]?.interest_areas_clusters[1]?.description : ""}
                onChange={(e: any) => {
                  let newFormValues = {...props.quizMeta};
                  // @ts-ignore: Object is possibly 'null'.
                  if(Array.isArray(newFormValues[element]?.interest_areas_clusters)){
                    newFormValues[element].interest_areas_clusters[1] = {...newFormValues[element].interest_areas_clusters[1], description: e.target.value}
                  }
                  else{
                    newFormValues[element] = {...newFormValues[element], interest_areas_clusters: [{description: e.target.value}]}
                  }
                  props.onChange(newFormValues);
                }
              }/>
              
              <br/>
              <br/>
              <h3>Interest areas 3 icon</h3>
              <input type="file" name="intereset_areas_3_icon"  onChange={(e: any) => {
                imageHandler(e, element, 2)
              }}/>


              <h3>Interest areas 3 description</h3>
              <input type="text-area" name="intereset_areas_3_description"
                value={props.quizMeta[element]?.interest_areas_clusters ? props.quizMeta[element]?.interest_areas_clusters[2]?.description : ""}
                onChange={(e: any) => {
                  let newFormValues = {...props.quizMeta};
                  // @ts-ignore: Object is possibly 'null'.
                  if(Array.isArray(newFormValues[element]?.interest_areas_clusters)){
                    newFormValues[element].interest_areas_clusters[2] = {...newFormValues[element].interest_areas_clusters[2], description: e.target.value}
                  }
                  else{
                    newFormValues[element] = {...newFormValues[element], interest_areas_clusters: [{description: e.target.value}]}
                  }
                  props.onChange(newFormValues);
                }
              }/>

              <br/>
              <br/>
              <h3>Interest areas 4 icon</h3>
              <input type="file" name="intereset_areas_4_icon"  onChange={(e: any) => {
                imageHandler(e, element, 3)
              }}/>


              <h3>Interest areas 4 description</h3>
              <input type="text-area" name="intereset_areas_4_description" value={props.quizMeta[element]?.interest_areas_clusters ? props.quizMeta[element]?.interest_areas_clusters[3]?.description : ""} onChange={(e: any) => {
                let newFormValues = {...props.quizMeta};
                // @ts-ignore: Object is possibly 'null'.
                if(Array.isArray(newFormValues[element]?.interest_areas_clusters)){
                  newFormValues[element].interest_areas_clusters[3] = {...newFormValues[element].interest_areas_clusters[3], description: e.target.value}
                }
                else{
                  newFormValues[element] = {...newFormValues[element], interest_areas_clusters: [{description: e.target.value}]}
                }
                props.onChange(newFormValues);
              }}/>
              </div>


            </div>
          ))}
          

      </div>
    )
}

export default RiasecQuizMetaForm