// @ts-nocheck
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import manojLogo from "../../../../src/assets/mentors/skillcircler.png";
import jagmohanMentor from "../../../../src/assets/mentors/Cdr. Jagmohan Bhogal.png";
import dhairyaMentor from "../../../../src/assets/mentors/dhairyaMentor.png";
import deeptiLogo2 from "../../../../src/assets/mentors/QCI-Logo 1.png";
import aryaMentor from "../../../../src/assets/mentors/aryaMentor.png";
import {
  default as sajalLogo2,
  default as sumitLogo2,
} from "../../../../src/assets/mentors/chegg.png";
import vijayMentor from "../../../../src/assets/mentors/vijay.png";
import nilayLogo1 from "../../../../src/assets/mentors/Indian_Navy_logo.png";
import nilayLogo2 from "../../../../src/assets/mentors/NilaySecLogo.png";
import piyushMentor from "../../../../src/assets/mentors/piyush.png";
import piyushLogo from "../../../../src/assets/mentors/VeriSmart.png";
import {
  default as piyushLogo1,
  default as rohanLogo1,
} from "../../../../src/assets/mentors/DTU.png";
import sumitLogo1 from "../../../../src/assets/mentors/dell.png";
import rohanLogo from "../../../../src/assets/mentors/Microsoft1.jpg";
import AbhinavMentor from "../../../../src/assets/mentors/Abhinav.png";
import divyaMentor from "../../../../src/assets/mentors/divya.png";
import sajalLogo1 from "../../../../src/assets/mentors/P&G_logo.png";
import MaryMentor from "../../../../src/assets/mentors/Mary.png";
import whit from "../../../../src/assets/mentors/pracup.png";
import sumitMentor from "../../../../src/assets/mentors/SumitAgrawal_Pic.png";
import AbhinavMentorLogo from "../../../../src/assets/mentors/ISB.jpg";
import AbhinavMentorLogo2 from "../../../../src/assets/mentors/telstar.png";
import dhairyaMentorlogo from "../../../../src/assets/mentors/kpmg.png";
import dhairyaMentorlogo2 from "../../../../src/assets/mentors/josh talks.png";

export default function ExpertSwipe() {
  const [screenWidth, setScreenWidth] = useState(0);
  const [readMore, setReadMore] = useState(-1);
  useEffect(() => {
    let width = window.innerWidth;
    setScreenWidth(width);
  }, []);
  const PAGES_S3_URL = "https://d2b560u3hlgvw0.cloudfront.net";
  const settings = {
    dots: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 324,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="same-slider-class-arrow right-arrow" onClick={onClick}>
        <svg
          width="11"
          height="15"
          viewBox="0 0 11 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.81836 1.53516L8.28296 9L1.81836 16.4646"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="same-slider-class-arrow left-arrow" onClick={onClick}>
        <svg
          width="11"
          height="15"
          viewBox="0 0 11 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.2793 1.53516L2.8147 9L9.2793 16.4646"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </div>
    );
  }

  const allMetorItems = [
    {
      mentorImg: AbhinavMentor,
      mentorName: "Abhinav Mahadevan",
      desc: "Delivery Manager with a track record of successful multi-million dollar project implementations for 14 years",
      mentorSubName:
        "Agile Coach- Telstra | MBA from ISB, Seasoned IT Program ",
      logoImgMentor: [AbhinavMentorLogo, AbhinavMentorLogo2],
    },
    {
      mentorImg: MaryMentor,
      mentorName: "Mary Tressa Gabriel",
      desc: "Supporting individuals in obtaining  entry-level project management positions.",
      mentorSubName:
        "Project Manager at Dell | Tools like: S-Suite|ASANA|Notion",
      logoImgMentor: [sumitLogo1, sumitLogo2],
    },

    {
      mentorImg: piyushMentor,
      mentorName: "Piyush Gupta",
      desc: "Founder, Career Mentor, and Product Management Expert with 7+ years running tech-driven businesses, Co-founder verismart.ai",
      mentorSubName: "Co-founder Stride Ahead",
      logoImgMentor: [piyushLogo, piyushLogo1],
    },
    {
      mentorImg: dhairyaMentor,
      mentorName: "Dhairya Gangwani",
      desc: "Guides and mentors leaders, entrepreneurs, industry experts, & Brands with building a personal brand for themselves on LinkedIn and generating more leads as well.",
      mentorSubName: "Career Coach | Public Speaker | Personal Branding",
      logoImgMentor: [dhairyaMentorlogo, dhairyaMentorlogo2],
    },
    {
      mentorImg: aryaMentor,
      mentorName: "Lovely Arya",
      mentorSubName: "Communication & Soft Skills Coach",
      desc: "Coaches Young Professionals to Master Confidence via Communication, Soft Skills & Personality Development.",
      logoImgMentor: [whit, manojLogo],
    },
    {
      mentorImg: divyaMentor,
      mentorName: "Divvya Bhauwala",
      desc: "Consulted more than 3000 early professionals with Image enhancing and Personality building",
      mentorSubName: "Soft-Skills Trainer | Image Consultant",
      logoImgMentor: [rohanLogo],
    },
    {
      mentorImg: vijayMentor,
      mentorName: "Vijay Kumar Gupta",
      desc: "13+ Years of Management Training and 30+ Years of Managerial Experience",
      mentorSubName: "Management Trainer | Motivational Speaker",
      logoImgMentor: [nilayLogo2],
    },
    {
      mentorImg: jagmohanMentor,
      mentorName: "Jagmohan Bhogal",
      desc: "Emotional Intelligence Trainer and Coach,Ex Navy and Quality council of India",
      mentorSubName: "Founder ThinkInfluence Academy",
      logoImgMentor: [nilayLogo1, deeptiLogo2],
    },
    {
      mentorImg: sumitMentor,
      mentorName: "Sumit Agarwal",
      desc: "Helped 7000+ MBA students with acing their careers into new heights",
      mentorSubName:
        "Director at Chrolochem pvt ltd | Ex Microsoft and P&G | Mba from XLRI",
      logoImgMentor: [rohanLogo, sajalLogo1],
    },
  ];

  return (
    <div className="classess-banner">
      <div className="container-md">
        <div className="classess-page-title">
          <h1>Classess taught by Industry Experts</h1>
          <p>
            Our Mentors are handpicked and rigorously vetted so that you get the
            best of the best. Here are a few of our reputed and highly qualified
            Industry Expert Mentors.
          </p>
        </div>
        <div className="slider-section-design">
          <Slider {...settings}>
            {allMetorItems?.map((perMentorItems, index) => {
              return (
                <div key={index}>
                  <div className="slider-section-card">
                    <div className="slider-image">
                      <img src={perMentorItems.mentorImg} alt="CardImage" />
                    </div>
                    <div className="slider-card-details">
                      <h5>{perMentorItems.mentorName}</h5>
                      <p>{perMentorItems.mentorSubName}</p>
                      {screenWidth > 786 ? (
                        <span>
                          {readMore === index ? (
                            <>
                              {perMentorItems.desc}{" "}
                              <a
                                onClick={() => setReadMore(-1)}
                                style={{
                                  fontSize: "12px",
                                  color: "rgb(15, 11, 203)",
                                  fontWeight: "700",
                                }}
                              >
                                ...Read less
                              </a>
                            </>
                          ) : (
                            <>
                              {perMentorItems?.desc
                                ?.split(" ")
                                ?.slice(0, 8)
                                ?.join(" ")}{" "}
                              {perMentorItems?.desc?.split(" ").length > 8 ? (
                                <a
                                  onClick={() => setReadMore(index)}
                                  style={{
                                    fontSize: "12px",
                                    color: "rgb(15, 11, 203)",
                                    fontWeight: "700",
                                  }}
                                >
                                  ...Read more
                                </a>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </span>
                      ) : (
                        <span>
                          {readMore === index ? (
                            <>
                              {perMentorItems.desc}{" "}
                              <a
                                onClick={() => setReadMore(-1)}
                                style={{
                                  fontSize: "12px",
                                  color: "rgb(15, 11, 203)",
                                  fontWeight: "700",
                                }}
                              >
                                ...Read less
                              </a>
                            </>
                          ) : (
                            <>
                              {perMentorItems?.desc
                                ?.split(" ")
                                ?.slice(0, 8)
                                ?.join(" ")}{" "}
                              {perMentorItems?.desc?.split(" ").length > 8 ? (
                                <a
                                  onClick={() => setReadMore(index)}
                                  style={{
                                    fontSize: "12px",
                                    color: "rgb(15, 11, 203)",
                                    fontWeight: "700",
                                  }}
                                >
                                  ...Read more
                                </a>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </span>
                      )}
                      {/* <span>{perMentorItems?.desc}</span> */}
                      <div className="slider-card-image-alignment">
                        {perMentorItems.logoImgMentor?.map((img) => (
                          <div>
                            <img src={img} alt="GoogleIcon" />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="get-started-button-center-alignment">
          <a href="#payment">
            <button>Take me there </button>
          </a>
        </div>
      </div>
    </div>
  );
}
