// @ts-nocheck
import { report } from "process";
import Lottie from "react-lottie";
import React, { useEffect, useRef, useState } from "react";
import LeadSquaredForm from "../../components/forms/LeadSquaredForm";
import DisappointedEmoji from "../../../assets/Disappointed-emoji.json";
import BigSmileyEmoji from "../../../assets/Big-smiley-emoji.json";
import BlackShadesEmoji from "../../../assets/Black-shades-emoji.json";
import HeartyEyesEmoji from "../../../assets/Heart-eyes-emoji.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Modal } from "antd";
import CustomLoader from "../../../assets/customLoader.gif";

let currentQuestion;
const sliderSettings = {
  className: "center",
  centerMode: true,
  infinite: true,
  speed: 500,
  centerPadding: "60px",
  arrows: true,
  slidesToShow: 1,
  afterChange: function (index) {
    currentQuestion.current.innerHTML = index + 1;
  },
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export default function AptitudeReportPage(props: any) {
  const show_correct_answer = props.reportData.show_correct_answer;
  const reportData = show_correct_answer ? props.reportData.content?.result : [];
  const totalQuestionsAttempted = props.reportData.content?.total_attempted_questions;
  const correctAnswers = props.reportData.content?.correct_answers_count
  const incorrectAnswers = props.reportData.content?.incorrect_answers_count
  const score = props.reportData.content?.score
  const timeTaken = props.reportData.time_taken
  const quiz_name = props.reportData.quiz_name
  const [hours, minutes, seconds] = timeTaken ? timeTaken.split(':') : [null, null, null];
  const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);
  const avgTimeTaken = Math.ceil(totalSeconds / totalQuestionsAttempted);
  const slickRef = useRef<Slider>(null);
  const [modalVisiblity, setModalVisility] = useState(false);
  const modalQuestion = useRef({});
  currentQuestion = useRef(null);
  const hasImage = htmlString => {
    var div = document.createElement('div');
    div.innerHTML = htmlString

    return Boolean(div.querySelector('img'));
  }
  const getModalData = () => {
    if (modalQuestion.current['questions']) {
      return (
        <>
          <div className="aptitude-quiz-question-wrapper">
            <div className="aptitude-quiz-question" dangerouslySetInnerHTML={{ __html: modalQuestion.current['questions'].en }} />
            <div className="universal-quiz-answers"
              style={{
                padding: "20px"
              }}
            >
              {modalQuestion.current['options'].map((option: any) => {
                const radioInput = <div className={modalQuestion.current['correct_answer'] == option.value ? "correct-answer" :
                  modalQuestion.current['user_response'] == option.value ? "user-answer" : undefined}>
                  <input type="radio" disabled value={option.value} />
                  <label>{option.value}</label>
                </div>
                return radioInput
              })}
            </div>
          </div>
        </>
      )
    } else {
      return undefined
    }
  }
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: score >= 90 ? HeartyEyesEmoji : score < 90 && score > 60 ? BlackShadesEmoji : score <= 60 && score > 40 ? BigSmileyEmoji : DisappointedEmoji,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const getHeaderStyles = () => {
    const baseStyle = {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "48px",
      lineHeight: "48px",
    }
    if (score > 60) {
      return { ...baseStyle, color: "#00C853" }
    }
    else if (score <= 60 && score > 40) {
      return { ...baseStyle, color: "#FFD600" }
    }
    else {
      return { ...baseStyle, color: "#E92E30" }
    }
  }

  return (
    <>
      {props.reportData.status === 200 ?
        <div id="aptitude-quiz-report-wrapper" className="aptitude-report-page-wrapper"
          style={{
            paddingTop: "150px",
          }}
        >
          <div
            id="aptitude-quiz-content"
            className="aptitude-quiz-summary aptitude-quiz-report-summary"
            style={{
              position: "relative",
              width: "810px",
              height: "fit-content",
              maxWidth: "60%",
              background: "#fff",
              margin: "0 auto",
              textAlign: "center",
              boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.16)",
              borderRadius: "20px",
              padding: "83px 0"
            }}
          >
            <div className="aptitude-quiz-emoji"
              style={{
                background: "#ffffff",
                borderRadius: "50%",
                position: "absolute",
                top: "0",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100px",
                padding: "10px",
                boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12);",
              }}
            >
              <Lottie options={lottieOptions} />
            </div>
            <div className="aptitude-quiz-summary-header"
              style={{
                paddingBottom: "32px",
              }}
            >
              <div
                style={getHeaderStyles()}
              >
                {correctAnswers * 2} Points
              </div>
              <div
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "24px",
                  lineHeight: "24px",
                  color: "#4F4F4F"
                }}
              >
                {score > 40 ? "Congratulations!" : "You can do better!"}
              </div>
            </div>
            <div className="aptitude-quiz-report-summary"
              style={{
                display: "grid",
                gridTemplateColumns: "150px 1px 150px",
                justifyContent: "center",
                columnGap: "80px",
                rowGap: "48px",
                justifyItems: "center",
                alignItems: "center",
              }}>
              <div className="universal-quiz-instruction-wrapper">
                <div className="universal-quiz-instruction">
                  <div className="universal-quiz-instruction-title">{correctAnswers}</div>
                </div>
                <div className="universal-quiz-instruction-info">Correct Answers</div>
              </div>

              <div className="vertical-line-150"></div>
              <div className="vertical-line-100"></div>

              <div className="universal-quiz-instruction-wrapper">
                <div className="universal-quiz-instruction">
                  <div className="universal-quiz-instruction-title">{incorrectAnswers}</div>
                </div>
                <div className="universal-quiz-instruction-info">Incorrect Answers</div>
              </div>

              <div className="horizontal-line-150"></div>
              <div className="spacer-desktop"></div>
              <div className="horizontal-line-150"></div>
              <div className="horizontal-line-100"></div>
              <div className="spacer-mobile"></div>
              <div className="horizontal-line-100"></div>

              <div className="universal-quiz-instruction-wrapper">
                <div className="universal-quiz-instruction">
                  <div className="universal-quiz-instruction-title">{totalQuestionsAttempted}</div>
                </div>
                <div className="universal-quiz-instruction-info">Questions Attempted</div>
              </div>

              <div className="vertical-line-150"></div>
              <div className="vertical-line-100"></div>

              <div className="universal-quiz-instruction-wrapper">
                <div className="universal-quiz-instruction">
                  <div className="universal-quiz-instruction-title">
                    {Number.isInteger(avgTimeTaken) ? avgTimeTaken : 0}s
                  </div>
                </div>
                <div className="universal-quiz-instruction-info">Average Time Taken</div>
              </div>

            </div>
            <div>
              <div>

              </div>
            </div>
          </div>
          {Object.keys(reportData).length > 0 ? <div className="aptitude-quiz-report-question-counter">
            Question <span className='current' ref={currentQuestion} style={{ fontSize: "16px", fontWeight: 600 }}>1</span>/{totalQuestionsAttempted}
          </div> : null}
          <div style={{ display: "block" }} className="aptitude-quiz-report-slider">
            <Slider ref={slickRef} {...sliderSettings} className="slider-wrapper">
              {
                Object.keys(reportData).length > 0 ?
                  Object.keys(reportData).map(key =>
                    <>
                      <div className="aptitude-quiz-question-wrapper">
                        <div className="aptitude-quiz-question" dangerouslySetInnerHTML={{ __html: reportData[key].questions.en }} />
                        <div className={
                          hasImage(reportData[key].questions.en) ?
                            "aptitude-quiz-answers-image universal-quiz-answers" : "aptitude-quiz-answers-text universal-quiz-answers"
                        }>
                          {reportData[key].options.map((option: any) => {
                            const radioInput = <div className={reportData[key].correct_answer == option.value ? "correct-answer" :
                              reportData[key].user_response == option.value ? "user-answer" : undefined}>
                              <input type="radio" disabled value={option.value} />
                              <label>{option.value}</label>
                            </div>
                            return radioInput
                          })}
                        </div>
                        {
                          hasImage(reportData[key].questions.en) ?
                            <div className="aptitude-quiz-reveal-answer-button">
                              <Button
                                style={{
                                  color: "#fff",
                                  background: "#1273B7",
                                  width: "100%",
                                  marginRight: "",
                                }}
                                onClick={(e) => {
                                  setModalVisility(true);
                                  modalQuestion.current = reportData[key]
                                }}
                              >
                                Click to review
                              </Button>
                            </div> :
                            null
                        }
                      </div>
                    </>
                  ) : <>

                  </>
              }
            </Slider>
          </div>
          <Modal
            closable={false}
            width={"80%"}
            style={{ top: 20 }}
            visible={modalVisiblity}
            onCancel={() => {
              setModalVisility(false)
            }
            }
            destroyOnClose={true}
            footer={null}
          >
            {getModalData()}
          </Modal>
        </div>
        :
        props.reportData.status === 428 ?
          <div id="lead-squared-form-wrapper" style={{ margin: "0 auto", padding: "20px" }}>
            <LeadSquaredForm
              source={`${quiz_name} quiz page`}
              product={quiz_name}
            />
          </div>
          :
          props.reportData.status === 202 ?
            <div className="invalid-report-id">
              <img src={CustomLoader} />
            </div>
            :
            <div className="invalid-report-id">
              <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" alt="loader_gif" />
            </div>
      }
    </>
  )
}