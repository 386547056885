import { ChangeEvent, FocusEvent, FormEvent, useState } from 'react';
import {
  APIError,
  ErrorType,
  FormData,
  FormFields,
  ValidationSchema,
} from '../utils/types';

const validateEntries = (
  schema: ValidationSchema,
  data: { key: FormFields; value: string }
): boolean | string => {
  const validatorForCurrentField = schema[data.key];

  if (validatorForCurrentField?.required && data.value === '') {
    return `${validatorForCurrentField.name} is required`;
  } else if (validatorForCurrentField?.regex) {
    const isValid = validatorForCurrentField?.regex.test(data.value);

    if (!isValid) {
      return validatorForCurrentField?.errorText as string;
    }
  }
  return false;
};

const useForm = ({
  actions: { onSubmit },
  validatorSchema,
  handleValidationOnBlur,
  validatePhoneNumber=true,
}: {
  actions: {
    onSubmit: any;
    onBlur?: any;
  };
  validatorSchema?: ValidationSchema;
  handleValidationOnBlur: boolean;
  validatePhoneNumber?: boolean;
}) => {
  const [isFormActive, setFormActive] = useState(false);
  const [formData, setFormData] = useState<FormData>({});
  const [formSubmitResponse, setFormSubmitResponse] =
    useState<{
      status: 'success' | 'fail';
      message: string;
    }>();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    setFormActive(true);
    const fieldName = e.target.name as FormFields;

    setFormData({
      ...formData,
      [fieldName]: { value: e.target.value, error: '' },
    });
  };

  const parseFormData = (data: FormData) => {
    const serializedFormData: any = {};

    const keys = Object.keys(data) as FormFields[];

    keys.forEach((key) => {
      serializedFormData[key] = data[key]?.value;
    });

    return serializedFormData;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>,training_id?:any) => {
    e.preventDefault();
    let errorLabel = document.getElementById('error_input_field')  as HTMLElement;
    errorLabel?errorLabel.remove():console.log("not present")
        const inputFields = document.querySelectorAll<HTMLInputElement>('input[name$="_phone_number"]');
        const values: string[] = [];
        const id: string[] = [];
        console.log(inputFields)
        for (let i = 0; i < inputFields.length; i++) {
          if (training_id!==null){
            console.log("HII")
            console.log(validatePhoneNumber)
            console.log(training_id)
            if (validatePhoneNumber && (!/^[0-9]+$/.test(inputFields[i].value)|| inputFields[i].value.length<9)){
                let errorField = document.getElementById(inputFields[i].id) as HTMLElement;
                console.log(errorField)
                const node = document.createElement("label")
                node.id = "error_input_field"
                node.append("Mob. number should not be less than 9, remove 0 or  country code");
                node.style.color = "red"
                errorField?.after(node)
                return
              }
          }
          else{
            if (validatePhoneNumber && !/^[0-9]+$/.test(inputFields[i].value)||inputFields[i].value.length>10 || inputFields[i].value.length<10){
                let errorField = document.getElementById(inputFields[i].id) as HTMLElement;
                const node = document.createElement("label")
                node.id = "error_input_field"
                node.append("Mob. number should have only 10 digits, remove 0 or  country code");
                node.style.color = "red"
                errorField?.after(node)
                return
              }
          }

            values.push(inputFields[i].value);
            id.push(inputFields[i].id)
            
        }
        console.log(values);
        console.log(id);
        console.log(formData);
    const submitResponse = (await onSubmit(parseFormData(formData))) as
      | APIError[]
      | string
      | void;

    const localFormData = { ...formData };

    if (submitResponse) {
      if (typeof submitResponse === 'string') {
        setFormSubmitResponse({ status: 'success', message: submitResponse });
      } else {
        submitResponse.forEach((error) => {
          const key = Object.keys(error)[0] as ErrorType;
          const value = error[key] as string;

          if (key === 'form_level_error') {
            setFormSubmitResponse({ status: 'fail', message: value });
          } else {
            const fieldData = localFormData[key];

            if (fieldData) {
              localFormData[key] = { ...fieldData, error: value };
            }
          }
        });
      }
      setFormData(localFormData);
    }
  };

  const handleBlur = (
    e: FocusEvent<HTMLInputElement> | FocusEvent<HTMLSelectElement>
  ) => {
    if (isFormActive && handleValidationOnBlur && validatorSchema) {
      const key = e.target.name as FormFields;
      const value = e.target.value;
      const isErrorOrValid = validateEntries(validatorSchema, { key, value });

      if (typeof isErrorOrValid === 'string') {
        setFormData({
          ...formData,
          [key]: { ...formData[key], error: isErrorOrValid },
        });
      }
    }
  };

  return {
    formData,
    formSubmitResponse,
    setFormData,
    setFormActive,
    handleChange,
    handleBlur,
    handleSubmit,
  };
};

export default useForm;
