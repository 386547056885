// @ts-nocheck
import { Quiz } from "../../../App";
import animationData from "../../../assets/Check-mark-completed.json";
import React, { useEffect } from "react";
import { PROCESSOR_S3_URL, S3_URL } from "../../../utils/constants";

export default function EmployabilityQuizPage(props: any) {

  const renderPopUp = props.renderPopUp;
  const submitNow = props.submitNow;
  const quizTypeName = props.quizTypeName;
  const questionNo = props.questionNo;
  const finalQuestionNo = props.finalQuestionNo;
  const handleNext = props.handleNext;
  const handlePrev = props.handlePrev;
  const handleQuizSubmit = props.handleQuizSubmit;
  const quizQuestions = props.quizQuestions;

  useEffect(()=>{
    let bar = document.querySelector(".progress-bar > div > div") as any;
    if(questionNo===1 && bar){
      bar.style.border = "none";
    }
  })
  
  if(renderPopUp){
    let text;
    text = document.querySelector("#universal-quiz-type-progress > div.progress-bar > div > div > span") as any;
    text.style.color = "#ffffff"
  }
  return (
    <div className="universal-quiz-page-wrapper">
    <div id="content">
        <Quiz
            data={quizQuestions}
            onSubmit={handleQuizSubmit}
            questionNo={questionNo}
            handleNext={handleNext}
            renderPopUp={renderPopUp}
            submitNow={submitNow}
            quizTypeName={quizTypeName}
        />
        </div>
        <div className="universal-quiz-page-footer">
          <img src={PROCESSOR_S3_URL + 'staging/ASSESSMENT_PROCESSOR/YI9OKN_Footer-circles.png'} />
        </div>
    </div>
  )
}