import React, { useEffect, FC, useState } from 'react';
import styled from 'styled-components';
import { blink } from '../../styles/animations';
import Flex from './Flex';

const Timer: FC<{ time: string; onCompletion?: any }> = ({
	time,
	onCompletion,
}) => {
	const timeArray = time.split(':');
	const [hours, setHours] = useState<number>(+timeArray[0]);
	const [minutes, setMinutes] = useState<number>(+timeArray[1]);
	const [seconds, setSeconds] = useState<number>(+timeArray[2]);

	useEffect(() => {
		let myInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			} else {
				if (minutes === 0) {
					if (hours === 0) {
						clearInterval(myInterval);
						onCompletion();
					} else {
						setHours(hours - 1);
						setMinutes(59);
						setSeconds(59);
					}
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000);

		return () => {
			clearInterval(myInterval);
		};
	});

	const getClass = () => {
		return hours === 0 ? (minutes < 5 ? 'blink' : '') : '';
	};
	return (
		<Container className={getClass()}>
			<h2>
				{hours < 10 ? `0${hours}` : hours}
				<Seperator>:</Seperator>
				{minutes < 10 ? `0${minutes}` : minutes}
				<Seperator>:</Seperator>
				{seconds < 10 ? `0${seconds}` : seconds}
			</h2>
		</Container>
	);
};

export default Timer;

const Container = styled(Flex)`
	&.blink {
		animation: ${blink} 2s linear infinite;
	}
`;

const Seperator = styled.span`
	margin: 0 0.2rem;
`;
