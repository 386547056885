import { red } from '@material-ui/core/colors';
import styled from 'styled-components';
import Flex from '../../components/Flex';
import React, { FC, useEffect, useState } from 'react';
import { ReportMetadata, TestDetail } from '../../../utils/types';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

import './careerTooltip.css';
import { CAREER_TOOLTIP } from '../../../utils/constants';

import rightIcon from '../../../assets/images/rightIcon.png';
import Button from '../../components/Button';
import CareerComp from '../../components/Report Section/CareerComp';

const CareerAndPersonalities: FC<{ reportMetadata: ReportMetadata, test: TestDetail }> = ({
	reportMetadata, test
}) => {
	const [jobRoles, setJobRoles] = useState<any[]>([]);
	const [active, setActive] = useState(-1);

	const handleClick = (data: any, index: number) => {
		setJobRoles([...data]);
		setActive(index);
	};

	const getWidth = () => {
		const { innerWidth: width } = window;
		return width;
	};

	useEffect(() => {
		window.addEventListener('resize', getWidth);
		setWindowWidth(getWidth());
	}, []);

	const [windowWidth, setWindowWidth] = useState(getWidth());

	return (
		<CareerAndPersonalitiesContainer column>
			<Heading>
				Career :
				<p data-tooltip={CAREER_TOOLTIP} className='career-tooltip'>
					<BsFillQuestionCircleFill
						className='info-icon'
						style={{ marginLeft: '1rem' }}
					/>
				</p>
			</Heading>

			<CareerContainer>
				{reportMetadata?.professions?.map((profession, index) => {
					return (
						<CareerComp
							test={test}
							key={`${profession.name}-${index}`}
							onClick={() => handleClick(profession.job_roles, index)}
							profession={profession}
							index={index}
							active={active}
							jobRoles={jobRoles}
						/>
					);
				})}
			</CareerContainer>

			<Heading style={{ width: '100%' }}>
				Matching famous personalities:
			</Heading>
			<PersonalitiesContainer>
				{reportMetadata?.famous_personalities?.map((personalities, index) => {
					return (
						<Personalities key={`${personalities.name}-${index}`}>
							<img src={personalities.image} alt={personalities.name} />
							<Flex column>
								<h2>{personalities.name}</h2>
								<span>{personalities.description}</span>
							</Flex>
						</Personalities>
					);
				})}
			</PersonalitiesContainer>
		</CareerAndPersonalitiesContainer>
	);
};

export default CareerAndPersonalities;

const CareerAndPersonalitiesContainer = styled(Flex)`
	font-family: Roboto;
	flex: 1;
	width: 100%;
	background: ${({ theme }) => theme.colors.white};
	padding: 3.2rem 5.3rem;
	border-radius: 10px;
	@media only screen and (max-width: 1024px) {
		padding: 1rem;
	}
`;

const CareerContainer = styled(CareerAndPersonalitiesContainer)`
	display: flex;

	padding: 0;
	margin-left: 0;
	color: ${({ theme }) => theme.colors.blue[500]};
	flex-direction: row;
	/* background-color: red; */
	/* width: 100%; */
	flex-wrap: wrap;

	div {
		padding-bottom: 0.5rem;
	}
	img {
		margin: 0 2.4rem;
	}

	@media screen and (max-width: 1024px) {
		flex-direction: column;
		margin: 0;
		padding: 0;
		font-size: 1.4rem;
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;
		margin: 0;
		padding: 0;
		font-size: 1.4rem;
		div {
			padding-bottom: 0;
			/* width: 100%; */
			/* padding: 1rem 1rem; */
			/* height: fit-content; */
		}
	}
`;

const PersonalitiesContainer = styled(Flex)`
	flex-wrap: wrap;
`;

const Personalities = styled(Flex)`
	flex: 1 1 40%;
	padding: 1.2rem;
	margin-bottom: 2.4rem;
	margin-right: 2.4rem;
	background: #f8faff;
	font-weight: normal;
	font-size: 1.6rem;
	line-height: 19px;
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.grey[400]};
	img {
		width: 5rem;
		height: 5rem;
		margin: 1rem;
	}

	h2 {
		font-weight: 600;
		line-height: 150%;
		font-size: 1.6rem;
		/* Grey 2 */
		color: ${({ theme }) => theme.colors.grey[500]};
	}

	@media screen and (max-width: 1024px) {
		flex: 1 1 100%;
		h2 {
			margin-left: 1.6rem;
		}
		span {
			font-size: 1.4rem;
			margin-left: 1.6rem;
		}
	}
`;

const Heading = styled(Flex)`
	font-weight: 500;
	font-size: 2rem;
	/* line-height: 150%; */
	color: ${({ theme }) => theme.colors.grey[400]};
	margin: 1.6rem 0;
	@media screen and (max-width: 1024px) {
		font-weight: 600;
		font-size: 2rem;
		margin: 1rem 0;
	}
`;
