import React, { forwardRef } from "react";
import RadioGroup from "antd/es/radio/group";
import Radio from "antd/es/radio/radio";
import FormItem from "antd/es/form/FormItem";
import { PROCESSOR_S3_URL, S3_URL } from "../../utils/constants";

export default forwardRef((props: any, ref: any) => {
  const { form, inputData, language } = props,
    { required, questions, defaultValue, id, options } = inputData,
    { getFieldDecorator } = form;

  return (
    <FormItem
      colon={false}
      label={
        <label
          dangerouslySetInnerHTML={{
            __html: questions[language] || "no question for selected language"
          }}
        />
      }
    >
      {getFieldDecorator(id, {
        initialValue: defaultValue,
        rules: [
          {
            required,
            message: "Required Field"
          }
        ]
      })(
      <div className="radioImageOptions">
        <RadioGroup
                  defaultValue={defaultValue}
                  >
          {options.map((option: any) => (
            <Radio
              // className={option.value === defaultValue ? "radioImageWrapper ant-radio-wrapper-checked" : "radioImageWrapper"}
              key={option.value}
              className={"radioImageWrapper"}
              value={option.value}
              onClick={props.onClick ? props.onClick : null}
              style={{
                // on long answers makes breakline so it does not overflow
                whiteSpace: "normal"
              }}
            >
            <div className="radioImage">
              <img alt={option.image} src={option.image
                ? PROCESSOR_S3_URL + option.image
                : PROCESSOR_S3_URL + "staging/ASSESSMENT_PROCESSOR/3L84E5_noimage.png"} />
              <div>{option.text[language]}</div>
            </div>
            </Radio>
          ))}
        </RadioGroup>
      </div>
      )}
    </FormItem>
  );
});
