import React, { FC } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { SocialIcon } from "react-social-icons";
import styled from "styled-components";
import Flex from "./Flex";

const SocialShare: FC<{
  url: string;
  bgColor?: string;
  twitter?: boolean;
  title?: string;
}> = ({ url, bgColor = "#fff", twitter = true, title }) => {
  const hashtags: string[] = ["stride2success"];
  return (
    <Flex direction="row" style={{ width: "100%" }}>
      {twitter && (
        <Link column flex={1}>
          <TwitterShareButton url={title || url} hashtags={hashtags}>
            <SocialIcon network="twitter" bgColor={bgColor} fgColor="#00ACED" />
          </TwitterShareButton>
          Twitter
        </Link>
      )}
      <Link column flex={1}>
        <FacebookShareButton url={url} hashtag="#stride2success" quote={title}>
          <SocialIcon network="facebook" bgColor={bgColor} fgColor="#3B5998" />
        </FacebookShareButton>
        Facebook
      </Link>
      <Link column flex={1}>
        <WhatsappShareButton url={title || url}>
          <SocialIcon network="whatsapp" bgColor={bgColor} fgColor="#25D366" />
        </WhatsappShareButton>
        Whatsapp
      </Link>
      <Link column flex={1}>
        <TelegramShareButton url={title || url}>
          <SocialIcon network="telegram" bgColor={bgColor} fgColor="#49A9E9" />
        </TelegramShareButton>
        Telegram
      </Link>
      <Link column flex={1}>
        <LinkedinShareButton url={url} source="https://strideahead.in">
          <SocialIcon network="linkedin" bgColor={bgColor} fgColor="#007FB1" />
        </LinkedinShareButton>
        LinkedIn
      </Link>
    </Flex>
  );
};

export default SocialShare;

const Link = styled(Flex)`
  margin: 0 1rem;
  text-align: center;
  font-size: 1.3rem;
  button {
    padding: 1rem 0 !important;
  }

  @media only screen and (max-width: 1024px) {
    margin: auto;
    font-size: 1.1rem;
    button {
      padding: 0.5rem 0 !important;
      a {
        div {
          width: 80% !important;
          height: 80% !important;
          top: 3px !important;
          left: 3px !important;
        }
      }
    }
  }
`;
