// @ts-nocheck
import React, { forwardRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import { Tabs, Tooltip, Row, Popconfirm, Button } from "antd";
import "react-quill/dist/quill.snow.css";
import "./quillFormInput.css";
import ISO6391 from "iso-639-1";
import AddDropdown from "./AddDropdown";
import TranslatedText from "../../../../../../../../translations/TranslatedText";
import { PROCESSOR_S3_URL, PROCESSOR_URL, S3_URL } from "../../../../../../../../utils/constants"


const { TabPane } = Tabs;
const { getNativeName } = ISO6391;

const Size = Quill.import("attributors/style/size");
const text_size = [
  "6px",
  "7px",
  "8px",
  "9px",
  "10px",
  "10.5px",
  "11px",
  "12px",
  "13px",
  "14px",
  "15px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
  "32px",
  "36px",
  "40px",
  "44px",
  "48px",
  "54px",
  "60px",
  "66px",
  "72px",
  "80px",
  "88px",
  "96px",
];
Size.whitelist = text_size;
Quill.register(Size, true);
var quillObj: any;
const uploadFiles = (uploadFileObj: any, filename: any, quillObj: any) => {
  const formdata = new FormData()
  formdata.append("file", uploadFileObj)
  fetch(`${PROCESSOR_URL}quiz/upload`, {
    method: "POST",
    body: formdata
  })
  .then(res=>res.json())
  .then(data=>{
    if(data['data']['key']){
      const range = quillObj.getEditorSelection();
      const res = PROCESSOR_S3_URL + data['data']['key']
      quillObj.getEditor().insertEmbed(range.index, 'image', res);
    }
  })
  .catch(err=>{
    console.log(err)
    alert("Failed to upload image. Please try again.")
  })
}  

const imageHandler = (a: any) => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();
  input.id = "testing"
  input.onchange = () => {  
  // @ts-ignore: Object is possibly 'null'.
    var file: any = input.files[0];
    var formData = new FormData();
    formData.append('image', file);
    var fileName = file.name;  
    uploadFiles(file, fileName, quillObj);

  };  
      // this part the image is inserted
      // by 'image' option below, you just have to put src(link) of img here. 
};

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, false] }, { font: [] }, { size: text_size }],
      [{ color: [] }, { background: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    handlers: {
      image: imageHandler
    }
  },
};

export default forwardRef((props: any, ref) => {
  const {
    value,
    defaultValue,
    onChange,
    currentLanguage,
    setLanguage,
    onNewLanguage,
    onRemoveLanguage,
  } = props;
  const questionLanguages: Array<string> = Object.keys(value);

  function onChangeHandler(
    content: string
    // delta: Quill.Delta,
    //  source: Quill.Sources,
    // editor: UnprivilegedEditor
  ) {
    onChange({ ...value, [currentLanguage]: content });
  }

  function preventPropagation(e?: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
  }


  return (
    <Tabs
      ref={ref as any}
      onChange={setLanguage}
      activeKey={currentLanguage}
      tabBarExtraContent={
        <AddDropdown disabled={questionLanguages} onChange={onNewLanguage} />
      }
    >
      {questionLanguages.map((lang) => (
        <TabPane
          // native language name
          tab={
            <Row>
              <Tooltip title={lang}>{getNativeName(lang)}</Tooltip>
              &nbsp;
              {questionLanguages.length > 1 ? (
                <Popconfirm
                  title={<TranslatedText id="confirm.action" />}
                  okText={<TranslatedText id="btn.yes" />}
                  cancelText={<TranslatedText id="btn.no" />}
                  onConfirm={(e) => {
                    preventPropagation(e);
                    onRemoveLanguage(lang);
                  }}
                >
                  <Button
                    shape="circle"
                    icon="close"
                    size="small"
                    onClick={preventPropagation}
                  />
                </Popconfirm>
              ) : null}
            </Row>
          }
          key={lang}
        >
          <ReactQuill
           ref={(el) => {  
            quillObj = el;  
          }}  
            theme="snow"
            placeholder="Question?"
            defaultValue={defaultValue}
            value={value[currentLanguage] || ""}
            onChange={onChangeHandler}
            modules={modules}
          />
        </TabPane>
      ))}
    </Tabs>
  );
});
