import React, { useState } from "react";
import AptitudeQuizPage from "./quiz_pages/AptitudeQuizPage"
import AskIITiansQuizPage from "./quiz_pages/askiitians/AskIITiansQuizPage";
import UniversalQuizDefault from "./quiz_pages/UniversalQuizDefault"

export default function UniversalQuizHandler(props){
    const [instructionsRead, setInstructionsRead] = useState(false);
    const [sampleQuestionsRead, setSampleQuestionsRead] = useState(false);
    const newProps = {...props}
    newProps.instructionsRead = instructionsRead;
    newProps.sampleQuestionsRead = sampleQuestionsRead;
    newProps.setInstructionsRead = setInstructionsRead;
    newProps.setSampleQuestionsRead = setSampleQuestionsRead;
    switch(props.quizPageTemplate){
        case "Aptitude Quiz":
            return <AptitudeQuizPage {
                ...newProps                    
            } />
        case 'askiitians_subjectwise_default':
            return <AskIITiansQuizPage {
                ...newProps
            } />
        default:
            return <UniversalQuizDefault {...props} />
    }

}