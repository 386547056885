import React, { useContext, useEffect } from "react";
import Row from "antd/es/row/index";
import Col from "antd/es/col/index";
import DeleteButton from "./DeleteButton";
import EditElement from "./EditElement/index";
import TranslatedText from "../../../translations/TranslatedText";
import SampleQuestionToggle from "./SampleQuestionToggle";
import RiasecTraitSelectInput from "./RiasecTraitSelectInput";
import QuizzContext, { IQuizzBuilderContext } from "../../../QuizzContext";
import DimentionsScoringCodeRadioInput from "./DimensionsScoringCodeRadioInput";
import AskIITiansSubjectSelectInput from "./AskIITiansSubjectSelectInput";

// applies delete and edit capabilities
export default (props: any) => {
  const { quizTypeName, templateTypeName } = useContext(QuizzContext) as IQuizzBuilderContext;
  const { toolboxData, inputData } = props;


  return (
    <div className="ElementWrapper">
      <Row>
        <Col xs={24} sm={18}>
          <TranslatedText id={toolboxData.name} />
        </Col>
        <Col xs={24} sm={6} style={{ textAlign: "right" }}>
          <EditElement {...props} />
          &nbsp;
          <DeleteButton id={inputData.id} />
          &nbsp;
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>{props.children}</div>
        <div>
          <div style={{ marginTop: "10px" }}>
            <SampleQuestionToggle
              checked={
                inputData.sampleQuestion ? inputData.sampleQuestion : false
              }
              id={inputData.id}
            />
          </div>
          {quizTypeName == "riasec_quiz" && (
            <div style={{ marginTop: "10px" }}>
              <RiasecTraitSelectInput
                id={inputData.id}
                defaultValue={inputData.trait}
              />
            </div>
          )}
          {templateTypeName == "askiitians_subjectwise_default" && (
            <div style={{ marginTop: "10px" }}>
              <AskIITiansSubjectSelectInput
                id={inputData.id}
                defaultValue={{ subject: inputData.subject, chapter: inputData.chapter }}
              />
            </div>
          )}
          {quizTypeName == "dimensions_quiz" && (
            <div style={{ marginTop: "10px" }}>
              <DimentionsScoringCodeRadioInput
                id={inputData.id}
                defaultValue={inputData.trait}
                scoringCode={inputData.scoring_code}
                dimOptions={props.dimOptions}
                setDimOptions={props.setDimOptions}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
