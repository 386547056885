import react, {useState} from 'react';
import SheeHulk from '../../assets/sheehulk.svg'
import SheeHulk2 from '../../assets/sheehulk2.svg'
import EnableTick from '../../assets/enable_tick2.svg'
import DisableTick from '../../assets/disable_tick.svg'
import LoGo from "../../assets/redutechImg/logo.svg";
import LoGo2 from "../../assets/redutechImg/redutech_lg.png";
import styled from 'styled-components';


const CalculatingPage2 = () => {
    const [enableIcon, setEnableIcon] = useState(false);
    const [enableIcon1, setEnableIcon1] = useState(false);
    const [enableIcon2, setEnableIcon2] = useState(false);
    const [enableIcon3, setEnableIcon3] = useState(false);
    const [enableIcon4, setEnableIcon4] = useState(false);

    setInterval(function () {
        setEnableIcon(true);
    }, 1000);
    setInterval(function () {
        setEnableIcon1(true);
    }, 2000);
    setInterval(function () {
        setEnableIcon2(true);
    }, 3000);

    return (

        <Maindiv className=" m-auto">
            <StyleDiv className="col-12 d-flex justify-content-center align-items-center ">
                <StrideLogoImg src={LoGo2}/> <StrideLogoImg2 className="ms-4" src={LoGo}/>
            </StyleDiv>
            <StyleDiv className="col-12 text-center"> <Image src={SheeHulk2}/> </StyleDiv>
            <StyleDiv className="col-12  col-12 d-flex justify-content-center">
                <div className="text-start">
                    <StyleH1>Running a Psychometric analysis</StyleH1>
                    <Div className="d-flex">
                        <Icon src={enableIcon ? EnableTick : DisableTick}/> <StyleP
                        style={{color: enableIcon ? "#8B292A" : "#000000"}}>Drawing Personalized Insights</StyleP>
                    </Div>
                    <Div className="d-flex">
                        <Icon src={enableIcon1 ? EnableTick : DisableTick}/> <StyleP
                        style={{color: enableIcon1 ? "#8B292A" : "#000000"}}>Formulating Course Recommendations</StyleP>
                    </Div>
                    <Div className="d-flex">
                        <Icon src={enableIcon2 ? EnableTick : DisableTick}/> <DisableP
                        style={{color: enableIcon2 ? "#8B292A" : "#000000"}}>Unlocking potential!</DisableP>
                    </Div>
                </div>
            </StyleDiv>
        </Maindiv>
    )

}

export default CalculatingPage2;


const Maindiv = styled.div`
      font-family: 'Poppins';
      background-color: #FAF9FF;
      padding: 0px;
      height:100%
       
      
      @media (max-width: 768px){
       background-color: #FAF9FF;
       height:100%
       padding: 20px
       }
 `;

const Div = styled.div`
   
    @media (max-width: 768px){
       width: 80%;
        margin:0 auto;
       }
`;

const StyleDiv = styled.div`
       padding-top: 28px
    `;

const StrideLogoImg = styled.img`
    width: 20%;
    @media (max-width: 768px){
       width: 20%;
       }
`;
const StrideLogoImg2 = styled.img`
    width: 35%;
    @media (max-width: 768px){
       width: 40%;
       }
`;

const Image = styled.img`
     width: 40%;
     
     @media (max-width: 768px){
       width: 50%;
       }
`;

const Icon = styled.img`
       width: 25px;
       height: 25px;
       
       
      @media (max-width: 768px){
        margin-top: 15px
       }
       
`;


const StyleH1 = styled.h1`
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 26px;
      text-align: center;
      color: #8B292A;
      @media (max-width: 768px){
       margin-bottom: 5px;
       font-size: 22px;
       }
      
    `;

const StyleP = styled.p`
       font-weight: 500;
       font-size: 16px;
       padding-left: 10px;
       
       @media (max-width: 768px){
       font-size: 12px;
       margin-top 16px;
       }
    `;

const DisableP = styled.p`
       font-weight: 500;
       font-size: 16px;
       padding-left: 10px;
       
       @media (max-width: 768px){
       font-size: 12px;
       margin-top 16px;
       }
    `;