import React, { useState, useEffect } from "react";
import { IGNITER_REDIRECT_URL_0 } from "../../../utils/constants";
import animationData from "../../../assets/Down-arrows.json";
import Lottie from "react-lottie";
import "./aboutsec.css";
import DiffTypeBox from "./DiffTypeBox";
const lock_img = require("../../../assets/IgniterLpQuiz/lock.png");
const rocket_img = require("../../../assets/IgniterLpQuiz/rocket.png");
const lost_img = require("../../../assets/IgniterLpQuiz/Group2.png");
const confident_img = require("../../../assets/IgniterLpQuiz/confident.png");
const confused_img = require("../../../assets/IgniterLpQuiz/confused.png");

function AboutSection(props) {
  
  const [achievedPercentage, setAchievedPercentage] = useState(6);
  const [imageToRender , setImageToRender] = useState("");
  useEffect(() => {
    if(props?.reportData?.score >= 0 && props?.reportData?.score >= 0){
      let percentage = (props?.reportData.score/props.reportData?.score)*100;
      setAchievedPercentage(percentage)
      if(percentage < 33){
        setImageToRender(lost_img.default)
      }else if(percentage >= 33 && percentage < 66){
        setImageToRender(confused_img.default) 
      }else{
        setImageToRender(confident_img.default) 
      }
    
    }
  },[props])  

  const downArrorLottieProps = {
    loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      } 
  }

  return (
    <div className="about_us_main_section">
      <div className="res_about_us_container">
        <div className="about_us_container">
          
            <DiffTypeBox
              rateImgContainerStyle="lost_img_container"
              imgAboutName={imageToRender}
              titleAboutText={props?.reportData?.title}
              extraAboutText={props?.reportData?.description}
            />


          <div className="dont_worry_container">
            <div className="dont_worry_content_container">
              <h6 className="dont_worry_title_text">
                Don't Worry; We have a solution for you.
              </h6>
              <div className="dont_worry_under_line" />
              <p className="dont_worry_extra_content">
                Check out our proprietary IDEA mentorship framework. We leverage
                scientifically created assessment (don't worry, it's not like a
                test you would sit for during your college exams, we don't pass
                or fail you). And then we match you with the perfect Industry
                Expert Mentor. Our Mentors will provide you with all the
                guidance, tools and insights needed to achieve your career
                goals.
                <br /> <br /> On top of that, you also get to be part of our
                exclusive community of professionals, where you'll receive peer
                mentorship, curated job offers, referrals and much more.
              </p>
              <button className="lock_img_btn" onClick={()=>{
                    window.open(props.CTADetails.url,"_SELF")
              }}>
                <img
                  src={lock_img.default}
                  alt="lock"
                  className="lock_img img-fluid"
                />
                {props.CTADetails.text}
              </button>
            </div>
            <div className="img_dont_worry_self">
              <img
                src={rocket_img.default}
                alt="rocket"
                className="rocket_img"
              />
            </div>
          </div>
          <div className="down-scroller">
            <Lottie options={downArrorLottieProps}
            height={60}
            width={60}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
