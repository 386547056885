export const getTraitName = (trait) => {
  switch (trait) {
    case "r":
      return "Realistic";
    case "i":
      return "Investigative";
    case "a":
      return "Artistic";
    case "s":
      return "Social";
    case "e":
      return "Enterprising";
    case "c":
      return "Conventional";
  }
};

export const getTraitColor = (trait) => {
  switch (trait) {
    case "r":
      return "#FD7D57";
    case "i":
      return "#86CEFA";
    case "a":
      return "#61D0A6";
    case "s":
      return "#FF57DA";
    case "e":
      return "#F3A465";
    case "c":
      return "#6A8E23";
  }
};

export const getTraitBackgroundColor = (trait) => {
  switch (trait) {
    case "r":
      return "#FFF1E7";
    case "i":
      return "#EAF7FF";
    case "a":
      return "#E6FFF7";
    case "s":
      return "#FFF2FF";
    case "e":
      return "#FFF1E7";
    case "c":
      return "#FAFFEF";
  }
};
