import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT } from '../utils/constants';
import { APIError, ReportMetadataResponse } from '../utils/types';

export const useAnalysisReport = (  token: string,  pdf: boolean = false, refetchIntervalInSeconds: number = 0, enabled : boolean = true) => {
  const fetchAnalysis = async (token: string, pdf) => {
    let url = API_ENDPOINT + 'report/' + token + '?pdf=' + pdf
    try {
      const {
        data: { data , status},
      
      } = await axios.get(url);

      data.status = status
    
      return data;
    } catch (error) {
      throw error.response.data;
    }
  };

  return useQuery<ReportMetadataResponse, AxiosResponse<APIError>>(
    ['analysis', token, pdf, enabled],
    () => fetchAnalysis(token, pdf),
    {
      retry: false,
      enabled: enabled,
      refetchInterval: refetchIntervalInSeconds * 1000
    }
  );
};
