import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "../../../../assets/cancel.png";

const DimensionsQuizMetaForm = (props: any) => {
  const [s, setS] = useState(props.quizMeta.strengths || []);
  const [sCurr, setSCurr] = useState("");
  const [w, setW] = useState(props.quizMeta.weaknesses || []);
  const [wCurr, setWCurr] = useState("");
  const [p, setP] = useState(props.quizMeta.potential_strengths || []);
  const [pCurr, setPCurr] = useState("");
  const [t, setT] = useState(props.quizMeta.tips_to_improve || []);
  const [tCurr, setTCurr] = useState("");
  const [dimensions, setDimensions] = useState(props.quizMeta.dimensions || {});
  let divRef: any = useRef({});

  const allDimensions = props.questions && props.questions.map((item: any, index: any) => item['trait'])
  const uniqueDimensions = allDimensions.filter((v, i, a) => a.indexOf(v) === i);
  const dimensionsPoints = ['high', 'medium', 'low'];

  const dimensionsHeading = [
    {
      name: "Strengths",
      state: s,
      setState: setS,
      currV: sCurr,
      setCurrV: setSCurr,
    },
    {
      name: "Weakness",
      state: w,
      setState: setW,
      currV: wCurr,
      setCurrV: setWCurr,
    },
    {
      name: "Potential Strength",
      state: p,
      setState: setP,
      currV: pCurr,
      setCurrV: setPCurr,
    },
    {
      name: "Tips to Improve",
      state: t,
      setState: setT,
      currV: tCurr,
      setCurrV: setTCurr,
    },
  ];


  useEffect(() => {
    let newFormValues = { ...props.quizMeta };
    // @ts-ignore: Object is possibly 'null'.
    newFormValues['strengths'] = s;
    newFormValues['weaknesses'] = w;
    newFormValues['potential_strengths'] = p;
    newFormValues['tips_to_improve'] = t;
    newFormValues['dimensions'] = dimensions;
    props.onChange(newFormValues);
  }, [s, w, p, t, dimensions]);

  return (
    <div>
      <h3>Quiz Meta</h3>
      <div className="form-inline" style={{ border: "3px solid black", padding: "10px" }}>
        {dimensionsHeading.map((element: any, index: any) => (
          <>
            <div>{element.name}</div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "15px",
                  alignSelf: "center",
                }}
              >
                <input
                  type="text"
                  name={element.name}
                  value={element.currV}
                  onChange={(e) => element.setCurrV(e.target.value)}
                />
                <div
                  onClick={() => {
                    element.setState((prev) => [...prev, element.currV]);
                    element.setCurrV("");
                  }}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "grey",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  Add
                </div>
              </div>
              {element.state.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "inline-block",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "lightgrey",
                      padding: "5px 10px 5px 10px",
                      margin: "5px",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      justifyContent: "space-between",
                      borderRadius: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div>{item}</div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        element.setState(
                          element.state.filter((v: string) => v !== item)
                        )
                      }
                    >
                      <img
                        src={CloseIcon}
                        alt="Add New Test"
                        style={{ width: "15px", height: "15px" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ))}
      </div>


      {uniqueDimensions.map((element: any, outerIndex: any) => {
        let ref;
        return (
          <div className="form-inline" key={outerIndex}>
            <br />
            <h3>Enter the following details for the dimension: {element}</h3>
            <br />
            <div style={{ border: "3px solid black", padding: "10px" }}>
              {
                dimensionsPoints.map((item, index) => {
                  let currentValue = "";
                  return (
                    <div>
                      <div>{item.toUpperCase()} pointers</div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            gap: "15px",
                            alignSelf: "center",
                          }}
                        >
                          <input
                            ref={ref => {
                              let i: any = divRef.current[outerIndex] || [];
                              i[index] = ref;
                              divRef.current[outerIndex] = i
                            }}
                            type="text"
                            name={element}
                            onChange={(e) => {
                              currentValue = e.target.value;
                            }}
                          />
                          <div
                            onClick={() => {
                              if (currentValue) {
                                let prevArray = dimensions?.[element]?.[item] || [];
                                let prevDimensionData = dimensions?.[element] || {};
                                let newDimensionData = prevArray ? { ...prevDimensionData, [item]: [...prevArray, currentValue] } : { [item]: [currentValue] };
                                setDimensions({ ...dimensions, [element]: newDimensionData });
                                divRef.current[outerIndex][index].value = "";
                              }
                            }}
                            style={{
                              cursor: "pointer",
                              backgroundColor: "grey",
                              borderRadius: "5px",
                              padding: "5px 10px",
                              color: "#fff",
                              fontWeight: "bold",
                            }}
                          >
                            Add
                          </div>
                        </div>

                        {dimensions[element]?.[item]?.map((value, index) => (
                          <div
                            key={index}
                            style={{
                              display: "inline-block",
                              marginTop: "10px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "lightgrey",
                                padding: "5px 10px 5px 10px",
                                margin: "5px",
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                                justifyContent: "space-between",
                                borderRadius: "10px",
                                alignItems: "center",
                              }}
                            >
                              <div>{value}</div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  let newArray = dimensions?.[element]?.[item]?.filter((v: string) => v !== value)
                                  setDimensions({ ...dimensions, [element]: { ...dimensions[element], [item]: newArray } });
                                }
                                }
                              >
                                <img
                                  src={CloseIcon}
                                  alt="Add New Test"
                                  style={{ width: "15px", height: "15px" }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )


                })
              }
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default DimensionsQuizMetaForm;
