import React, { useState } from 'react'
import { API_ENDPOINT, PROCESSOR_S3_URL, PROCESSOR_URL, STRIDE_ID, STRIDE_ID_AUTH } from '../../../utils/constants'
import { splitFullName } from '../../../utils/strings'


function LeadSquaredForm(props: any) {
    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [contactNumber, setContactNumber] = useState<number>()
    
    const handleSubmit = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        let form = document.querySelector("#lead-squared-form") as any;
        if(!form.reportValidity()){
            return false;
        }
        const pathname: string = window.location.pathname
        const id = pathname.split("/")[pathname.split("/").length - 1]
        let formdata = {
            user: {
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone_no: contactNumber,
            },
            source: props.source,
            product: props.product
        }
        fetch(`${STRIDE_ID_AUTH}lead`, {
            method: "POST",
            body: JSON.stringify(formdata),
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res=>{
            if(res.status === 200){
                console.log('Ready to submit');
                fetch(`${API_ENDPOINT}quiz/report/${id}`, {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formdata)
                })
                .then(res=>{
                    if (res.status === 200){
                        window.location.reload();
                    } else {
                        console.log(res.json())
                        alert("Error occurred while updating report.")
                        window.location.reload()
                    }
                })
                .catch(err=>{
                    console.log(err)
                    alert("Error occurred while updating report.")
                    window.location.reload()
                })
                return res.json()
            }else{
                throw new Error("Error occurred while generating lead.")
            }
        })
        .then(data=>{
            console.log(data)
            window.parent.postMessage(JSON.stringify({code: "lead form submitted", data: formdata}), "*");
        })
        .catch(err=>{
            console.log(err)
            alert("Error occurred while generating lead.")
        })
    }
    
    return (
        <form id="lead-squared-form" onSubmit={handleSubmit}> 
            <div className="form-description">
                <div>
                    You're <span className="stride-blue">almost there!</span>
                </div>
                <div>
                    Let us understand you better to generate your report
                </div>
            </div>
            <div className="horizontal-line">
            </div>  
            <div style={{"display":"flex", "justifyContent": "space-between"}}>
                <div style={{"position": "relative"}}>
                    <div style={{"textAlign": "left", "marginLeft":"10px", "height":"27px","fontFamily":"Poppins","fontStyle":"normal","fontWeight":500,"fontSize":"18px","lineHeight":"27px","display":"flex","alignItems":"stretch","color":"#333333"}}>First name</div>
                    <div className="double-input-asterik">
                    *
                    </div>
                    <input  style={{"margin":"10px",padding: "10px"}} placeholder="First Name" onChange={(e)=>{
                        setFirstName(e.target.value);
                    }} required type="text" value={first_name} name="last_name" />
                </div>
                <div style={{"position": "relative"}}>
                    <div style={{"textAlign": "left", "marginLeft":"10px", "height":"27px","fontFamily":"Poppins","fontStyle":"normal","fontWeight":500,"fontSize":"18px","lineHeight":"27px","display":"flex","alignItems":"stretch","color":"#333333"}}>Last name</div>
                    <div className="double-input-asterik">
                    *
                    </div>
                    <input  style={{"margin":"10px", padding: "10px"}} placeholder="Last Name" onChange={(e)=>{
                        setLastName(e.target.value);
                    }} required type="text" value={last_name} name="last_name" />
                </div>
            </div>
            <div style={{"marginLeft":"10px", "height":"27px","fontFamily":"Poppins","fontStyle":"normal","fontWeight":500,"fontSize":"18px","lineHeight":"27px","display":"flex","alignItems":"stretch","color":"#333333"}}>
                <div>Email address</div>
            </div>
            <div style={{"position": "relative", "display":"flex"}}>
                <div className="single-input-asterik">
                *
                </div>
                <input style={{"margin":"10px", padding: "10px", width: "100%"}} placeholder="youremail@address.com" onChange={(e)=>{
                    setEmail(e.target.value);
                }} required type="text" value={email} name="email" pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$"/>
            </div>
            <div style={{"marginLeft":"10px", "height":"27px","fontFamily":"Poppins","fontStyle":"normal","fontWeight":500,"fontSize":"18px","lineHeight":"27px","display":"flex","alignItems":"stretch","color":"#333333"}}>
                <div>Mobile number</div>
            </div>
            <div style={{"position": "relative" ,"display":"flex"}}>
                <div className="single-input-asterik">
                *
                </div>
                <input style={{"margin":"10px", padding: "10px", width: "100%"}} placeholder="1234567890" onChange={(e)=>{
                setContactNumber(parseInt(e.target.value));
                }} required type="tel" name="contact-number" pattern="[1-9]{1}[0-9]{9}"/>
            </div>
            <div style={{"margin":"0 10px","height":"40px","left":"0px","top":"0px","background":"#1273B7","borderRadius":"2px"}} onClick={handleSubmit}>
                <div style={{"lineHeight": "2.2", "marginTop": "24px", "cursor": "pointer", "width":"100%","height":"100%","fontFamily":"Poppins","fontStyle":"normal","fontWeight":600,"fontSize":"18px","textAlign":"center","color":"#FFFFFF"}}>
                    Get my report
                </div>
            </div>
            <div className="security-logo">
                <img src={PROCESSOR_S3_URL + "staging/ASSESSMENT_PROCESSOR/6WCUN3_Security logos.png"} alt="" />
            </div>
        </form>
    )
}

export default LeadSquaredForm