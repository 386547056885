import styled from "styled-components";
import {IoMdCheckmarkCircle} from 'react-icons/io'
import Recommended from '../../../assets/recommended.svg'
import ModalContainer from "../DetailModal/ModalContainer";
import PromocodeModal from "../PromocodeModal/PromocodeModal";
import Modal from "../Modal/Modal";
import Modal2 from "../Modal/Modal2"
import React, {useEffect, useState} from "react";
import { ThreeDots} from 'react-loader-spinner'
import {
    API_ENDPOINT,
    PAGES_URL,
    HIGH_MEDIUM_PROMOCODE,
    HIGH_PROMOCODE,
    LOW_MEDIUM_PROMOCODE,
    LOW_PROMOCODE
} from "../../../utils/constants";
import axios from "axios";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

const RedutechPaymentSection = (props: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());
    const orderId = urlParams?.orderId;

    const {ApptitudeData, discount, setOpen, product, courseType, setSuccess, setShowToast, setToastText} = props;
    const [showModalNo, setShowModalNo] = useState<any>(0);
    const [promoCodes, setPromoCodes] = useState<any>(product?.promo_codes);
    const [appliedPromoCode, setAppliedPromoCode] = useState<any>();
    const [reportVariant, setReportVariant] = useState<any>();
    const [dataType, setDataType] = useState<any>();
    const [courseName, setCourseName] = useState<any>();
    const [open2, setOpen2] = React.useState(false);
    const [loader,setLoader] = React.useState(false)

    const handleClickOpen = () => {
        setOpen2(true);
    };

    const handleClose = () => {
        setOpen2(false);
    };
    const data = {
        data1: [
            {
                title: "Big data analytics program",
                content: "Get expertise in making data driven decisions, Data Analytics & Predictive Analytics",
                point1: "Job opportunities",
                point2: "Blended learning",
                point3: "Co-branded certificate by FutureSkills Prime & CDAC.",
                point4: "FutureSkills Prime badge that can be added to your LinkedIn profile.",
                point5: "Eligible for GOVT. OF INDIA Incentives",

            },
        ],
        data2: [
            {
                title: "Cloud solution architect program",
                content: "Get introduced to Cloud computing fundamentals, Open source and Proprietary Cloud tools",
                point1: "Job opportunities",
                point2: "Blended learning",
                point3: "Co-branded certificate by FutureSkills Prime & CDAC.",
                point4: "FutureSkills Prime badge that can be added to your LinkedIn profile.",
                point5: "Eligible for GOVT. OF INDIA Incentives",
            },
        ],
        data3: [
            {
                title: "Cyber security program",
                content: "Establish strong fundamentals and build core competencies in Cyber Security",
                point1: "Job opportunities",
                point2: "Blended learning",
                point3: "Co-branded certificate by FutureSkills Prime & CDAC.",
                point4: "FutureSkills Prime badge that can be added to your LinkedIn profile.",
                point5: "Eligible for GOVT. OF INDIA Incentives",
            },
        ]
    }
    useEffect(() => {
        if (ApptitudeData?.report_metadata?.recommended_course === "CC") {
            setReportVariant('https://futureskillsprime.in/course/bridge-course-for-cloud-solution-architect')
        } else if (ApptitudeData?.report_metadata?.recommended_course === "CS") {
            setReportVariant("https://futureskillsprime.in/course/pragmatic-approach-to-cyber-security")
        } else if (ApptitudeData?.report_metadata?.recommended_course === "BDA") {
            setReportVariant("https://futureskillsprime.in/course/big-data-analytics")
        }
    }, [ApptitudeData?.report_metadata?.recommended_course])

    useEffect(() => {
        if (courseType === "Cloud solution architect") {
            setDataType(data?.data2)
            setCourseName("Cloud solution architect along with Placement assistance")
            setReportVariant('https://futureskillsprime.in/course/bridge-course-for-cloud-solution-architect')
        } else if (courseType === "Cyber Security") {
            setCourseName("Cyber security along with Placement assistance")
            setDataType(data?.data3)
            setReportVariant("https://futureskillsprime.in/course/pragmatic-approach-to-cyber-security")
        } else if (courseType === "Big Data Analytics") {
            setDataType(data?.data1)
            setCourseName("Big Data analytics along with Placement assistance")
            setReportVariant("https://futureskillsprime.in/course/big-data-analytics")
        }
    }, [courseType])

    let emailData2 = {
        template: "RailEdutech Cohort Purhcase",
        email: ApptitudeData?.email,
        context: {
            course_link: "reportVariant",
            course_name: courseType,
            user_name: ApptitudeData?.name,
            report_link: reportVariant
        },
        provider: 'redutech',
        subject:`Thank You for Your Interest in ${courseType} `

    }
    let emailData = {
        template: "RailEdutech Course Purchase",
        email: ApptitudeData?.email,
        context: {
            course_link: "reportVariant",
            course_name: courseType,
            user_name: ApptitudeData?.name,
            report_link: reportVariant
        },
        provider: 'redutech',
        subject:`Thank You for Your Interest in ${courseType} `

    }
    const sendEmail = () => {
        return fetch(`${PAGES_URL}send-email`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(emailData),
        })
            .then((res) => {
                if (res?.status === 200) {
                    handleClickOpen()
                    setLoader(false)
                } else {
                    setShowToast(true)
                    setSuccess(false)
                    setToastText("Please Try Again!")
                }
            }).catch((error) => {
                setShowToast(true)
                setSuccess(false)
                setToastText("Please Try Again!")
            })
    };

    useEffect(() => {
        if (orderId) {
            if (ApptitudeData?.report_metadata?.score < 30) {
                setAppliedPromoCode(LOW_PROMOCODE);
            } else if (ApptitudeData?.report_metadata?.score < 50) {
                setAppliedPromoCode(LOW_MEDIUM_PROMOCODE);
            } else if (ApptitudeData?.report_metadata?.score < 80) {
                setAppliedPromoCode(HIGH_MEDIUM_PROMOCODE);
            } else {
                setAppliedPromoCode(HIGH_PROMOCODE);
            }

            if (typeof product !== 'undefined') {
                setShowModalNo(1);
            } else {
                alert('Something Failed on Server');
            }
        }
    }, [])
    return (
        
        <div className='lorem-text-style-section' id='payment'>

            <div className="container-md">
                <InnerContainer>
                    <div className="row gap-lg-0 gap-5">
                        <div className="col-lg-4 col-12">
                            {dataType?.map((value, index) => {
                                return (
                                    <SmallCard>
                                        <p>{value?.title}</p>
                                        {/*<h2>Rs. 2499 + Gst</h2>*/}
                                        <h5>{value?.content}</h5>
                                        <h6><span><IoMdCheckmarkCircle/></span>{value?.point1}</h6>
                                        <h6><span><IoMdCheckmarkCircle/></span>{value?.point2}</h6>
                                        <h6><span><IoMdCheckmarkCircle/></span>{value?.point3}</h6>
                                        <h6><span><IoMdCheckmarkCircle/></span>{value?.point4}</h6>
                                        <h6><span><IoMdCheckmarkCircle/></span>{value?.point5}</h6>
                                        {loader?<CardButton style={{width: "100%",display:'flex',justifyContent:"center",marginTop:'60px'}}
                                        >
                                        <ThreeDots 
height="30" 
width="80" 
radius="9"
color="#ffff" 
ariaLabel="three-dots-loading"
wrapperStyle={{}}
visible={true}
 />
</CardButton>
 : <CardButton style={{width: "100%"}}
 onClick={() =>{ 
     setLoader(true)
     sendEmail()
     }
 }>Get Started</CardButton>
 }
                                       
                                    


                                        

                                    </SmallCard>
                                )
                            })}
                        </div>
                        <div className="col-lg-8 col-12">
                            <BigCard>
                                <p className="d-flex justify-content-between">{courseName}<img
                                    src={Recommended}/></p>
                                <h2>CDAC Program Fee + Rs. {discount}/-(Placement Training)</h2>
                                <h5>This Plan will provide course curriculum driven by Industry mentors, Blended
                                    learning, PLacement assistance</h5>
                                <h6><span><IoMdCheckmarkCircle/></span>Build standout resume, LinkedIn profile, & cover
                                    letter with expert guidance.</h6>
                                <h6><span><IoMdCheckmarkCircle/></span>Ace interviews with mock sessions & insider tips.
                                </h6>
                                <h6><span><IoMdCheckmarkCircle/></span>Access curated library of career resources & case
                                    studies.</h6>
                                <h6><span><IoMdCheckmarkCircle/></span>Partnered with 300+ recruiters for guaranteed
                                    placement assistance.</h6>
                                <h6><span><IoMdCheckmarkCircle/></span>Access recorded videos to upskill for future job
                                    prospects.</h6>

                                <CardButton style={{width: "50%"}} onClick={() => {
                                    if (ApptitudeData?.report_metadata?.score < 30) {
                                        setAppliedPromoCode(LOW_PROMOCODE);
                                    } else if (ApptitudeData?.report_metadata?.score < 50) {
                                        setAppliedPromoCode(LOW_MEDIUM_PROMOCODE);
                                    } else if (ApptitudeData?.report_metadata?.score < 80) {
                                        setAppliedPromoCode(HIGH_MEDIUM_PROMOCODE);
                                    } else {
                                        setAppliedPromoCode(HIGH_PROMOCODE);
                                    }

                                    if (typeof product !== 'undefined') {
                                        setShowModalNo(1);
                                    } else {
                                        alert('Something Failed on Server');
                                    }
                                }}>Get Started</CardButton>
                                
                            </BigCard>
                        </div>
                    </div>
                </InnerContainer>
            </div>
            {showModalNo > 0 && (
                <ModalContainer setOpen={setShowModalNo}>
                    <>
                        {showModalNo === 1 && (
                            <PromocodeModal
                                setOpen={setShowModalNo}
                                setPromoCodes={setPromoCodes}
                                promoCodes={promoCodes}
                                product={product}
                                aPromoCode={appliedPromoCode}
                                userData={ApptitudeData}
                                emailData={emailData2}
                                orderIdInUrl={orderId}
                                clientId={"redutech"}
                                color={"#8B292A"}
                                backgroundColor={"#8B292A"}
                            />
                        )}
                    </>
                    <>{showModalNo === 4 &&
                    <Modal color={"#8B292A"} backgroundColor={"#8B292A"} setOpen={setShowModalNo}/>}</>
                    {showModalNo === 5 &&
                    <Modal2 color={"#8B292A"} backgroundColor={"#8B292A"} setOpen={setShowModalNo}/>}
                </ModalContainer>
            )}
            <Dialog
                open={open2}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledDialogTitle
                    id="alert-dialog-title">{`Congratulations on choosing to enroll in our ${courseType} course`}</StyledDialogTitle>
                <DialogContent>
                    <StyledDialogContentText id="alert-dialog-description">
                        Discount details have been sent on your registered email (Check Your Spam) which can be used while checking out.
                        Further instructions and links have been provided in the email.
                        Don't miss out on this opportunity to boost your skills and advance your career.
                    </StyledDialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} style={{fontSize:"18px", color:"#8B292A"}} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default RedutechPaymentSection;

const StyledDialogTitle = styled(DialogTitle)`
    h2{
      font-size:26px;
      color:#8B292A;
      font-weight:700;
      @media(max-width:768px){
      font-size:26px;
      }
    }
`;

const StyledDialogContentText = styled(DialogContentText)`
      
        font-size:24px !important;
        color:#000000 !important;
        @media(max-width:768px){
        font-size:18px;
        }
      
`;

const SmallCard = styled.div`
  background: #FFFFFF;
  border-radius: 20px; 
  padding:30px;
  
  

  p{
    color:#3D3636;
    font-size:24px;
    font-weight:600;
    margin-bottom:30px;
    @media(max-width:768px){
     font-size: 20px;
    }
  }
  h2{
    color:#0C3838;
    font-size:48px;
    font-weight:600;
    margin-bottom:20px;
    @media(max-width:768px){
     font-size: 35px;
    }
  }
  h5{
    color:#3D3636;
    font-size:16px;
    font-weight:500;
    margin-left:0px;
    margin-bottom:35px;
    @media(max-width:768px){
     font-size: 14px;
    }
  }
  h6{
    color:#4F4F4F;
    font-size:16px;
    font-weight:500;
    margin-bottom:30px;
    display: flex;
    align-items: flex-start;
    
    span{
     width: 19px;
     height: 19px;
     color: #27AE60;
      margin-right:10px;
     svg{
      width: 19px;
      height: 19px;
     }
    }
    
    @media(max-width:768px){
     font-size: 14px;
     margin-bottom:25px;
    }
  }
`;

const BigCard = styled.div`
   background: #044063;
   border-radius: 20px;
   padding:30px;
   @media(max-width:768px){
   padding:15px;
   }

   p{
    color:#FFFFFF;
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    @media(max-width:768px){
     font-size: 16px;
     margin-bottom:15px;
     img{
      max-width:35%;
     }
    }
  }
  h2{
    color:#FFFFFF;
    font-size:28px;
    font-weight:600;
    margin-bottom:20px;
    @media(max-width:768px){
     font-size: 18px;
     margin-bottom:12px;
    }
  }
  h5{
    color:#FFFFFF;
    font-size:16px;
    font-weight:500;
    margin-left:0px;
    margin-bottom:30px;
    @media(max-width:768px){
     font-size: 14px;
     margin-bottom:18px;
    }
  }
  h6{
    color:#FFFFFF;
    font-size:16px;
    font-weight:500;
    margin-bottom:35px;
    display: flex;
    align-items: flex-start;
    
     span{
     width: 19px;
     height: 19px;
     color: #27AE60;
      margin-right:10px;
     svg{
      width: 19px;
      height: 19px;
     }
    }
    
    @media(max-width:768px){
     font-size: 14px;
     margin-bottom:12px;
    }
  }
`;

const InnerContainer = styled.div`
    padding:69px 0px;
`;

const CardButton = styled.button`
   font-weight: 600;
   font-size: 18px;
   background: #8B292A;
   border-radius: 4.09032px;
   color:#FFFFFF;
   padding: 16px 50px;
   border:none;
   margin-top:30px;
   @media(max-width:768px){
     padding: 16px 50px;
     width:100% !important;
     margin-top:20px;
    }
`;
