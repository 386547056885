import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import TestBackground from '../../../assets/test/test_background.svg';
import { useCurrentProfile } from '../../../hooks/useProfile';
import useTestQuestions from '../../../hooks/useTestQuestions';
import { blink } from '../../../styles/animations';
import {
	API_ENDPOINT,
	PROFILE_NAVIGATION_CTA,
	STRIDE_PROFILE_LINK
} from '../../../utils/constants';
import {
	APIError,
	CurrentUserProfile,
	FetchResponse,
	QuestionAnswers
} from '../../../utils/types';
import Button from '../../components/Button';
import Flex from '../../components/Flex';
import Loading from '../../components/Loading';
import Navbar from '../../components/Navbar';
import SubmitTest from '../../components/popups/SubmitTest';
import TimeExcedded from '../../components/popups/TimeExcedded';
import ErrorFallback from '.././ErrorFallback';
import CustModal from '.././quiz_pages/Intro/CustModal';

const succssful_img = require('../../../assets/Group 34421.png');

const EmployabilityTestPage = ({ testId, test }) => {
	// @ts-ignore
	const location = useLocation<CurrentUserProfile>();
	const history = useHistory();
	const { reportId } = useParams<{ reportId: string }>();
	const [reportLink, setReportLink] = useState<any>();
	const [showPrompt, setShowPrompt] = useState<boolean>(false);

	const [page, setPage] = useState<number>(1);
	const [autoMoveToNext, setAutoMoveToNext] = useState<boolean>(test.test_configs?.auto_next_page);
	const [openExplanations, setOpenExplanations] = useState<number[]>([]);

	const [answers, setAnswers] = useState<QuestionAnswers[]>([]);
	const [showSubmitConfirmation, setShowSubmitConfirmation] =
		useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [timeExcedded, setTimeExcedded] = useState<boolean>(false);

	const { data: questions, isLoading } = useTestQuestions(testId, page);

	const { isSuccess } = useCurrentProfile();
	const [isAppFlow, setIsAppFlow] = useState(false);

	const [time, setTime] = useState(test?.test_time);
	const timeArray =
		typeof time !== 'undefined' && time !== null && time.split(':');
	const [hours, setHours] = useState<number>(+timeArray[0]);
	const [minutes, setMinutes] = useState<number>(+timeArray[1]);
	const [seconds, setSeconds] = useState<number>(+timeArray[2]);
	const currentUrlParams = new URLSearchParams(window.location.search);

	useEffect(() => {
		if (typeof time !== 'undefined' && time !== null) {
			let myInterval = setInterval(() => {
				if (seconds > 0) {
					setSeconds(seconds - 1);
				} else {
					if (minutes === 0) {
						if (hours === 0) {
							clearInterval(myInterval);
							setTimeExcedded(true);
						} else {
							setHours(hours - 1);
							setMinutes(59);
							setSeconds(59);
						}
					} else {
						setMinutes(minutes - 1);
						setSeconds(59);
					}
				}
			}, 1000);

			return () => {
				clearInterval(myInterval);
			};
		}
	});

	const getClass = () => {
		return hours === 0 ? (minutes < 5 ? 'blink' : '') : '';
	};

	useEffect(() => {
		window.onbeforeunload = null;
		// get params from query string
		const urlParams = new URLSearchParams(window.location.search);
		const flow = urlParams.get('flow');
		if (flow === 'app') {
			setIsAppFlow(true);
		}
	}, []);

	const retakeTest = () => {
		history.push({
			pathname: `/test/${testId}`,
			search: location.search,
		});
	};

	const handleOptionSelect = (id: number, answer: string) => {
		const index = getQuestionIndexInAnswers(id);

		const newAnswers = [...answers];
		index > -1 && newAnswers.splice(index, 1);
		newAnswers.push({ id, answer });
		setAnswers([...newAnswers]);
	};

	const getQuestionIndexInAnswers = (id: number) => {
		return answers.findIndex((answer) => {
			return answer.id === id;
		});
	};

	const allQuestionAnswered = () => {
		return questions?.questions?.every(
			(question) => getQuestionIndexInAnswers(question.id) >= 0,
		);
	};

	const toggleShowExplanation = (id: number) => {
		const index = openExplanations.indexOf(id);
		const newExplanations = [...openExplanations];
		index > -1 ? newExplanations.splice(index, 1) : newExplanations.push(id);
		setOpenExplanations([...newExplanations]);
	};

	const getSelectedAnswer = (id: number) => {
		const index = getQuestionIndexInAnswers(id);
		return answers[index];
	};

	const submitTest = async (): Promise<APIError[] | void> => {
		const urlSearchParams = new URLSearchParams(history.location.search);
		const redirectUrl = urlSearchParams.get('redirect_to');
		const testFlow = urlSearchParams.get('flow');
		try {
			const postData = {
				report_id: reportId,
				response: [...answers],
			};
			let url = new URL(`${API_ENDPOINT}questions/${testId}`);
			url.search = urlSearchParams.toString();
			const { data: response } = await axios.post<
				FetchResponse<{ report_link: string }>
			>(
				url.href,
				postData,
			);
			setShowSubmitConfirmation(false);
			if (testFlow == 'app') {
				setIsModalOpen(true);
				setReportLink(response.data.report_link);
			} else {
				if (redirectUrl) {
					document.location.href = redirectUrl;
				} else {
					history.push({
						pathname: `/feedback/${testId}/${reportId}`,
						state: {
							reportLink: response.data.report_link,
							name: location.state.name,
							email: location.state.email,
						},
					});
				}
			}
		} catch (error) {
			const errors = error?.response?.message;
			return errors;
		}
	};
	// @ts-ignore
	const renderQuestions = () => {
		return (
			<QuestionContainer column>
				{questions?.questions?.map(({ id, question, options }, index) => {
					// @ts-ignore
					return (
						<QuestionCard test={test} column key={`${id}-${index}`}>
							<QuestionCount>{`Question ${
								index + 1 + (page - 1) * questions?.pagination_data?.page_size
							}`}</QuestionCount>
							<Flex style={{ marginBottom: '1rem' }}>
								<Question>
									{' '}
									<div
										dangerouslySetInnerHTML={{ __html: question }}
									></div>{' '}
								</Question>
							</Flex>
							{options.map(({ text, value, image }: any, index) => {
								return (
									<OptionContainer
									    test={test}
										className='d-flex align-items-center'
										key={`${id}-${index}`}
										selected={getSelectedAnswer(id)?.answer === value}
									>
										<QuestionLabel className='d-flex align-items-center'>
											<Option
												type='radio'
												value={value}
												id={`${id}`}
												name={`${id}`}
												onChange={() => handleOptionSelect(id, value)}
												required
												checked={getSelectedAnswer(id)?.answer === value}
											/>

											<span>
												{image ? (
													<div>
														<p>{text ? text?.en : ''}</p>
														<img
															src={
																'https://d1dhn4cz1wmlqo.cloudfront.net/' + image
															}
															width={100}
															height={100}
														/>
													</div>
												) : (
													value
												)}
											</span>
										</QuestionLabel>
									</OptionContainer>
								);
							})}
						</QuestionCard>
					);
				})}
			</QuestionContainer>
		);
	};
	const getProgress = () => {
		const pagination = questions?.pagination_data;
		return pagination && pagination.page_elements
			? Math.round((answers.length * 100) / pagination?.page_elements)
			: 0;
	};

	const renderTestDetail = () => {
		return (
			<TestName>
				<img src={TestBackground} alt='Background' />
				<div>
					{test?.test_display_label
						? test?.test_display_label
						: test?.test_name}
				</div>
			</TestName>
		);
	};

	const renderProgress = () => {
		if (questions?.pagination_data?.page_no) {
			const pages = Math.ceil(
				questions?.pagination_data?.page_elements
					? questions?.pagination_data?.page_elements /
							questions?.pagination_data?.page_size
					: 1,
			);
			var foo: number[] = [];

			for (var i = 1; i <= pages; i++) {
				foo.push(i);
			}
			return foo;
		}
	};

	const renderFooter = () => {
		return (
			<Footer>
				<GoBackContainer>
				<GoBackButton
						disabled={!questions?.pagination_data?.page_previous}
						onClick={() => {
							setPage(page - 1)
							test.test_configs?.auto_next_page && setAutoMoveToNext(false)
						}}
					>
						<HiOutlineArrowLeft />
						Go Back
					</GoBackButton>
				</GoBackContainer>
				<ProgressContainer>
					<ProgressDiv>{getProgress()} %</ProgressDiv>
				</ProgressContainer>
				<ContinueContainer>
					{questions?.pagination_data?.page_next ? (
						<ContinueButton test={test}
							disabled={!allQuestionAnswered()}
							onClick={() => {
								setPage(page + 1)
								test.test_configs?.auto_next_page && setAutoMoveToNext(true)
							}}
						>
							Continue <HiOutlineArrowRight />
						</ContinueButton>
					) : (
						<ContinueButton test={test}
							disabled={!allQuestionAnswered()}
							onClick={() => setShowSubmitConfirmation(true)}
						>
							Submit Test
							<HiOutlineArrowRight />
						</ContinueButton>
					)}
				</ContinueContainer>
			</Footer>
		);
	};

	useEffect(() => {
		if (!isSuccess && !location.state.name) {
			history.push({
				pathname: `/test/${testId}`,
				search: location.search,
			});
		}
		if (timeExcedded && test?.auto_submit) {
			submitTest();
		}
	}, [isSuccess, location, answers, time, timeExcedded]);

	useEffect(() => {
		if(autoMoveToNext && allQuestionAnswered() && questions?.pagination_data?.page_next){
			setPage(page + 1)
		}
	}, [answers])

	if (!isLoading) {
		const customLogo = test?.logo_image;
		return questions?.questions ? (
			<Fragment>
				<Navbar homepage='https://strideahead.in' customLogo={customLogo} />
				<Container column>
					{typeof test?.test_time !== 'undefined' && test?.test_time !== null && (
						<TimerContainer test={test}>
							<ContainerTimer test={test} className={getClass()}>
								<h2>
									{hours < 10 ? `0${hours}` : hours}
									<Seperator>:</Seperator>
									{minutes < 10 ? `0${minutes}` : minutes}
									<Seperator>:</Seperator>
									{seconds < 10 ? `0${seconds}` : seconds}
								</h2>
							</ContainerTimer>
						</TimerContainer>
					)}
					{renderTestDetail()}
					{renderQuestions()}
					<Progress>
						{renderProgress()?.map((page, index) => {
							return (
								<ProgressBar test={test}
									key={index}
									completed={
										questions && page <= questions?.pagination_data?.page_no
									}
								/>
							);
						})}
					</Progress>
					{renderFooter()}
				</Container>
				{showSubmitConfirmation && (
					<SubmitTest
						onClose={() => setShowSubmitConfirmation(false)}
						onSubmit={() => submitTest()}
					/>
				)}
				{isModalOpen && (
					<CustModal
						hideCloseButton={true}
						open={isModalOpen}
						onClose={() => setIsModalOpen(false)}
					>
						<div className='pop_box_sucessfull_container'>
							<div className='sucessfull_img_container'>
								<img
									src={succssful_img.default}
									alt='sucessfull_img_'
									className='sucessfull_img_self'
								/>
							</div>

							<h2 className='test_completed_text'>Test Completed</h2>
							<p className='text_succes_extra_content'>
								Your report is being generated, and will be ready shortly
							</p>
							<div
								className='success-btn-wrapper'
								style={{ justifyContent: 'center', gap: '15px' }}
							>
								<button
									className='done_btn'
									onClick={() => {
										setIsModalOpen(false);
										window.location.replace(`${reportLink}`+'?'+ currentUrlParams.toString());
									}}
								>
									VIEW REPORT
								</button>
								{isAppFlow && (
									<button
										className='profile_navigation_btn'
										onClick={() => {
											setIsModalOpen(false);
											window.location.replace(`${STRIDE_PROFILE_LINK}`);
										}}
									>
										{PROFILE_NAVIGATION_CTA}
									</button>
								)}
							</div>
						</div>
					</CustModal>
				)}
				{timeExcedded &&
					(test?.auto_submit ? (
						<Loading  message='Submitting your Test. Please wait...' />
					) : (
						<TimeExcedded onClick={() => retakeTest()} />
					))}
			</Fragment>
		) : (
			<ErrorFallback error='500' showNavbar={false} />
		);
	} else {
		return <>{<Loading  />}</>;
	}
};

export default EmployabilityTestPage;

const Container = styled(Flex)`
	width: 100%;
	background-color: ${(props) => props.theme.colors.white};
	height: auto;
	margin: 0px auto;
`;

const TestName = styled(Flex)`
	position: relative;
	width: 80%;
	margin: 0px auto;
	margin-top: 5rem;
	font-weight: 500;
	font-size: 3.4rem;
	align-items: center;

	img {
		width: 100%;
		object-fit: cover;
		height: 100%;
	}

	div {
		position: absolute;
		margin: 0rem 4rem;
		color: ${(props) => props.theme.colors.white};
	}

	@media only screen and (max-width: 1024px) {
		width: 90%;
		margin-top: 1rem;
		font-size: 1.8rem;
		padding: 1rem 0;
		background-image: url(${TestBackground});
		height: 6rem;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 4px;
		img {
			display: none;
		}
		div {
			margin: 0rem 1rem;
		}
	}
`;

const Footer = styled(Flex)`
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 6.8rem;
	background: ${(props) => props.theme.colors.white};
	@media only screen and (max-width: 1024px) {
		height: 5rem;
	}
`;

const GoBackContainer = styled(Flex)`
	width: 20rem;
	flex: 1;
	background: none;
	border: 0px;
	color: ${(props) => props.theme.colors.grey[500]};
`;

const GoBackButton = styled(Button)`
	color: ${(props) => props.theme.colors.grey[500]};
	width: 20rem;
	height: 5rem;
	margin: auto 2rem;
	background-color: ${(props) => props.theme.colors.white};
	font-weight: 600;
	font-size: 1.6rem;
	line-height: 150%;
	border: 0px;
	svg {
		vertical-align: middle;
		width: 25px;
		font-weight: bold;
		margin-right: 10px;
	}

	@media only screen and (max-width: 1024px) {
		margin: auto 1rem;
		width: max-content;
		text-align: left;
		font-size: 1.2rem;
	}
`;

const ProgressContainer = styled(Flex)`
	width: 20rem;
	flex: 1;
	color: ${(props) => props.theme.colors.blue[500]};
`;

const ProgressBar = styled(Flex)<{ completed?: boolean , test?:any }>`
	height: 0.5rem;
	width: 100%;
	background-color: ${(props) => props.test?.test_configs?.theme?.bg_color ||
		props.theme.colors.blue[200]};

	${({ completed, test, theme }) =>
		completed &&
		css`
		background-color: ${() =>
			test?.test_configs?.theme?.primary_color ||
			theme.colors.blue[500]};
				
		`}
	border-right: 2px solid ${(props) => props.theme.colors.red[100]};
`;

const ProgressDiv = styled(Flex)`
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: ${(props) => props.theme.colors.grey[500]};
	margin: auto;
`;

const ContinueContainer = styled(Flex)`
	width: 20rem;
	float: right;
	flex: 1;
	flex-direction: row-reverse;
`;

const ContinueButton = styled(Button)`
  width: 20rem;
  height: 5rem;
  background-color : ${(props) => props.test?.test_configs?.theme?.bg_color ||
	props.theme.colors.blue[500]};
 color : ${(props) => props.test?.test_configs?.theme?.primary_color ||
	props.theme.colors.white};
  margin auto 2rem;
  svg{
    vertical-align: bottom;
    width: 25px;
    font-weight: bold;
    margin-left: 10px;
  }

  @media only screen and (max-width: 1024px) {
    margin: auto 1rem;
    text-align: right;
    width: max-content;
    padding: 1rem;
    height: auto;
    font-size: 1.2rem;
  }
`;

const Progress = styled(Flex)`
	flex: 1;
	position: fixed;
	bottom: 6.9rem;
	width: 100%;
`;

const QuestionContainer = styled(Flex)`
	width: 80%;
	height: 100%;
	margin: auto;
	margin-top: 5rem;
	margin-bottom: 9rem;
	@media only screen and (max-width: 1024px) {
		width: 90%;
		margin-top: 1rem;
	}
`;

const QuestionCard = styled(Flex)`
	padding: 2rem;
	border-left: 5px solid ${(props) => props.theme.colors.white};
	&:hover {
		border-left: 5px solid ${(props) => props.test?.test_configs?.theme?.bg_color ||
			props.theme.colors.blue[500]};
		border-radius: 2px;
		box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
	}
`;

const QuestionCount = styled(Flex)`
	font-weight: normal;
	font-size: 1.4rem;
	line-height: 195%;
	color: ${(props) => props.theme.colors.grey[400]};
	@media only screen and (max-width: 1024px) {
		font-size: 1.2rem;
	}
`;

const Question = styled(Flex)`
	font-weight: 600;
	font-size: 2.6rem;
	line-height: 100%;
	color: ${(props) => props.theme.colors.grey[600]};
	flex: 1;
	align-items: center;
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@media only screen and (max-width: 1024px) {
		font-size: 1.8rem;
		margin-bottom: 0;
	}
`;

const Explanation = styled(Flex)<{ hidden?: boolean }>`
	font-size: 2rem;
	font-weight: 500;
	line-height: 100%;
	margin-bottom: 2.5rem;
	flex: 1;
	color: ${(props) => props.theme.colors.green[600]};
	${({ hidden }) =>
		hidden &&
		css`
			display: none;
		`}

	@media only screen and (max-width: 1024px) {
		font-size: 1.2rem;
		margin-bottom: 1rem;
	}
`;

const AnswerExplanation = styled.div`
	font-size: 16px;
	line-height: 19px;
	margin-left: 1rem;
	color: ${(props) => props.theme.colors.green[600]};
	@media only screen and (max-width: 1024px) {
		margin-left: 0;
		font-size: 1.4rem;
		margin-top: 0.5rem;
	}
`;

const ExplanationButton = styled(Button)`
  width: max-content;
  height: auto;
  background: rgba(64, 123, 255, 0.22);
  border-radius: 4px;
  color: ${(props) => props.theme.colors.blue[500]};
  border: 0px;
  font-size: 1.6rem
  line-height: 150%;
  svg{
    vertical-align: middle;
    font-size: 2rem;
  }
  padding: 1.2rem;
  @media only screen and (max-width: 1024px) {
    font-size: 1.2rem;
    padding: .5rem 1rem;
    svg{
      font-size: 1.5rem;
    }
  }
`;

const OptionContainer = styled(Flex)<{ selected?: boolean , test?:any }>`
	border: 1px solid ${(props) => props.theme.colors.grey[200]};
	box-sizing: border-box;
	border-radius: 10px;
	background: #d8efff;
	margin-bottom: 1rem;

	${({ selected , test , theme }) =>
		selected &&
		css`
			border-color: ${() => test?.test_configs?.theme?.bg_color ||
				theme.colors.green[400]};
			label {
				color: ${() => test?.test_configs?.theme?.primary_color ||
					theme.colors.green[400]};
			}
			input {
				border-color: ${() => 
					test?.test_configs?.theme?.bg_color ||
					theme.colors.green[400]};
				background: ${() => 
					test?.test_configs?.theme?.bg_color ||
					theme.colors.green[400]};
			}
		`}
`;
const Option = styled.input`
	width: 15px;
	height: 15px;
	&:checked {
		color: red;
	}
`;

const QuestionLabel = styled.label`
	background: #ffff;
	padding: 1.5rem;
	border-radius: 10px;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	color: ${(props) => props.theme.colors.grey[500]};
	width: 100%;
	cursor: pointer;
	flex-direction: row;
	display: flex;
	span {
		margin-left: 1rem;
		display: inherit;
	}

	@media only screen and (max-width: 1024px) {
		span {
			margin-left: 1rem;
			display: block;
		}
	}
`;

const TimerContainer = styled(Flex)<{ test?:any }>`
	width: 100%;
	font-size: 1.6rem;
	color: ${(props) => props.test?.test_configs?.theme?.primary_color ||
		 props.theme.colors.white};
	background: ${(props) =>  props.test?.test_configs?.theme?.bg_color ||
		props.theme.colors.green[600]};
	padding: 0.8rem 0;
	position: sticky;
	top: 6rem;
	z-index: 1;
	div {
		margin: auto;
	}

	@media only screen and (max-width: 1024px) {
		font-size: 1.2rem;
	}
`;

const ContainerTimer = styled(Flex)<{ test?:any }>`
	
	h2 {
			color: ${(props) => props.test?.test_configs?.theme?.primary_color ||
					props.theme.colors.white};
	}
	&.blink {
		animation: ${blink} 2s linear infinite;
	}
`;

const Seperator = styled.span`
	margin: 0 0.2rem;
`;
