// @ts-nocheck
import React from "react";
import ReactPlayer from "react-player";

interface Props {
  url: any ;
  height?: string;
  width: string;
  type?: string;
}
export const VideoPlayer = ({ height, width, url, type }: Props) => {
  return (
    <div
      style={{
        overflow: "hidden",
        width: "min-content",
        height: "min-content",
        borderRadius: "0.5rem",
      }}
    >
      {type ? (
        <video
          src={url || ""}
          controls
          style={{
            width: width,
            height: height,
            objectFit: "cover",
            borderRadius: "1rem",
          }}
        />
      ) : (
        <ReactPlayer
          controls
          url={url}
          width={width}
          height={height}
          style={{ objectFit: "cover" }}
        />
      )}
    </div>
  );
};
