import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import TestBackground from '../../../assets/test/test_background.svg';
import { useCurrentProfile } from '../../../hooks/useProfile';
import useTestQuestions from '../../../hooks/useTestQuestions';
import { blink } from '../../../styles/animations';
import './aptitudetestpage.css'
import {
	API_ENDPOINT,
	PROFILE_NAVIGATION_CTA,
	STRIDE_PROFILE_LINK,
} from '../../../utils/constants';
import {
	APIError,
	CurrentUserProfile,
	FetchResponse,
	QuestionAnswers,
} from '../../../utils/types';
import Button from '../../components/Button';
import Flex from '../../components/Flex';
import Loading from '../../components/Loading';
import Navbar from '../../components/Navbar';
import SubmitTest from '../../components/popups/SubmitTest';
import TimeExcedded from '../../components/popups/TimeExcedded';
import ErrorFallback from '.././ErrorFallback';
import CustModal from '.././quiz_pages/Intro/CustModal';
import { options } from '@amcharts/amcharts4/core';
import CalculatingPage from '../CalculatingPage';
const succssful_img = require('../../../assets/Group 34421.png');

const AptitudeTestPage = ({ testId, test }) => {


	// @ts-ignore
	const location = useLocation<CurrentUserProfile>();
	const history = useHistory();
	const { reportId } = useParams<{ reportId: string }>();
	const [reportLink, setReportLink] = useState<any>();
	const [showPrompt, setShowPrompt] = useState<boolean>(false);
	const [showCalculatingPage, setShowCalculatingPage] = useState<boolean>(false);
	const [contextId, setContextId] = useState<any>([]);

	const contextHashMap = new Map();

	const [page, setPage] = useState<number>(1);
	const [autoMoveToNext, setAutoMoveToNext] = useState<boolean>(test?.test_configs?.auto_next_page);
	const [openExplanations, setOpenExplanations] = useState<number[]>([]);

	const [answers, setAnswers] = useState<QuestionAnswers[]>([]);
	const [showSubmitConfirmation, setShowSubmitConfirmation] =
		useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [timeExcedded, setTimeExcedded] = useState<boolean>(false);

	const { data: questions, isLoading } = useTestQuestions(testId, page);

	const { isSuccess } = useCurrentProfile();

	const [isAppFlow, setIsAppFlow] = useState(false);

	// const [time, setTime] = useState(test?.test_time);
	const [time, setTime] = useState(test?.test_time);
	const timeArray =
		typeof time !== 'undefined' && time !== null && time.split(':');
	const [hours, setHours] = useState<number>(+timeArray[0]);
	const [minutes, setMinutes] = useState<number>(+timeArray[1]);
	const [seconds, setSeconds] = useState<number>(+timeArray[2]);
	const currentUrlParams = new URLSearchParams(window.location.search);
	const TestBackgroundImage = test?.test_configs?.header_background_image ?
		test?.test_configs?.header_background_image :
		TestBackground

	
	useEffect(() => {
		if (typeof time !== 'undefined' && time !== null) {

			let myInterval = setInterval(() => {


				if (seconds > 0) {
					setSeconds(seconds - 1);
				} else {
					if (minutes === 0) {
						if (hours === 0) {
							clearInterval(myInterval);
							setTimeExcedded(true);
						} else {
							setHours(hours - 1);
							setMinutes(59);
							setSeconds(59);
						}
					} else {
						setMinutes(minutes - 1);
						setSeconds(59);
					}
				}
			}, 1000);

			return () => {
				clearInterval(myInterval);
			};
		}
	});



	const getClass = () => {
		return hours === 0 ? (minutes < 5 ? 'blink' : '') : '';
	};

	useEffect(() => {
		// get params from query string
		// window.onbeforeunload = null;

		window.onbeforeunload = confirmExit;
		function confirmExit() {
			return "show warning";
		}
		const urlParams = new URLSearchParams(window.location.search);
		const flow = urlParams.get('flow');
		console.log('flow', flow)
		if (flow === 'app') {
			setIsAppFlow(true);
		}
	}, []);

	useEffect(() => {
		let mapData: any = questions?.questions;
		typeof mapData !== 'undefined' &&
			mapData
				.slice()
				.reverse()
				.map((d : any, i : any) => {
					typeof d.context !== 'undefined'
						? contextHashMap.set(d.context?.id, {
							idOfQue: d.id,
						})
						: contextHashMap.set(0, {
							idOfQue: 0,
						});
					setContextId(contextHashMap);
				});
	}, [questions]);


	useEffect(() => {
		console.log(timeArray, "135")
	})


	useEffect(() => {
		console.log("time 74", test)
	})

	const retakeTest = () => {
		history.push({
			pathname: `/test/${testId}`,
			search: location.search,
		});
	};

	const handleOptionSelect = (id: number, answer: string) => {
		const index = getQuestionIndexInAnswers(id);


		const newAnswers = [...answers];
		console.log('newAnswers', newAnswers)
		index > -1 && newAnswers.splice(index, 1);
		newAnswers.push({ id, answer });
		setAnswers([...newAnswers]);
	};

	const getQuestionIndexInAnswers = (id: number) => {
		return answers.findIndex((answer) => {
			return answer.id === id;
		});
	};

	const allQuestionAnswered = () => {
		return questions?.questions?.every(
			(question) => getQuestionIndexInAnswers(question.id) >= 0,
		);
	};

	const toggleShowExplanation = (id: number) => {
		const index = openExplanations.indexOf(id);
		const newExplanations = [...openExplanations];
		index > -1 ? newExplanations.splice(index, 1) : newExplanations.push(id);
		setOpenExplanations([...newExplanations]);
	};

	const getSelectedAnswer = (id: number) => {
		const index = getQuestionIndexInAnswers(id);
		return answers[index];
	};

	const submitTest = async (): Promise<APIError[] | void> => {
		const urlSearchParams = new URLSearchParams(history.location.search);
		const redirectUrl = urlSearchParams.get('redirect_to');
		const testFlow = urlSearchParams.get('flow');
		const skipFeedbackParam = urlSearchParams.get('skip_feedback');
		const skipFeedbackConfig = test.test_configs?.skip_feedback_page;
		let url = new URL(`${API_ENDPOINT}questions/${testId}`);
		window.onbeforeunload = null;
		// setShowCalculatingPage(true)
		// console.log('line 225', showCalculatingPage)
		url.search = urlSearchParams.toString();
		try {
			const postData = {
				report_id: reportId,
				response: [...answers],
			};
			const { data: response } = await axios.post<
				FetchResponse<{ report_link: string }>
			>(
				url.href,
				postData,
			);
			setShowSubmitConfirmation(false);
			if (testFlow == 'app') {
				setIsModalOpen(true);
				setReportLink(response.data.report_link);

			} else {
				if (redirectUrl) {
					document.location.href = redirectUrl;
				}
				else if(skipFeedbackConfig === false && test.redirect_url){
					window.location.href = test.redirect_url
				}
				 else if (
					skipFeedbackParam?.toLowerCase() == 'true' ||
					skipFeedbackConfig
				) {
					window.parent.location.href = response.data.report_link;
				} else {
					history.push({
						pathname: `/feedback/${testId}/${reportId}`,
						state: {
							reportLink: response.data.report_link,
							name: location.state.name,
							email: location.state.email,
						},
					});
				}
			}
		} catch (error) {
			const errors = error?.response?.message;
			return errors;
		}
	};

	useEffect(() => {
		// @ts-ignore
		console.log("230", questions?.questions[0])
	})
	// @ts-ignore
	const renderQuestions = () => {
		return (
			<QuestionContainer column test={test}>
				{contextId.size > 0 &&
					questions?.questions?.map(
						({ id, question, context_required, options, context }, index) => {
							const idFromHash = contextId.get(context?.id)?.idOfQue;

							// @ts-ignore
							return (
								<>
									{context_required && idFromHash == id && (
										<>
											<div
												dangerouslySetInnerHTML={{ __html: context.data }}
											></div>
										</>
									)}
									<QuestionCard column key={`${id}-${index}`} test={test}>
										<QuestionCount>{`Question ${index +
											1 +
											(page - 1) * questions?.pagination_data?.page_size
											}`}</QuestionCount>
										<Flex style={{ marginBottom: '1rem' }}>
											<Question className='d-flex justify-content-center text-center'>
												{' '}

												<div className="question_container"
													dangerouslySetInnerHTML={{ __html: question }}
												></div>{' '}

											</Question>
										</Flex>
										{/* {questions?.questions[0]?.options[0]} */}
										{/* {questions?.questions[0]?.options.map(({ text, label, value, image }: any, index) => {
											console.log('268', index)
											return (
												<></>
											)
										})} */}

										{/* 								
										{questions?.questions[0]?.options.map(option => option.image )
											? */}
										<div
											style={{ width: "100%", flexWrap: 'wrap', justifyContent: 'space-between', display: "flex", flexDirection: "row" }}>
											{options.map(
												({ text, label, value, image }: any, index) => {
													return (
														<OptionContainer
															test={test}

															className={image ? "option_image_width" : "option_content_width"}
															key={`${id}-${index}`}
															selected={getSelectedAnswer(id)?.answer === value}


														>

															<QuestionLabel className='d-flex align-items-center'>
																<Option test={test}
																	type='radio'
																	value={value}
																	id={`${id}`}
																	name={`${id}`}
																	onChange={() => handleOptionSelect(id, value)}
																	required
																	checked={
																		getSelectedAnswer(id)?.answer === value
																	}
																/>

																<span>
																	{image ? (
																		<div className='option_content_container'>
																			<p>{text ? text?.en : ''}</p>
																			<img
																				src={
																					'https://d1dhn4cz1wmlqo.cloudfront.net/' +
																					image
																				}
																			// style={{
																			// 	width: "auto",
																			// 	height: "auto"

																			// }}
																			/>
																		</div>
																	) : (
																		label
																	)}
																</span>
															</QuestionLabel>
														</OptionContainer>
													);
												},
											)}
										</div>
										{/* :
											<div>This array does not contain any images</div>
										} */}












									</QuestionCard >
								</>
							);
						},
					)}
			</QuestionContainer >
		);
	};
	const getProgress = () => {
		const pagination = questions?.pagination_data;
		return pagination && pagination.page_elements
			? Math.round((answers.length * 100) / pagination?.page_elements)
			: 0;
	};



	const handleContinue = (e) => {
		setPage(page + 1)
		test.test_configs?.auto_next_page && setAutoMoveToNext(true)
	}


	const renderTestDetail = () => {
		return (
			<TestName test={test} TestBackgroundImage={TestBackgroundImage}>
				<img src={TestBackgroundImage} alt='Background' />
				<div>
					{test?.test_display_label
						? test?.test_display_label
						: test?.test_name}
				</div>
			</TestName>
		);
	};

	const renderProgress = () => {
		if (questions?.pagination_data?.page_no) {
			const pages = Math.ceil(
				questions?.pagination_data?.page_elements
					? questions?.pagination_data?.page_elements /
					questions?.pagination_data?.page_size
					: 1,
			);
			var foo: number[] = [];

			for (var i = 1; i <= pages; i++) {
				foo.push(i);
			}
			return foo;
		}
	};

	const renderFooter = () => {
		return (
			<Footer>
				<GoBackContainer>
					<GoBackButton
						disabled={!questions?.pagination_data?.page_previous}
						onClick={() => {
							setPage(page - 1)
							test.test_configs?.auto_next_page && setAutoMoveToNext(false)
						}}
					>
						<HiOutlineArrowLeft />
						Go Back
					</GoBackButton>
				</GoBackContainer>
				<ProgressContainer>
					<ProgressDiv>{getProgress()} %</ProgressDiv>
				</ProgressContainer>
				<ContinueContainer test={test}>
					{questions?.pagination_data?.page_next ? (
						<ContinueButton
							test={test}
							disabled={!allQuestionAnswered()}
							onClick={handleContinue}
						>
							Continue <HiOutlineArrowRight />
						</ContinueButton>
					) : (
						<ContinueButton
							disabled={!allQuestionAnswered()}
							onClick={() => setShowSubmitConfirmation(true)}
							test={test}
						>
							Submit Test
							<HiOutlineArrowRight />
						</ContinueButton>
					)}
				</ContinueContainer>
			</Footer>
		);
	};

	useEffect(() => {
		if (!isSuccess && !location?.state?.name) {
			history.push({
				pathname: `/test/${testId}`,
				search: location.search,
			});
		}
		if (timeExcedded && test?.auto_submit) {
			submitTest();
		}
	}, [isSuccess, location, answers, time, timeExcedded]);

	useEffect(() => {
		if (autoMoveToNext && allQuestionAnswered() && questions?.pagination_data?.page_next) {
			setPage(page + 1)
		}
	}, [answers])


	if (!isLoading) {
		// if (showCalculatingPage) {
		// 	return (
		// 		<CalculatingPage />)
		// }

		const customLogo = test?.logo_image;
		return questions?.questions ? (
			<Fragment>
				<Navbar homepage='https://strideahead.in' customLogo={customLogo} />
				<Container column>
					{/* {typeof test?.test_time !== 'undefined' && test?.test_time !== null && ( */}
					{test?.test_time === null || test?.test_time === undefined || test?.test_time === ""
						?
						""
						:

						<TimerContainer test={test}>

							<ContainerTimer test={test} className={getClass()}>

								<h2>
									{hours < 10 ? `0${hours}` : hours}
									<Seperator>:</Seperator>
									{minutes < 10 ? `0${minutes}` : minutes}
									<Seperator>:</Seperator>
									{seconds < 10 ? `0${seconds}` : seconds}
								</h2>
							</ContainerTimer>
						</TimerContainer>
					}

					{/* )} */}
					{renderTestDetail()}
					{renderQuestions()}
					{/* {showCalculatingPage === true ? <CalculatingPage /> : ''} */}

					<Progress>
						{renderProgress()?.map((page, index) => {
							return (
								<ProgressBar test={test}
									key={index}
									completed={
										questions && page <= questions?.pagination_data?.page_no
									}
								/>
							);
						})}
					</Progress>
					{renderFooter()}
				</Container>
				{showSubmitConfirmation && (
					<SubmitTest
						test={test}
						onClose={() => setShowSubmitConfirmation(false)}
						onSubmit={() => submitTest()}
					/>

				)}
				{isModalOpen && (
					<CustModal
						hideCloseButton={true}
						open={isModalOpen}
						onClose={() => setIsModalOpen(false)}
					>
						<div className='pop_box_sucessfull_container'>
							<div className='sucessfull_img_container'>
								<img
									src={succssful_img.default}
									alt='sucessfull_img_'
									className='sucessfull_img_self'
								/>
							</div>

							<h2 className='test_completed_text'>Test Completed</h2>
							<p className='text_succes_extra_content'>
								Your report is being generated, and will be ready shortly
							</p>
							<div
								className='success-btn-wrapper'
								style={{ justifyContent: 'center', gap: '15px' }}
							>
								<button
									className='done_btn'
									onClick={() => {
										setIsModalOpen(false);
										window.location.replace(`${reportLink}` + '?' + currentUrlParams.toString());
									}}
								>
									VIEW REPORT
								</button>
								{isAppFlow && (
									<button
										className='profile_navigation_btn'
										onClick={() => {
											setIsModalOpen(false);
											window.location.replace(`${STRIDE_PROFILE_LINK}`);
										}}
									>
										{PROFILE_NAVIGATION_CTA}
									</button>
								)}
							</div>
						</div>
					</CustModal>
				)}
				{timeExcedded &&
					(test?.auto_submit ? (
						<Loading message='Submitting your Test. Please wait...' />
					) : (
						<TimeExcedded onClick={() => retakeTest()} />
					))}

			</Fragment>
		) : (
			<ErrorFallback error='500' showNavbar={false} />
		);

	} else {
		return <>{<Loading />}</>;
	}
};

export default AptitudeTestPage;

const Container = styled(Flex)`
	width: 100%;
	font-family:  ${(props) =>
		props.test?.test_configs?.theme?.primary_font || "Poppins"};
	background-color: ${(props) => props.theme.colors.white};
	height: auto;
	margin: 0px auto;
`;

const TestName = styled(Flex) <{ TestBackgroundImage?: any }>`
	position: relative;
	width: 80%;
	font-family:  ${(props) =>
		props.test?.test_configs?.theme?.primary_font || "Poppins"};
	margin: 0px auto;
	margin-top: 6rem;
	font-weight: 500;
	font-size: 3.4rem;
	align-items: center;

	img {
		width: 100%;
		object-fit: cover;
		height: 100%;
	}

	div {
		position: absolute;
		margin: 0rem 4rem;
		color: ${(props) => props.theme.colors.white};
	}

	@media only screen and (max-width: 1024px) {
		width: 90%;
		margin-top: 6rem;
		font-size: 1.8rem;
		padding: 1rem 0;
		background-image: url(${(props) => props.TestBackgroundImage});
		height: 6rem;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 4px;
		img {
			display: none;
		}
		div {
			margin: 0rem 1rem;
		}
	}
`;

const Footer = styled(Flex)`
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 6.8rem;
	background: ${(props) => props.theme.colors.white};
	@media only screen and (max-width: 1024px) {
		height: 5rem;
	}
`;

const GoBackContainer = styled(Flex)`
	width: 20rem;
	flex: 1;
	background: none;
	border: 0px;
	color: ${(props) => props.theme.colors.grey[500]};
`;

const GoBackButton = styled(Button)`
	color: ${(props) => props.theme.colors.grey[500]};
	width: 20rem;
	height: 5rem;
	margin: auto 2rem;
	background-color: ${(props) => props.theme.colors.white};
	font-weight: 600;
	font-size: 1.6rem;
	line-height: 150%;
	border: 0px;
	svg {
		vertical-align: middle;
		width: 25px;
		font-weight: bold;
		margin-right: 10px;
	}

	@media only screen and (max-width: 1024px) {
		margin: auto 1rem;
		width: max-content;
		text-align: left;
		font-size: 1.2rem;
	}
`;

const ProgressContainer = styled(Flex)`
	width: 20rem;
	flex: 1;
	color: ${(props) => props.theme.colors.blue[500]};
`;

const ProgressBar = styled(Flex) <{ completed?: boolean, test?: any }>`
	height: 0.5rem;
	width: 100%;
	background-color: ${(props) => props.theme.colors.blue[200]};

	${({ completed, test, theme }) =>
		completed &&
		css`
		background-color: ${() =>
				test?.test_configs?.theme?.bg_color ||
				theme.colors.blue[500]};
				
		`}
	border-right: 2px solid ${(props) => props.theme.colors.red[100]};
`;

const ProgressDiv = styled(Flex)`
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: ${(props) => props.theme.colors.grey[500]};
	margin: auto;
`;

const ContinueContainer = styled(Flex)`
	font-family:  ${(props) =>
		props.test?.test_configs?.theme?.primary_font || "Poppins"};
	width: 20rem;
	float: right;
	flex: 1;
	flex-direction: row-reverse;
`;

const ContinueButton = styled(Button)`
  width: 20rem;
  display: flex;
  align-items: center;
	justify-content: center;
  height: 5rem;
  background-color : ${(props) => props.test?.test_configs?.theme?.bg_color ||
		props.theme.colors.blue[500]};
  color : ${(props) => props.theme.colors.white};
  margin auto 2rem;
  svg{
    vertical-align: bottom;
    width: 25px;
    font-weight: bold;
    margin-left: 10px;
  }

  @media only screen and (max-width: 1024px) {
    margin: auto 1rem;
    text-align: right;
    width: max-content;
    padding: 1rem;
    height: auto;
    font-size: 1.2rem;
  }
`;

const Progress = styled(Flex)`
	flex: 1;
	position: fixed;
	bottom: 6.9rem;
	width: 100%;
	@media only screen and (max-width: 700px) {
		bottom: 5rem;
	  }
`;

const QuestionContainer = styled(Flex)`
	font-family:  ${(props) =>
		props.test?.test_configs?.theme?.primary_font || "Poppins"};
	width: 80%;
	height: 100%;
	margin: auto;
	margin-top: 5rem;
	margin-bottom: 9rem;
	@media only screen and (max-width: 1024px) {
		width: 90%;
		margin-top: 1rem;
	}
`;

const QuestionCard = styled(Flex)`
	padding: 2rem;
	border-left: 5px solid ${(props) => props.theme.colors.white};
	&:hover {
		border-left: 5px solid ${(props) => props.test?.test_configs?.theme?.bg_color ||
		props.theme.colors.blue[500]};
		border-radius: 2px;
		box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
	}

	@media(max-width:767px){
		padding: 0;
	}
`;

const QuestionCount = styled(Flex)`
	font-weight: normal;
	font-size: 1.4rem;
	line-height: 195%;
	color: ${(props) => props.theme.colors.grey[400]};
	@media only screen and (max-width: 1024px) {
		font-size: 1.2rem;
	}
`;

const Question = styled(Flex)`
	font-weight: 600;
	font-size: 2.6rem;
	line-height: 100%;
	color: ${(props) => props.theme.colors.grey[600]};
	flex: 1;
	align-items: center;
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@media only screen and (max-width: 1024px) {
		font-size: 1.8rem;
		margin-bottom: 0;
	}
`;

const Explanation = styled(Flex) <{ hidden?: boolean }>`
	font-size: 2rem;
	font-weight: 500;
	line-height: 100%;
	margin-bottom: 2.5rem;
	flex: 1;
	color: ${(props) => props.theme.colors.green[600]};
	${({ hidden }) =>
		hidden &&
		css`
			display: none;
		`}

	@media only screen and (max-width: 1024px) {
		font-size: 1.2rem;
		margin-bottom: 1rem;
	}
`;

const AnswerExplanation = styled.div`
	font-size: 16px;
	line-height: 19px;
	margin-left: 1rem;
	color: ${(props) => props.theme.colors.green[600]};
	@media only screen and (max-width: 1024px) {
		margin-left: 0;
		font-size: 1.4rem;
		margin-top: 0.5rem;
	}
`;

const ExplanationButton = styled(Button)`
  width: max-content;
  height: auto;
  background: rgba(64, 123, 255, 0.22);
  border-radius: 4px;
  color: ${(props) => props.theme.colors.blue[500]};
  border: 0px;
  font-size: 1.6rem
  line-height: 150%;
  svg{
    vertical-align: middle;
    font-size: 2rem;
  }
  padding: 1.2rem;
  @media only screen and (max-width: 1024px) {
    font-size: 1.2rem;
    padding: .5rem 1rem;
    svg{
      font-size: 1.5rem;
    }
  }
`;

const OptionContainer = styled(Flex) <{ selected?: boolean, test?: any }>`
	border: 1px solid ${(props) => props.theme.colors.grey[200]};
	box-sizing: border-box;
	border-radius: 10px;
	background: #d8efff;
	margin-bottom: 1rem;

	${({ selected, test, theme }) =>
		selected &&
		css`
			border-color: ${() => test?.test_configs?.theme?.bg_color ||
				theme.colors.green[400]};
			label {
				color: ${() => test?.test_configs?.theme?.primary_color ||
				theme.colors.green[400]};
			}
			input {
				border-color: ${() =>
				test?.test_configs?.theme?.bg_color ||
				theme.colors.green[400]};
				background: ${() =>
				test?.test_configs?.theme?.bg_color ||
				theme.colors.green[400]};
			}
		`}
`;
const Option = styled.input<{ test?: any }>`
	width: 15px;
	height: 15px;
	&:checked {
		color: props.test?.test_configs?.theme?.bg_color ||
		red;
	}
`;

const QuestionLabel = styled.label`
	background: #ffff;
	padding: 1rem;
	border-radius: 10px;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	color: ${(props) => props.theme.colors.grey[500]};
	width: 100%;
	cursor: pointer;
	flex-direction: row;
	display: flex;
	span {
		margin-left: 1rem;
		display: inherit;
	}

	@media only screen and (max-width: 1024px) {
		span {
			margin-left: 1rem;
			display: block;
			font-size: 12px;
		}
	}
`;

const TimerContainer = styled(Flex) <{ test?: any }>`
	width: 100%;
	font-size: 1.6rem;
	color: ${(props) => props.test?.test_configs?.theme?.primary_color ||
		props.theme.colors.white};
	background: ${(props) => props.test?.test_configs?.theme?.bg_color ||
		props.theme.colors.green[600]};
	padding: 0.8rem 0;
	position: sticky;
	top: 6rem;
	z-index: 1;
	div {
		margin: auto;
	}

	@media only screen and (max-width: 1024px) {
		font-size: 1.2rem;
	}
`;

const ContainerTimer = styled(Flex) <{ test?: any }>`
	
	h2 {
		margin:0;
			color: ${(props) => props.test?.test_configs?.theme?.primary_color ||
		props.theme.colors.white};
	}
	&.blink {
		animation: ${blink} 2s linear infinite;
	}
`;

const Seperator = styled.span`
	margin: 0 0.2rem;
`;
