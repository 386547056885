import styled from 'styled-components';
import Flex from './Flex';
import { Text1Css } from './Typography';

interface MenuIProps {
  right?: string;
  top?: string;
  left?: string;
  bottom?: string;
}

export const DropdownItem = styled(Flex)`
  width: 100%;
  padding: 1.5rem 2rem;
  ${Text1Css}
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[100]};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  }
`;

export const DropdownMenu = styled(Flex)<MenuIProps>`
  width: 20rem;
  position: absolute;
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  background-color: #fff;
  box-shadow: 0 2px 1rem ${({ theme }) => theme.colors.grey[100]};
  border-radius: 3px;
  overflow: hidden;
`;
