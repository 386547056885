import React from "react";
import "./difftypebox.css";

function DiffTypeBox(props) {
  return (
    <div className="lost_container">
      <div className="left_lost_container">
        {/* <div className="lost_img_container"> */}
        <div className={props.rateImgContainerStyle}>
          <img
            src={props.imgAboutName}
            alt="lost_img"
            className="lost_img_self"
          />
        </div>
      </div>

      <div className="lost_content_container">
        <h6 className="lost_text">{props.titleAboutText}</h6>
        <div className="lost_under_line" />
        <p className="lost_extra_content">{props.extraAboutText}</p>
      </div>
    </div>
  );
}

export default DiffTypeBox;
