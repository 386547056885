import React from 'react';
// @ts-ignore
import RadarChart from 'react-svg-radar-chart';
import 'react-svg-radar-chart/build/css/index.css'
import './RadarComponent.css'
import {useParams} from "react-router-dom";
import {useAnalysisReport} from "../../../hooks/useAnalysisReport";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Plugin,
    Legend,
} from 'chart.js';
import {Radar} from 'react-chartjs-2';


function RaderDiagram() {
    const {token} = useParams<{ token: string }>();
    const {data: reportMeta} = useAnalysisReport(token);
    const reportData = reportMeta?.report_metadata;
    console.log(reportData)
    ChartJS.register(
        RadialLinearScale,
        PointElement,
        LineElement,
        Filler,
        Tooltip,
        // Legend
    );

    const data = {
        labels: reportData?.dimensions.map((dimension: any) => {
            return `${dimension?.name}: ${dimension?.percentage}%`;
        }),
        datasets: [
            // data
            {
                label: 'Your Performance',
                data :  reportData?.dimensions.map((dimension: any) => {
                    return `${dimension?.percentage/100}`;
                }),
                backgroundColor: 'rgba(227, 58, 47, 0.1)',
                borderColor: '#E33A2F',
                borderWidth: '2px'
            },
            {
                label: 'Top Candidates',
                data: [0.41, 1, 0.29, 0.7, 0.8].slice(0,reportData?.dimensions?.length),
                backgroundColor: 'rgba(26, 100, 214, 0.1)',
                borderColor: '#1A64D6',
                borderWidth: '2px'
            },
            {
                label: 'Average',
                data: [0.81, 0.94, 0.89, 0.79, 0.85].slice(0,reportData?.dimensions?.length),
                backgroundColor: 'rgba(47, 150, 101, 0.1)',
                borderColor: ' #2F9665',
                borderWidth: '1.6px'
            },
        ],

    };
    
    const options = {
            scales: {
                r: {
                    ticks: {
                        display:  false
                    },
                    pointLabels: {
                        color: '#192A46',
                        // backdropColor:"#000",
                        font: {
                            size: window.innerWidth >= 768 ? 13 : 7
                        }
                    }
                },
            }
    }
    return (
        <div className="radar-diagram">
            {console.log(data.labels)}
            <Radar
                //@ts-ignore
                data={data}
                options={options}
                type={"radar"}/>
        </div>

    );
}


export default RaderDiagram;