import React, {useEffect, useState} from 'react';
import chartImage1 from "../../../assets/images/chart.png";
import styled from "styled-components";

export default function RedutechThreeMonthReport({reportMeta, courseType}: any) {

    const [dataType, setDataType] = useState<any>();
    const data = {
        data1: [
            {
                title: "Fulfill Industry Demand",
                content: "Our course is designed to fulfill the growing demand for big data analytics professionals, with job openings projected to grow by 19% over the next decade."
            }, {
                title: "High-Paying Career",
                content: "With our course, you can earn a high-paying income, with a salary of up to 25 lakhs per year in big data analytics."
            }, {
                title: "Transferable Skills",
                content: "The skills you gain from our course are highly transferable, with 83% of big data professionals reporting that they have been able to apply their skills to multiple industries."
            },
        ],
        data2: [
            {
                title: "Fulfill Industry Demand",
                content: "Our Course Provides In-Demand Skills for Cloud Computing professionals with job openings projected to grow by 16% over the next decade."
            }, {
                title: "High-Paying Career",
                content: "Cloud computing jobs are some of the highest-paying in the tech industry.  Earn a Salary of up to 22 lakhs per year with Cloud Computing Jobs."
            }, {
                title: "Transferable Skills",
                content: "74% of employers find a shortage of cloud computing skills in job candidates. Gain Highly Transferable Skills and Become an Indispensable Asset."
            },
        ],
        data3: [
            {
                title: "Fulfill Industry Demand",
                content: "Our Course Provides In-Demand Skills for a Fulfilling Career in Cyber Security, with a projected job growth of 31% over the next decade. "
            }, {
                title: "High-Paying Career",
                content: "Cybersecurity jobs are some of the highest-paying in the tech industry. Earn a Salary of up to 20 lakhs per year with Cyber Security Jobs."
            }, {
                title: "Transferable Skills",
                content: "82% of employers report a shortage of cyber security skills in the workforce.  Gain Highly Transferable Skills and Become an Indispensable Asset."
            },
        ]
    }

    useEffect(() => {
        if (reportMeta?.report_metadata?.recommended_course === "CC") {
            setDataType(data?.data2)
        } else if (reportMeta?.report_metadata?.recommended_course === "CS") {
            setDataType(data?.data3)
        } else if (reportMeta?.report_metadata?.recommended_course === "BDA") {
            setDataType(data?.data1)
        }
    }, [reportMeta?.report_metadata?.recommended_course])

    return (
        <div className="three-month-section">
            <div className="container-md">
                <div className="page-title2 pb-5">
                    <h3 style={{color: "#8B292A"}}>Personalized Recommendation!</h3>
                </div>
                <SubHeading className="d-flex justify-content-center">
                    Unlock your {courseType} potential and stride ahead in your career with
                    our course - Here's why!
                </SubHeading>
                <Divider>
                    <hr/>
                </Divider>
                <div className="three-month-grid">
                    <div className="three-month-grid-items">
                        {/* <h3>Stop Feeling Overwhelmed</h3>
          <p>Increase your productivity levels</p> */}
                        <img src={chartImage1} alt="chartImage"/>
                    </div>
                    <div className="three-month-grid-items">
                        <div className="all-text-alignment">
                            {dataType?.map((value, index) => {
                                return (
                                    <div>
                                        <h4>{value?.title}</h4>
                                        <h6>
                                            {value?.content}
                                        </h6>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const SubHeading = styled.h4`
     color: #3D3636;
     font-weight: 600;
     font-size: 24px;
     text-align:center;
     @media (max-width: 768px){
       font-size:14px;
     }
`;

const Divider = styled.div`
   width:100%;
   display:flex;
   justify-content:center;
   align-items: center;
   
   hr{
    width: 150px;
    height: 4px;
    border-radius: 50%;
    background: #8B292A;
    opacity: 1;
    border: none;
    margin-top:20px;
    margin-bottom:40px;
   }
`;

