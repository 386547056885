import {  useEffect, useMemo } from "react";
import React, { useState } from 'react'
import ReactQuill, { Quill } from "react-quill";

export default function RandomQuizMetaForm(props){
    const {addFormFields, removeFormFields, imageHandler} = props;
    return (
        <div>
            <label>Quiz Meta</label>
            {props.quizMeta.map((element: any, index: any) => (
                <div className="form-inline" key={index}>
                <br />
                <div style={{ border: "3px solid black", padding: "10px" }}>
                    <h3>Title </h3>
                    <input
                    type="text"
                    name="title"
                    value={props.quizMeta[index].title}
                    onChange={(e: any) => {
                        let newFormValues: Array<any> = [...props.quizMeta];
                        // @ts-ignore: Object is possibly 'null'.
                        newFormValues[index] = {
                        ...newFormValues[index],
                        title: e.target.value,
                        };
                        props.onChange(newFormValues);
                    }}
                    />
                    <h3>Description </h3>
                    <input
                    type="text-area"
                    name="description"
                    value={props.quizMeta[index].description}
                    onChange={(e: any) => {
                        let newFormValues: Array<any> = [...props.quizMeta];
                        // @ts-ignore: Object is possibly 'null'.
                        newFormValues[index] = {
                        ...newFormValues[index],
                        description: e.target.value,
                        };
                        props.onChange(newFormValues);
                    }}
                    />
                    <h3>Image </h3>
                    <input
                    type="file"
                    name="image"
                    onChange={(e: any) => {
                        imageHandler(e, index);
                    }}
                    />
                </div>
                <button
                    type="button"
                    className="button remove"
                    onClick={() => removeFormFields(index)}
                >
                    Remove
                </button>
                </div>
            ))}

            <div className="button-section">
                <button
                className="button add"
                type="button"
                onClick={() => addFormFields()}
                >
                Add
                </button>
            </div>
            </div>
    )
}