import React from 'react'
import {Fragment} from "react";
import {data} from "../../../data";
import styles from "../../../styles/ProgramFor.module.css";

const {content, title} = data.programForSection;

export default function ProgramDetail() {
    return (
        <div className="container-md">
            <div className={styles.mainContainer}>
                <h1>{title}</h1>
                <hr/>
                <div className={styles.contentContainer}>
                    {content?.map((item, i) => (
                        <div className={styles.content} key={i}>
                            <img src={item.image}/>
                            <h4>{item.heading}</h4>
                            {item.description1.map((des, i) => (
                                <Fragment key={i}>
                                    {des.bold ? <b>{des.bold}</b> : <p>{des.normal}</p>}
                                </Fragment>
                            ))}
                            <br/>
                            <br/>
                            {item.description2.map((des, i) => (
                                <Fragment key={i}>
                                    {des.bold ? <b>{des.bold}</b> : <p>{des.normal}</p>}
                                </Fragment>
                            ))}
                        </div>
                    ))}
                </div>
                <div className={styles.applyButtonStyle}>
                    <a href="#payment">
                        <button>Take me there</button>
                    </a>
                </div>
            </div>
        </div>
    )
}
