import React, { useState } from "react";
import CareerImage from "../../../assets/skillsicon/job specific.svg";
import TajIcon from "../../../assets/images/taj.svg";
import CareerImage1 from "../../../assets/skillsicon/soft skills.svg";
import CareerImage2 from "../../../assets/skillsicon/work skills.svg";
import Branding from "../../../assets/skillsicon/Branding.svg";
import Creating from "../../../assets/skillsicon/Creating.svg";
import Industry from "../../../assets/skillsicon/Industry.svg";
import interpersonal from "../../../assets/skillsicon/interpersonal.svg";
import Interview from "../../../assets/skillsicon/Interview.svg";
import Jobsearch from "../../../assets/skillsicon/Job search.svg";
import Management from "../../../assets/skillsicon/Management.svg";
import Manager from "../../../assets/skillsicon/manager.svg";
import TeamManagement from "../../../assets/skillsicon/Team Management.svg";
import Using from "../../../assets/skillsicon/using.svg";
import Speaking from "../../../assets/skillsicon/Speaking.svg";
import telling from "../../../assets/skillsicon/telling.svg";
import ProfileBuilding from "../../../assets/skillsicon/Profile Building.svg";


export default function CareerSection() {
  const [textOpen, setTextOpen] = useState<any>(0);
  const [textOpen2, setTextOpen2] = useState<any>(0);
  const [textOpen3, setTextOpen3] = useState<any>(0);

  let jobSpecific = [
    {
      heading: "Be an effective and efficient manager",
      content: ["SMART Goal Techniques", "Project Planning using trending tools and techniques", "Project scope and its divergence"],
      icon:Manager
    },
    {
      heading: "Team Management",
      content: ["Monitoring the performance of team", "Project Risk Management", "Conflict Resolution"],
      icon:TeamManagement
    },
    {
      heading: "Management of Stakeholders",
      content: ["Management of Clients", "How to make interactive dashboards to show to clients", "Spreadsheet as a Management tool"],
      icon:Management
    },
    {
      heading: "Ensuring Productivity using Trello",
      content: ["Basic functionalities and customization", "Trello quick tips", "Kanban & Kanban Boards"],
      icon:Using
    },
    {
      heading: "Other Industry relevant tools",
      content: ["JIRA-fundamentals", "Basics of Asana", "Importance of these tools"],
      icon:Industry
    },
  ];
  let softSkill = [
    {
      heading: "Art of Creating Interactive Presentations and Proposals",
      content: ["Apply powerful communication techniques", "Recognize diversity and create inclusiveness"],
      icon:Creating
    },
    {
      heading: "Advance your interpersonal skills to build rapport",
      icon:interpersonal,
      content: ["Effective way to understand your customers, colleagues and management", "Adaptability to respond spontaneously; sharing expert knowledge"],
    },
    {
      heading: "Art of Public Speaking and presenting your ideas",
      icon:Speaking,
      content: [" Self-assertion techniques that soothe the speaker and convince the listener", "Navigate in critical conversations where every word counts"],
    },
    {
      heading: "Understand the Art of negotiation & Story telling",
      icon:telling,
      content: ["Organize your thoughts into clear business-appropriate plans for discussion", "Verbal, Non verbal & Email communication"],
    }
  ];

  let workSkill = [
    {
      heading: "Personal Branding",
      content: ["Basics on how to start building your profile on Linked-In", "How to craft your personal brand story"],
      icon:Branding
    },
    {
      heading: "Profile Building & Cover letter",
      icon:ProfileBuilding,
      content: ["How to customize your resumes and cover letters to match each job opportunity", "DOs and DON'Ts of Resume","Including the most relevant keywords"],
    },
    {
      heading: "Interview and Etiquette",
      icon:Interview,
      content: ["How to answer questions using STAR Method", "Importance of Body Language","Pitch, Tone and Intonation during the interview","Business/Interview Attire and Etiquette"],
    },
    {
      heading: "Basics of Networking and Job search",
      icon:Jobsearch,
      content: ["Creating your profile clickable and searchable", "How to be on top of the search results","Leveraging content creation on LinkedIn to land jobs","Hacks to crack entry level management role interviews in various domains"],
    }
  ];


const jobSpecificHandler=(key:any)=>{

        setTextOpen(key)
    
}
  return (
    <div className="career-section-alignment">
      <div className="container-md">
        <div className="page-title">
          <h4>
            Management Associate Program will equip you with Hard,Soft and Job Readiness skills with Placement Opportunities.
          </h4>
          <p>Cohort program will help you to excel your management career</p>
        </div>
        <div className="two-col-grid">
          <div className="two-col-grid-items">
            <div className="image-style">
              <img src={CareerImage} alt="CareerImage" />
            </div>
          </div>
          <div className="two-col-grid-items">
            <div className="new-dummy-text-style">
              <p>Job specific Skills</p>
              <span>These are certain aptitudes or capacities that a person can have and objectively exhibit.</span>
            </div>
            <div className="all-question-box-alignment">
              {jobSpecific?.map((job,i:any) => {
                return (
                  <div className="question-box" key={i}>
                    <div className="question-box-header">
                      <div className="question-box-header-items">
                        <div className="taj-icon">
                          <img src={job?.icon} alt="TajIcon" />
                        </div>
                      </div>
                      <div className="question-box-header-items" onClick={() => textOpen == i ? setTextOpen(-1) : setTextOpen(i)}>
                        <h6>{job?.heading}</h6>
                      </div>
                      {textOpen == i ? <div className="question-box-header-items" onClick={() => setTextOpen(-1)}>
                        <div className="action-button-blue">-</div>
                      </div>:<div className="question-box-header-items" onClick={() => setTextOpen(i)}>
                        <div className="action-button">+</div>
                      </div>}
                    </div>
                    <div className={textOpen == i ? "new-text-show" : "new-text-hidden"}>
                      {job?.content?.map((con,i) => {
                        return (
                          <div className="text-style" key={i}>
                            <p>{i + 1 }.  {con}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="new-col-grid">
          <div className="new-col-grid-items">
            <div className="new-dummy-text-style">
              <p>Soft skills</p>
              <span>These skills help us to communicate our ideas clearly and persuasively, solve problems creatively, and adapt to change.</span>
            </div>
            <div className="all-question-box-alignment">
              {softSkill?.map((job,i:any) => {
                return (
                  <div className="question-box">
                    <div className="question-box-header">
                      <div className="question-box-header-items">
                        <div className="taj-icon">
                          <img src={job?.icon} alt="TajIcon" />
                        </div>
                      </div>
                      <div className="question-box-header-items" onClick={() => textOpen2 == i ? setTextOpen2(-1) : setTextOpen2(i)}>
                        <h6>{job?.heading}</h6>
                      </div>
                      {textOpen2 == i ? <div className="question-box-header-items" onClick={() => setTextOpen2(-1)}>
                        <div className="action-button-blue">-</div>
                      </div>:<div className="question-box-header-items" onClick={() => setTextOpen2(i)}>
                        <div className="action-button">+</div>
                      </div>}
                    </div>
                    <div className={textOpen2 == i ? "new-text-show" : "new-text-hidden"}>
                      {job?.content?.map((con,i) => {
                        return (
                          <div className="text-style" key={i}>
                            <p>{i + 1 }.  {con}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="new-col-grid-items">
            <div className="new-image-style">
              <img src={CareerImage1} alt="CareerImage1" />
            </div>
          </div>
        </div>
        <div className="two-col-grid">
          <div className="two-col-grid-items">
            <div className="image-style">
              <img src={CareerImage2} alt="CareerImage" />
            </div>
          </div>
          <div className="two-col-grid-items">
            <div className="new-dummy-text-style">
              <p>Work Skills</p>
              <span>Skills are a set of skills and behaviors that are necessary to get any job.</span>
            </div>
            <div className="all-question-box-alignment">
              {workSkill?.map((job,i:any) => {
                return (
                  <div className="question-box">
                    <div className="question-box-header">
                      <div className="question-box-header-items">
                        <div className="taj-icon">
                          <img src={job?.icon} alt="TajIcon" />
                        </div>
                      </div>
                      <div className="question-box-header-items" onClick={() => textOpen3 == i ? setTextOpen3(-1) : setTextOpen3(i)}>
                        <h6>{job?.heading}</h6>
                      </div>
                      {textOpen3 == i ? <div className="question-box-header-items" onClick={() => setTextOpen3(-1)}>
                        <div className="action-button-blue">-</div>
                      </div>:<div className="question-box-header-items" onClick={() => setTextOpen3(i)}>
                        <div className="action-button">+</div>
                      </div>}
                      
                    </div>
                    <div className={textOpen3 == i ? "new-text-show" : "new-text-hidden"}>
                      {job?.content?.map((con,i) => {
                        return (
                          <div className="text-style" key={i}>
                            <p>{i + 1 }.  {con}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
