import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useForm from '../../../hooks/useForm';
import { useCurrentProfile } from '../../../hooks/useProfile';
import useTestTypes from '../../../hooks/useTestTypes';
import { slideDown } from '../../../styles/animations';
import {
  ADD_TEST_VALIDATION_SCHEMA,
  API_ENDPOINT,
  PROCESSOR_URL,
  RADIO_OPTIONS_AUTH_TYPE,
  RADIO_OPTIONS_TIMED_ASSESSMENT,
} from '../../../utils/constants';
import { formatDate } from '../../../utils/helperFunctions';
import {
  APIError,
  FetchResponse,
  FormFields,
  IOption,
  TestPage,
  ReportPage,
  TestPageConfigs,
  ReportPageConfigs,
  keyValue
} from '../../../utils/types';
import Button from '../Button';
import DateTimeInput from '../DateTimeInput';
import DurationInput from '../DurationInput';
import Flex from '../Flex';
import Input from '../Input';
import Modal, { CloseButton } from '../Modal';
import Radio from '../Radio';
import Select from '../Select';
import { ModalHeading } from '../Typography';
import {BsFillPlusSquareFill} from 'react-icons/bs';
import {MdDeleteForever} from 'react-icons/md';

dayjs.extend(relativeTime);

const AddTest: FC<{ onClose: any; onSuccess: any }> = ({onClose, onSuccess,}) => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [configJson, setConfigJson] = useState<TestPageConfigs>({key:"", value:""})
  const [testConfigJsonArr, setTestConfigJsonArr] = useState([]);
  const [testPage, setTestPage] = useState<TestPage[]>([]);
  const [reportPage, setReportPage] = useState<ReportPage[]>([]);
  const [expiryDate, setExpiryDate] = useState<string>('');
  const [timedAssessment, setTimedAssessment] = useState<string>('');

  const [time, setTime] = useState<string>('01:00');
  const { data: currentProfile } = useCurrentProfile();

  const updateTimedAssessment = (e: ChangeEvent<HTMLInputElement>) => {
    setTimedAssessment(e.target.value);
  };

  const queryClient = useQueryClient();

  const { data: types } = useTestTypes();
  const actionOnSubmit = async (
      data: { [key in FormFields]?: string | string[] }
  ): Promise<APIError[] | void> => {
    setLoading(true);
    try {
      const postData = {
        test_name: data.test_name,
        test_display_label: data.test_display_label,
        test_auth: parseInt(data.test_auth as string),
        no_of_questions: data.no_of_questions,
        test_type_id: Number(data.test_type),
        test_page_id:Number(data.test_page),
        report_page_id:Number(data.report_page),
        test_page_url:data.test_url,
        report_page_url:data.report_url,
        test_configs: NewTestConfigsJson,
        test_expiry: formatDate(expiryDate),
        logo_image: data.logo_image
      };

      if (timedAssessment === '1') {
        postData['test_time'] = time + ':00';
      }

      await axios.post<FetchResponse<{ message: string }>>(
          API_ENDPOINT + 'test',
          postData
      );
      setLoading(false);
      queryClient.invalidateQueries(['testList']);
      onSuccess(true);

      history.push(`/admin/${currentProfile?.user_id}/test`);
    } catch (error: any) {
      setLoading(false);
      const errors = error.response.data.error as APIError[];
      onSuccess(true);
      return errors;
    }
  };

  const  getTestPage= async(e)=>{
    const test = await types?.find(s=>s.id === Number(e));
    // @ts-ignore
    await axios.get(API_ENDPOINT + `test-pages?test_type_code=${test?.code}`)
        .then((testPageData)=>{
          setTestPage(testPageData?.data.data.result)
        }).catch((err)=>{
          console.log(err)
        })

  }

  const  getReportPage= async(e)=>{
    const test = await types?.find(s=>s.id === Number(e));
    // @ts-ignore
    await axios.get(API_ENDPOINT + `report-pages?test_type_code=${test?.code}`)
        .then((testReportData)=>{
          setReportPage(testReportData?.data.data.result)
        }).catch((err)=>{
          console.log(err)
        })
  }

  const { formData, setFormData, handleChange, handleBlur, handleSubmit } =
      useForm({
        actions: { onSubmit: actionOnSubmit },
        validatorSchema: ADD_TEST_VALIDATION_SCHEMA,
        handleValidationOnBlur: true,
      });

  const handleTestTypeChange = async (e) => {
    const {textContent, value  } = e.target;
    const test = await types?.find(s=>s.id ===  Number(value));
    // @ts-ignore
    setFormData({
      ...formData,
      test_name: { value: test ? test?.test_name : "", error: '' },
      test_type: { value:value, error: '' },
      // test_code: { value: newValue.code, error: '' },

    });
  };
  const handleTestUrlChange = (e) => {
    const {code, textContent, value } = e.target;
    // @ts-ignore
    setFormData({
      ...formData,
      test_url: { value: value, error: '' },

    });
  };
  const handleReportUrlChange = (e) => {
    const {code, textContent, value } = e.target;
    // @ts-ignore
    setFormData({
      ...formData,
      report_url: { value: value, error: '' },

    });
  };

  const handleTestPageChange = (e) => {
    const { textContent, value } = e.target;
    setFormData({
      ...formData,
      test_page: { value: value, error: '' },

    });
  };

  const handleReportPageChange = (e) => {
    const { textContent, value } = e.target;
    setFormData({
      ...formData,
      report_page: { value: value, error: '' },

    });
  };

  const handleTestPageKeyChange = (e) => {
    const { textContent, value } = e.target;
    setFormData({
      ...formData,
      test_config_json_key: { value: value, error: '' },
    });
    configJson.key= value;
  };

  const handleTestPageValueChange = (e) => {
    const { textContent, value } = e.target;
    setFormData({
      ...formData,
      test_config_json_value: { value: value, error: '' },
    });
    configJson.value= value;
  };

  const configJsonKeyEditChange =(e,i)=>{
    const { textContent, value } = e.target;
    // @ts-ignore
    setTestConfigJsonArr(prev=>{
      // @ts-ignore
      const temp = [...prev];
      const exist = temp.find((s,index)=> index === i);
      if (!!exist){
        // @ts-ignore
        exist.key = value;
        return temp;
      }
    })
  }

  const configJsonValueEditChange =(e,i)=>{
    const { textContent, value } = e.target;
    // @ts-ignore
    setTestConfigJsonArr(prev=>{
      // @ts-ignore
      const temp = [...prev];
      const exist = temp.find((s,index)=> index === i);
      if (!!exist){
        // @ts-ignore
        exist.value = value;
        return temp;
      }
    })
  }

  const getConfigJsonArr =()=>{
    // @ts-ignore
    if(testConfigJsonArr.some((e)=> e.key === formData.test_config_json_key?.value)){
      alert("Duplicate Key")
    } else if (formData.test_config_json_value?.value === ""){
      alert("Fill Both Field")
    }else {
      // @ts-ignore
      setTestConfigJsonArr((old)=>[...old, {key: configJson.key, value: configJson.value}]);
      setFormData({
        ...formData,
        test_config_json_value: { value: "", error: '' },
        test_config_json_key: { value: "", error: '' },
      });
    }
  }




  

  const NewTestConfigsJson = {};
  testConfigJsonArr.map(({key,value})=>{
    NewTestConfigsJson[key] = value;
  })

  const deleteConfigJson=(Ele)=>{
    // @ts-ignore
    setTestConfigJsonArr(testConfigJsonArr.filter((e)=>e.key !== Ele ))
  }


  const handleReportPageKeyChange = (e) => {
    const { textContent, value } = e.target;
    setFormData({
      ...formData,
      report_config_json_key: { value: value, error: '' },
    });
    configJson.key= value;
  };

  const handleReportPageValueChange = (e) => {
    const { textContent, value } = e.target;
    setFormData({
      ...formData,
      report_config_json_value: { value: value, error: '' },
    });
    configJson.value= value;
  };

  const getReportConfigJsonArr =()=>{
    // @ts-ignore
    if(testConfigJsonArr.some((e)=> e.key === formData.report_config_json_key?.value)){
      alert("Duplicate Key");
    }else if (formData.report_config_json_value?.value === ""){
      alert("Fill Both Field");
    }else {
      // @ts-ignore
      setTestConfigJsonArr((old)=>[...old, {key: configJson.key, value: configJson.value}]);
      setFormData({
        ...formData,
        report_config_json_value: { value: "", error: '' },
        report_config_json_key: { value: "", error: '' },
      });
    }
  }

  testConfigJsonArr.map(({key,value})=>{
    NewTestConfigsJson[key] = value;
  })

  const reportConfigJsonKeyEditChange =(e,i)=>{
    const { textContent, value } = e.target;
    // @ts-ignore
    setTestConfigJsonArr(prev=>{
      // @ts-ignore
      const temp = [...prev];
      const exist = temp.find((s,index)=> index === i);
      if (!!exist){
        // @ts-ignore
        exist.key = value;
        return temp;
      }
    })
  }

  const reportConfigJsonValueEditChange =(e,i)=>{
    const { textContent, value } = e.target;
    // @ts-ignore
    setTestConfigJsonArr(prev=>{
      // @ts-ignore
      const temp = [...prev];
      const exist = temp.find((s,index)=> index === i);
      if (!!exist){
        // @ts-ignore
        exist.value = value;
        return temp;
      }
    })
  }

  const deleteReportConfigJson=(Ele)=>{
    // @ts-ignore
    setTestConfigJsonArr(testConfigJsonArr.filter((e)=>e.key !== Ele ))
  }

  const handleLogoChange = async (e) => {

    try {
      const postData = new FormData()
      postData.append("file", e.target.files[0])
      const res = await axios.post(PROCESSOR_URL + "quiz/upload", postData)
      if (res.data.data.key) {
        setFormData({
          ...formData,
          logo_image: { value: res.data.data.key , error: '' },
        });
      }
      else throw new Error("Error occured while uploading Image");
    } catch (error) {
      console.log(error)
      setFormData({
        ...formData,
        logo_image: { value: '', error: 'Error occured while uploading Image' },
      });
    }
  }

  const isButtonDisabled = () => {
    return !(
        formData.test_name?.value !== '' &&
        formData.test_name?.error === '' &&
        formData.test_type?.value !== '' &&
        formData.test_type?.error === '' &&
        formData.test_auth?.value !== '' &&
        formData.test_url?.value !== '' &&
        formData.report_url?.value !== '' &&
        formData.test_page?.value !== '' &&
        formData.report_page?.value !== '' &&
        formData.no_of_questions?.value !== '' &&
        formData.no_of_questions?.error === '' &&
        !isLoading
    );
  };

  useEffect(()=>{
    getTestPage(formData.test_type?.value);
    getReportPage(formData.test_type?.value);
  },[formData?.test_type]);


  // @ts-ignore
  return (
      <Modal onClose={() => onClose(false)}>
        <Container  column onClick={(e) => e.stopPropagation()}>
          <Flex justify='center' align='center'>
            <ModalHeading>Add Test</ModalHeading>
            <CloseButton onClick={() => onClose(false)} />
          </Flex>
          <form  onSubmit={handleSubmit}>
            <Select
                label='Type'
                name='test_type'
                value={formData?.test_type?.value ?? ""}
                onChange={handleTestTypeChange}
                onBlur={handleBlur}
                autoFocus
                required
                tabIndex={2}
                options={types?.map(
                    (type: any) => ({ value: +type.id , label: type.test_name, name:type.test_name } as IOption)
                )}
            />
            <Input
                label='Test Name'
                name='test_name'
                type='text'
                value={formData?.test_name?.value ?? ""}
                error={formData.test_name?.error}
                onChange={handleChange}
                onBlur={handleBlur}
                autoFocus
                // required
                tabIndex={1}
            />
            <Input
                label='Test Display Label'
                name='test_display_label'
                type='text'
                value={formData.test_display_label?.value}
                error={formData.test_display_label?.error}
                onChange={handleChange}
                onBlur={handleBlur}
                autoFocus
                required
                tabIndex={1}
            />

            <Radio
                label='Select test auth type'
                name='test_auth'
                type='radio'
                value={formData.test_auth?.value}
                onChange={handleChange}
                onBlur={handleBlur}
                options={RADIO_OPTIONS_AUTH_TYPE}
                required
                tabIndex={3}
            />
            <DateTimeInput
                label='Expiry Date'
                name='test_expiry'
                value={expiryDate}
                onChange={(date) => setExpiryDate(date)}
                minDate={new Date()}
            />
            <Radio
                label='Timed Assessment?'
                name='timesAssessment'
                type='radio'
                value={timedAssessment}
                onChange={(e) => updateTimedAssessment(e)}
                options={RADIO_OPTIONS_TIMED_ASSESSMENT}
                tabIndex={3}
            />
            {timedAssessment === '1' && (
                <DurationInput
                    name='test_time'
                    value={time}
                    label='Duration (HH:MM)'
                    onChange={(value) => setTime(value)}
                />
            )}
            <Input
                label='Test URL'
                name='test_url'
                type='text'
                value={formData.test_url?.value}
                // error={formData.no_of_questions?.error}
                onChange={handleTestUrlChange}
                onBlur={handleBlur}
                required
                tabIndex={1}
                min={1}
            />
            <Input
                label='Report URL'
                name='report_url'
                type='text'
                value={formData.report_url?.value}
                // error={formData.no_of_questions?.error}
                onChange={handleReportUrlChange}
                onBlur={handleBlur}
                tabIndex={1}
                min={1}
            />
            <div className="d-flex align-items-center">
              <Input
                  label='Test Page Configs'
                  name='test_page_key'
                  placeholder="key"
                  className="w-50"
                  type='text'
                  value={formData.test_config_json_key?.value}
                  // error={formData.no_of_questions?.error}
                  onChange={handleTestPageKeyChange}
                  onBlur={handleBlur}
                  tabIndex={1}
                  min={1}
              />
              <Input
                  label='&#10240;'
                  name='test_page_value'
                  placeholder="Value"
                  className="w-50 mx-2"
                  type='text'
                  value={formData.test_config_json_value?.value}
                  // error={formData.no_of_questions?.error}
                  onChange={handleTestPageValueChange}
                  onBlur={handleBlur}
                  tabIndex={1}
                  min={1}
              />
              < BsFillPlusSquareFill size={35} className="mt-4" onClick={getConfigJsonArr}/>
            </div>
            <div>
              {testConfigJsonArr?.map((keyValue: any, index)=>{
                return(
                    <div key={index} className="d-flex">
                      <Input type="text" onChange={(e)=>configJsonKeyEditChange(e,index)} className="w-50" value={keyValue?.key} />
                      <Input type="text" onChange={(e)=>configJsonValueEditChange(e,index)} className="w-50 mx-2" value={keyValue?.value} />
                      <div>
                        <MdDeleteForever size={25} className="mt-4" onClick={()=> deleteConfigJson(keyValue?.key)} />
                      </div>
                    </div>
                )
              })}
            </div>
            <div className="d-flex align-items-center">
              <Input
                  label='Report Page Configs'
                  name='report_page_key'
                  placeholder="key"
                  className="w-50"
                  type='text'
                  value={formData.report_config_json_key?.value}
                  // error={formData.no_of_questions?.error}
                  onChange={handleReportPageKeyChange}
                  onBlur={handleBlur}
                  tabIndex={1}
                  min={1}
              />
              <Input
                  label='&#10240;'
                  name='report_page_value'
                  placeholder="Value"
                  className="w-50 mx-2"
                  type='text'
                  value={formData.report_config_json_value?.value}
                  // error={formData.no_of_questions?.error}
                  onChange={handleReportPageValueChange}
                  onBlur={handleBlur}
                  tabIndex={1}
                  min={1}
              />
              < BsFillPlusSquareFill size={35} className="mt-4" onClick={getReportConfigJsonArr}/>
            </div>
            <div>
              {testConfigJsonArr?.map((keyValue: any, index)=>{
                return(
                    <div key={index} className="d-flex">
                      <Input type="text" className="w-50" onChange={(e)=>reportConfigJsonKeyEditChange(e,index)} value={keyValue?.key} />
                      <Input type="text" className="w-50 mx-2" onChange={(e)=>reportConfigJsonValueEditChange(e,index)} value={keyValue?.value} />
                      <div>
                        <MdDeleteForever size={25} className="mt-4" onClick={()=> deleteReportConfigJson(keyValue?.key)}/>
                      </div>
                    </div>
                )
              })}
            </div>
            <Select
                label='Test Page'
                name='test_page'
                value={formData.test_page?.value}
                onChange={handleTestPageChange}
                onBlur={handleBlur}
                autoFocus
                required
                tabIndex={2}
                options={testPage?.map(
                    (type) => ({value: type.id, label: type.label} as IOption)
                )}
            />
            <Select
                label='Report Page'
                name='report_page'
                value={formData.report_page?.value}
                onChange={handleReportPageChange}
                onBlur={handleBlur}
                autoFocus
                required
                tabIndex={2}
                options={reportPage?.map(
                    (type) => ({ value: type.id, label: type.label } as IOption)
                )}
            />
            <Input
                label='Number of Questions per page'
                name='no_of_questions'
                type='number'
                value={formData.no_of_questions?.value}
                error={formData.no_of_questions?.error}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                tabIndex={5}
                min={1}
            />
            <Input
                label='Add an optional custom logo'
                name='optional_logo'
                type='file'
                accept="image/png,image/jpeg,image/jpg"
                defaultValue={formData.logo_image?.value}
                error={formData.logo_image?.error}
                onChange={handleLogoChange}
                onBlur={handleBlur}
            />

            <div style={{marginBottom: "20px", fontSize: "large"}}>{formData.logo_image ? "Uploaded Image": ""}</div>

            <Flex>
              <AddButton
                  type='submit'
                  isLoading={isLoading}
                  disabled={isButtonDisabled()}
              >
                Add Test
              </AddButton>
            </Flex>
          </form>
        </Container>
      </Modal>
  );
};

export default AddTest;

const Container = styled(Flex)`
  width: 40rem;
  padding: 3rem;
  background-color: #fff;
  border-radius: 4px;
  animation: ${slideDown} 0.4s ease-out;
  overflow-y: auto;
  max-height: 90%;
  form {
    margin-top: 2rem;
  }
  input[type=file]{
    color:transparent;
  }
  label{
  font-size: 14px;
  }
`;

var AddButton = styled(Button)`
  width: 100%;
  height: 5rem;
  background-color: ${(props) => props.theme.colors.blue[500]};
`;
