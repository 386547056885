import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT } from '../utils/constants';
import { APIError, Cards, TestCards } from '../utils/types';

const useCards = () => {
  const fetchCards = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(API_ENDPOINT + 'cards');
      return data;
    } catch (error) {
      throw error.response.data;
    }
  };

  return useQuery<Cards, AxiosResponse<APIError>>(['cards'], fetchCards, {
    retry: false,
  });
};

export default useCards;

export const useTestCards = (userId?: string) => {
  const fetchTestCards = async (userId?: string) => {
    try {
      const {
        data: { data },
      } = await axios.get(API_ENDPOINT + 'cards/' + userId);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  };

  return useQuery<TestCards, AxiosResponse<APIError>>(
    ['testCards', userId],
    () => fetchTestCards(userId),
    {
      retry: false,
    }
  );
};
