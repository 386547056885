import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { IoMdLock } from "react-icons/io";
import styled from "styled-components";
import Lock from "../../../assets/test/lock.svg";
import useForm from "../../../hooks/useForm";
import { slideDown } from "../../../styles/animations";
import "./testlogin.css";
import {
  API_ENDPOINT,
  LOCALHOST,
  STRIDE_ID,
  STRIDE_ID_AUTH,
  TEST_DETAIL_VALIDATION_SCHEMA,
} from "../../../utils/constants";
import {
  APIError,
  FetchResponse,
  FormFields,
  TestDetail,
} from "../../../utils/types";
import Button from "../Button";
import Flex from "../Flex";
import Input, { InputGroup } from "../Input";
import { STRIDE_TERMS_AND_CONDITION } from "../../../utils/constants";
import Modal from "../Modal";
import { FcGoogle } from "react-icons/fc";
import { Text2Css } from "../Typography";
import validator from "@rjsf/validator-ajv6";
import Form from "@rjsf/core";
import axios from "axios";
import { MdClose } from "react-icons/md";
import { MultiStepForm, Step } from "react-multi-form";
import TestTermsAndConditions from "./TestTermsAndConditions";
import { or } from "@amcharts/amcharts4/core";

const TestLogin: FC<{
  isClosed?: boolean;
  validatePhoneNumber?: boolean;
  onSubmit: any;

  defaultValue?: any;

  checkAuth?: any;
  testId: string;

  showLoginButton: boolean;
  setIsAuth: any;
  test: TestDetail;
}> = ({
  isClosed = false,
  validatePhoneNumber = true,
  onSubmit,
  testId,
  checkAuth,
  defaultValue,
  showLoginButton,
  test,
  setIsAuth,
}) => {
  // console.log('showLoginButton', showLoginButton)
  // console.log('checkAuth', checkAuth)
  // console.log('setIsAuth', setIsAuth)
  // console.log('isClosed', isClosed)
  // console.log('onSubmit', onSubmit)
  // console.log('testId', testId)
  // console.log('defaultValue', defaultValue)
  //console.log("test in  login", test);
  const currentUrlParams = new URLSearchParams(window.location.search);
  const [fields, setFields] = useState<any>();
  const [message, setMessage] = useState({ type: "", message: "" });
  const [alertStyle, setAlertStyle] = useState(false);
  const [showConcent, setShowConcent] = useState<any>(true);
  const [showAlert, setShowAlert] = useState(false);
  const [hideSignGoogleBtn, setHideSignGoogleBtn] = useState<any>(false);
  // const [active, setActive] = React.useRef(1)
  const [stepperVal, setStepperVal] = useState(1);
  const active = useRef(1);
  const [hideModal, setHideModal] = useState(true);
  const [user, setUser] = useState<any>({});
  const lock_image = test?.test_configs?.terms_and_conditions_image
    ? test?.test_configs?.terms_and_conditions_image
    : Lock;

  const actionOnSubmit = async (data: {
    [key in FormFields]?: string | string[];
  }): Promise<APIError[] | void> => {
    const newFormData: any = formData;
    const referral_code = currentUrlParams.get("referral_code");
    if (referral_code) {
      newFormData.referral_code = referral_code;
    }
    console.log("API called 2");
    apiAuth(newFormData);
  };

  const [buttonLoaded, setButtonLoaded] = useState(false);

  const handleButtonLoad = () => {
    setButtonLoaded(true);
  };

  useEffect(() => {
    const fname = currentUrlParams.get("fname");
    const lname = currentUrlParams.get("lname");
    const email = currentUrlParams.get("email");
    const phoneNo = currentUrlParams.get("phone_no");
    const trainingId = currentUrlParams.get("training_id");
    if (test.auth_status === 0) {
      console.log("auth_status_zero");
      setHideSignGoogleBtn(true);
    }
    if (
      fname &&
      lname &&
      email &&
      phoneNo &&
      trainingId &&
      test?.auth_status !== 1 &&
      !checkAuth
    ) {
      setUser({
        name: fname + " " + lname,
        email: email,
        phone_number: phoneNo,
        training_id: trainingId,
      });
    }
    if (
      trainingId &&
      (lname === "" || email === "" || phoneNo === "" || fname === "")
    ) {
      setMessage({ type: "error", message: "Please fill all the fields" });
      setUser({
        name: fname + " " + lname,
        email: email,
        phone_number: phoneNo,
        training_id: trainingId,
      });
    }
  }, []);

  useEffect(() => {
    // const form = document.querySelector('form');
    // if (form){
    if (Object.keys(user).length !== 0) {
      setFormData(user);
      // for (const property in user) {
      // const input = form.querySelector<HTMLInputElement>(`input[name="root_${property}"]`);
      // if(input){
      // input.value = user[property];
      // const event = new Event('change', { bubbles: true });
      // input.addEventListener('change', (event: Event) => {
      //   console.log(`Input "${(event.target as HTMLInputElement).name}" changed to "${(event.target as HTMLInputElement).value}"`);

      //   // Replace console.log with any other function you want to call when the input changes.
      //   setFormData({})
      // });
      // input.dispatchEvent(event);
      // }
      //   }
      // console.log(form)
      // }
      document.getElementById("test-form-btn")?.click();
    }
  }, [user, fields?.data?.form_schema]);

  useEffect(() => {
    console.log("API called 1");
    JSON.stringify(user) !== "{}" && apiAuth(user);
    if (JSON.stringify(user) !== "{}") {
      setHideSignGoogleBtn(true);
    }
  }, [user]);

  useEffect(() => {
    console.log("setting user");
    if (checkAuth && JSON.stringify(user) === "{}") {
      const currentUrlParams = new URLSearchParams(window.location.search);
      let refresh_token = currentUrlParams.get("refresh_token");
      fetch(`${API_ENDPOINT}me`, {
        credentials: "include",
        headers: {
          refresh_token: refresh_token || "",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setFormData(data?.data);
          if (data?.success) {
            if (data?.data?.name) {
              console.log("data", data?.data);
              setUser(data?.data);
            } else {
              setMessage({
                type: "error",
                message: "Please fill all the fields",
              });
            }
          }
        })
        .catch((err) => {
          setMessage({ type: "error", message: "Fail to fetch" });
        });
    }
  }, []);

  const apiAuth = async (data: any) => {
    console.log("data3", data);
    data.phone_number = data.phone_number || "";
    try {
      const currentUrlParams = new URLSearchParams(window.location.search);
      let refresh_token = currentUrlParams.get("refresh_token");
      const config = {
        method: "post",
        // @ts-ignore
        url: `${API_ENDPOINT}form/${test?.form_id}/proceed`,
        headers: {
          "Content-Type": "application/json",
          refresh_token: refresh_token,
        },
        data: JSON.stringify(data),
      };
      // @ts-ignore
      const resp: any = await axios(config);
      setMessage({ type: "success", message: `${resp?.data?.message}` });
      active.current++;
      setStepperVal(active.current);
      if (resp) {
        const postData = {
          response_id: resp?.data?.data?.resposne_id,
          response: data,
        };
        console.log(postData);
        const currentUrlParams = new URLSearchParams(window.location.search);
        let refresh_token = currentUrlParams.get("refresh_token");
        const config = {
          method: "post",
          // @ts-ignore
          url: `${API_ENDPOINT}form/${test?.form_id}/save?test_display_id=${test?.test_display_id}`,
          headers: {
            "Content-Type": "application/json",
            refresh_token: refresh_token,
          },
          data: JSON.stringify(postData),
        };

        //@ts-ignore
        const saveData = await axios(config);
        console.log("saveData", saveData);
        if (saveData?.data?.data?.respone_key) {
          const userDetails = {
            ...data,
            response_key: saveData?.data?.data?.respone_key,
          };
          onSubmit(userDetails);
          setIsAuth(true);
        } else {
          setMessage({
            type: "error",
            message: "Some issue, Please Try again",
          });
        }
      } else {
        setMessage({
          type: "error",
          message: "Kindly complete your details to continue..",
        });
      }
    } catch (error: any) {
      if (error?.response?.status === 412) {
        setMessage({ type: "error", message: `${error?.message}` });
      } else {
        setMessage({
          type: "error",
          message: "Kindly complete your details to continue.",
        });
      }
    }
  };

  // useEffect(()=>{
  //     axios.get(`${API_ENDPOINT}me`)
  //         .then((authUser)=>{
  //             if(authUser?.data?.success){
  //                 console.log(authUser)
  //                 if(authUser?.data?.data?.name && authUser?.data?.data?.phone_number && authUser?.data?.data?.email ){
  //                   console.log("aith")
  //                     apiAuth(formData)
  //                 }else {
  //                     setMessage({ type: "error", message: "Please fill all the fields" });
  //                 }
  //             }
  //         })
  // },[]);

  useEffect(() => {
    // @ts-ignore
    fetch(`${API_ENDPOINT}form/${test?.form_id}`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("data in setfields", data);
        setFields(data);
      })
      .catch((err) => {
        setMessage({ type: "error", message: "Fail to fetch " });
      });
  }, []);

  useEffect(() => {
    console.log("stepperVal", stepperVal);
  }, [stepperVal]);

  useEffect(() => {
    message.message ? setShowAlert(true) : setShowAlert(false);
  }, [message]);

  useEffect(() => {
    message?.type === "success" ? setAlertStyle(true) : setAlertStyle(false);
  }, [message]);

  const redirectToLogin = () => {
    currentUrlParams.set("form", "submitted");

    console.log("window.location.origin", window.location.origin);
    console.log("window.location.origin", window.location.pathname);

    //https://login.strideahead.in/login?form=submitted&redirect_to=http%3A%2F%2Ftests.dev.strideahead.in%3A3000%2Ftest%2FT1667814889653558&source=Communication
    currentUrlParams.set(
      "redirect_to",
      `${window.location.origin + window.location.pathname}`
    );
    test && currentUrlParams.set("source", test.test_name);
    window.open(`${STRIDE_ID}login?${currentUrlParams.toString()}`, "_self");
  };

  const { formData, setFormData, handleChange, handleBlur, handleSubmit } =
    useForm({
      actions: { onSubmit: actionOnSubmit },
      validatorSchema: TEST_DETAIL_VALIDATION_SCHEMA,
      handleValidationOnBlur: true,
      validatePhoneNumber: validatePhoneNumber,
    });

  // console.log(formData, "form")

  // const disableButton = () => {
  //     return !(
  //         formData.first_name?.value !== '' &&
  //         formData.first_name?.error === '' &&
  //         formData.email?.value !== '' &&
  //         formData.email?.error === '' &&
  //         formData.phone_no?.value !== '' &&
  //         formData.phone_no?.error === ''
  //
  //     );
  // };

  const renderForm = () => {
    return (
      <>
        <Fragment>
          {isClosed && <Login>Login or sign up to start the test. </Login>}
          {!hideSignGoogleBtn ? (
            <GoogleSignInText test={test} onClick={redirectToLogin}>
              <FcGoogle className="google_icon" /> Sign in with Google
            </GoogleSignInText>
          ) : null}
          {/* {showLoginButton ? <AddButton test={test} onClick={redirectToLogin}>Sign In using Email Or Google
                        Account</AddButton> : null} */}
          {!isClosed && (
            <Fragment>
              {!hideSignGoogleBtn ? (
                <OrContainer align="center" test={test}>
                  <span className="line"></span>
                  <span style={{ margin: "0 2rem" }}>
                    {showLoginButton
                      ? "or fill the form below to continue"
                      : "Fill the form below to continue"}
                  </span>
                  <span className="line"></span>
                </OrContainer>
              ) : (
                ""
              )}
              <form>
                {showAlert && (
                  <div
                    className={
                      alertStyle ? "alert alert-success " : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {message?.message}
                  </div>
                )}

                {fields?.data?.form_schema && (
                  <StyledForm
                    test={test}
                    schema={fields?.data?.form_schema}
                    uiSchema={fields?.data?.ui_schema}
                    formData={formData}
                    validator={validator}
                    liveValidate={false}
                    onChange={(e: any) => {
                      setFormData(e.formData);
                    }}
                    // onSubmit={(e: any) => actionOnSubmit2(e)}
                    // onBlur={(e: any) => autoSave()}
                  >
                    <AddButton
                      id="test-form-btn"
                      onLoad={handleButtonLoad}
                      type="submit"
                      style={{ height: "auto" }}
                      onClick={(e: any) =>
                        handleSubmit(e, currentUrlParams.get("training_id"))
                      }
                      test={test}
                    >
                      Continue
                    </AddButton>
                  </StyledForm>
                )}
              </form>
            </Fragment>
          )}
        </Fragment>
      </>
    );
  };

  // if (hideModal === true && stepperVal < 3) {

  return (
    <>
      {hideModal === true ? (
        <Modal width="60rem">
          <Container column onClick={(e) => e.stopPropagation()}>
            <div
              className="close_modal_btn"
              onClick={() => setHideModal(false)}
            >
              <MdClose />
            </div>
            {/* <img src={lock_image} alt='Lock' /> */}
            {renderForm()}
            <GDPRContainer>
              <IoMdLock />
              Compliant with the GDPR
            </GDPRContainer>
          </Container>
        </Modal>
      ) : (
        <div className="open_login_form_container">
          <button onClick={() => setHideModal(true)}>Get started</button>
        </div>
      )}
    </>
  );
};

export default TestLogin;

const Container = styled(Flex)`
  height: fit-content;
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 4.3rem;
  background-color: #fff;
  border-radius: 4px;
  animation: ${slideDown} 0.4s ease-out;
  width: 90%;
  img {
    width: 30rem;
    margin: auto;
    margin-bottom: 2.5rem;
  }
  .close_modal_btn {
    position: relative;
    justify-content: right;
    display: flex;
    margin: -1rem -1rem 0 0;
    cursor: pointer;
  }
  .close_modal_btn svg {
    font-size: 0.5rem;

    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 4px;
    color: #333;
    transition: 0.5s all ease-in-out;
  }
  .close_modal_btn svg:hover {
    color: #fff;
    background: #1273b7;
  }
  @media only screen and (max-width: 991px) {
    img {
      width: 20rem;
      margin: auto;
      margin-bottom: 2.5rem;
    }
  }
  @media (max-width: 767px) {
    padding: 3.5rem;
    width: 90%;
    max-height: 80%;
    img {
      width: 15rem;
      margin: auto;
      margin-bottom: 0;
    }
  }
  @media (min-width: 767px) {
    max-height: 80%;
    width: 500px;
    img {
      width: 15rem;
      margin: auto;
      margin-bottom: 0;
    }
  }
  @media (min-width: 767px) and (max-width: 1024px) {
    max-height: 80%;
    padding: 2.3rem 4.3rem 2rem;
    img {
      width: 20rem;
      margin: auto;
      margin-bottom: 0;
    }
  }

  @media (min-width: 767px) and (max-height: 600px) {
    max-height: 70%;
    padding: 3.5rem;
  }

  @media (min-width: 1200px) {
    max-height: 80%;
    img {
      width: 20rem;
      margin: auto;
      margin-bottom: 2rem;
    }
  }
  @media (min-width: 1200px) and (max-height: 600px) {
    max-height: 75%;
    img {
      width: 15rem;
      margin-bottom: 0;
    }
  }
  @media (min-width: 1200px) and (min-height: 600px) {
    max-height: 80%;
    padding: 3rem 3.5rem 2rem;
    img {
      width: 18rem;
      margin-bottom: 0;
    }
  }
`;

const Login = styled(Flex)`
  font-weight: 600;
  font-size: 2rem;
  line-height: 150%;
  align-items: center;
  text-align: center;
  color: ${(props) =>
    props.test?.test_configs?.theme?.primary_color ||
    props.theme.colors.blue[500]};
  margin: 1rem auto;
  @media only screen and (max-width: 1024px) {
    font-size: 1.6rem;
  }
`;
const AddButton = styled(Button)`
  width: 100%;
  height: 5rem;
  background-color: ${(props) =>
    props.test?.test_configs?.theme?.bg_color || props.theme.colors.blue[500]};
  color: ${(props) => props.theme.colors.white};
  border-radius: 2px;
  cursor: pointer;
  margin-top: 3rem;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  @media only screen and (max-width: 425px) {
    height: fit-content;
    text-align: center;
  }
  @media (max-width: 767px) {
    margin-top: 2rem;
    padding: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    height: 4rem;
    margin-top: 2rem;
    padding: 1rem;
    font-size: 1.4rem;
  }
  @media (min-width: 991px) {
    height: 4rem;
    font-size: 1.4rem;
    margin-top: 2.5rem;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
`;

const GoogleSignInText = styled(Button)`
  background: transparent;
  border: 1px solid #0000002b;
  color: #4f4f4f;
  width: 100%;
  font-size: 14px;
  height: 4rem;
  border-radius: 2px;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  gap: 12px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  &:hover {
    border: 1px solid #333;
  }
  @media (max-width: 767px) {
    font-size: 12px;
    height: 3.5rem;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 12px;
  }
`;

const OrContainer = styled(Flex)`
  ${Text2Css}
  width: 100%;
  color: ${(props) =>
    props.test?.test_configs?.theme?.primary_color ||
    props.theme.colors.blue[500]};
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 24px;
  margin: 2.5rem 0;
  .line {
    display: block;
    height: 1px;
    flex-grow: 1;
    background-color: ${(props) => props.theme.colors.grey[300]};
  }
  .fill_txt {
    font-size: 1.5rem;
    margin: 0px 1rem;
    color: #4f4f4f;
    font-weight: 500;
  }
  @media (max-width: 767px) {
    margin: 1rem 0;
    .fill_txt {
      font-size: 1.1rem;
      margin: 0px 0.4rem !important;
      text-align: center;
    }
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 1.4rem;
    line-height: 20px;
    margin: 1.2rem 0 0rem;
    .fill_txt {
      margin: 0px 2rem;
      font-size: 1.1rem;
    }
  }
  @media (min-width: 991px) {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 20px;
    margin: 1.3rem 0 0rem;
    .fill_txt {
      margin: 0px 2rem;
      font-size: 1.2rem;
    }
  }
  @media (min-width: 1025px) {
    .fill_txt {
      font-size: 1.2rem;
      margin: 0px 1rem !important;
    }
  }
`;

const GDPRContainer = styled(Flex)`
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  line-height: 150%;
  margin: 1.5rem auto 0;
  color: ${(props) => props.theme.colors.grey[400]};
  align-items: center;
  svg {
    margin-right: 1rem;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 1.2rem;
  }
  @media (min-width: 1025px) {
    font-size: 1.2rem;
  }
`;

const StyledForm = styled(Form)<{ test?: any }>`
 ${Text2Css}
  #root__title{
   display:none;
  }
  #root__description{
   display:none;
  }
  label {
    font-family:  ${(props) =>
      props.test?.test_configs?.theme?.primary_font || "Poppins"};
    color: #4F4F4F;
    font-weight: normal;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  select {
    font-family:  ${(props) =>
      props.test?.test_configs?.theme?.primary_font || "Poppins"};
    border: 1.5px solid #E0E0E0 !important;
    font-size: 1.2rem;
    border-radius: 10px !important;
    line-height: 40px !important;
    padding: 1.4rem;
    width: 100%;
    appearance: revert;
  }
  input {
    font-family:  ${(props) =>
      props.test?.test_configs?.theme?.primary_font || "Poppins"};
    border: 1px solid #E0E0E0 !important;
    box-sizing: border-box;
    border-radius: 10px !important;
    font-size: 1.2rem;
    line-height: 40px;
    width: 100%;
   
  }
  
  &:focus{
  box-shadow: 0 0 2px #1273B7;
  }
  input[type="checkbox"] {
    margin: 0 10px;
  }
  @media(max-width:767px){
    label{
 
        margin-bottom: 0.3rem;
    }
    }
    input,select {
        border-radius: 8px !important;
        font-size: 1rem;
        line-height: 1.9rem;
    }
  }
  @media(min-width:767px) and (max-width:991px){
    input,select {
        font-size: 1.3rem;
        line-height: 2.9rem;
  }
}
@media(min-width:991px){
    input,select {
       
    font-size: 1.2rem;
    line-height: 2.5rem;
    }
    
}
@media(min-width:1200px){
    select{
        height: 38px;
        padding:0;
        line-height: 2.5rem !important;
    }
}
  // button {
  //   font-family: "Poppins";
  //   width: 100%;
  //   border: none;
  //   padding: 15px 30px;
  //   background-color: ${(props: any) =>
    props?.test?.test_configs?.theme?.bg_color} !important;
  //   color : ${(props) => props.theme.colors.white};
  //   border-radius: 0px !important;
  //   margin-top: 3rem;
  //   font-size: 20px;
  // }
  // button:hover {
  //   color: #fff !important;
  //   background: #1273B7;
  // }
`;
