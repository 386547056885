import React, { forwardRef } from "react";
import RadioGroup from "antd/es/radio/group";
import Radio from "antd/es/radio/radio";
import FormItem from "antd/es/form/FormItem";
import { unset } from "lodash";

export default forwardRef((props: any, ref: any) => {
  const { form, inputData, language } = props,
    { required, questions, defaultValue, id, options, correctAnswer } = inputData,
    { getFieldDecorator } = form;

  return (
    <FormItem
      className={props.quizTemplateName == "aptitude-quiz" ? "aptitude-quiz-question-option-wrapper" : undefined}
      colon={false}
      label={<label
        style={{
          marginBottom: "32px",
        }}
          dangerouslySetInnerHTML={{
            __html: questions[language] || "no question for selected language"
          }}
        />}
    >
      {getFieldDecorator(id, {
        initialValue: defaultValue,
        rules: [
          {
            required,
            message: "Required Field"
          }
        ]
      })(
        <RadioGroup
          className={props.sampleQuestion ? "sample-question" : undefined}
          disabled={correctAnswer}
          style={
            props.quizTemplateName == "aptitude-quiz" ? {
            paddingTop: "32px",
          }
          :
          {}
        }
        >
          {options.map((option: any) => {
            return (
              <Radio
                className={props.sampleQuestion ? "sample-question" : undefined}
                onClick={props.onClick ? props.onClick : null}
                key={option.value}
                value={option.value}
                style={{
                  whiteSpace: "normal"
                }}
              >
                {option.text[language]}
              </Radio>
            )
          })}
        </RadioGroup>
      )}
    </FormItem>
  );
});
