import axios from 'axios';
import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Error401 from '../../assets/errors/401.png';
import Error404 from '../../assets/errors/404.png';
import Error500 from '../../assets/errors/500.png';
import { API_ENDPOINT } from '../../utils/constants';
import { AppError } from '../../utils/types';
import Button from '../components/Button';
import Flex from '../components/Flex';
import Navbar from '../components/Navbar';
import { Heading1, Text1Css } from '../components/Typography';

const ErrorFallback: FC<{
  error: AppError;
  requestAnotherLink?: any;
  showNavbar?: false;
  url?: string;
}> = ({ error, requestAnotherLink, showNavbar = true, url }) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const handleLogout = async () => {
    try {
      await axios.get(API_ENDPOINT + 'logout');
      queryClient.invalidateQueries(['profile', 'current']);
      history.push('/');
    } catch (error) {
      alert('Something went wrong');
    }
  };

  const renderErrorImage = () => {
    switch (error) {
      case '404':
        return <ErrorImage src={Error404} />;
      case '401':
        return <ErrorImage src={Error401} />;
      case '500':
      default:
        return <ErrorImage src={Error500} />;
    }
  };

  const getErrorTitle = () => {
    switch (error) {
      case '500':
      case '404':
        return 'Something went wrong';
      case '401':
      default:
        return 'Hold Up! Access Denied';
    }
  };

  const getErrorDescription = () => {
    switch (error) {
      case '401':
        return 'This page is not available for this credential. Please contact admin if you have any issue.';
      case '404':
        return 'This page is not available. Please contact admin if you have any issue.';
      case '500':
      default:
        return 'We’re not exactly sure what happened, but our servers say something is wrong.';
    }
  };

  const getButtonText = () => {
    switch (error) {
      case '401':
        return 'Try login again using StrideID';
      case '500':
      case '404':
      default:
        return 'Go to Homepage';
    }
  };

  const clickHandler = () => {
    switch (error) {
      case '401':
        handleLogout();
        break;
      case '404':
      case '500':
      default: {
        history.push(url || '/');
        break;
      }
    }
  };

  return (
    <Container column>
      {showNavbar && <Navbar />}
      <StyledFlex column align='center' hidePadding={!showNavbar}>
        {renderErrorImage()}
        <Title>{getErrorTitle()}</Title>
        <Description>{getErrorDescription()}</Description>
        <ActionButton onClick={clickHandler}>{getButtonText()}</ActionButton>
      </StyledFlex>
    </Container>
  );
};

export default ErrorFallback;

const Container = styled(Flex)`
  min-height: 100%;
  width: 100%;
`;

const StyledFlex = styled(Flex)<{ hidePadding: boolean }>`
  padding-top: 7rem;
  ${({ hidePadding }) =>
    hidePadding &&
    css`
      padding-top: 0;
    `}
`;

const ErrorImage = styled.img`
  width: 35rem;
  margin: 4rem 0;
`;

const Title = styled(Heading1)`
  color: ${({ theme }) => theme.colors.red[500]};
  text-align: center;
`;

const Description = styled.p`
  ${Text1Css}
  margin: 2rem 0;
  text-align: center;
`;

const ActionButton = styled(Button)`
  width: 30rem;
`;
