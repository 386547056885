// @ts-nocheck
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import './index.css';
import { light } from './styles/theme';
import { ThemeProvider } from 'styled-components';

axios.defaults.withCredentials = true;

ReactDOM.render(
  <ThemeProvider theme={light}>
    <QueryClientProvider client={new QueryClient()}>
      <App />
      <ReactQueryDevtools />
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
