import { report } from "process";
import Lottie from "react-lottie";
import React, { useEffect, useState } from "react";
import { PROCESSOR_S3_URL, S3_URL } from "../../../utils/constants";
import LeadSquaredForm from "../../components/forms/LeadSquaredForm";
import animationData from "../../../assets/Down-arrows.json";
import CustomLoader from "../../../assets/customLoader.gif";

export default function ReportPage(props: any) {
  const quiz_name = props.reportData.quiz_name;
  useEffect(()=>{
    if(document.querySelector(".report-progress-bar > .bar")){
        progressBarAnimation();
        }
  }, [])
  
  const progressBarAnimation = () => { 
    const elem = document.querySelector(".report-progress-bar > .bar") as any;
    const percentIndicator = document.querySelector("#report-wrapper > div > div:nth-child(2) > div.report-progress-bar > div > span") as any;
    let i = 1;
    var width = 100;
    var id = setInterval(frame, 15);
    function frame() {
      if (width <= 10) {
        clearInterval(id);
        i = 0;
      } else {
        width--;
        percentIndicator.innerHTML = width + "%"
        elem.style.width = width + "%";
      }
    }

  }

  const sendScrollDownMessage = () => {
    window.parent.postMessage(JSON.stringify({code: "scroll down arrow clicked"}), "*");
  }

  const downArrorLottieProps = {
    loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      } 
  }
  return (
    <div id="container">
      <div id="top-wave" style={{display: props.reportData.status === 200 ? "block" : "none"}}>
        <img className="desktop" src={S3_URL + "Top-wave.svg"} alt="" />
        <img className="mobile" src={PROCESSOR_S3_URL  + "staging/ASSESSMENT_PROCESSOR/Top-wave (1).svg"} alt="" />
        <div className="report-title">
          <div>
            Your Attitude Trait is
          </div>
          <div>
            {props.reportData.status === 200 ? props.reportData.content.title : ""}
          </div>
        </div>
      </div>

      {props.reportData.status === 200 ?
      <div id="report-wrapper"> 
          <div className="report-content">
            <div>
              <img src={PROCESSOR_S3_URL + props.reportData.content.image} />
              <p className="content">
                <span>According to our analysis and understanding, your Attitude trait is <span style={{fontWeight: "bold"}}>{props.reportData.content.title}</span></span>
                {props.reportData.content.description}
              </p>
            </div>
            <div>
              <div className="report-progress-bar">
                <div className="bar">
                <span>100%</span>
                </div>
              </div>
              <p>
              This is just 10% of what your Attitude trait say about you, <span style={{fontWeight: 500}}>scroll down to know more!</span>
              </p>
              <div onClick={sendScrollDownMessage} className="scroll-down-arrow-animation">
                <Lottie options={downArrorLottieProps}
                height={60}
                width={60}
                />
              </div>
            </div>
          </div>
      </div> : 
      props.reportData.status === 428 ? 
        <div id="lead-squared-form-wrapper" style={{margin: "0 auto", padding: "20px"}}>
          <LeadSquaredForm 
          source={`${quiz_name} quiz page`}
          product={quiz_name}
          />
        </div> 
      : 
      props.reportData.status === 202 ? 
        <div className="invalid-report-id">
          <img src={CustomLoader} />  
        </div>
      :
        <div className="invalid-report-id">
          <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" alt="loader_gif" />
        </div>
      }
    </div>
  )
}