// @ts-nocheck
import React from 'react';
import { Button } from "antd";
import { useState } from "react";
import { Quiz } from "../../../App"

export default function AptitudeQuizSampleQuestion(props: any) {
    if(props.sampleQuestions.length <= 0){
      props.setSampleQuestionsRead(true)
    }
    return (
        <>
            <div className="header"
            style={{
              paddingRight: "80px",
              paddingLeft: "80px",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "40px",
              lineHeight: "40px",
              color: "#333333",
            }}>
                Sample Question
            </div>

            <Quiz
              data={props.sampleQuestions}
              onSubmit={()=>{
                props.setSampleQuestionsRead(true);
              }}
              quizTemplateName={"aptitude-quiz"}
              questionCounterStyles={props.questionCounterStyles}
              sampleQuestion={true}
              quizMeta={props.quizMeta}
              quizTypeName={"universal-quiz-sample-question"}
            />
        </>
    )
}