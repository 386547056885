import React from "react";
import "./headermain.css";
const vector_img = require("../../../assets/IgniterLpQuiz/Mask group.png");
const right_img = require("../../../assets/IgniterLpQuiz/lady_img.png");

function HeaderMain(props) {
  return (
    <div className="main_header_container">
      <div className="under_main_header_container">
        <div className="left_side_container">
          <div className="left_side_img_container">
            <img
              src={vector_img.default}
              className="vector_img_header"
              alt="header_left_img"
            />
          </div>

          <div className="header_title_container">
            <h1 className="header_title_text">Employability</h1>
            <h1 className="header_title_yellow_text">Score</h1>
          </div>
        </div>
        <div className="right_side_container">
          <img
            src={right_img.default}
            className="img_header_bg img-fluid"
            alt="header_img"
          />
        </div>
      </div>
    </div>
  );
}

export default HeaderMain;
