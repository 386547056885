import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useAnalysisReport } from "../../../hooks/useAnalysisReport";
import Flex from "../../components/Flex";
import Loading from "../../components/Loading";
import Navbar from "../../components/Navbar";
import CareerAndPersonalities from ".././analysis/CareerAndPersonalities";
import TestDetails from ".././analysis/TestDetails";
import Traits from ".././analysis/Traits";
import ErrorFallback from ".././ErrorFallback";

import "./stridepac.css";
import StrongSuit from "./strongSuit/stringSuit";
import ReportSummary from "./summary/reportSummary";
import UserDetailsNew from "./userDetails/userDetails";

const staticData = {
  disclaimer: `Personality is a dynamic constitution of human character. Each personality type has some distinct features which makes some professional choices
more suitable for each personality type. This does not mean that a person with a said personality type cannot excel in other fields. Our personality
report is generated solelv based on the answers you gave in the personality test. In our report. vou fill find the career choices most suitable for you
personality type but you can do well in any field you put your efforts into.`,
};
const StridePcaDefault = ({ test, testId, isFetched }) => {
  const { token } = useParams<{ token: string }>();

  const { data: reportMeta, isSuccess, isLoading } = useAnalysisReport(token);

  return (
    <Container column>
      {!isLoading ? (
        isSuccess ? (
          reportMeta?.report_metadata ? (
            <React.Fragment>
              <Content column>
                <div className="disclaimer_container">
                  <h1>Disclaimer :</h1>
                  <p>{staticData?.disclaimer}</p>
                </div>
                <DetailAndSummaryContainer>
                  <ReportSummary
                    testId={testId}
                    authenticate={false}
                    reportMeta={reportMeta}
                  />
                  <UserDetailsNew
                    reportMeta={reportMeta}
                    testId={testId}
                    authenticate={false}
                    bookingLink={test.test_configs?.booking_link ? test.test_configs?.booking_link : null}
                  />
                </DetailAndSummaryContainer>
                <StrongSuit reportMeta={reportMeta} test={test}/>
                <div className="disclaimer_container">
                  <h1>Note :</h1>
                  <p>
                    Personality is just one of the factors to consider while
                    choosing a career path. Other factors like aptitude,
                    emotional quotient, interest, and work style and ability are
                    also equally important to determine the profession best
                    suited for us
                  </p>
                </div>
                <Traits
                  traitMap={reportMeta?.report_metadata["trait-map-content"]}
                />
                <CareerAndPersonalities
                  test={test}
                  reportMetadata={reportMeta?.report_metadata}
                />
              </Content>
            </React.Fragment>
          ) : // null)
          isFetched ? (
            <Loading />
          ) : null
        ) : (
          <ErrorFallback
            error="500"
            showNavbar={false}
            url={`/test/${testId}`}
          />
        )
      ) : // null)}
      isFetched ? (
        <Loading />
      ) : null}
    </Container>
  );
};

export default StridePcaDefault;

const Container = styled(Flex)`
  width: 100%;
  background: ${({ theme }) => theme.colors.blue[50]};
  font-family: Poppins;
  @media only screen and (max-width: 1024px) {
    background: ${({ theme }) => theme.colors.grey[50]};
    padding: 1.2rem;
  }
`;

const Content = styled(Flex)`
  max-width: 1250px;
  margin: 1.5rem auto;
  margin-top: 6rem;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const DetailAndSummaryContainer = styled(Flex)`
  width: 100%;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
