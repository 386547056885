import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { PAGES_URL } from '../utils/constants';
import { APIError, ReportMetadataResponse } from '../utils/types';

export const useProduct = (productId: string) => {
  const fetchAnalysis = async (productId: string) => {
    try {
      const {
        data
      } = await axios.get(PAGES_URL + 'product/' + productId);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  };

  return useQuery<any, AxiosResponse<APIError>>(
    ['product', productId],
    () => fetchAnalysis(productId),
    {
      retry: false,
    }
  );
};
