import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT } from '../utils/constants';
import { APIError, Response } from '../utils/types';

const useReportResponse = (reportId: string) => {
  const fetchResponse = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${API_ENDPOINT}report-response/${reportId}`);
      return data;
    } catch (error) {
      throw error.response.error;
    }
  };

  return useQuery<Response, AxiosResponse<APIError>>(
    ['response', reportId],
    fetchResponse,
    {
      retry: false,
    }
  );
};

export default useReportResponse;
