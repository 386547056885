import React, { FC, useRef, useState } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import styled, { css } from 'styled-components';
import useOutsideClick from '../../hooks/useOutsideClick';
import { fadeIn, slideDown } from '../../styles/animations';
import Flex from './Flex';
import Separator from './Separator';

const TableEditButton: FC<{}> = ({ children }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const popoverRef = useRef(null);

  useOutsideClick(popoverRef, () => setShowDropdown(false));

  return (
    <Flex ref={popoverRef}>
      <EditButton onClick={() => setShowDropdown(!showDropdown)}>
        <HiDotsVertical />
      </EditButton>
      {showDropdown && (
        <PopoverContainer
          align='center'
          justify='center'
          // onClick={() => onClose(false)}
        >
          <Container column onClick={(e) => e.stopPropagation()}>
            {children}
          </Container>
        </PopoverContainer>
      )}
    </Flex>
  );
};

export default TableEditButton;

const PopoverContainer = styled(Flex)`
  position: absolute;
  top: 10%;
  right: 60%;
  z-index: 2;
  animation: ${fadeIn} 0.4s;
`;

const Container = styled(Flex)`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.grey[200]};
  animation: ${slideDown} 0.4s ease-out;
`;

const EditButton = styled.button`
  outline: none;
  border: none;
  height: fit-content;
  padding: 1.2rem 2rem;
  border-radius: 4px;
  background: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey[100]};
  }

  font-size: 2rem;
  cursor: pointer;
`;

export const Action = styled(Flex)<{ disabled?: boolean }>`
  cursor: pointer;
  padding: 1rem 2rem;

  color: ${(props) => props.theme.colors.grey[500]};
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${(props) => props.theme.colors.red[500]};
    `}
  img {
    height: 2.8rem;
    weight: 2.8rem;
    color: ${(props) => props.theme.colors.grey[500]};
    padding: 5px;
    border-radius: 0px 4px 4px 0px;
  }
  span {
    margin-left: 1rem;
    width: 100%;
    white-space: nowrap;
    text-align: left;
  }
`;

export const StyledSeparator = styled(Separator)`
  margin: 0;
`;
