// @ts-nocheck
import React, { FC } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AuthenticatedApp from "../pages/AuthenticatedApp";
import ErrorFallback from "../pages/ErrorFallback";
import QuizBuilder from "../pages/QuizApp";
import TestApp from "../pages/TestApp";
import UnauthenticatedApp from "../pages/UnauthenticatedApp";
// import IgniterLpQuiz from '../pages/report_pages/IgniterQuizReport'
import QuizList from "../components/QuizList";
import TestPage from "../components/TestPage";
import ReportPage from "../components/ReportPage";
import RegisterPage from "../pages/RegisterPage";
import LoginPageCohort from "../pages/LoginPageCohort";
import OtpPage from "../pages/OtpPage";

const RoutesContainer: FC = () => {

    return (
        <BrowserRouter>
            <Switch>
                <Route path='/testQuizApp' component={QuizList} />
                <Route path='/:authPath(login)' component={UnauthenticatedApp} />
                <Route
                    path='/:appPaths(|admin|report|response|brainwave)'
                    component={AuthenticatedApp}
                />
                <Route path='/:testPath(test|feedback|analysis)' component={TestApp} />
                <Route path='/:quizPath(quiz)' component={QuizBuilder} />
                <Route path='/test-page' component={TestPage}/>
                <Route path='/report-page' component={ReportPage}/>
                <Route path="/form/:formId" exact component={LoginPageCohort}/>
                <Route path="/form/:formId/verification" exact component={OtpPage}/>
                <Route path='/form/:formId/:responseId' exact component={RegisterPage}/>
                {/* <Route path='/:igniterLpQuiz' component={IgniterLpQuiz} /> */}
                <Route path='*'>
                    <ErrorFallback error='404' />
                </Route>
            </Switch>
        </BrowserRouter>
    );
};

export default RoutesContainer;
