import styled, { css } from 'styled-components';
import Flex from './Flex';

export const CountContainer = styled(Flex)`
  width: 100%;
`;

export const CountCard = styled(Flex)<{ pointer?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  max-width: 24rem;
  height: 14rem;
  background-color: ${(props) =>
    props.color
      ? props.theme.colors[props.color][400]
      : props.theme.colors.blue[500]};
  border-radius: 5px;
  font-size: 2.4rem;
  font-weight: 500;
  color: #fff;
  margin-right: 4rem;
  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}

  &.last-child {
    margin-right: 0px;
  }

  .count {
    font-size: 4rem;
    margin-top: 2rem;
  }
`;
