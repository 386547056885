import React from 'react';
import chartImage1 from "../../../assets/images/chart-image.png";

export default function ThreeMonthReport() {
  return (
    <div className="three-month-section">
    <div className="container-md">
      <div className="page-title">
        <h3>4 months to get into the ladder of getting a Manager level skills</h3>
      </div>
      <div className="three-month-grid">
        <div className="three-month-grid-items">
          {/* <h3>Stop Feeling Overwhelmed</h3>
          <p>Increase your productivity levels</p> */}
          <img src={chartImage1} alt="chartImage" />
        </div>
        <div className="three-month-grid-items">
          <div className="all-text-alignment">
            <div>
              <h4>86%</h4>
              <h6>
              of the users started with similar skill gap and felt stuck in their career before joining us
              </h6>
            </div>
            <div>
              <h4>98%</h4>
              <h6>
              of them have grown up in their career ladder through our proprietary mentorship program
              </h6>
            </div>
            <div>
              <h4>80%</h4>
              <h6>
              of them got average package above 10 Lpa
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
