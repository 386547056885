import React, { FC } from 'react';
import styled from 'styled-components';
import AlarmBell from '../../../assets/test/alarm-bell.svg';
import { slideDown } from '../../../styles/animations';
import Flex from '../Flex';
import Modal from '../Modal';

const TimeExcedded: FC<{ onClick: any }> = ({ onClick }) => {
  return (
    <Modal width='60rem'>
      <Container column onClick={(e) => e.stopPropagation()}>
        <img src={AlarmBell} alt='Time Excedded' />
        <Message>Time Excedded</Message>
        <Text>Your assessment time is exceeded. Please retake the test.</Text>
        <Flex justify='center' style={{ margin: 'auto' }}>
          <ContinueContainer>
            <Continue onClick={() => onClick()}>Retake Test</Continue>
          </ContinueContainer>
        </Flex>
      </Container>
    </Modal>
  );
};

export default TimeExcedded;

const Container = styled(Flex)`
  width: 37rem;
  height: fit-content;
  padding: 2rem;
  background-color: #fff;
  border-radius: 4px;
  animation: ${slideDown} 0.4s ease-out;
  text-align: center;
  img {
    width: 17rem;
    margin: auto;
    margin-bottom: 1.2rem;
  }

  @media only screen and (max-width: 1024px) {
    width: 80%;
  }
`;

const ContinueContainer = styled(Flex)`
  flex: 1;
`;

const Continue = styled(Flex)`
  background-color: ${(props) => props.theme.colors.blue[500]};
  color: ${(props) => props.theme.colors.white};
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

const Message = styled(Flex)`
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  justify-content: center;
  color: ${(props) => props.theme.colors.blue[500]};
  margin-bottom: 1rem;
`;

const Text = styled(Flex)`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 0rem 5rem;
  color: ${(props) => props.theme.colors.grey[500]};
  margin: 0 auto;
  margin-bottom: 2rem;
`;
