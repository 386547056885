import React, { FC, FocusEvent, SelectHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { IOption } from '../../utils/types';
import Flex from './Flex';

interface IProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  error?: string;
  options?: IOption[];
}

const Select: FC<IProps> = ({
  label,
  name,
  placeholder,
  required,
  autoFocus,
  error,
  value,
  onChange,
  className,
  style,
  onBlur,
  children,
  options,
  ...rest
}) => {
  const [focused, setFocused] = useState(autoFocus || false);

  const handleOnBlurEvent = (event: FocusEvent<HTMLSelectElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <Container align='center' justify='space-between'>
      {label && (
        <label>
          {label}
          {required && ' *'}
        </label>
      )}
      <select
        id={name}
        name={name}
        required={required}
        placeholder={placeholder}
        value={value}
        autoFocus={autoFocus}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onBlur={handleOnBlurEvent}
        className={`${error && 'error'} ${focused && 'focused'}`}
        {...rest}
      >
        <option key='' value=''></option>
        {options?.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <span className='error'>{error}</span>}
    </Container>
  );
};

export default Select;

const Container = styled(Flex)`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  font-weight: 400;

  label {
    color: ${({ theme }) => theme.colors.grey[500]};
    font-weight: normal;
    width: 100%;
  }

  select {
    width: 100%;
    height: 5rem;
    padding-left: 1.6rem;
    margin-top: 0.5rem;
    outline: none;
    font-size: inherit;
    border: 1px solid ${({ theme }) => theme.colors.grey[500]};

    /* Focused state styles */
    &.focused {
      box-shadow: 0 0 2px ${({ theme }) => theme.colors.blue[500]};
      border-color: ${({ theme }) => theme.colors.blue[500]};
    }

    /* Error state styles */
    &.error {
      box-shadow: 0 0 2px ${({ theme }) => theme.colors.red};
      border-color: ${({ theme }) => theme.colors.red[500]};
    }

    border-radius: 0.6rem;
    color: ${({ theme }) => theme.colors.grey[500]};
    transition: 0.1s;

    option,
    option:checked {
      background: white !important;
      border: 1px solid ${({ theme }) => theme.colors.grey[500]} !important;
    }
  }

  span.error {
    margin-top: 0.8rem;
    margin-left: 0.4rem;
    color: ${({ theme }) => theme.colors.red[500]};
  }
`;
