import React from "react";
import "./custmodal.css";
import { MdClose } from "react-icons/md";

export default function CustModal({ open, children, onClose, hideCloseButton }) {
  if (!open) return null;

  return (
    <>
      <div className="overlay_styles" />
      <div className="modal_styles">
        {!hideCloseButton ? <div className="close_btn_text_container">
          <button onClick={onClose} className="close_btn_dialog">
            <MdClose className="md_close_btn_icon1" />
          </button>
        </div> : null}
        {children}
      </div>
    </>
  );
}
