import React, {FC, useEffect, useState} from "react";
import styled from "styled-components";
import {slideDown} from "../../../../styles/animations";
import {ReportMetadataResponse} from "../../../../utils/types";
import Button from "../../../components/Button";
import Flex from "../../../components/Flex";
import Modal from "../../../components/Modal";

import Truncate from "react-truncate";

import "./summary.css";
import {Row} from "antd";
import {useCurrentProfile} from "../../../../hooks/useProfile";
import useTest from "../../../../hooks/useTest";
import {DICEBAR_URL} from "../../../../utils/constants";

const Summary: FC<{
    test2?: any;
    data?: any;
    testId: string;
    authenticate: boolean;
    reportMeta: ReportMetadataResponse;
}> = ({reportMeta, testId, authenticate, test2, data}) => {
    const {data: test, isLoading} = useTest(testId);
    const {data: currentProfile} = useCurrentProfile(
        !isLoading && authenticate
    );
    const [lines, setLines] = useState(0);
    const [occupiedLines, setOccupiedLines] = useState(0);
    const [showSummary, setShowSummary] = useState<boolean>(false);
    console.log(test2?.name)
    useEffect(() => {
        // @ts-ignore
        const descHeight = document.getElementById("desc")?.clientHeight / (test2?.name ? 60 : 25);
        // @ts-ignore
        const occupied = document.getElementById("text_lines")?.clientHeight / 27;
        if (descHeight > 0) setLines(descHeight);
        if (occupied > 0) setOccupiedLines(occupied);
    }, [occupiedLines]);

    const getWidth = () => {
        const {innerWidth: width} = window;
        return width;
    };

    useEffect(() => {
        window.addEventListener("resize", getWidth);
        setWindowWidth(getWidth());
    }, []);

    const [windowWidth, setWindowWidth] = useState(getWidth());

    return (
        <Container column>
            <ROW>
                <img
                    className="heade_image"
                    src={currentProfile?.profile_pic || DICEBAR_URL + (test2?.name ? test2?.name : data?.name) + ".svg"}
                    alt={test2?.name ? test2?.name : data?.name}
                />
                <UserDetailsContainer column>
                    Hi,
                    <div>{test2?.name ? test2?.name : data?.name}</div>
                </UserDetailsContainer>
            </ROW>

            <h1 className="heading m-0">Summary:</h1>
            <p className="summary-text" id="desc">
                {windowWidth > 768 ? (
                    <Truncate
                        lines={
                            // @ts-ignore
                            parseInt(lines)
                        }
                    >
                        <p id="text_lines">
                            {reportMeta?.description}
                        </p>
                    </Truncate>
                ) : (
                    <p id="text_lines">{reportMeta?.description}</p>
                )}
            </p>
            {occupiedLines > lines && windowWidth > 768 && (
                <KnowMoreButton onClick={() => setShowSummary(true)} test={test}>
                    Know more
                </KnowMoreButton>
            )}

            {showSummary && (
                <Modal onClose={(showModal) => setShowSummary(showModal)}>
                    <SummaryModal column>
                        <h2>Summary</h2>
                        <div>{reportMeta?.description}</div>
                    </SummaryModal>
                </Modal>
            )}
        </Container>
    );
};

export default Summary;

const Container = styled(Flex)`
  flex: 1;
  padding-right: 4rem;
  border-radius: 10px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin: 1.6rem 0;
    padding: 1.2rem;
    height: fit-content;
  }
`;

const SummaryModal = styled(Flex)`
  width: 100rem;
  padding: 4rem;
  height: fit-content;
  background-color: #fff;
  border-radius: 1rem;
  animation: ${slideDown} 0.4s ease-out;
  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-weight: 600;
  }
  div {
    font-family: Roboto;
    font-size: 2rem;
    line-height: 150%;
  }

  @media only screen and (max-width: 1024px) {
    width: 90%;
    padding: 3rem;
    h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }
    div {
      font-family: Roboto;
      font-size: 1.5rem;
      line-height: 150%;
    }
  }
`;

const KnowMoreButton = styled(Button)`
  width: max-content;
  padding: 1rem;
  height: auto;
  background: #c1eafb;
  border-radius: 4px;
  background-color: ${(props) => props.test?.test_configs?.theme?.primary_color || props.theme.colors.blue[500]};
  color: ${(props) => props.test?.test_configs?.theme?.secondary_color || props.theme.colors.white[500]};

  font-weight: normal;
  @media only screen and (max-width: 1024px) {
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
  }
`;

const ROW = styled(Flex)`
  img {
    width: 10rem;
    height: 10rem;
    margin-right: 3.5rem;
    margin-bottom: 2rem;
    @media only screen and (max-width: 1024px) {
      width: 7rem;
      height: 7rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }
`;

const UserDetailsContainer = styled(Flex)`
  font-weight: 600;
  font-size: 36px;
  line-height: 150%;
  color: ${({theme}) => theme?.colors?.grey[400]};
  div {
    color: ${({theme}) => theme?.colors?.grey[600]};
  }
  @media only screen and (max-width: 1024px) {
    font-size: 2.4rem;
    margin-left: 2rem;
  }
`;

const Divider = styled(Flex)`
  width: 100%;
  height: 2px;
  background: ${({theme}) => theme.colors.grey[200]};
`;

const Heading = styled(Flex)`
  font-weight: 500;
  font-size: 2.5rem;
  color: ${({theme}) => theme.colors.grey[400]};
  width: 15rem;
  margin: 2.4rem 0;

  @media only screen and (max-width: 1024px) {
    font-size: 1.6rem;
    width: 10rem;
  }
`;

const Value = styled(Flex)`
  font-weight: 500;
  font-size: 2.5rem;
  margin: 2.4rem 0;
  color: ${({theme}) => theme.colors.grey[600]};
  @media only screen and (max-width: 1024px) {
    font-size: 1.6rem;
  }
`;
