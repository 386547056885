export const addElement = (item: object) => {
  return {
    type: "ADD_ELEMENT",
    item,
  } as const;
};

export const patchElement = (id: string, newitem: Object) => {
  return {
    type: "PATCH_ELEMENT",
    id,
    newitem,
  } as const;
};

export const deleteElement = (id: string) => {
  return {
    type: "DELETE_ELEMENT",
    id,
  } as const;
};

export const moveElement = (oldIndex: number, newIndex: number) => {
  return {
    type: "MOVE_ELEMENT",
    oldIndex,
    newIndex,
  } as const;
};

export const toggleSampleQuestion = (id: string, value: boolean) => {
  return {
    type: "TOGGLE_SAMPLE_QUESTION",
    id,
    value,
  } as const;
};

export const addRiasecTrait = (id: string, value: string) => {
  return {
    type: "ADD_RIASEC_TRAIT",
    id,
    value,
  } as const;
};

export const addAskIITiansSubject = (id: string, value: string) => {
  return {
    type: "ADD_ASKIITIANS_SUBJECT",
    id,
    value,
  } as const;
};

export const addAskIITiansChapter = (id: string, value: string) => {
  return {
    type: "ADD_ASKIITIANS_CHAPTER",
    id,
    value,
  } as const;
};

export const addScoringCode = (id: string, value: string) => {
  return {
    type: "ADD_SCORING_CODE",
    id,
    value,
  } as const;
};

export const addDimension = (id: string, value: string) => {
  return {
    type: "ADD_DIMENSION",
    id,
    value,
  } as const;
};

export type IActions = ReturnType<
  | typeof addElement
  | typeof deleteElement
  | typeof patchElement
  | typeof moveElement
  | typeof toggleSampleQuestion
  | typeof addRiasecTrait
  | typeof addScoringCode
  | typeof addDimension
  | typeof addAskIITiansSubject
  | typeof addAskIITiansChapter
>;
