import styled from "styled-components";
import React from "react";
import {data} from "../../../data";
import User from "../../../assets/user.png";

const {heading, willGet} = data.WhatYouWillGet;
const WhatYouWillGet = () => {
    return (
        <PaddingDiv>
        <MainDiv className="container-md">
            <Heading1 className="d-flex justify-content-center">
                {heading}
            </Heading1>
            <Divider>
                <hr/>
            </Divider>
            <div className="row">
                {willGet?.map((content, index) => {
                    return (
                        <div className="col-lg-4" col-6>
                            <MiniCard className="d-flex align-items-center">
                                <Img className="d-flex justify-content-center align-items-center">
                                    <img src={content?.icon}/>
                                </Img>
                                <Content>
                                    <p>
                                        {content?.text}
                                    </p>
                                </Content>
                            </MiniCard>
                        </div>
                    )
                })}
            </div>
        </MainDiv>
        </PaddingDiv>
    )
}

export default WhatYouWillGet;

const MainDiv = styled.div`
   
`;

const PaddingDiv = styled.div`
   padding: 0px 0px 120px;
`;

const Heading1 = styled.h4`
     color: #0C3838;
     font-weight: 700;
     font-size: 48px;
     margin-top:100px;
     margin-bottom:30px;
     line-height:160%;
     text-align:center;
     @media (max-width: 768px){
       font-size:26px;
     }
`;

const Divider = styled.div`
   width:100%;
   display:flex;
   justify-content:center;
   align-items: center;
 
   hr{
    width: 110px;
    height: 4px;
    border-radius: 50%;
    background: #8B292A;
    opacity: 1;
    border: none;
    margin-top:20px;
    margin-bottom:40px;
   }
`;

const MiniCard = styled.div`
   margin-bottom:15px;
`;

const Img = styled.div`
    width: 70px;
    height: 70px;
    @media (max-width: 768px){
       width: 50px;
       height: 50px;
     }
`;

const Content = styled.div`
    width: 70%;
    text-align: start;
    margin-left:20px;
    p{
    margin-top:6px;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: #281E42;
    @media (max-width: 768px){
      font-size: 14px;
     }
    }
`;
