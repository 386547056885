// @ts-nocheck
import { Select } from 'antd';
import React, { useContext, useEffect, useRef } from 'react';
import { addRiasecTrait } from "../../reducer/actions";
import QuizzContext, { IQuizzBuilderContext } from '../../../QuizzContext';

export default function RiasecTraitSelectInput(props: any){
    const { Option } = Select;
    const { dispatch } = useContext(QuizzContext) as IQuizzBuilderContext;
    useEffect(() => {
      if(!props.defaultValue){
        dispatch(addRiasecTrait(props.id, 'r'));
      }
    }, [])
    return (
      <>
        <div style={{marginBottom: '10px'}}>Select a Trait Type for given question</div>
        <Select
          onSelect={(value) => {
            dispatch(addRiasecTrait(props.id, value));
          }}
          defaultValue={props.defaultValue ? props.defaultValue : "r"}
        >
          <Option value="r">R</Option>
          <Option value="i">I</Option>
          <Option value="a">A</Option>
          <Option value="s">S</Option>
          <Option value="e">E</Option>
          <Option value="c">C</Option>
        </Select>
      </>
    );
}