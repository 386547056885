import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Laughing from "../../assets/images/feedback_icon/laughing.svg"
import Smile from "../../assets/images/feedback_icon/smile.svg"
import Neutral from "../../assets/images/feedback_icon/neutral.svg"
import Frown from "../../assets/images/feedback_icon/frown.svg"
import Angry from "../../assets/images/feedback_icon/angry.svg"
import ShareIcon from "../../assets/images/share.svg"
import { useParams } from "react-router-dom";
import moment from 'moment';
import { useAnalysisReport } from "../../hooks/useAnalysisReport";
import RiasecTestReport from "./report_pages/RiasecTestReport";
import AskIITiansReportPage from "./report_pages/AskIITiansReportPage";
import StridePcaDefault from "./report_pages/StridePcaDefault";
import axios from "axios";
import { API_ENDPOINT } from "../../utils/constants";

import Toast from "../components/Toast";
import { copyToClipboard } from "../../utils/helperFunctions";


function ReportDisclosure({ test, test1, reportData, testId }) {
    // console.log('test1', test1)
    // console.log('testId', testId)
    // console.log('line 22', test)
    // console.log('reportData', reportData)

    // console.log('line 22', data)
    // const { token } = useParams<{ token: string }>();
    let params = new URLSearchParams(document.location.search);
    const token = params.get("report_id")
    console.log(token,"token")
    const { data, data: reportMeta } = useAnalysisReport(token||'');
    const reportData1 = reportMeta?.report_metadata;
    // console.log('reportData1', reportData1)




    const [toastText, setToastText] = useState<string>("");
    const [showToast, setShowToast] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const copyLink = (link: string) => {
        copyToClipboard(link);
        setToastText('Your report link has been copied.');
        setShowToast(true);
        setSuccess(true);
    };


    return (
        <MainContainer>
            <LeftContainer className="row justify-content-around">
                <div className="col-lg-11 col-10">
                    <H2>{test1?.test_name}</H2>
                    <H3>{test?.name}</H3>
                    <HR />
                    <AssessmentDetailsExtend>
                        <H4>Test ID : <Span> {testId}</Span></H4>
                        {test?.report_date === null || test?.report_date === undefined || test?.report_date === "" ?
                            "" :
                            <H4>Test Date : <Span>{moment(test?.report_date).format('Do MMMM YYYY')}</Span></H4>
                        }
                        {test?.test_time === null || test?.test_time === undefined || test?.test_time === "" ?
                            "" :
                            <H4>Allotted Time : <Span>{test?.allotted_time ?? "--"}</Span></H4>
                        }
                        {test?.time_taken === null || test?.time_taken === undefined || test?.time_taken === "" ?
                            "" : <H4>Time taken to Complete : <Span>{reportData?.time_taken_to_complete ?? "--"}</Span></H4>
                        }
                    </AssessmentDetailsExtend>
                    <AssessmentDetailsExtend2>
                        <p>Career Interest (RIASEC Code) : <Span>{reportData?.riasec?.type_code}</Span></p>
                        <p>Personality Assessment (MBTI Code) : <Span>{reportData?.pca?.phrase}</Span></p>
                        <p>Learning Loss Assessment : <Span>{reportData?.learning_losss?.score}</Span></p>
                    </AssessmentDetailsExtend2>
                </div>
                <div className="col-lg-1 col-2 ">
                    <img onClick={() => copyLink(test?.report_link)} src={ShareIcon} />
                </div>
            </LeftContainer>
            <HR2 />
            <h2>Hi {(test?.name)?.split(' ')[0]} !</h2>
            <p>Congratulations on taking the first step toward your ideal career. Here is your report!</p>
            <h2>Report Disclosure</h2>
            <p>This report provides information using results from Career interest assessment, Personality
                inventory, and knowledge loss assessment. This forms a career persona around a person, fitting
                them into a career family and providing an accurate career pathway through scientifically
                rigorous and standardized methods to help them discover their ideal career path and reach their
                full potential!</p>
            <h2>Career Interest</h2>
            <p>The assessment of interests is based on Holland's theory. The theory states that people and the
                environments they operate in (careers) can be classified into a combination of six interest or
                vocational personality types and congruence with the environment leads to satisfaction,
                persistence, and success. The six types identified are Realistic, Investigative, Artistic,
                Social, Enterprising, and Conventional, also called ‘RIASEC’.</p>
            <p> The six types can be classified as follow -</p>
            <div className="tableDiv">
                <Table className="table table-bordered">
                    <TableHead>
                        <tr className="text-center">
                            <th scope="col"></th>
                            <th scope="col">Interest & Abilities</th>
                            <th scope="col">Typical Traits</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        <tr>
                            <th scope="row">R - Realistic</th>
                            <td>Realistic types (doers) are those who tend to adopt a concrete approach to problem solving and can often be assertive, competitive and interested in activities that require motor coordination, skill and strength.
                            </td>
                            <td> They particularly enjoy practical things that can be seen, touched, and used like tools, equipment, machinery and hardware. 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">I - Investigative</th>
                            <td>Investigative types (thinker) are inclined towards complexities with a soluion focused view. They tend to be very analytical and prefer to work with data and logic. They view themselves as precise, scientific, and intellectual.
                            </td>
                            <td>They possess the ability to understand and solve problems of scientific and mathematical nature.
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">A - Artistic</th>
                            <td>Artistic types (creators) are the ones that think outside the box. Creative individuals possess a unique ability to think differently, challenging the status quo and generating fresh, imaginative ideas.
                            </td>
                            <td>They possess a natural inclination to see things from various angles and to break away from rigid rules, constantly pushing boundaries in the pursuit of originality.
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">S - Social</th>
                            <td>Social types (helpers) are the ones that tend to be welcoming, sociable and are highly likely to serve and help others, while finding great joy in it. They view themselves as helpful, friendly, and trustworthy.
                            </td>
                            <td>They possess an inclination towards teaching, counseling, or serving the community.
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">E - Enterprising</th>
                            <td>Enterprising types (persuaders) are the ones who have the natural ability to lead others. They always aim for successful new ventures. They can often be very persuasive and possess the ability to make a sale.
                            </td>
                            <td>They view themselves as energetic, ambitious, and sociable.

                            </td>
                        </tr>
                        <tr>
                            <th scope="row">C - Conventional</th>
                            <td>Conventional types (organizers) tend to like structure, rules and order. They are often very organized and methodical, and like to get things done on time.
                            </td>
                            <td>These precision-loving perfectionists thrive in structured indoor office environments.

                            </td>
                        </tr>
                    </TableBody>
                </Table>
            </div>

            <h2>Personality Assessment</h2>
            <p>Personality assessment measures an individual’s distinct characteristics that define their work
                styles and potential patterns of behavior across varied situations. It comprises of major 4
                areas of introspection.
            </p>
            <List>
                <ul>
                    <li><LiSpan>Attention focus</LiSpan> - Extraversion (E) vs Introversion (I)</li>
                    <ol style={{ listStyle: "lower-alpha" }}>
                        <li><Li2Span>Extroversion</Li2Span> - Directing energy towards the external world of
                            people and objects.
                        </li>
                        <li><Li2Span>Introversion</Li2Span> - Directing energy towards the internal world of
                            experiences and
                            ideas.
                        </li>
                    </ol>
                    <li><LiSpan>Information intake</LiSpan> - Sensing (S) vs Intuition (N)</li>

                    <ol style={{ listStyle: "lower-alpha" }}>
                        <li><Li2Span>Sensing</Li2Span> - Concentration based on perceptions and intakes by five
                            senses.
                        </li>
                        <li><Li2Span>Intuition</Li2Span> - Concentration on pattern perception going beyond
                            senses.
                        </li>
                    </ol>

                    <li><LiSpan>Decision Making</LiSpan> - Thinking (T) vs Feeling (F)</li>
                    <ol style={{ listStyle: "lower-alpha" }}>
                        <li><Li2Span>Thinking</Li2Span> - Inference drawn based on logical analysis with a focus
                            on objectivity
                            and
                            detachment.
                        </li>
                        <li><Li2Span>Feeling</Li2Span> - inference drawn based on personal and social values
                            with a focus on
                            understanding
                            and harmony.
                        </li>
                    </ol>
                    <li><LiSpan>World Perception</LiSpan> - Judging (J) vs Perceiving (P)</li>
                    <ol style={{ listStyle: "lower-alpha" }}>
                        <li><Li2Span>Judging</Li2Span> - Decisiveness and closure when dealing with the outer
                            world.
                        </li>
                        <li><Li2Span>Perceiving</Li2Span> - Flexibility and spontaneity when dealing with the
                            outer world.
                        </li>
                    </ol>
                </ul>
            </List>
            <h2>Learning Loss Assessment</h2>
            <p>learning loss assessment refers to the measure of the learning loss accumulated over the years,
                which helps understand the ambit of regression and forgetting in a child’s learning.
                This will help you identify the learning gaps in your concept understanding from the previous
                two grades.
            </p>
            <p>
                Its major focus area is on two subjects, mathematics, and science, which are further divided
                into chapters and topics for a categorical understanding of specific work areas. This will help
                you identify your problem areas and strengths through a quantitative knowledge gap analysis. It
                further helps you understand specific topics that require attention and chapters which will help
                you gain excellence in higher grades.
            </p>
            {
                showToast && <Toast onClose={(showToast) => setShowToast(showToast)}
                    success={success}>
                    {toastText}
                </Toast>
            }
        </MainContainer>
    )
};

function Disclaimer({ testId, test }) {
    const [feedBack, setFeedBack] = useState<boolean>(false);
    const [showFeedBack, setShowFeedBack] = useState<boolean>(false);
    const [rating, setRating] = useState('');
    const [comment, setComment] = useState<string>('');
    const [toastText, setToastText] = useState<string>("");
    const [showToast, setShowToast] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    // const setToast = (success: boolean) => {
    //     setSuccess(success);
    //     setShowToast(true);
    // };
    const FeedBackSend = async () => {
        const feedBackData = {
            name: test?.name,
            email: test?.email,
            report_id: test?.id,
            rating: rating,
            comment: comment
        }
        try {
            const data = await axios.post(`${API_ENDPOINT}feedback/${testId}`, feedBackData)
            if (data?.data?.success) {
                setSuccess(true);
                setShowToast(true);
                setToastText(data?.data?.message);
                setShowFeedBack(true);
            } else {
                setSuccess(false);
                setShowToast(true);
                setToastText("Fail To Share Your FeedBack Message, Please Try Again")
            }
        } catch (error) {
            setSuccess(false);
            setShowToast(true);
            setToastText(error?.message)
        }

    }

    useEffect(() => {
        if (test?.feedback_shared) {
            setShowFeedBack(true);
        }
    }, [test?.feedback_shared])

    return (
        <DisclaimerMainDiv>
            <h2>Disclaimer</h2>
            <p>
                Psychometric tests are based on highly standardized methodology and scientific rigor, which makes them
                extremely generalizable, however, in no way does it provide an exhaustive persona around a person nor do
                we claim absolute reliability, the test has its limitations to define a broad concept like personality.
                <p>
                    The results thus obtained are for an overall understanding of the self to facilitate better
                    decision-making. For increasing the validity and further consideration of dimensional factors, a
                    mentor
                    consultation for interpreting the assessment and specifying career pathways is recommended.</p>
            </p>
            {!showFeedBack && (!feedBack ?
                <FeedbackDiv className="text-center">
                    <h2>Feel free to drop us your feedback</h2>
                    <p>How was your overall experience?</p>
                    <RatingDiv>
                        <img src={Laughing} onClick={() => {
                            setRating('5');
                            setFeedBack(true);
                        }} />
                        <img src={Smile} onClick={() => {
                            setRating('4');
                            setFeedBack(true);
                        }} />
                        <img src={Neutral} onClick={() => {
                            setRating('3');
                            setFeedBack(true);
                        }} />
                        <img src={Frown} onClick={() => {
                            setRating('2');
                            setFeedBack(true);
                        }} />
                        <img src={Angry} onClick={() => {
                            setRating('1');
                            setFeedBack(true);
                        }} />
                    </RatingDiv>
                </FeedbackDiv>
                :
                <FeedBackMessageDiv>
                    <h2 style={{ marginTop: "0px" }}>How can we improve?</h2>
                    <MessageInput>
                        <div className="form-floating">
                            <textarea className="form-control" value={comment}
                                onChange={(e: any) => setComment(e.target.value)}
                                placeholder="Write your feedback"
                                id="floatingTextarea"></textarea>
                            <label htmlFor="floatingTextarea">Write your feedback</label>
                        </div>
                    </MessageInput>
                    <FeedBackButton onClick={() => {
                        FeedBackSend();
                        setFeedBack(false);
                    }}>Share feedback</FeedBackButton>
                </FeedBackMessageDiv>
            )}
            {
                showToast &&
                <Toast onClose={(showToast) => setShowToast(showToast)} success={success}>
                    {toastText}
                </Toast>
            }
        </DisclaimerMainDiv>
    )
}


export default function AskiitiansHackathonReportPage({ test, reportName }) {
    const { token, testId } = useParams<{ token: string, testId: string }>();

    let params = new URLSearchParams(document.location.search);
    const report_id = params.get("report_id")
    console.log(report_id,"token")
    
    const { data, data: reportMeta, isSuccess, isLoading } = useAnalysisReport(report_id||'');
    const [component, setComponent] = useState<any>();
    const reportData = reportMeta?.report_metadata;
    // const reportDate = reportMeta?.report_metadata.report_date;    
    // console.log({ reportData })
    // console.log({ data })

    const components = () => {

    }

    useEffect(() => {
        switch (reportName) {
            case "Report Disclosure":

            default:
                setComponent(<ReportDisclosure test={data} test1={test} reportData={reportData} testId={testId} />)
                break;

            case "Riasec Report Page":
                setComponent(<RiasecTestReport reportData2={reportData?.riasec} test={data} testId={testId}
                    isFetched={undefined} />)
                break;

            case "AskIItians Report Page":
                setComponent(<AskIITiansReportPage reportData2={reportData?.learning_losss} isSuccess2={isSuccess}
                    isLoading2={isLoading} test={data}
                    testId={testId}
                    isFetched={undefined}
                     />
                    )
                break;

            case "PCA Report Page":
                setComponent(<StridePcaDefault reportData2={reportData?.pca} test={data} isSuccess2={isSuccess}
                    isLoading2={isLoading}
                    testId={testId}
                    isFetched={undefined} />)
                break;
        }
    }, [reportName || reportData]);

    return (
        <div className="container-fluid">
            <AssessmentDetails>
                <Content>
                    {
                        component
                    }
                    <Disclaimer testId={testId} test={data} />
                </Content>
            </AssessmentDetails>
        </div>
    )
};

const AssessmentDetails = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins';
`;
const MainContainer = styled.div`
   @media(max-width:768px){
     padding:10px;
   }
`;
const DisclaimerMainDiv = styled.div`
   @media(max-width:768px){
     padding:10px;
   }
`;
const LeftContainer = styled.div`
img{
  cursor: pointer;
}
@media(max-width:768px){
img{
   width:30px;
   height:30px;
  }
}  
`;
const H2 = styled.h1`
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 700 !important;
   font-size: 24px !important;
   line-height: 60px !important;
   color: #1D2B35 !important;
   margin-top:0px !important;
   margin-bottom:14px !important;
   
@media(max-width:768px){
   font-size: 18px !important;
   line-height: 30px !important;
   }
`;
const H3 = styled.h3`
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 600 !important;
   font-size: 20px !important;
   line-height: 36px !important;
   color: #1D2B35 !important;
   
@media(max-width:768px){
   font-size: 16px !important;
   line-height: 20px !important;
   }
`;
const H4 = styled.h4`
   font-weight: 500 !important;
   font-size: 16px !important;
   line-height: 30px !important;
   color: rgba(29, 43, 53, 0.8);
   
@media(max-width:768px){
   font-size: 12px !important;
   line-height: 20px !important;
   }
`;
const HR = styled.hr`
width: 50px;
height: 0px;
border-color: 2px solid #47556B;
`;
const HR2 = styled.hr`
width: auto;
height: 0px;
border-color: 2px solid #47556B;
margin-top:20px;
`;
const Span = styled.span`
   font-weight: 600;
   font-size: 16px;
   line-height: 20px;
   color: rgba(29, 43, 53, 0.8);
   
@media(max-width:768px){
   font-size: 14px !important;
   line-height: 20px !important;
   }
`;
const AssessmentDetailsExtend = styled.div`

`;
const AssessmentDetailsExtend2 = styled.div`
    margin-top:30px;
    p{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #1D2B35;
    }
    
@media(max-width:768px){
    margin-top:10px !important;
   p{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px !important;
    color: #1D2B35;
    }
   }
`;
const Content = styled.div`
    h2{
    margin-top:30px !important;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #1D2B35;
    }
    p{
    margin-top:20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #1D2B35;
    }
    
@media(max-width:768px){
  h2{
    margin-top:20px !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #1D2B35;
    }
  p{
    margin-top:15px;
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
    color: #1D2B35;
    }
   }
`;
const Table = styled.table`
    border: 1px solid rgba(29, 43, 53, 0.5) !important;
`;
const TableHead = styled.thead`
    font-size:16px;
@media(max-width:768px){
   font-size: 10px;
   }
`;
const TableBody = styled.tbody`
    font-size:16px;
    
    th{
      width:18%;
      padding:15px !important;
    }
    td{
     padding:15px !important;
    }
@media(max-width:768px){
   font-size:9px;
    
    th{
      width:28%;
      padding:8px !important;
    }
    td{
     padding:8px !important;
    }
   }
`;
const List = styled.div`
   ul{
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #1D2B35 !important;
    
@media(max-width:768px){
   font-size: 10px !important;
    line-height: 20px !important;
    margin:0px;
   }
}   
`;
const LiSpan = styled.span`
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #1D2B35;
@media(max-width:768px){
    font-size: 12px !important;
    line-height: 20px !important;
   }
`;
const Li2Span = styled.span`
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #1D2B35;
@media(max-width:768px){
    font-size: 12px !important;
    line-height: 20px !important;
   }    
    
`;
const FeedbackDiv = styled.div`
   width:100%;
   background: transparent ;
   border-radius: 10px;
   padding-top: 10px;
   padding-bottom:30px; 
   p{
   font-weight: 600;
   font-size: 16px;
   line-height: 27px;
   color: #4F4F4F;
@media(max-width:768px){
   font-size: 14px;
   line-height: 20px;
  }
}
   
`;
const RatingDiv = styled.div`
   img{
    margin:5px;
    opacity: 0.5;
    cursor: pointer;
    &:hover {
    opacity: 1;
  }
@media(max-width:768px){
   width:30px;
   height:30px;
  }
   }
`;
const FeedBackMessageDiv = styled.div`
    background: transparent;
    border-radius: 10px;
    padding:30px;
@media(max-width:768px){
   padding:15px;
  }
`;
const MessageInput = styled.div`

  textarea{
    resize: none;
    background:transparent;
    height:100px !important;
    border: 1px solid rgba(29, 43, 53, 0.4) !important;
    border-radius: 10px !important;
    padding-top: 20px !important;
}
  }
  label{
    color: #1d2b35d6;
    font-weight: 400;
    font-size: 16px;
@media(max-width:768px){
   font-size: 12px;
}    
  }
  .form-control:focus{
   background:transparent !important;
   border: 1px solid rgba(29, 43, 53, 0.4) !important;
   border-radius: 10px !important;
  }

`;
const FeedBackButton = styled.button`
      background: #1273B7;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      border:none;
      padding: 13px 21px;
      color: #FFFFFF;
      margin-top:30px;
@media(max-width:768px){
   font-size: 12px;
   margin-top:15px;
   padding: 8px 15px;
}     
`;
