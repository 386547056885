import styled from 'styled-components';

const Separator = styled.span`
  display: block;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey[100]};
  margin: 2rem 0;
`;

export default Separator;

export const HeadingSeparator = styled.span`
  display: block;
  border: 2px solid ${({ theme }) => theme.colors.yellow[500]};
  width: 15%;
  margin-bottom: 30px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.yellow[500]};
`;
