import React, { FC } from 'react';
import { useCurrentProfile } from '../../hooks/useProfile';
import AdminDashboard from './AdminDashboard';
import UserDashboard from './UserDashboard';
import ErrorFallback from './ErrorFallback';
import BrainwaveAdminDashboard from './BrainwaveDashboard';

const Dashboard: FC = () => {
  const { data: currentProfile } = useCurrentProfile();

  const renderDashboard = () => {
    if (currentProfile?.role === 'admin' || currentProfile?.role === 'client') {
      return <UserDashboard />;
    } else if (currentProfile?.role === 'superadmin') {
      return <AdminDashboard />;
    } else if (currentProfile?.role === "brainwave-admin") {
      return <BrainwaveAdminDashboard />
    } else {
      return <ErrorFallback error='401' />;
    }
  };

  return <>{renderDashboard()}</>;
};

export default Dashboard;
