import styled from 'styled-components';
import { TestDetail } from '../../utils/types';

interface IProps {
  column?: boolean;
  align?: string;
  justify?: string;
  rowReverse?: boolean;
  flex?: number;
  direction?: string;
  test?: TestDetail
}

const Flex = styled.div<IProps>`
  display: flex;
  flex-direction: ${(props) =>
    (props.column && 'column') ||
    (props.rowReverse && 'row-reverse') ||
    props.direction};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  flex: ${(props) => props.flex};
`;

export default Flex;
