import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { useAnalysisReport } from "../../../hooks/useAnalysisReport";
import {
    IGNITER_REDIRECT_URL_0,
    IGNITER_REDIRECT_URL_1, IGNITER_REDIRECT_URL_2,
    IGNITER_REPORT_CTA_0, IGNITER_REPORT_CTA_1, IGNITER_REPORT_CTA_2
} from "../../../utils/constants";
import Flex from "../../components/Flex";
import Loading from "../../components/Loading";
import Navbar from "../../components/Navbar";
import ErrorFallback from ".././ErrorFallback";
import Footer from ".././Footer";
import AboutSection from "../../components/About section/AboutSection";
import HeaderMain from "../../components/Header section/HeaderMain";
import WorkMain from "../../components/Work section/WorkMain";
import "./stridepac.css";

;
const EmployabilityReportPage = ({ test, testId, isFetched }) => {
    const { token } = useParams<{ token: string }>();

    const [CTADetails, setCTADetails] = useState({});
    const customLogo = test?.logo_image;
    const { data: reportMeta, isSuccess, isLoading } = useAnalysisReport(token);
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();

    useEffect(() => {
        setCTADetails(URLProvider(query?.get("cta_type")));
    }, []);
    const URLProvider = (cta_type) => {
        switch (cta_type) {
            case "0":
                return {
                    url: IGNITER_REDIRECT_URL_0,
                    text: IGNITER_REPORT_CTA_0,
                };
            case "1":
                return {
                    url: IGNITER_REDIRECT_URL_1,
                    text: IGNITER_REPORT_CTA_1,
                };
            case "2":
                return {
                    url: IGNITER_REDIRECT_URL_2,
                    text: IGNITER_REPORT_CTA_2,
                };
            default:
                return {
                    url: IGNITER_REDIRECT_URL_0,
                    text: IGNITER_REPORT_CTA_0,
                };
        }
    };
    return (
        <Container column>
            {!isLoading ? (
                isSuccess ? (
                    reportMeta?.report_metadata ? (
                        <React.Fragment>
                            <Navbar
                                homepage="https://strideahead.in"
                                customLogo={customLogo}
                            />

                            <div>

                                <HeaderMain />

                                <AboutSection reportData={reportMeta?.report_metadata} CTADetails={CTADetails} />

                                <WorkMain CTADetails={CTADetails} />

                            </div>

                            <Footer />
                        </React.Fragment>
                    ) : // null)
                        isFetched ? (
                            <Loading />
                        ) : null
                ) : (
                    <ErrorFallback
                        error="500"
                        showNavbar={false}
                        url={`/test/${testId}`}
                    />
                )
            ) : // null)}
                isFetched ? (
                    <Loading />

                ) : null}

        </Container>
    );
};

export default EmployabilityReportPage;

const Container = styled(Flex)`
  width: 100%;
  background: ${({ theme }) => theme.colors.blue[50]};
  font-family: Poppins;
  @media only screen and (max-width: 1024px) {
    background: ${({ theme }) => theme.colors.grey[50]};
    padding: 1.2rem;
  }
`;

const Content = styled(Flex)`
  max-width: 1250px;
  margin: 1.5rem auto;
  margin-top: 6rem;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const DetailAndSummaryContainer = styled(Flex)`
  width: 100%;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
