import React, { PureComponent, useCallback } from "react";
import animationData from "../assets/Check-mark-completed.json";
import QuizzContext, {
  getDefaultContext,
  IQuizzContext,
} from "../QuizzContext";
import { Button, Empty, Form } from "antd";
import { LOCALHOST, API_ENDPOINT } from "../utils/constants";
import UniversalQuizPopUp from "../ui/components/popups/UniversalQuizPopUp";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import CustomProgressBar from "../ui/components/ProgressBar";

class Quizz extends PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.totalQuestions = props.data.length;
    this.state = {
      questionNo: 1,
      renderPopUp: false,
      submitNow: false,
      finalQuestionNo: 1,
      showGIFLoader: false,
      submitData: null,
      // selectedAnswers: {},
    };

    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  totalQuestions;
  quizStartTime;
  quizEndTime;
  selectedAnswers = {};
  pathname: string = window.location.pathname;
  id = this.pathname.split("/")[this.pathname.split("/").length - 1];

  handleQuizSubmit(e) {
    let timeTaken: any = (this.quizEndTime || new Date()) - this.quizStartTime;
    timeTaken = new Date(timeTaken).toISOString().substr(11, 8);
    let formData = {
      quiz_id: this.id,
      quiz_response: this.selectedAnswers,
      time_taken: timeTaken,
    };
    fetch(`${API_ENDPOINT}quiz/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          return Promise.reject(500);
        }
      })
      .then((data) => {
        this.setState({ renderPopUp: true });
        this.setState({ submitData: data });
        const report_url = `${LOCALHOST}quiz/report/${this.state.submitData["data"]["data"]}${window.location.search}`
        const callback_url = `${API_ENDPOINT}quiz/report/${this.state.submitData["data"]["data"]}`
        window.parent.postMessage(JSON.stringify(
          { code: "quiz_submitted", "report_url": report_url, callback_url: callback_url, report_id: this.state.submitData["data"]["data"] }),
          "*"
        );
      })
      .catch((err) => {
        console.log(err);
        alert("Failed to submit the quiz. Please try again.");
      });
  }

  handleNext() {
    const value = this.state.questionNo + 1;
    if (value <= this.props.data.length) {
      this.setState({ questionNo: value });
      if (value > this.state.finalQuestionNo) {
        this.setState({ finalQuestionNo: value });
      }
    } else {
      if (this.props.sampleQuestion) {
        this.props.onSubmit();
        return;
      }
      if (window.parent.location == window.location) {
        this.handleQuizSubmit(this.props);
      } else {
        this.setState({ submitNow: true });
        window.parent.postMessage(JSON.stringify({ code: "show popup" }), "*");
      }
    }
  }

  getPercentage(): any {
    if (this.state.renderPopUp) {
      return 99;
    }
    return (((this.state.questionNo - 1) / this.totalQuestions) * 100).toFixed(
      0
    );
  }

  handlePrev() {
    const value = this.state.questionNo - 1;
    if (value >= 1) {
      this.setState({ questionNo: value });
    }
  }

  async onAnswerSelect(e) {
    e?.preventDefault && e.preventDefault();
    e?.stopPropagation && e.stopPropagation();

    await new Promise((resolve, _reject) => setTimeout(resolve, 500));
    const { form } = this.props;
    form.validateFields((err: any, value: any) => {
      if (!err) {
        const key = Object.keys(value)[0];
        const answer = value[key];
        this.selectedAnswers[key] = answer;
        // this.setState({
        //   selectedAnswers: {
        //     ...this.state.selectedAnswers,
        //     [key]: answer,
        //   },
        // });
      }
    });

    if (
      this.props.quizTypeName !== "riasec_quiz" &&
      this.props.quizTypeName !== "dimensions_quiz"
    ) {
      let loader = document.getElementById("quiz-page-loading-gif") as any;
      let optionsWrapper = document.querySelector(
        ".react-quizzes-quizz .ant-radio-group"
      ) as any;
      loader.style.display = "block";
      optionsWrapper.style.display = "none";
    }
    this.handleNext();
  }

  static defaultProps = {
    submitButton: true,
  };

  handleLoadingScreen() {
    let loader = document.getElementById("quiz-page-loading-gif") as any;
    let optionWrapper = document.querySelector(
      ".react-quizzes-quizz .ant-radio-group"
    ) as any;
    let options = document.querySelectorAll(
      ".react-quizzes-quizz .ant-radio-group label"
    ) as any;

    if (this.state.showGIFLoader) {
      if (this.state.renderPopUp && optionWrapper) {
        optionWrapper.style.display = optionWrapper ? "grid" : null;
        loader.style.display = "none";
      }
      let counter = 0;
      for (let option of options as any) {
        if (option.querySelector(".radioImage img")) {
          option
            .querySelector(".radioImage img")
            .addEventListener("load", (e) => {
              counter += 1;
              if (
                counter >=
                optionWrapper.querySelectorAll(".radioImage img").length
              ) {
                loader.style.display = "none";
                optionWrapper.style.display = "grid";
              }
            });
        } else {
          this.setState({ showGIFLoader: false });
        }
      }
    } else {
      loader.style.display = "none";
      if (optionWrapper) {
        optionWrapper.style.display = "grid";
        optionWrapper.style.gridTemplateColumns = "1fr 1fr";
      }
    }

    if (this.state.submitNow) {
      this.handleQuizSubmit(new MouseEvent("click"));
    }
  }

  questionToRender(contextValue: IQuizzContext) {
    const { data, form } = this.props;
    this.totalQuestions = data.length;
    let questions;
    let newData = data.slice(this.state.questionNo - 1, this.state.questionNo);
    let currentQuestionAnswer: string;
    if (data.length != 0) {
      const currentQuestionId = data[this.state.questionNo - 1].id;
      if (currentQuestionId in this.selectedAnswers) {
        currentQuestionAnswer = this.selectedAnswers[currentQuestionId];
      }
    }

    if (newData[0]?.element === "ImageRadioButtons") {
      this.setState({ showGIFLoader: true });
    }

    questions = newData.map((item: any, index) => {
      const current_key = item.element;
      const found_toolbox_input = contextValue["toolBox"].find(
        (toolBoxInput: any) => current_key === toolBoxInput.key
      ) as any;
      const Component = found_toolbox_input
        ? found_toolbox_input.Component
        : Empty;
      item.correctAnswer = this.props.quizMeta
        ? this.props.quizMeta["correct_answers"][item.id]
        : null;
      item.defaultValue = item.correctAnswer
        ? item.correctAnswer.answer
        : currentQuestionAnswer;
      return (
        <div className={"question-options-div radio-quiz"} key={index}>
          <Component
            // render the toolbox component
            key={item.id}
            form={form}
            quizTemplateName={this.props.quizTemplateName}
            quizCorrectAnswer={item.correct_answer}
            sampleQuestion={this.props.sampleQuestion}
            toolboxData={found_toolbox_input}
            inputData={item}
            language={contextValue["language"]}
            onClick={
              this.props.sampleQuestion ? null : this.onAnswerSelect.bind(this)
            }
          />
          {item.correctAnswer && item.correctAnswer.explaination ? (
            <div
              style={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontStyle: "normal",
                fontSize: "16px",
                lineHeight: "20px",
                color: "#4f4f4f",
                textAlign: "left",
                paddingTop: "32px",
              }}
            >
              Explaination:
              <br />
              <br />
              {item.correctAnswer.explaination}
            </div>
          ) : null}
        </div>
      );
    });
    return questions;
  }

  render() {
    if (this.props.timeUp) {
      this.setState({ renderPopUp: true });
    }
    const { form, data, submitButton, ...rest } = this.props,
      contextValue = getDefaultContext(rest as IQuizzContext);

    const lottieOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <>
        {this.state.renderPopUp ? (
          <UniversalQuizPopUp
            lottieOptions={lottieOptions}
            handleSubmit={() => {
              let params = new URLSearchParams(window.location.search);
              if (params.get("redirectParent") === "true") {
                window.parent.location.replace(
                  `${LOCALHOST}quiz/report/${this.state.submitData["data"]["data"]}${window.location.search}`
                );
              } else {
                window.location.replace(
                  `${LOCALHOST}quiz/report/${this.state.submitData["data"]["data"]}${window.location.search}`
                );
              }
            }}
          />
        ) : null}
        <div className="react-quizzes-quizz">
          <div id="quiz-page-loading-gif">
            {this.state.showGIFLoader ? (
              <img
                src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif"
                alt="loader_gif"
              />
            ) : null}
          </div>

          {this.props.quizTemplateName === "aptitude-quiz" &&
            !this.props.sampleQuestion ? (
            <>
              <div
                style={{
                  width: "100px",
                  position: "absolute",
                  top: "0",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
                  borderRadius: "50%",
                }}
              >
                <CircularProgressbar
                  background={true}
                  value={parseInt(this.getPercentage())}
                  text={`${parseInt(this.getPercentage())}`}
                  backgroundPadding={6}
                  styles={{
                    path: {
                      color: `#ffffff`,
                      strokeLinecap: "round",
                      stroke: "#1273B7",
                    },
                    text: {
                      fill: "#333333",
                      fontSize: "24px",
                      fontWeight: 500,
                      fontFamily: "Poppins",
                    },
                    trail: {
                      stroke: "#ffffff",
                    },
                    background: {
                      fill: "#ffffff",
                    },
                  }}
                />
              </div>
            </>
          ) : this.props.quizTypeName === "universal_quiz" ||
            this.props.quizTypeName === "employability_quiz" ||
            this.props.quizTypeName === "riasec_quiz" ||
            this.props.quizTypeName === "dimensions_quiz" ? (
            <>
              <div id="universal-quiz-type-progress">
                <CustomProgressBar
                  handleNext={this.handleNext}
                  handlePrev={this.handlePrev}
                  showPrev={
                    this.state.questionNo !== 1 || this.state.renderPopUp
                  }
                  showNext={this.state.questionNo < this.state.finalQuestionNo}
                  percentage={this.getPercentage()}
                />
              </div>
            </>
          ) : this.props.quizTypeName == "random_quiz" ? (
            <div className="random-quiz-type-progress">
              <CustomProgressBar
                handleNext={this.handleNext}
                handlePrev={this.handlePrev}
                showPrev={this.state.questionNo !== 1 || this.state.renderPopUp}
                showNext={this.state.questionNo < this.state.finalQuestionNo}
                percentage={this.getPercentage()}
              />
            </div>
          ) : null}
          <QuizzContext.Provider value={contextValue}>
            <Form
              style={
                this.props.quizTemplateName == "aptitude-quiz"
                  ? {
                    paddingRight: "80px",
                    paddingLeft: "80px",
                    paddingBottom: "32px",
                  }
                  : {}
              }
              className={
                this.props.quizTemplateName == "aptitude-quiz"
                  ? "aptitude-quiz react-quizzes-quizz"
                  : "react-quizzes-quizz"
              }
            >
              <div
                className="question-counter"
                style={this.props.questionCounterStyles}
              >
                <div>
                  Question {this.state.questionNo}
                  <span style={{ fontSize: "10px" }}>
                    /{this.totalQuestions}
                  </span>
                </div>
                <div className="dashed-line"></div>
              </div>
              {this.questionToRender(contextValue)}
            </Form>
            {this.props.sampleQuestion ? (
              <>
                <Button
                  className="universal-quiz-sample-button"
                  block={true}
                  style={{
                    background: "#1273B7",
                    padding: "0px",
                    height: "40px",
                    color: "white",
                  }}
                  onClick={this.handleNext}
                >
                  {this.state.questionNo == this.totalQuestions
                    ? "Start Test"
                    : "Next"}
                </Button>
              </>
            ) : null}
            {this.props.showNextPrevButtons ? (
              <div
                className="footer-next-prev-btn-wrapper"
                style={{
                  display: "flex",
                }}
              >
                {this.state.questionNo > 1 ? (
                  <Button
                    className="universal-quiz-next-button"
                    block={true}
                    style={{
                      background: "#1273B7",
                      padding: "0px",
                      height: "40px",
                      color: "white",
                    }}
                    onClick={this.handlePrev}
                  >
                    Prev
                  </Button>
                ) : null}
                <Button
                  className="universal-quiz-next-button"
                  block={true}
                  style={{
                    background: "#1273B7",
                    padding: "0px",
                    height: "40px",
                    color: "white",
                  }}
                  onClick={this.handleNext}
                >
                  {this.state.questionNo == this.totalQuestions
                    ? "Finish"
                    : "Next"}
                </Button>
              </div>
            ) : null}
          </QuizzContext.Provider>
        </div>
      </>
    );
  }

  componentDidUpdate() {
    this.handleLoadingScreen();
    if ((this.state.renderPopUp || this.props.timeUp) && !this.quizEndTime) {
      this.quizEndTime = new Date();
    }
  }

  componentDidMount() {
    this.handleLoadingScreen();
    this.quizStartTime = new Date();
  }
}

export default Form.create<any>()(Quizz);
