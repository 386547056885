import React, {useEffect, useState} from "react";
import {IoIosArrowUp, IoIosArrowDown} from "react-icons/io";
import {data} from "../../../data";
import styles from "../../../styles/redutechFaq.module.css";

export const faqs = data.faqsSection2;

const AccordionItem = ({faq, showIndex, setIndex, index}: any) => {
    return (
        <section
            id="faq"
            className={showIndex == index ? styles.faqCardOpen : styles.faqCard}
        >
            <div className={styles.question} onClick={() => setIndex(index)}>
                <p>
                    <span>Q{index + 1}. </span>
                </p>
                <h5>{faq.question}</h5>
                {showIndex !== index ? (
                    <IoIosArrowDown
                        className={styles.iconDown}
                        onClick={() => setIndex(index)}
                    />
                ) : (
                    <IoIosArrowUp
                        className={styles.iconUp}
                        onClick={() => setIndex(index)}
                    />
                )}
            </div>
            {showIndex == index && (
                <div className={styles.answer}>
                    <hr/>
                    <p>{faq.answer}</p>
                </div>
            )}
        </section>
    );
};
export const RedutechFAQ = () => {
    const [currentIndex, setCurrentIndex] = useState<any>([]);
    const handleAddIndex = (index: number) => {
        setCurrentIndex(index);
    };
    return (
        <div className="container-md">
            <div id="faq" className={styles.newFaqContainer}>
                <div className={styles.faqContainer}>
                    <p style={{color:"#8B292A"}}>Frequently asked questions</p>
                    <hr/>
                    <div className={styles.faqCardContainer}>
                        {faqs?.map((faq, index) => (
                            <AccordionItem
                                faq={faq}
                                key={index}
                                index={index}
                                showIndex={currentIndex}
                                setIndex={handleAddIndex}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
