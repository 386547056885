import { report } from 'process';
import Lottie from 'react-lottie';
import React, { useEffect, useState } from 'react';
import {
	API_ENDPOINT,
	PROCESSOR_S3_URL,
	PROCESSOR_URL,
	S3_URL,
	STRIDE_ID_AUTH,
} from '../../utils/constants';
import LeadSquaredForm from '../components/forms/LeadSquaredForm';
import animationData from '../../assets/Down-arrows.json';
import UniversalQuizReport from './report_pages/UniversalQuizDefaultReport';
import RandomQuizReport from './report_pages/RandomQuizReport';
import UniversalQuizReportHandler from './report_pages/UniversalQuizReportHandler';
import IgniterQuizReport from './report_pages/IgniterQuizReport';

export default function ReportPage(props: any) {
	const [reportData, setReportData] = useState<any>({});

	const fetchReportData = () => {
		const pathname: string = window.location.pathname;
		const id = pathname.split('/')[pathname.split('/').length - 1];
		fetch(`${API_ENDPOINT}quiz/report/${id}`, { credentials: 'include' })
			.then((res) => res.json())
			.then((data) => {
				setReportData({
					status: data.status,
					quiz_name: data.data.report_data?.content?.quiz_metadata?.quiz_name,
					quiz_type_name:
						data.data.report_data?.content?.quiz_metadata?.quiz_type_name,
					score: data.data?.report_data?.content?.score,
					content: data.data.report_data?.content?.report_metadata,
					template_name:
						data.data.report_data?.content?.quiz_metadata
							?.report_page_template_name,
					time_taken: data.data.report_data?.time_taken,
					total_question_count:
						data.data.report_data?.content?.quiz_metadata?.total_question_count,
					show_correct_answer:
						data.data.report_data?.content?.quiz_metadata?.show_correct_answer,
					user: {
						name: data.data.report_data?.name || 'No Name',
						email: data.data.report_data?.email || 'No Email',
					},
				});
			})
			.catch((err) => {
				console.log(err);
				console.log('Failed to get report data. Please try again.');
			});
	};

	useEffect(() => {
		fetchReportData();
	}, []);

	const quizTypeName = reportData.quiz_type_name;

	return quizTypeName === 'universal_quiz' ? (
		<UniversalQuizReportHandler reportData={reportData} />
	) : quizTypeName === 'employability_quiz' ? (
		<IgniterQuizReport reportData={reportData} />
	) : (
		<RandomQuizReport reportData={reportData} />
	);
}
