// @ts-nocheck
import { Switch, TextField } from '@material-ui/core'
import { template } from 'lodash'
import { Input } from 'antd';
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { PROCESSOR_URL } from '../../../utils/constants'
import TimeField from 'react-simple-timefield';


function QuizDetailsForm(props: any) {
    const [quizTypes, setQuizTypes] = useState([])
    const [quizPageTemplates, setQuizPageTemplates] = useState([])
    const [reportPageTemplates, setReportPageTemplates] = useState([])
    const [quizTypeSelected, setQuizTypeSelected] = useState(false)
    const getQuizTypes = ()=>{
        fetch(`${PROCESSOR_URL}quiz/types`)
        .then(res=>res.json())
        .then(data=>{
            setQuizTypes(data['results'])
        })
        .catch(err=>{
            console.log(err)
            alert("Failed to fetch quiz types. Please try again.")
        })
    }

    const getQuizPageTemplates = ()=>{
        fetch(`${PROCESSOR_URL}quiz/templates`)
        .then(res=>res.json())
        .then(data=>{
            setQuizPageTemplates(data['results'])
        })
        .catch(err=>{
            console.log(err)
            alert("Failed to fetch quiz page templates. Please try again.")
        })
    }

    const getReportPageTemplates = ()=>{
        fetch(`${PROCESSOR_URL}quiz/report/templates`)
        .then(res=>res.json())
        .then(data=>{
            setReportPageTemplates(data['results'])
        })
        .catch(err=>{
            console.log(err)
            alert("Failed to fetch report page templates. Please try again.")
        })
    }
    
    useEffect(()=>{
        getQuizTypes();
        getQuizPageTemplates();
        getReportPageTemplates();
    }, [])


    let quizTypeOptions = quizTypes ? quizTypes.map(quiz => {
        return { value: quiz['id'], label: quiz['name'] };
    }) : []

    let quizPageTemplateOptions = quizPageTemplates ? quizPageTemplates.filter(tempalte=>{
        return tempalte['quiz_type'] == props.quizType
    }).map(template => {
        return { value: template['id'], label: template['name'] };
    }) : []

    let reportPageTemplateOptions = reportPageTemplates ? reportPageTemplates.filter(template=>{
        return template['quiz_type'] == props.quizType
    }).map(template => {
        return { value: template['id'], label: template['name'] };
    }) : []

    let collectLeadOptions = [{value: true, label: "Yes"}, {value: false, label: "No"}]
    return (
        <>
            <h2>Enter a quiz name </h2> 
            <Input
                placeholder="Quiz Name"
                value={props.quizName}
                allowClear
                onChange={(e)=>{
                    props.onChange('name', e.target.value)
                }}
             />

            <h2>Enter a quiz label </h2> 
            <Input
                placeholder="Quiz Label"
                allowClear
                value={props.quizLabel}
                onChange={(e)=>{
                    props.onChange('label', e.target.value)
                }}
             />

            <h2>Select the quiz type</h2>
            <Select
                {...props}
                value = {
                    quizTypeOptions.filter(option => 
                    option.value === props.quizType)
                }
                id="quizType"
                onChange = {(data: any)=>{
                    if(quizTypeSelected){
                        if(window.confirm(`Changing the quiz type will reset the questions list, are you sure you want to continue?`)){
                            props.onChange('quizType', data.value)
                            props.onChange('quizTypeName', data.label)
                            props.onChange('resetQuizQuestions', [])
                        }
                    }
                    else{
                        props.onChange('quizType', data.value)
                        props.onChange('quizTypeName', data.label)
                        props.onChange('resetQuizQuestions', [])
                        setQuizTypeSelected(true)
                    }
                    getQuizPageTemplates();
                    getReportPageTemplates();
                }}
                options={quizTypeOptions}
                >
            </Select>

            <h2>Select the quiz page template</h2>
            <Select
                {...props}
                value = {
                    quizPageTemplateOptions.filter(option => 
                    option.value === props.quizTemplate)
                }
                id="quizTemplate"
                onChange = {(data: any)=>{
                    props.onChange('quizTemplate', data.value)
                    props.onChange('quizTemplateName', data.label)
                }}
                options={quizPageTemplateOptions}
                >
            </Select>

            <h2>Select the report page template</h2>
            <Select
                {...props}
                value = {
                    reportPageTemplateOptions.filter(option => 
                    option.value === props.reportTemplate)
                }
                id="reportTemplate"
                onChange = {(data: any)=>{
                    props.onChange('reportTemplate', data.value)
                }}
                options={reportPageTemplateOptions}
                >
            </Select>

            <h2>Enter quiz description</h2>
            <TextField
                value = {props.quizDescription ? props.quizDescription : null}
                id="description"
                onChange = {(e: any)=>{
                    props.onChange('description', e.target.value)
                }}
                label="Description" 
                variant="outlined"
                multiline
                fullWidth
                style={{fontSize: "14px"}}
                >
            </TextField>

            <h2>Enter pre quiz description</h2>
            <TextField
                value = {props.preQuizDescription ? props.preQuizDescription : null}
                id="pre_quiz_description"
                onChange = {(e: any)=>{
                    props.onChange('pre_quiz_description', e.target.value)
                }}
                label="Pre Quiz Description" 
                variant="outlined"
                multiline
                fullWidth
                style={{fontSize: "14px"}}
                >
            </TextField>
            
            <h2>Collect lead?</h2> 
            <Select
                {...props}
                value = {
                collectLeadOptions.filter(option => 
                    option.value === props.collectLead)
                }
                options={collectLeadOptions}
                id="collectLead"
                onChange = {(data: any)=>{
                    props.onChange('collectLead', data.value)
                }}
            >
            </Select>

            <h2>Is this a timed test?</h2>
            <Switch
              color='primary'
              value={props.timedQuiz}
              checked={props.timedQuiz}
              onChange={(e)=>{
                  props.onChange('isTimed', e.target.checked)
              }}
            />

            {
                props.timedQuiz ?
                <>
                    <h2>Select a quiz duartion</h2>
                    <TimeField
                        value={props.quizDuration ? props.quizDuration : "00:00:30"}
                        onChange={(event, value) => {
                            props.onChange('quizDuration', value)
                        }}
                        colon=":"
                        showSeconds
                        style={{
                            width: "100px",
                            textAlign: "center"
                        }}
                    />
                </>
                :
                null
            }
        </>
    )
}

export default QuizDetailsForm
