import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useAdminList from '../../hooks/useAdminList';
import useCards from '../../hooks/useCards';
import { API_ENDPOINT } from '../../utils/constants';
import { capitalize } from '../../utils/helperFunctions';
import { APIError, FetchResponse } from '../../utils/types';
import Flex from '../components/Flex';
import Header from '../components/Header';
import Loading from '../components/Loading';
import SearchBox from '../components/SearchBox';
import {
  Pagination,
  Table,
  TableContainer,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from '../components/Table';
import Toast from '../components/Toast';
import ToggleSwitch from '../components/ToggleSwitch';

dayjs.extend(relativeTime);

const AdminsList = () => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);

  const queryClient = useQueryClient();

  const { data: cards } = useCards();

  const { isLoading, isSuccess, data } = useAdminList(page, search);

  const disableAdmin = async (
    adminId: number,
    status: number
  ): Promise<APIError[] | void> => {
    try {
      const postData = {
        action: status === 1 ? 0 : 1,
      };
      await axios.put<FetchResponse<{ message: string }>>(
        API_ENDPOINT + 'admin/' + adminId,
        postData
      );

      queryClient.invalidateQueries(['adminList']);
      queryClient.invalidateQueries(['cards']);
      queryClient.invalidateQueries(['profile', 'current']);
      setToastText(
        `The Admin has been ${status === 1 ? 'disabled' : 'enabled'}.`
      );
      setSuccess(true);
      setShowToast(true);
    } catch (error) {
      const errors = error.response.data.error as APIError[];
      setToastText(error.response.data.error[0]);
      setSuccess(false);
      setShowToast(true);
      return errors;
    }
  };

  const renderUserList = () => {
    if (isSuccess) {
      return data?.users?.map((user, index) => (
        <TR key={`${user.id}-${index}`}>
          <TD maxWidth='20%' centered>
            <StyledLink to={`/admin/${user.id}/test`}>{user.name}</StyledLink>
          </TD>
          <TD maxWidth='20%' centered>
            {user.role === 'superadmin' ? 'Super Admin' : capitalize(user.role)}
          </TD>
          <TD maxWidth='20%' centered>
            {user.email}
          </TD>
          <TD maxWidth='15%' centered>
            {user.test_count}
          </TD>
          <TD maxWidth='15%' centered>
            {user.report_count}
          </TD>
          <TD maxWidth='10%' centered>
            <ToggleSwitch
              checked={user.status === 1}
              onClick={() => disableAdmin(user.id, user.status)}
            />
          </TD>
        </TR>
      ));
    }
  };

  return (
    <Container column>
      {isLoading && <Loading />}
      <Header
        align='center'
        justify='space-between'
        rowReverse
        style={{ background: '#ffffff', borderRadius: '4px' }}
      >
        <SearchBox
          placeholder='Search Admins by Name, Email'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <AdminCount>
          <h2>Admins: </h2>
          <div>{cards?.admin_count}</div>
          <h2>Super Admins:</h2>
          <div> {cards?.superadmin_count}</div>
        </AdminCount>
      </Header>
      <TableContainer style={{ margin: '0rem' }}>
        <Table style={{ flexGrow: 1, backgroundColor: '#fff' }}>
          <THead>
            <TR>
              <TH centered>Name</TH>
              <TH centered>Role</TH>
              <TH centered>Email</TH>
              <TH centered>Total Tests</TH>
              <TH centered>Total Reports</TH>
              <TH centered>Active</TH>
            </TR>
          </THead>
          <TBody>{renderUserList()}</TBody>
        </Table>
      </TableContainer>
      {!isLoading && data?.pagination_data && (
        <Pagination
          currentPage={page}
          prevPage={data?.pagination_data.page_prev}
          nextPage={data?.pagination_data.page_next}
          nextPageChangeHandler={
            data?.pagination_data.page_next
              ? function () {
                  setPage((currentPage) => currentPage + 1);
                }
              : undefined
          }
          prevPageChangeHandler={
            data?.pagination_data.page_prev
              ? function () {
                  setPage((currentPage) => currentPage - 1);
                }
              : undefined
          }
        />
      )}
      {showToast && (
        <Toast onClose={(toast) => setShowToast(toast)} success={success}>
          {toastText}
        </Toast>
      )}
    </Container>
  );
};

export default AdminsList;

const Container = styled(Flex)`
  width: 95%;
  margin: auto;
  height: 100%;
`;

const StyledLink = styled(Link)`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.blue[500]};
  &:visited {
    color: ${(props) => props.theme.colors.blue[500]};
  }
`;

const AdminCount = styled(Flex)`
  font-size: 16px;
  line-height: 19px;
  h2 {
    font-size: 16px;
    font-weight: 600;
    margin-left: 2rem;
  }
  div {
    font-weight: normal;
    margin: 0 4rem 0 1rem;
  }
`;
