import React, { FC } from 'react';
import styled from 'styled-components';
import { TraitResponse, TraitMap, TraitPercentage } from '../../../utils/types';
import Flex from '../../components/Flex';
import Trait from '../../components/Trait';

const Traits: FC<{ traitMap: TraitMap }> = ({ traitMap }) => {
  const getTraits = (trait?: TraitResponse): TraitPercentage[] | undefined => {
    return (
      trait &&
      Object.keys(trait)
        .filter((key) => key.endsWith('%'))
        .map((key) => {
          const s = key.split(' ');
          return {
            name: s[0],
            percentage: trait[key],
          };
        })
    );
  };
  return (
    <Container column>
      {Object.keys(traitMap).map((traitMapKey, index) => {
        const trait = traitMap[traitMapKey];
        return (
          <TraitsContainer key={`${traitMapKey}-${index}`}>
            <Trait
              name={traitMapKey.split('-')[0]}
              description={trait.description}
              traits={getTraits(trait)}
              index={index}
            />
          </TraitsContainer>
        );
      })}
    </Container>
  );
};

export default Traits;

const Container = styled(Flex)`
  flex: 1;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  padding: 3.2rem 5.3rem;
  border-radius: 10px;
  margin: 2rem 0;
  @media screen and (max-width: 1024px) {
    margin: 1rem 0;
    padding: 0;
  }
`;

const TraitsContainer = styled(Flex)`
  width: 95%;
  padding: 3rem;
  margin: 1rem auto;
  border-radius: 15rem;
  box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.04);
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0;
    box-shadow: none;
    padding: 1.2rem;
  }
`;
