// @ts-nocheck
import React, { useReducer, memo, useEffect } from "react";
import { reducer, initialState } from "./reducer/reducer";
import Row from "antd/es/row/index";
import Col from "antd/es/col/index";
import ToolBox from "./ToolBoxContainer";
import FormPreview from "./FormPreview/FormPreview";
import QuizzContext, { getDefaultContext } from "../QuizzContext";
import { usePrevious } from "../customHooks";
import isEqual from "lodash.isequal";
import "../assets/FormBuilder.css";

export default memo(function(props: any) {
  const {updateCorrectAnswer, quizTypeName, templateTypeName, onChange, initialValue, ...rest } = props,
    [state, dispatch] = useReducer(reducer, initialState(initialValue)),
    contextValue = {
      ...getDefaultContext(rest),
      state,
      dispatch,
      updateCorrectAnswer,
      quizTypeName,
      templateTypeName,
    },
    formData = state.get("data");
    const random_quiz_controls = ['ImageRadioButtons']
    const universal_quiz_controls = ['ImageRadioButtons', 'RadioButtons', 'LabelText']
    const employability_quiz_controls = ['ImageRadioButtons', 'RadioButtons']
    const riasec_quiz_controls = ['SliderInput']
    const dimensions_quiz_controls = ['SliderInput', 'ImageRadioButtons', 'RadioButtons']
  
  switch(props.quizTypeName){
    case "universal_quiz":
      contextValue.toolBox = contextValue.toolBox.filter(function(value, index, arr){ 
        return universal_quiz_controls.includes(value['key']) ? true : false
      });
      break
    
    case "random_quiz":
      contextValue.toolBox = contextValue.toolBox.filter(function(value, index, arr){ 
        return random_quiz_controls.includes(value['key']) ? true : false
      });
      break
    
    case "employability_quiz":
      contextValue.toolBox = contextValue.toolBox.filter(function(value, index, arr){ 
        return employability_quiz_controls.includes(value['key']) ? true : false
      });
      break
    

    case "riasec_quiz":
      contextValue.toolBox = contextValue.toolBox.filter(function(value, index, arr){ 
        return riasec_quiz_controls.includes(value['key']) ? true : false
      });
      break

    case "dimensions_quiz":
      contextValue.toolBox = contextValue.toolBox.filter(function(value, index, arr){ 
        return dimensions_quiz_controls.includes(value['key']) ? true : false
      });
      break

    default:
      break
  }

  const previousFormData = usePrevious(formData);

  // effect to set
  useEffect(() => {
    // effect to notify parent that form data had a diff
    if (
      typeof onChange === "function" &&
      previousFormData &&
      !isEqual(previousFormData, formData)
    ) {
      onChange(formData);
    }
  }, [onChange, formData, previousFormData]);

  // creates two colons 1 with toolbox and the other with the added inputs
  return (
    <div className="react-quizzes-builder">
      <QuizzContext.Provider 
      value={contextValue}>
        <Row style={{ height: "100%" }}>
          <Col sm={6} xs={24} order={0}>
            <ToolBox
              updateCorrectAnswer={updateCorrectAnswer}
            />
          </Col>
          <Col xs={24} sm={18} order={1} style={{ height: "100%" }}>
            <FormPreview />
          </Col>
        </Row>
      </QuizzContext.Provider>
    </div>
  );
});
