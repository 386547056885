import React from "react";
import AptitudeReportPage from "./AptitudeQuizReport";
import SubjectwiseReportPage from "./SubjectwiseReport";
import UniversalQuizReportPage from "./UniversalQuizDefaultReport";

export default function UniversalQuizReportHandler(props: any) {
    const template_name: string = props.reportData.template_name
    const renderReport = () => {
        switch(template_name) { 
        case "Aptitude Quiz":
            return <AptitudeReportPage {...props}/>;
        case "askiitians_subjectwise_default":
            return <SubjectwiseReportPage {...props}/>;
        default:
            return <UniversalQuizReportPage {...props}/>;
        }
    };

    return (
        renderReport()
    );
}