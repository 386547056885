import React, { useState, useEffect } from "react";
import { PROFILE_NAVIGATION_CTA, STRIDE_PROFILE_LINK } from "../../../utils/constants";
import Lottie from "react-lottie";

export default function UniversalQuizPopUp(props) { 
    const [isAppFlow, setIsAppFlow] = useState(false);

    useEffect(() => {
        // get params from query string
        const urlParams = new URLSearchParams(window.location.search);
        const flow = urlParams.get('flow');
        if (flow === 'app') {
            setIsAppFlow(true);
        }
    }, []);

    return (
        <div id="thank-you-modal-wrapper">
        <div id="thank-you-modal" className="modal fade show universal-quiz-popup">
        <div className="modal-dialog">
            <div className="modal-content universal-quiz-popup">
                <div className="modal-body">
                    <div className="success-animation">
                    <Lottie options={props.lottieOptions}
                    />
                    </div>
                    <div className="heading">
                        <div>Quiz completed successfully</div>
                        <div>Just one more step to get your results</div>
                    </div>
                    <div className="progress-wrapper">
                        <div>
                            <span>99%</span>
                        </div>
                    </div>
                    <div className="success-btn-wrapper" style={{paddingTop: "8px"}}>
                        <button id="submitBtn"
                        type="button" className="btn btn-default done_btn" data-dismiss="modal" onClick={props.handleSubmit}>VIEW REPORT</button>
                        {isAppFlow &&
                        <button
                        type="button" className="btn btn-default profile_navigation_btn" data-dismiss="modal" onClick={() => {window.location.replace(`${STRIDE_PROFILE_LINK}`)}}>{PROFILE_NAVIGATION_CTA}</button>}
                    </div>
                </div>
            </div>
        </div>
    </div>
      </div>
    )
}