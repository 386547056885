// @ts-nocheck
import React, { forwardRef, useRef } from "react";
import Tabs from "antd/es/tabs/index";
import Row from "antd/es/row/index";
import Col from "antd/es/col/index";
import ToggleButton from 'react-toggle-button'
import Input from "antd/es/input/Input";
import { Upload, message, Button } from 'antd';
import cloneDeep from "lodash.clonedeep";
import TextWithInfo from "../../../../../../../../ReusableComponents/TextWithInfo";
import AddInputOption from "./AddInputOption";
import { PROCESSOR_S3_URL, PROCESSOR_URL } from "../../../../../../../../utils/constants";
import { transform } from "lodash";
import SampleQuestionExplainationInput from "../../../../../../../../ui/components/SampleQuestionExplainationInput";
import OptionScoreInput from "../../../../../../../../ui/components/OptionScoreInput";
import { Select } from 'antd';

const { Option } = Select;

const { TabPane } = Tabs;

export default forwardRef((props: any, ref) => {
  const inputId = props.inputData.id;
  const sampleQuestion = props.inputData.sampleQuestion
  const deleteButtonRef = useRef<Array<HTMLDivElement>>([]);
  const {
    quizTypeName,
    value,
    onChange,
    currentLanguage,
    setLanguage,
    languagesList
  } = props;

  const correctAnswer = quizTypeName === "universal_quiz" ? props.quizCorrectAnswer[inputId]?.answer : null
  // handles on change ofr text and value
  function onChangeInput(index: number, content: string, language?: string, img=false, score=false) {
    const valueClone = cloneDeep(value);
    if(score){
      valueClone[index]['score'] = content
      onChange(valueClone);
      return
    }
    if (language) {
      // language exists means its text editing
      if(img===true){
        valueClone[index]["image"] = content;
      }else{
        valueClone[index]["text"][language] = content;
      }
      
    } else {
      // value editing
      valueClone[index]["value"] = content;
    }

    onChange(valueClone);
  }

  function updateCorrectOption(key, value){
    props.updateCorrectAnswer(key, value)
  }
  // handles delete option
  function onDelete(index: number) {
    if(value[index].value == correctAnswer){
      alert("Cannot delete the correct answer");
      return false
    }
    const valueClone = cloneDeep(value);
    // removes provided index
    valueClone.splice(index, 1);

    onChange(valueClone);
  }

  // on add new option
  function onAddOption(newOption: object) {
    const valueClone = cloneDeep(value);
    valueClone.push(newOption);
    onChange(valueClone);
  }

  const uploadProps = {
    accept: 'image/*',
    multiple: false,
    name: 'file',
    action: `${PROCESSOR_URL}quiz/upload`,
  };
  return (
    <Row>
      <Row className="add-options-button-wrapper">
        <AddInputOption
          existingOptions={value}
          languagesList={languagesList}
          onAdd={onAddOption}
        />
      </Row>

      {value.map((option: any, i: number) => {
        return (
          <Row className="quiz-options-row">
            <div>
              <div
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "20px",
                  color: "#333333",
                  float: "left"
                }}
              >
                Option {i+1}
              </div>
              {i > 0 ? 
                <div
                  onClick={() =>
                    onDelete(i)
                  }
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "16px",
                    color: "#e92e30",
                    cursor: "pointer",
                    float: "right"
                  }}
                >
                  X Delete 
                </div> :
                null
              }
            </div>
            <div className="options-hl"></div>
            <div className="quiz-options-fields">
              <div className="text-input">
                <div className="option-input-header">
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "18px",
                      lineHeight: "18px",
                      color: "#333333"
                  }}
                  >
                    Text
                  </div>
                  {i === 0 ?
                    <div>
                      <TextWithInfo 
                        title={
                          <>
                            <p>Each option can be translated</p>
                            <p>Current Language:&nbsp;{currentLanguage}</p>
                          </>
                        }
                      >
                      </TextWithInfo>
                    </div> :
                    null  
                  }
                </div>
                <Tabs
                  onChange={setLanguage}
                  activeKey={currentLanguage}
                  renderTabBar={() => <div />}
                >
                  {languagesList.map((language: string) => {
                    return (
                      <TabPane tab={language} key={language}>
                        <Input
                          style={{
                            background: "#E7F1F7",
                            borderRadius: "4px"
                          }}
                          key={i}
                          value={option.text[language]}
                          onChange={e => onChangeInput(i, e.target.value, language)}
                        />
                      </TabPane>
                    )
                  })} 
                </Tabs>
              </div>
  
              <div className="options-vl"></div>
  
              <div className="value-input">
                <div className="option-input-header">
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "18px",
                      lineHeight: "18px",
                      color: "#333333"
                    }}
                  >
                    Value
                  </div>
  
                  {i === 0 ?
                    <div>
                      <TextWithInfo
                        title="Value is hidden from user, and its the same betwen languages">
                      </TextWithInfo>
                    </div> :
                    null
                  }
                </div>
                <Input
                  style={{
                    background: "#E7F1F7",
                    borderRadius: "4px"
                  }}
                  value={option.value}
                  onChange={e => onChangeInput(i, e.target.value)}
                />
              </div>
              
              <div className="options-vl"></div>
  
              {props.type === "ImageRadioButtons" ?
                <>
                  <div className="image-input">
                    <div
                      style={{
                        marginBottom: "19px"
                      }}
                      className="option-input-header"
                    >
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "18px",
                          lineHeight: "18px",
                          color: "#333333"
                        }}>Image Upload
                      </div>
  
                      {i === 0 ?
                        <div>
                          <TextWithInfo
                            title="Image upload">
                          </TextWithInfo>
                        </div> :
                        null  
                      }
                    </div>
  
                    <div
                      className="image-upload-button-wrapper"
                      style={{
                        position: "relative",
                        background: "#f2f2f2",
                        borderRadius: "2px",
                        height: "60px",
                        width: "130px"
                      }}
                    >
                      {!value[i]['image'] || value[i]['image'] == "" ? 
                        <Upload
                          {...uploadProps}
                          onRemove={() => {
                            const valueClone = cloneDeep(value);
                            // removes provided index
                            valueClone[i]["image"] = undefined;
                            onChange(valueClone);
                          }}
                          onChange={(info: any) => {
                            if (info.file.status === "done") {
                              onChangeInput(
                                i,
                                info["file"]["response"]["data"]["key"],
                                "en",
                                true
                              );
                              message.success(`${info.file.name} file uploaded successfully`);
                            } else if (info.file.status === "error") {
                              message.error(`${info.file.name} file upload failed.`);
                            }
                          }}
                          key={"image-" + i}
                        >
                          <div
                            style={{
                              position: "absolute",
                              left: "50%",
                              top: "50%",
                              transform: "translate(-50%, -50%)",
                              height: "95%",
                              width: "95%",
                              border: "1px dashed #bdbdbd",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "14px",
                              textTransform: "uppercase",
                              color: "#bdbdbd",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer"
                            }}
                            className="image-upload-button"
                            >  
                              JPEG/PNG
                          </div>
                        </Upload> :
                        <div
                          style={{
                            height: "100%",
                            width: "100%"
                          }}
                          onMouseEnter={(e)=>{
                            // @ts-ignore: Object is possibly 'null'.
                            deleteButtonRef.current[i].style.display = "flex"
  
                          }}
                          onMouseLeave={(e)=>{
                            // @ts-ignore: Object is possibly 'null'.
                            deleteButtonRef.current[i].style.display = "none"
                          }}
                          className="image-upload-preview">
                          <img
                            style={{
                              height: "100%",
                              width: "100%"
                            }}
                            src={PROCESSOR_S3_URL + value[i]['image']}
                          />
                          <div
                            ref={(el)=>{
                                deleteButtonRef.current[i] = el as HTMLInputElement
                              }
                            }
                            className="image-remove-button"
                            onClick={(e)=>{
                              const valueClone = cloneDeep(value);
                              // removes provided index
                              valueClone[i]['image'] = ""
                              onChange(valueClone);
                              message.success(`image cleared successfully`);
                            }}
                            style={{
                              display: "none",
                              cursor: "pointer",
                              fontFamily: "Poppins",
                              fontWeight: "normal",
                              fontStyle: "normal",
                              fontSize: "14px",
                              lineHeight: "14px",
                              color: "#ffffff",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "100%",
                              width: "100%",
                              background: "rgba(0, 0, 0, 0.3)"
                            }}
                          >
                            X Remove
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </> :
                null
              }
              
              {props.type == "ImageRadioButtons" && quizTypeName === "universal_quiz" ? 
                <div className="options-vl">
                </div> :
                null
              }
  
              {quizTypeName === "universal_quiz" ?
                <div className="correct-answer-input">
                  <div
                    className="option-input-header"
                  >
                    <div
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "18px",
                        lineHeight: "18px",
                        color: "#333333"
                      }}>Correct Answer
                    </div>
                    {i === 0 ?
                      <div>
                        <TextWithInfo
                          title="Correct answer">
                        </TextWithInfo>
                      </div> :
                      null  
                    }
                  </div>
  
                  <div className="correct-answer-toggle">
                    <ToggleButton
                      colors={{
                        activeThumb: {
                          base: '#1273b7',
                        },
                        inactiveThumb: {
                          base: '#bdbdbd',
                        },
                        active: {
                          base: '#e7f1f7',
                          hover: '#e7f1f7',
                        },
                        inactive: {
                          base: '#f2f2f2',
                          hover: '#f2f2f2',
                        }
                      }}
                      
                      activeLabelStyle={{
                        color: "#1273b7"
                      }}
                      inactiveLabelStyle={{
                        color: "#bdbdbd"
                      }}
                      value={option.value === correctAnswer}
                      onToggle={(e)=>{
                        const value = {
                          explaination: undefined,
                          answer: option.value
                        }
                        updateCorrectOption(props.inputData.id, value)
                      }}
                    />
                  </div>
                  <div className="correct-answer-explaintaion">
                    {(option.value === correctAnswer && sampleQuestion) ? <SampleQuestionExplainationInput defaultValue={props.quizCorrectAnswer[inputId].explaination} onChange={(e)=>{
                      const value = {
                        explaination: e.target.value,
                        answer: option.value
                      }
                      updateCorrectOption(inputId, value)
                    }} /> : null}
                  </div>
                </div>
                : quizTypeName == "employability_quiz" ?
                   <OptionScoreInput
                    index={i}
                    value={value[i]['score']}
                    currentLanguage={currentLanguage}
                    onInput={onChangeInput}
                    type={props.type}
                    quizTypeName={props.quizTypeName}
                />
                :
                null
              }

            </div>
          </Row>
          )
      })}
    </Row>
  );
});
