import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT, PAGINATION_LIMIT } from '../utils/constants';
import { APIError, PaginationData, AdminUser } from '../utils/types';

const useAdminList = (page = 1, search?: string, limit = PAGINATION_LIMIT) => {
  const fetchUsers = async ({ page, limit, search }) => {
    try {
      const {
        data: { data },
      } = await axios.get(API_ENDPOINT + 'admin', {
        params: { page, limit, search },
      });
      return data;
    } catch (error) {
      throw error.response.data;
    }
  };

  return useQuery<
    { users: AdminUser[]; pagination_data: PaginationData },
    AxiosResponse<APIError>
  >(['adminList', page, search], () => fetchUsers({ page, limit, search }), {
    retry: false,
  });
};

export default useAdminList;
