import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAnalysisReport } from '../../../hooks/useAnalysisReport';
import CustomLoading from '../../components/CustomLoading';
import Flex from '../../components/Flex';
import Navbar from '../../components/Navbar';
import CareerAndPersonalities from '.././analysis/CareerAndPersonalities';
import Summary from '.././analysis/Summary';
import Traits from '.././analysis/Traits';
import UserDetails from '.././analysis/UserDetails';
import ErrorFallback from '.././ErrorFallback';


const DwecStrideDefault = ({test, testId, isFetched}) => {
    const { token } = useParams<{ token: string }>();

    const { data: reportMeta, isSuccess, isLoading } = useAnalysisReport(token);
    const customLogo = test?.logo_image;
    return (
        <Container column>
            {!isLoading ? (
                isSuccess ? (
                        reportMeta?.report_metadata ? (
                            <React.Fragment>
                                <Navbar homepage='https://strideahead.in' customLogo={customLogo} authenticate={false}/>
                                <Content column>
                                    <DetailAndSummaryContainer>
                                      <UserDetails
                                            reportMeta={reportMeta}
                                            testId={testId}
                                            authenticate={false}
                                            bookingLink={test.test_configs?.booking_link ? test.test_configs?.booking_link : null}/>
                                        <Summary reportMeta={reportMeta} />
                                    </DetailAndSummaryContainer>

                                    <Traits
                                        traitMap={reportMeta?.report_metadata['trait-map-content']}
                                    />

                                    <CareerAndPersonalities 
                                        test={test}
                                        reportMetadata={reportMeta?.report_metadata}
                                    />

                                </Content>

                            </React.Fragment>
                        ) : (
                            // null)
                            <CustomLoading message="Report Is Generating. Please wait..."/>          )
                    )
                    :
                    (
                        <ErrorFallback
                            error='500'
                            showNavbar={false}
                            url={`/test/${testId}`}
                        />
                    )
            ) : (
                // null)}
                <CustomLoading message="Report Is Generating. Please wait..."/>      )}
        </Container>
    );
};

export default DwecStrideDefault;

const Container = styled(Flex)`
  width: 100%;
  background: ${({ theme }) => theme.colors.blue[50]};
  font-family: Poppins;
  @media only screen and (max-width: 1024px) {
    background: ${({ theme }) => theme.colors.grey[50]};
    padding: 1.2rem;
  }
`;

const Content = styled(Flex)`
  max-width: 1250px;
  margin: 1.5rem auto;
  margin-top: 6rem;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const DetailAndSummaryContainer = styled(Flex)`
  width: 100%;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
