import React from 'react';
import styled from 'styled-components';
import { STRIDEAHEAD } from '../../utils/constants';
import Flex from '../components/Flex';
import FaceBook from '../../assets/footer/facebook.svg';
import Twitter from '../../assets/footer/twitter.svg';
import Discord from '../../assets/footer/discord.svg';
import Instagram from '../../assets/footer/instagram.svg';
const Footer = () => {
  const navigateTo = (path: string) => {
    window.open(`${STRIDEAHEAD}${path}`);
  };

  return (
    <FooterContainer>
      <LeftContainer flex={2}>
        <Flex>
          <span>Copyright {new Date().getFullYear() - 1}-{new Date().getFullYear()} | Stride Ahead</span>
        </Flex>
        <Flex>
          <span onClick={() => navigateTo('terms-of-service')}>
            Terms of Service
          </span>
          <span onClick={() => navigateTo('privacy-policy')}>
            Privacy Policy
          </span>
          <span onClick={() => navigateTo('#')}>Contact Us</span>
        </Flex>
      </LeftContainer>
      <Flex flex={1} justify='flex-end'>
        <a href='mailto:info@strideahed.in'>info@strideahead.in</a>
        <Social
          href='https://www.instagram.com/stride2success/'
          target='_blank'
        >
          <img src={Instagram} alt='Instagram' />
        </Social>
        <Social href='https://www.facebook.com/stride2success/' target='_blank'>
          <img src={FaceBook} alt='FaceBook' />
        </Social>
        <Social href='https://twitter.com/strideahead2' target='_blank'>
          <img src={Twitter} alt='Twitter' />
        </Social>
        <Social href='https://discord.gg/k263SzahtN' target='_blank'>
          <img src={Discord} alt='Discord' />
        </Social>
      </Flex>
    </FooterContainer>
  );
};

export default Footer;

const Social = styled.a`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  padding: 0.5rem;
  margin: 0 1rem;
  width: 2.5rem;
  height: 2.5rem;
`;

const FooterContainer = styled(Flex)`
  background: ${({ theme }) => theme.colors.footer['black']};
  padding: 1.3rem 5%;
  font-size: 1.6rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey[300]};
  span {
    margin-right: 2rem;
    cursor: pointer;
  }
  a {
    color: ${({ theme }) => theme.colors.grey[300]};
    text-decoration: none;
    margin-right: 1rem;
  }
  img{
    vertical-align: unset !important;
    border: unset !important;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    font-size: 1.3rem;
    div {
      justify-content: center;
    }
  }
`;

const LeftContainer = styled(Flex)`
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    div {
      margin: 0.8rem 0;
    }
    margin-bottom: 0.8rem;
  }
`;
