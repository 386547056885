import styled from 'styled-components';
import Flex from './Flex';

const Export = ({ label ,test_id,func}) => {

  return (
    <Container onClick={() => func(test_id)}>
        {label}
    </Container>
  );
};

export default Export;


const Container = styled(Flex)`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey[200]};
  border-radius: 4px;
  width: max-content;
  height: 4rem;
  padding: 0.7rem;
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.blue[500]};
  font-weight: 600;
  cursor: pointer;
`;