// @ts-nocheck
import axios from 'axios';
import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useLocation} from "react-router-dom";
import {BiArrowBack} from 'react-icons/bi';
import styled from 'styled-components';
import useForm from '../../hooks/useForm';
import {
    REGISTER_FORM_VALIDATION_SCHEMA,
    API_ENDPOINT,
    STRIDE_ID_AUTH,
} from '../../utils/constants';
import {APIError, FetchResponse, FormFields} from '../../utils/types';
import Button from '../components/Button';
import Flex from '../components/Flex';
import FormStatus from '../components/FormStatus';
import Input from '../components/Input';
import Separator from '../components/Separator';
import {Heading1, Text1Css} from '../components/Typography';
import StrideImg from '../../assets/stride_img.png';
import StrideLogo from '../../assets/stride_logo.svg';
import OtpInput from 'react-otp-input';
import callImg from '../../assets/InputBox/call.png';
import {useWindowDimensions} from './report_pages/utils';
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import Navbar from "../components/Navbar";


const OtpPage = () => {
    const history: any = useHistory();
    const location: any = useLocation();
    const params: any = useParams();
    const [isLoading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [getData, setGetData] = useState<any>({});
    const [googleLoginResponse, setGoogleLoginResponse] = useState<{
        status: 'success' | 'fail';
        message: string;
    }>();
    const [showForm, setShowForm] = useState(true);
    const [message, setMessage] = useState({type: "", message: ""})
    const [alertStyle, setAlertStyle] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [disable, setDisable] = useState(true);
    const [reportId, setReportId] = useState(null);
    const [otp1, setOtp1] = useState("");
    const [trueOtp, setTrueOtp] = useState("")
    const [showOtpForm, setShowOtpForm] = useState(false);
    const {width} = useWindowDimensions();

    const phone_number: number = location.state.detail;
    const responseId = location?.state?.response_id;
    const is_phone_verified = location?.state?.detail

    const OtpInputStyle = {
        position: 'relative',
        fontSize: '25px',
        textAlign: 'center',
        width: width > 600 ? '50px' : `${width / 7.8}px`,
        height: width > 600 ? '50px' : `${width / 7.8}px`,
        border: ' 1.5px solid #938CA9',
        borderRadius: '10px',
        marginLeft: 'auto',
    };

    const {formData, setFormData, handleChange, handleBlur, handleSubmit,setFormActive} =
        useForm({
            actions: {onSubmit: ""},
            validatorSchema: REGISTER_FORM_VALIDATION_SCHEMA,
            handleValidationOnBlur: true,
        });


    const getdata = () => {
        fetch(`${API_ENDPOINT}form/${params?.formId}`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => {
                setGetData(data);
            })
            .catch((err) => {
                setMessage({type: "error", message: err})
            });
    };


    const phone_no = {
        phone_number: formData?.phone_no?.value,

    };
    const get_otp = async () => {
        fetch(`${STRIDE_ID_AUTH}otp`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(phone_no),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data?.status === 200) {
                    setTrueOtp(data?.data?.OTP)
                    setMessage({type: "success", message: data?.message})
                } else if (data?.status === 412) {
                    setMessage({type: "error", message: data?.message})
                }
            })
            .catch((err) => {
                setMessage({type: "error", message: err})
            });
    };

    const re_send_otp = async () => {
        fetch(`${STRIDE_ID_AUTH}otp`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify({phone_number: formData?.phone_no?.value}),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data?.status === 200) {
                    setTrueOtp(data?.data?.OTP)
                    setMessage({type: "success", message: data?.message})
                } else if (data?.status === 412) {
                    setMessage({type: "error", message: data?.message})
                }

            })
            .catch((err) => {
                setMessage({type: "error", message: err})
            });
    };


    const verifyOTP = async (otp) => {
        const verifyOtp = {
            phone_number: formData?.phone_no?.value,
            otp: otp
        }

        const verifyOtpData = {
            phone_no: formData?.phone_no?.value,
            // is_phone_verified: true
        }

        fetch(`${STRIDE_ID_AUTH}verify-otp`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(verifyOtp),
        })
            .then((res) => res.json())
            .then(async (data) => {
                if (data.success) {
                    console.log(data?.success, " successss")
                    try {
                        await axios.patch<FetchResponse<{ message: string }>>(
                            `${STRIDE_ID_AUTH}me`,
                            verifyOtpData
                        ).then((res) => {
                            history.push({
                                pathname: `/form/${params?.formId}/${responseId}`
                            })
                        })
                    } catch (error: any) {
                        setMessage({type: "error", message: error?.response?.data?.error[0]?.form_level_error})
                    }
                } else {
                    setMessage(data?.message)
                    setMessage({type: "error", message: data?.message})
                }
            })
            .catch((err: any) => {
                setMessage(err?.error)
                setMessage({type: "error", message: err?.error})
            });
    };

    const OtphandleChange = (otp: any) => {
        setOtp1(otp)
        if (otp.length === 4) {
            verifyOTP(otp)
        }
    };

    useEffect(() => {
        if (location?.state?.detail){
            const e:any = {
                target:{
                    name:"phone_no",
                    value:location?.state?.detail
                }
            }
            try {
                 setFormActive(true);
                handleBlur(e);

            }catch (e) {
            }
            setFormData({
                ...formData,
                phone_no: {value: location?.state?.detail, error: ''},
            });
        }


    }, [])

    useEffect(() => {
        message ? setShowAlert(true) : setShowAlert(false);
    }, [message]);

    useEffect(() => {
        message?.type === "success" ? setAlertStyle(true) : setAlertStyle(false)
    }, [message])


    useEffect(() => {
        getdata();
    }, []);
    useEffect(() => {
        if (false) {
            setDisable(false);
        } else if (true) {
            setTimeout(() => {
                setDisable(false);
            }, 100);
        }
    }, [getData]);


    useEffect(() => {
        try {
            fetch(`${STRIDE_ID_AUTH}me`, {
                credentials: 'include',
            }).then((res) => {
                if (res?.status === 401) {
                    history.push({
                        pathname: `/form/${params?.formId}`,
                    })
                }
            })
        } catch (e) {
            console.log(e, "errr")
        }

    }, [])

    const isButtonDisabled = () => {
        return !(
            formData.phone_no?.value !== '' &&
        // // @ts-ignore
        //     /^[6789]\d{9}$/.test(formData.phone_no?.value)  &&
            formData.phone_no?.error === '' &&
            !isLoading
        );
    };

    const isButtonDisabled1 = () => {
        return !(
            (page == 1 && !isLoading)
        );
    };

    const prevPage = () => {
        setPage((currentPage) => currentPage - 1);
    };

    useEffect(()=>{
        console.log(formData,'---------formdat----')
    },[formData])
    return (
        <>
            <Container>
            <Navbar/>
                <Sidebar align='center' justify='center'>
                    <div className='pt-5'>
                        {/*<img src={StrideLogo}/>*/}
                    </div>
                    {
                        getData?.data?.banner_image ? (
                                <img src={StrideImg} alt='Auth Graphic'/>
                            ) :
                            (
                                <iframe
                                    width="500px" height="400px" src={getData?.data?.banner_video}
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            )
                    }
                    {width > 600 && (
                        <SubContentFooter>© copyright, 2022 Stride Ahead</SubContentFooter>
                    )}
                </Sidebar>
                <Content>
                    <Title>Register Your Account</Title>
                    <SubTitle>
                        For the purpose of industry regulations, we need your details
                    </SubTitle>
                    <Separator/>

                    {showForm && (
                        <>
                            <FormCont>
                                <StyleInput
                                    name='phone_no'
                                    type='number'
                                    placeholder='Phone Number'
                                    value={formData?.phone_no?.value}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={formData?.phone_no?.error}
                                    required
                                    autoFocus
                                    showSuccessIndicator
                                    icon={<ImageComp src={callImg}/>}
                                />
                            </FormCont>
                            <ButtonDiv>
                                <StyledButtonPrev
                                    type='submit'
                                    onClick={prevPage}
                                    disabled={page > 1 ? false : isButtonDisabled1()}
                                    isLoading={isLoading}
                                >
                                    <BiArrowBack/>
                                </StyledButtonPrev>
                                <StyledButtonNext
                                    type='submit'
                                    onClick={() => {
                                        get_otp();
                                        setShowOtpForm(true);
                                        setShowForm(false);
                                    }}
                                    disabled={isButtonDisabled()}
                                    isLoading={isLoading}
                                >
                                    Next
                                </StyledButtonNext>
                            </ButtonDiv>
                        </>
                    )}
                    {showOtpForm &&
                    <>
                        {showAlert &&
                        <div className={alertStyle ? "alert alert-success " : "alert alert-danger"} role="alert">
                            {message?.message}
                        </div>}
                        <Otpdiv>
                            <SubContent>We have just sent a verification code to</SubContent>
                            <SubContent style={{fontWeight: 600, color: '#352958'}}>
                                {
                                    formData?.phone_no?.value
                                }
                            </SubContent>
                            <div style={{width: '100%', padding: 10, overflow: 'hidden'}}>
                                <OtpInput
                                    value={otp1}
                                    onChange={(otp) => OtphandleChange(otp)}
                                    numInputs={4}
                                    inputStyle={OtpInputStyle}
                                    separator={
                                        <span
                                            style={{
                                                marginRight: width > 600 ? '20px' : `${width / 100}px`,
                                            }}
                                        >
									{' '}
								</span>
                                    }
                                    focusStyle={handleBlur}
                                    isInputNum={true}
                                />
                            </div>
                            <SendLine className=''>
                                <ReSendButton onClick={re_send_otp}>Send the code again</ReSendButton>
                            </SendLine>
                        </Otpdiv>
                        <ButtonDiv>
                            <StyledButtonPrev
                                type='submit'
                                onClick={() => {
                                    setShowOtpForm(false);
                                    setShowForm(true);
                                }}
                                isLoading={isLoading}
                            >
                                <BiArrowBack/>
                            </StyledButtonPrev>
                        </ButtonDiv>
                    </>
                    }

                </Content>
                {width < 600 && (
                    <SubContentFooter>© copyright, 2022 Stride Ahead</SubContentFooter>
                )}
            </Container>
        </>
    );
};

export default OtpPage;

const Container = styled(Flex)`
	min-height: 100vh;

	@media (max-width: 1024px) {
		flex-direction: column;
		align-items: center;
	}
	@media (max-width: 600px) {
		width: 100%;
		/* background: red; */
	}
`;

const FormCont = styled.div`
	margin-top: 60px;
	@media (max-width: 600px) {
		margin-top: 0px;
	}
`;

const ImageComp = styled.img`
	height: 22px;
	width: 22px;
	position: absolute;
	top: 20px;
	right: 10px;
`;

const StyledButtonNext = styled(Button)`
	margin-top: 2rem;
	border-radius: 10px;
	width: 80%;
	border: none;
	background: linear-gradient(135deg, #5330ba 0%, #8e2596 100%);
	@media (max-width: 600px) {
		/* width: 100%; */
		padding: 5px 5px;
		height: 50px;
	}
`;

const StyledButtonPrev = styled(Button)`
	background: #f2effb;
	border: 1.5px solid #352958;
	border-radius: 10px;
	margin-top: 2rem;
	padding: 10px 10px;
	width: 15%;
	color: #352958;
	@media (max-width: 600px) {
		/* width: 100%; */
		padding: 5px 5px;
		height: 50px;
	}
`;

const StyleInput = styled(Input)`
	border: 1.5px solid #938ca9 !importent;
	border-radius: 20px !importent;
	width: 100%;
`;


const ButtonDiv = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 80px;
	@media (max-width: 600px) {
		margin-top: 0px;
	}
`;

const Title = styled.h1`
	font-weight: 600;
	font-size: 40px;
	line-height: 60px;
	color: #352958;
	@media (max-width: 600px) {
		font-size: 20px;
		/* background: red; */
	}
`;

const SubTitle = styled.p`
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
	color: #938ca9;
	@media (max-width: 600px) {
		font-size: 12px;
		line-height: 12px;
		/* background: red; */
	}
`;

const SubContent1 = styled.p`
    width:70%
    margin-top: -30px;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #938CA9; 

    @media (max-width: 600px) {
        width:100%
		font-size: 12px;
        line-height: 20px;
        margin-top: 10px;
		/* background: red; */
	}
`;
const SubContent = styled.p`
	font-weight: 400;
	font-size: 18px;
	line-height: 30px;
	color: #938ca9;
	@media (max-width: 600px) {
		/* position: absolute; */
		font-size: 14px;
		line-height: 18px;
	}
`;

const SubContentFooter = styled.p`
	font-weight: 400;
	font-size: 18px;
	line-height: 30px;
	color: #938ca9;
	@media (max-width: 600px) {
		/* position: absolute; */
		bottom: -10px;
		font-size: 14px;
	}
`;

const ReSendButton = styled.button`
    background: none;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: #80289F;
    
`;

const Otpdiv = styled.div`
	margin-top: 60px;
	width: 60%;
	@media (max-width: 600px) {
		/* position: absolute; */
		width: 80%;
		margin-top: 0px;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		/* position: absolute; */
		width: 100%;
		margin-top: 0px;
	}
`;


const Subtext = styled.p`
	${Text1Css}
	margin: 2rem 0;
	a {
		text-decoration: none;
		font-weight: 600;
		color: ${({theme}) => theme.colors.blue[500]};
	}
`;

const SendLine = styled.p`
	width: 100%;
    text-align:end;
	a {
		text-decoration: none;
		font-size: 16px;
		font-weight: 600;
		color: #80289f;
	}
	@media (max-width: 600px) {
		text-align: right;
		margin-top: 5px;
	}
`;

const ForgotPassword = styled.span`
	${Text1Css}
	font-weight: 600;
	color: ${({theme}) => theme.colors.blue[500]};
	cursor: pointer;
`;

const Sidebar = styled(Flex)`
	width: 45%;
	height:100vh;
	background: #f2effb;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	iframe {
		width: 80%;
		height: 43%;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
		border-radius: 20px;
	}

	img {
		width: 100%;
		max-width: 42rem;
	}

	@media (max-width: 1024px) {
		// height: 8rem;
		width: 100%;
		padding: 1rem 0;

		a {
			height: 100%;
			cursor: pointer;

			img {
				width: auto;
				height: 100%;
			}
		}
	}

	@media (max-width: 600px) {
		width: 100%;
		background: #ffff;
		height: auto;
		padding: 2rem 2rem;
	
		iframe{
		   margin-top:4rem;
		}
		
	}
`;

const Content = styled.div`
	width: 55%;
	padding: 10rem 10rem;
	flex-grow: 1;
    
	@media (max-width: 1024px) {
		width: 80%;
	}

	@media (max-width: 600px) {
		width: 100%;
		padding: 2rem 2rem;
	}
`;
