import React, {FC, useState} from 'react';
import styled, {css} from 'styled-components';
import {slideDown} from '../../styles/animations';
import {TraitPercentage} from '../../utils/types';
import Button from './Button';
import Flex from './Flex';
import Modal from './Modal';
import Donut from './Donut';

interface IProps {
    name: string;
    description: string;
    traits: TraitPercentage[] | undefined;
    index: number;
}

const colors = ['green', 'pink', 'blue', 'yellow'];
const Trait: FC<IProps> = ({name, description, traits, index}) => {
    const getColor = (percentage: number) => {
        return percentage >= 50 ? colors[index] : 'grey';
    };

    const getDominantTrait = () => {
        return (
            traits &&
            (traits[0].percentage > traits[1].percentage ? traits[0] : traits[1])
        );
    };
    const [showSummary, setShowSummary] = useState<boolean>(false);
    return (
        <>
            {traits && (
                <>
                    <Donut
                        trait={traits && traits[0]}
                        color={getColor(traits[0].percentage)}
                    />
                    <TraitDetails column>
                        <TraitName> {name}</TraitName>
                        <TraitDescriptionAndPercentage column>
                            <TraitDescription>{description.split('.')[0]}.</TraitDescription>

                            <Flex>
                                <TraitNameAndPerc
                                    flex={1}
                                    color={getColor(traits[0].percentage)}
                                >
                                    {traits[0].percentage}% {traits[0].name.toUpperCase()}
                                </TraitNameAndPerc>
                                <TraitNameAndPerc
                                    flex={1}
                                    color={getColor(traits[1].percentage)}
                                    justify='flex-end'
                                >
                                    {traits[1].percentage}% {traits[1].name.toUpperCase()}
                                </TraitNameAndPerc>
                            </Flex>
                            <TraitPercentageLine column>
                                <Line
                                    perc1={traits[0].percentage}
                                    perc2={traits[1].percentage}
                                    color={colors[index]}
                                ></Line>
                            </TraitPercentageLine>
                        </TraitDescriptionAndPercentage>
                        <ViewMoreButton
                            onClick={() => setShowSummary(true)}
                            color={colors[index]}
                        >
                            View more
                        </ViewMoreButton>
                    </TraitDetails>
                    <Donut trait={traits[1]} color={getColor(traits[1].percentage)}/>
                    {showSummary && (
                        <Modal onClose={(showModal) => setShowSummary(showModal)}>
                            <TraitModal align='center'>
                                <Donut
                                    trait={getDominantTrait()}
                                    size={14}
                                    innerSize={12}
                                    color={colors[index]}
                                    showOnMobile
                                />
                                <Description column color={colors[index]} flex={2}>
                                    <SubH2>
                                        You're mostly{' '}
                                        <span>{getDominantTrait()?.name.toUpperCase()}</span>
                                    </SubH2>
                                    <div>{description}</div>
                                </Description>
                            </TraitModal>
                        </Modal>
                    )}
                </>
            )}
        </>
    );
};

export default Trait;

const TraitNameAndPerc = styled(Flex)<{ color: string }>`
  display: none;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-bottom: 1.6rem;
  @media screen and (max-width: 1024px) {
    display: flex;
    ${({color, theme}) =>
    color &&
    css`
        color: ${theme.colors.report[color]};
      `}
  }
`;

const ViewMoreButton = styled(Button)<{ color: string }>`
  width: max-content;
  padding: 1rem 2rem;
  height: auto;
  background: #c1eafb;
  border-radius: 4px;
  ${({color, theme}) =>
    color &&
    css`
      color: ${theme.colors.report[color]};
    `}
  ${({color, theme}) =>
    color &&
    color !== 'blue' &&
    css`
      background: ${theme.colors.report[color]}40;
    `};

  @media only screen and (max-width: 1024px) {
    margin-top: 1rem;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
  }
`;

const TraitDescriptionAndPercentage = styled(Flex)`
  @media only screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

const TraitModal = styled(Flex)`
  width: 100rem;
  height: fit-content;
  padding: 4rem;
  background-color: #fff;
  border-radius: 1rem;
  animation: ${slideDown} 0.4s ease-out;
  div {
    font-family: Roboto;
    font-size: 2rem;
    line-height: 150%;
  }
  @media only screen and (max-width: 1024px) {
    width: 80%;
    padding: 3rem;
    flex-direction: column;
    div {
      font-family: Roboto;
      font-size: 1.4rem;
      line-height: 150%;
      font-weight: 400;
    }
  }
`;

const SubH2 = styled.h1`
    margin-top:0px !important;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-weight: 600;
@media only screen and (max-width: 1024px) {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: 600;
}
`;

const Description = styled(Flex)<{ color: string }>`
  margin-left: 3rem;
  ${({color, theme}) =>
    color &&
    css`
      span {
        color: ${theme.colors.report[color]};
      }
    `};
  @media screen and (max-width: 1024px) {
    margin-left: 0;
    margin-top: 4rem;
  }
`;

const TraitDetails = styled(Flex)`
  width: 50%;
  align-items: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media (min-width:1025px) and (max-width: 1200px) {
    width: 32%;
  }
`;
const TraitName = styled(Flex)`
  font-size: 3.2rem;
  text-align: center;
  font-weight: 600;

  color: ${({theme}) => theme.colors.grey[500]};
  @media screen and (max-width: 1024px) {
    font-size: 1.6rem;
    line-height: 150%;
    margin-bottom: 0;
  }
`;

const TraitDescription = styled(Flex)`
  font-size: 15px;
  text-align: center;
  height: 13rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  align-items: center;

  /* Grey 2 */
  color: ${({theme}) => theme.colors.grey[400]};

  @media screen and (max-width: 1024px) {
    font-size: 1.4rem;
    line-height: 2rem;
    height: 4rem;
    width: 80%;
    margin: auto;
    display: table;
  }
`;

const TraitPercentageLine = styled(Flex)`
  background: ${({theme}) => theme.colors.grey[50]};
  //   background: red;
  width: 100%;
  height: 1.2rem;
  border-radius: 30px;
  margin: 1rem 0;
  @media screen and (max-width: 1024px) {
    margin: 0.7rem 0;
    height: 1rem;
  }
`;

const Line = styled(Flex)<{ perc1: number; perc2: number; color: string }>`
  width: 100%;
  height: 100%;
  border-radius: 10rem;
  ${({color, theme}) =>
    color &&
    css`
      background: ${theme.colors.report[color]};
    `};
  ${({perc1, perc2}) =>
    perc1 > perc2
        ? css`
          margin-right: ${perc2}%;
          width: ${100 - perc2}%;
        `
        : css`
          margin-left: ${perc1}%;
          width: ${100 - perc1}%;
        `};
`;
