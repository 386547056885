import React, { useState } from "react";
import CloseIcon from "../../../../assets/cancel.png";

export const ArrayInputMetaForm = (props) => {
  const [arr, setArr] = useState<any[]>(props.value || []);
  const [text, setText] = useState("");

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          gap: "15px",
          alignSelf: "center",
        }}
      >
        <input
          type="text"
          name={props.name}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <div
          onClick={() => {
            props.parentCallback([...arr, text]);
            setArr((prev) => [...prev, text]);
            setText("");
          }}
          style={{
            cursor: "pointer",
            backgroundColor: "grey",
            borderRadius: "5px",
            padding: "5px 10px",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          Add
        </div>
      </div>
      {arr.map((item, index) => (
        <div
          key={index}
          style={{
            display: "inline-block",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              backgroundColor: "lightgrey",
              padding: "5px 10px 5px 10px",
              margin: "5px",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
              borderRadius: "10px",
              alignItems: "center",
            }}
          >
            <div>{item}</div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.parentCallback(arr.filter((v: string) => v !== item));
                setArr(arr.filter((v: string) => v !== item));
              }}
            >
              <img
                src={CloseIcon}
                alt="Add New Test"
                style={{ width: "15px", height: "15px" }}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
