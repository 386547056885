import React, { useEffect } from 'react';
import TakeVectore from '../../../assets/images/take-vectore.svg';
export default function TakeMeSection(props: any) {
	useEffect(() => {
		setTimeout(() => {
			const el: any = window.document.getElementById('showSticky');
			const observer = new IntersectionObserver((entries) => {
				// console.log(entries);
				if (entries[0].isIntersecting) {
					props.showSticky(true);
				} else {
					entries[0].boundingClientRect.bottom > 0 && props.showSticky(false);
				}
			});

			observer.observe(el);
		}, 300);
	}, []);

	const { ApptitudeData } = props;
	return (
		<div id='showSticky' className='take-section-banner'>
			<div className='container-md take-section-banner-box-center-alignment'>
				<div className='take-box'>
					<div className='image-center-alignment'>
						<img src={TakeVectore} alt='TakeVectore' />
					</div>
					<div className='heigh-alignment'>
						<div className='heigh-input'>
							<input type='text' placeholder='Your Score' disabled />
						</div>
						<div className='button-style'>
							<p>{ApptitudeData?.percentage.toFixed(0)}%</p>
						</div>
					</div>
					<div className='text-box-button-alignment'>
						<p
							dangerouslySetInnerHTML={{ __html: ApptitudeData?.more_details }}
						/>

						<div>
							<a href='#payment'>
								<button>Take me There</button>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
