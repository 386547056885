import React, { useEffect, useState } from "react";
import "./Modal.css";
import paymentImage from "../../../assets/Payment/paymentSuccess.png";
import Share from "../../../assets/Payment/Share.png";
import Calendar from "../../../assets/Payment/Calendar.png";
import {useHistory, useParams} from 'react-router-dom';
import { SHARE_LINK, CALENDER_LINK } from "../../../../src/utils/constants";
interface Props {
    setOpen(data: any): any;
    color?: any;
    backgroundColor?:any;
    courseName?:any;
}

const Modal2 = ({ setOpen, color, backgroundColor, courseName }: Props) => {
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("payment", "done")
    const history = useHistory();
    useEffect(()=>{
        history.push(window.location.pathname + "?" + currentUrlParams.toString());
    }, [])

    return (
        <div className="detailContainer" onClick={(e) => e.stopPropagation()}>
            <div className="payment-image">
                <img src={paymentImage} alt={paymentImage} />
            </div>
            <div>
                <p className="payment-text">Congratulations for enrolling in the program and expanding your career opportunities!</p>
            </div>
            <div>
                <p className="payment-description">
                    Details to purchase and access the {courseName} course has been shared on your email.
                    Discount details have been sent on your registered email (Check Your Spam) which can be used while checking out. Further instructions and links have been provided in the email.


                    Don't miss out on this opportunity to boost your skills and advance your career NOW!!

                </p>
            </div>
        </div>
    );
};

export default Modal2;
