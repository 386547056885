import React, { forwardRef, useState, useEffect } from "react";
import { Slider } from "antd";
import FormItem from "antd/es/form/FormItem";

const neutral = require("../../assets/neutral_emoticon.png");

export default forwardRef((props: any, ref: any) => {
  const { form, inputData, language } = props,
    { required, questions, id, options } = inputData,
    { getFieldDecorator } = form;

  let defaultValue = 3;

  const [marks, setMarks] = useState({
    1: "Strongly \n Disagree",
    2: "Disagree",
    3: "Neutral",
    4: "Agree",
    5: "Strongly \n Agree",
  });

  useEffect(() => {
    if (options && Array.isArray(options)) {
      setMarks(options.reduce((acc: any, curr: any) => {
        return { ...acc, [curr.value]: curr.text[language] };
      }, {}));
    }
  }, [options, language]);

  return (
    <FormItem
      colon={false}
      label={
        <label
          dangerouslySetInnerHTML={{
            __html: questions[language] || "no question for selected language",
          }}
        />
      }
    >
      {getFieldDecorator(id, {
        initialValue: defaultValue,
        rules: [
          {
            required,
            message: "Required Field",
          },
        ],
      })(
        <Slider
          ref={ref}
          // defaultValue={defaultValue}
          min={1}
          max={5}
          included={false}
          marks={marks}
          onAfterChange={props.onClick ? props.onClick : null}
          tooltipVisible={false}
        />
      )}
    </FormItem>
  );
});
