import React, { useEffect, useState } from "react";

import ManagementAptitudeImage from "../../../assets/images/Management Aptitude.svg";
import ScoreIcon from "../../../assets/images/score.svg";
export default function ReportApptitude(props: any) {
  const { ApptitudeData } = props;
  const [apptitudeDetails, setApptitudeDetails] = useState([]);

  useEffect(() => {
    let item = Object.entries(ApptitudeData?.dimensions);
    let orderData: any = item
      ?.map((obj: any) => {
        return { ...obj[1], header: obj[0] };
      })
      .sort((a, b) => (a.percentage < b.percentage ? 1 : -1));
    setApptitudeDetails(orderData);

  }, []);

  return (
    <div className="management-aptitide-section-alignemnt">
      <div className="container-md">
        <div className="all-common-grid-alignment">
          {apptitudeDetails?.map((apptitude: any, i: any) => {
            return (
              <div className="common-grid" key={i}>
                <div className="common-grid-items">
                  <div className="box-header-style">
                    <h2>{apptitude?.header.charAt(0).toUpperCase() + apptitude?.header.substring(1)}</h2>
                    <p>{apptitude?.description}</p>
                  </div>
                  <div className="score-box">
                    <div className="score-grid">
                      <div className="score-grid-items">
                        <div className="rounded-icon">
                          <img src={ScoreIcon} alt="ScoreIcon" />
                        </div>
                      </div>
                      <div className="score-grid-items">
                        <p>Your Score</p>
                        <div className="score-range">
                          <div className="score-range-active" style={{ width: "50%" }}></div>
                        </div>
                      </div>
                      <div className="score-grid-items">
                        <h6>{apptitude?.percentage}%</h6>
                      </div>
                    </div>
                  </div>
                  <div className="child-text-style">
                    <p>{apptitude?.stats}</p>
                    <p>How will this help?</p>
                    <div className="list-type-text">
                      <ul>
                        {apptitude?.how_will_it_help?.map((answer: any, i: any) => {
                          return <li key={i}>{answer}</li>;
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="common-grid-items">
                  <div className="image-center-alignment">
                    <img src={apptitude?.image != ""?  apptitude?.image:ManagementAptitudeImage} alt="ManagementAptitudeImage" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
