import React from 'react';
import styled from 'styled-components';
import Flex from './Flex';
import Input from './Input';

const SearchBox = ({ placeholder, value, onChange }) => {
  return (
    <Container>
      <Input
        placeholder={placeholder}
        className='searchBox'
        showSearchIndicator
        value={value || ''}
        onChange={onChange}
        inputHeight='4rem'
      />
    </Container>
  );
};

export default SearchBox;

const Container = styled(Flex)`
  width: 50rem;
  float: right;
  margin-right: 2rem;
  .searchBox {
    width: 100%;
  }
  input {
    margin: 0.5rem;
  }
`;
