import React, { useEffect, useState } from 'react';

import styles from './stickyButton.module.css';
import exp from 'constants';
import { BOOKING_LINK } from '../../utils/constants';

export const StickyButton = (props: any) => {
	return (
		<>
			{props.isVisible && (
				<div className={styles.purchase_button_containerNis} id='show'>
					<p className={styles.contentLeft}>
						Still need assistance in deciding if this is the right course for
						you? Talk to our Academic Experts now!
					</p>
					<div className={styles.right_sectionNis}>
						<a
							className='d-flex justify-content-center align-items-center'
							href={BOOKING_LINK}
							target='_blank'
						>
							Book Now
						</a>
					</div>
				</div>
			)}
		</>
	);
};
