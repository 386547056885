import React, {FC, useRef, useState} from 'react';
import styled from 'styled-components';
import Askiitians_logo from '../../assets/images/Askiitians_logo.svg';
import Flex from "../components/Flex";
import {NavLink} from 'react-router-dom';
import {DropdownItem, DropdownMenu} from "../components/DropdownMenu";
import {AiOutlineMenuUnfold} from 'react-icons/ai';

const Sidebar2 = ({setReportName}) => {
    const [active, setActive] = useState<number>(1);
    return (
        <Container2 column>
            <NavItemsContainer column>
                <NavItem className={`${active === 1 && "is_active"}`} onClick={() => {
                    setReportName("Report Disclosure");
                    setActive(1);
                }}>
                    Report Disclosure
                </NavItem>
                <NavItem className={`${active === 3 && "is_active"}`} onClick={() => {
                    setReportName("PCA Report Page");
                    setActive(3);
                }}>
                    Personality Assessment
                </NavItem>
                <NavItem className={`${active === 2 && "is_active"}`} onClick={() => {
                    setReportName("Riasec Report Page");
                    setActive(2);
                }}>
                    Career Interest
                </NavItem>
                <NavItem className={`${active === 4 && "is_active"}`} onClick={() => {
                    setReportName("AskIItians Report Page");
                    setActive(4);
                }}>
                    Learning Loss Assessment
                </NavItem>
            </NavItemsContainer>
        </Container2>
    );
};


function MobileMenu({setReportName}) {
    const [showDropdown, setShowDropdown] = useState(false);
    const [active, setActive] = useState<number>(1);
    const avatarRef = useRef(null);

    return (
        <Container3 ref={avatarRef}>
            <AiOutlineMenuUnfold color="#828282" onClick={() => setShowDropdown(!showDropdown)}/>
            {showDropdown && (
                <DropdownMenu column right='0' top='5rem' style={{width:"25rem"}}>
                    <DropdownItem className={`${active === 1 && "is_active"}`} onClick={() => {
                        setReportName("Report Disclosure");
                        setActive(1);
                    }}>Report Disclosure</DropdownItem>
                    <DropdownItem className={`${active === 3 && "is_active"}`} onClick={() => {
                        setReportName("PCA Report Page");
                        setActive(3);
                    }}>Personality Assessment</DropdownItem>
                    <DropdownItem className={`${active === 2 && "is_active"}`} onClick={() => {
                        setReportName("Riasec Report Page");
                        setActive(2);
                    }}>Career Interest</DropdownItem>
                    <DropdownItem className={`${active === 4 && "is_active"}`} onClick={() => {
                        setReportName("AskIItians Report Page");
                        setActive(4);
                    }}>Learning Loss Assessment</DropdownItem>
                </DropdownMenu>
            )}
        </Container3>
    );
}

const AskiitiansHackathonNavBar = ({setReportName}) => {

    return (
        <>
            <Container className="justify-content-between">
                <LogoContainer justify='center' align='center'>
                    <a href="#">
                        <img src={Askiitians_logo} alt='AskIItians logo'/>
                    </a>
                </LogoContainer>
                <Menu>
                    <MobileMenu setReportName={setReportName}/>
                </Menu>
            </Container>
            <SideBarShow>
                <Sidebar2 setReportName={setReportName}/>
            </SideBarShow>
        </>
    );
};

export default AskiitiansHackathonNavBar;

const Container = styled(Flex)`
	width: 100%;
	height: 6rem;
	position:sticky;
	top:0;
	background-color: #fff;
	box-shadow: 0 1px 1rem ${({theme}) => theme.colors.grey[100]};
	z-index: 120;
`;

const LogoContainer = styled(Flex)`
	height: 100%;
	width: 26rem;
	padding-left:50px;
	padding-top:10px;
	padding-bottom:10px;

	@media only screen and (max-width: 1024px) {
		justify-content: left;
		padding-left: 1.3rem;
	}
`;

const Container2 = styled(Flex)`
  height: 100%;
  width: 256px;
  margin-top: 6rem;
  top:0;
  position: fixed;
  background-color: #fff;
  box-shadow: 0 1px 1rem ${({theme}) => theme.colors.grey[100]};
  z-index: 4;
`;

const Container3 = styled.div`
  position: relative;
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  margin-left: auto;
  margin-right: 1.3rem;
  cursor: pointer;
  margin-top:1.5rem;

  svg {
    width: 3rem;
    height: 3rem;
    border-radius: 2rem;
  }
`;

const NavItemsContainer = styled(Flex)`
  width: 100%;
`;

const NavItem = styled.button`
  display: flex;
  align-items: center;
  height: 5rem;
  width: 100%;
  padding-left: 3rem;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: 'Poppins';
  color: #1D2B35;;
  text-decoration: none;
  border:none;
  background: transparent;

  &:hover {
    color: #1273B7;
  }

  svg {
    font-size: 2rem;
    margin-right: 1rem;
  }
`;

const SideBarShow = styled.div`
   
   @media(max-width:992px){
      display:none;
   }

`;
const Menu = styled.div`
  
  @media(min-width:993px){
      display:none;
   }
   
   @media(max-width:992px){
      display:block;
   }
   
`;