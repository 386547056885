import React, { FC, FocusEvent, InputHTMLAttributes, useState } from 'react';
import styled, { css } from 'styled-components';
import { shake } from '../../styles/animations';
import { IOption } from '../../utils/types';
import Flex from './Flex';
import { Text1Css } from './Typography';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  showSuccessIndicator?: boolean;
  options?: IOption[];
}

const Radio: FC<IProps> = ({
  type,
  label,
  name,
  placeholder,
  required,
  autoFocus,
  error,
  value,
  onChange,
  className,
  style,
  onBlur,
  showSuccessIndicator = false,
  options,
  ...rest
}) => {
  const [focused, setFocused] = useState(autoFocus || false);

  const handleOnBlurEvent = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <Container
      focused={focused}
      error={error}
      className={className}
      style={style}
    >
      {label && (
        <label>
          {label}
          {required && ' *'}
        </label>
      )}
      {options?.map((option, index) => (
        <RadioGroup key={index}>
          <input
            type='radio'
            name={name}
            id={name}
            required={required}
            placeholder={placeholder}
            value={option.value}
            autoFocus={autoFocus}
            formNoValidate
            onChange={onChange}
            onFocus={() => setFocused(true)}
            onBlur={handleOnBlurEvent}
            checked={value === option.value}
          />
          <label htmlFor={name}>{option.label}</label>
        </RadioGroup>
      ))}

      {error && <span className='error'>{error}</span>}
    </Container>
  );
};

export default Radio;

const Container = styled.div<{ focused: boolean; error?: string }>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100% ${Text1Css} /* Shake if there is an error */
    ${({ error }) =>
      error &&
      css`
        animation: ${shake} 0.7s ease-out;
      `}
    label {
    color: ${({ theme }) => theme.colors.grey[500]};
    font-weight: normal;
    margin-top: 0.5rem;
    width: 100%;
  }

  span.error {
    margin-top: 0.8rem;
    margin-left: 0.4rem;
    color: ${({ theme }) => theme.colors.red[500]};
  }
`;

const RadioGroup = styled(Flex)`
  position: relative;
  width: 100%;
  cursor: pointer;
  height: 3rem;
  color: #4f4f4f;
  margin-top: 0.5rem;
  outline: none;
  font-size: 1.6rem;
  border-radius: 0.6rem;

  label {
    margin-top: 0.2rem;
    padding-left: 1.5rem;
  }

  input {
    width: 6%;
    height: 1.5rem;
    padding: 1.6rem;
    margin-top: 0.5rem;
    outline: none;
    font-size: inherit;
    border: 1px solid ${({ theme }) => theme.colors.grey[500]};
    padding-left: 0rem;

    /* Focused state styles */
    &.focused {
      box-shadow: 0 0 2px ${({ theme }) => theme.colors.blue[500]};
      border-color: ${({ theme }) => theme.colors.blue[500]};
    }

    /* Error state styles */
    &.error {
      box-shadow: 0 0 2px ${({ theme }) => theme.colors.red};
      border-color: ${({ theme }) => theme.colors.red[500]};
    }

    border-radius: 0.6rem;
    color: ${({ theme }) => theme.colors.grey[500]};
    transition: 0.1s;
  }
`;
