import React, { FC } from 'react';
import styled from 'styled-components';
import LoadingImage from '../../assets/customLoader.gif';
import { blink, fadeIn } from '../../styles/animations';
import Flex from './Flex';
import "./customloading.css";

const Loading: FC<{ message?: string }> = ({ message }) => {
  return (
    <Container>
      <div className="loader-wrapper">
<div className="loader"></div>
<div className="loader-text">{message}</div>
</div>
    </Container>
  );
};

export default Loading;


const Container = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
`;
const Image = styled.img`
  width: 15rem;
  height: 6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;

const Message = styled(Flex)`
  width: 100%;
  color: red;
  font-size: 20px;
  position: absolute;
  top: 85%;
  left: 50%;
  color: ${({ theme }) => theme.colors.grey[500]};
  animation: ${blink} 0.5s linear infinite;
`;
