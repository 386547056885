// @ts-nocheck
import React from 'react';
import '../../assets/antd.css';
import '../../assets/FormBuilder.css';
import './report_pages/RiasecQuizReport.css'
import QuizList from '../components/QuizList'
import { Redirect, Route, Switch } from 'react-router';
import QuizPage from './QuizPage';
import ReportPage from './ReportPage';

const QuizBuilder = () => {
  return (
    <Switch>
        <Route 
          path='/quiz'
          exact
          component={QuizList} />;
        <Route 
          path='/quiz/:id'
          exact
          component={QuizPage} />;
        <Route
          path='/quiz/report/:id'
          exact
          component={ReportPage}
        />
        <Route path='*'>
          <Redirect to='/404' />
        </Route>
    </Switch>
  );
};

export default QuizBuilder;