import React, { useEffect, useState } from "react";
import "./Modal.css";
import paymentImage from "../../../assets/Payment/paymentSuccess.png";
import Share from "../../../assets/Payment/Share.png";
import Calendar from "../../../assets/Payment/Calendar.png";
import {useHistory, useParams} from 'react-router-dom';
import { SHARE_LINK, CALENDER_LINK } from "../../../../src/utils/constants";
interface Props {
  setOpen(data: any): any;
    color?: any;
    backgroundColor?:any;
}

const Modal = ({ setOpen, color, backgroundColor }: Props) => {
  const currentUrlParams = new URLSearchParams(window.location.search);
  currentUrlParams.set("payment", "done")
  const history = useHistory();
  useEffect(()=>{
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  }, [])

  return (
    <div className="detailContainer" onClick={(e) => e.stopPropagation()}>
      <div className="payment-image">
        <img src={paymentImage} alt={paymentImage} />
      </div>
      <div>
        <p className="payment-text">Payment successful!</p>
      </div>
      <div>
        <p className="payment-description">
          Dear Strider, You have successfully started your journey towards
          upgrading your managerial skills by joining Management Associate
          program by Stride Ahead. You will be performing various case studies,
          capstone Project and understand about various managerial tools in this
          cohort.
        </p>
      </div>
      <div className="payment-box-footer">
        {typeof SHARE_LINK !== "undefined" && SHARE_LINK !== "" && (
          <div
            className="share-image"
            onClick={() => {
              window.open(SHARE_LINK, "_blank");
            }}
          >
            <img src={Share} alt={Share} />
          </div>
        )}
        <div
          className="add-calender"
          onClick={() => {
            window.open(CALENDER_LINK, "_blank");
          }}
        >
          <div className="calender-logo">
            <img src={Calendar} alt={Calendar} />
          </div>
          <p className="add-calender-text">Add to calender </p>
        </div>
      </div>
    </div>
  );
};

export default Modal;
