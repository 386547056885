import react, { useState } from 'react';
import SheeHulk from '../../assets/sheehulk.svg'
import EnableTick from '../../assets/enable_tick.svg'
import DisableTick from '../../assets/disable_tick.svg'
import StrideLogo from '../../assets/stride_logo.svg';
import styled from 'styled-components';


const CalculatingPage = () => {
    const [enableIcon, setEnableIcon] = useState(false);
    const [enableIcon1, setEnableIcon1] = useState(false);
    const [enableIcon2, setEnableIcon2] = useState(false);
    const [enableIcon3, setEnableIcon3] = useState(false);
    const [enableIcon4, setEnableIcon4] = useState(false);

    setInterval(function () {
        setEnableIcon(true);
    }, 1000);
    setInterval(function () {
        setEnableIcon1(true);
    }, 2000);
    setInterval(function () {
        setEnableIcon2(true);
    }, 3000);
    setInterval(function () {
        setEnableIcon3(true);
    }, 4000);
    setInterval(function () {
        setEnableIcon4(true);
    }, 5000);

    return (

        <Maindiv className=" m-auto">
            <StyleDiv className="col-12 d-flex justify-content-center align-items-center ">
                <StrideLogoImg src={StrideLogo} />
            </StyleDiv>
            <StyleDiv className="col-12 text-center"> <Image src={SheeHulk} /> </StyleDiv>
            <StyleDiv className="col-12  col-12 d-flex justify-content-center">
                <div className="text-start">
                    <StyleH1> Our AI is working on your performance</StyleH1>
                    <Div className="d-flex">
                        <Icon src={enableIcon ? EnableTick : DisableTick} /> <StyleP
                            style={{ color: enableIcon ? "#000000" : "#706E84" }}>Calculating your Basic management
                            aptitude.</StyleP>
                    </Div>
                    <Div className="d-flex">
                        <Icon src={enableIcon1 ? EnableTick : DisableTick} /> <StyleP
                            style={{ color: enableIcon1 ? "#000000" : "#706E84" }}> Analysing your critical thinking and
                            Analytical
                            abilities.</StyleP>
                    </Div>
                    <Div className="d-flex">
                        <Icon src={enableIcon2 ? EnableTick : DisableTick} /> <DisableP
                            style={{ color: enableIcon2 ? "#000000" : "#706E84" }}> Assessing your orientation toward
                            solutions.</DisableP>
                    </Div>
                    <Div className="d-flex">
                        <Icon src={enableIcon3 ? EnableTick : DisableTick} /> <DisableP
                            style={{ color: enableIcon3 ? "#000000" : "#706E84" }}> Calculating your Team collaboration
                            Skills.</DisableP>
                    </Div>
                    <Div className="d-flex">
                        <Icon src={enableIcon4 ? EnableTick : DisableTick} /> <DisableP
                            style={{ color: enableIcon4 ? "#000000" : "#706E84" }}> Personalizing the solution to enhance
                            these
                            skills.</DisableP>
                    </Div>
                </div>
            </StyleDiv>
        </Maindiv>
    )

}

export default CalculatingPage;


const Maindiv = styled.div`
      font-family: 'Poppins';
      background-color: #FAF9FF;
      padding: 0px;
      height:100%
       
      
      @media (max-width: 768px){
       background-color: #FAF9FF;
       height:100%
       padding: 20px
       }
 `;

const Div = styled.div`
   
    @media (max-width: 768px){
       width: 80%;
        margin:0 auto;
       }
`;

const StyleDiv = styled.div`
       padding-top: 28px
    `;

const StrideLogoImg = styled.img`
    width: 25%;
    
    @media (max-width: 768px){
       width: 60%;
       }
`;

const Image = styled.img`
     width: 15%;
     
     @media (max-width: 768px){
       width: 50%;
       }
`;

const Icon = styled.img`
       width: 25px;
       height: 25px;
       
       
      @media (max-width: 768px){
        margin-top: 15px
       }
       
`;


const StyleH1 = styled.h1`
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 26px;
      text-align: center;
      color: #352958;
      @media (max-width: 768px){
       margin-bottom: 5px;
       font-size: 22px;
       }
      
    `;

const StyleP = styled.p`
       font-weight: 500;
       font-size: 16px;
       padding-left: 10px;
       
       @media (max-width: 768px){
       font-size: 12px;
       margin-top 16px;
       }
    `;

const DisableP = styled.p`
       font-weight: 500;
       font-size: 16px;
       padding-left: 10px;
       
       @media (max-width: 768px){
       font-size: 12px;
       margin-top 16px;
       }
    `;