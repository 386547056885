import React, {FC, useEffect,useState, useRef} from 'react';
import styled from 'styled-components';
import LoadingImage from '../../assets/stride_logo_loading.gif';
import {blink, fadeIn} from '../../styles/animations';
import Flex from './Flex';

const Loading: FC<{ message?: string, LoaderImage?: string }> = ({message, LoaderImage}) => {
    return (
        <Container>
            <Image src={LoaderImage || LoadingImage} alt='Loading...'/>
            <Message>{message}</Message>
        </Container>
    );
};

export default Loading;

const Container = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
  animation: ${fadeIn} 0.4s;
`;
const Image = styled.img`
  width: 15rem;
  height: 6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;

const Message = styled(Flex)`
  width: 100%;
  color: red;  
  font-size: 20px;
  position: absolute;
  top: 55%;
  left: 38%;
  color: ${({theme}) => theme.colors.grey[500]};
  animation: ${blink} 0.5s linear infinite;
`;
