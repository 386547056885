import React,{useState} from 'react';
import { Button } from "antd";

import "./instruction.css"
const correct_img = require('../../../assets/Group 34418.png')




export default function AptitudeQuizInstructions(props: any) {

  
  const quizDurations = props.quizDuration ? props.quizDuration.split(":")[1] : undefined; 

  
const allInstructionBox: any[] = [
  {
    instructionNumber:`${props.totalQuestions}`,
    instructionsNumbertxtOne:"Total",
    instructionsNumbertxtTwo:"Questions",
    instuctionBgColor:"#EBFFED",
    instructionsTextColor:'#44DB53',
    unit:""
  },
  {
    instructionNumber:`${quizDurations}`,
    instructionsNumbertxtOne:"Time",
    instructionsNumbertxtTwo:"Alloted",
    instuctionBgColor:"#EBF9FF",
    instructionsTextColor:'#00B2FF',
    unit:"mins"
  },
  {
    instructionNumber:1,
    instructionsNumbertxtOne:"Correct",
    instructionsNumbertxtTwo:"Answer",
    instuctionBgColor:"#F6F2FF",
    instructionsTextColor:'#8A56FF',
    unit:"points"
  },
  {
    instructionNumber:"0",
    instructionsNumbertxtOne:"Incorrect",
    instructionsNumbertxtTwo:"Answers",
    instuctionBgColor:"#FFF0F0",
    instructionsTextColor:'#FF4D4D',
    unit:'points'
  },
  {
    instructionNumber:null,
    instructionsNumbertxtOne:"Single Correct",
    instructionsNumbertxtTwo:"Answer",
    instuctionBgColor:"#FFF6E8",
    instructionsTextColor:'#FF41B3',
    unit:"",
    imageIcon:correct_img.default,
  },
  {
    instructionNumber:`${props.totalSampleQuestions}`,
    instructionsNumbertxtOne:"Sample",
    instructionsNumbertxtTwo:"Questions",
    instuctionBgColor:"#FFF0F9",
    instructionsTextColor:'#FF41B3',
    unit:""
  }, 
]
  

const renderInstructionBox = allInstructionBox.map((perInstruction) => {
    return (
      <>
      {perInstruction?.instructionNumber != "undefined" ? 
        
        <div className="instruction_box_same_style" style={{backgroundColor:perInstruction.instuctionBgColor}} key={Math.random()}>
          {perInstruction?.imageIcon ? null 
          : 
          <div className="instruction_title_number_text">
            <h6 className="instrution_number_text_same_style" style={{color:perInstruction.instructionsTextColor}}>{perInstruction.instructionNumber}</h6>
            {
              perInstruction?.unit && perInstruction?.unit != "" ?
              <span className="instrution_black_number_text">{perInstruction.unit}</span>
              :
              null
            }
          </div>}  
   
          {
          perInstruction?.imageIcon ? 
          <div className="instruction_correct_box_img">
            <img src={perInstruction.imageIcon} alt="correct" className="instruction_img_correct" />
          </div>
          : null
          }
   
          <div>
            <h6 className="instruction_number_text_one">{perInstruction.instructionsNumbertxtOne} <br/>   {perInstruction.instructionsNumbertxtTwo}</h6>
          </div>
        </div>
         : null}
      </>
      )
    })
    return(
      <>
      
      <div className="main_aptitude_container" >
      
      <div className="instruction_top_text_container">
      <h1 className="instruction_title">{props.quizLabel !== '' ? props.quizLabel : props.quizName}</h1>
      <p className="instruction_extra_msg_text">{props.preQuizDescription !== '' ? props.preQuizDescription : props.quizDescription}</p>
      </div>
      
      <div className="instruction_all_box_contianer">
      
      {renderInstructionBox}
      {/* <div className="instruction_per_box_bg_color1 instruction_box_same_style">
      <div className="instruction_correct_box_img">
      <img src={correct_img.default} alt="correct" className="instruction_img_correct" />
      </div>
      
      <h6 className="instruction_number_text_one">Single Correct <br />Answer</h6>
      
      </div>
       */}
      </div>
      
      <Button
      className="universal-quiz-instructions-button proceed_btn"
      block={true}
      style={{
        background: "#1273B7",
      }}
      onClick={(e) => {
        e.preventDefault();
        props.setInstructionsRead(true);
      }}
      >
      Proceed
      </Button>
      
      </div>
      </>      
      );
    }