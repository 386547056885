import React, {useEffect, useState} from "react";
import {AiFillCloseCircle} from "react-icons/ai";
import {
    IoIosArrowBack,
    IoIosArrowForward,
    IoIosArrowRoundBack,
} from "react-icons/io";
import {
    RiCheckboxBlankCircleLine,
    RiCheckboxBlankCircleFill,
} from "react-icons/ri";
import GridLoader from "react-spinners/GridLoader";
// import {Gri}

import {BsFillTagFill} from "react-icons/bs";
import "./PromocodeModal.css";
import {data as modalData} from "../../../data";

import {makePayment, makePayment2} from "../../../api/razorPay";

//@ts-ignore
import discount from "../../../assets/PromocodeModal/discount.png"; //@ts-ignore
import payment from "../../../assets/PromocodeModal/payments.svg"; //@ts-ignore
import {MAP_FEATURES,MAP_FEATURES_FOR_REDUTECH, PAGES_URL} from "../../../utils/constants";

interface Props {
    setOpen(data: any): any;

    setPromoCodes(data: any): any;

    promoCodes: any;
    product: any;
    aPromoCode: any;
    userData: any;
    orderIdInUrl: any;
    color?: any;
    backgroundColor?: any;
    emailData?: any;
    clientId?: any;
}

const PromocodeModal = ({
                            setOpen,
                            setPromoCodes,
                            promoCodes,
                            product,
                            aPromoCode,
                            userData,
                            orderIdInUrl,
                            color,
                            emailData,
                            clientId,
                            backgroundColor
                        }: Props) => {
    interface orderIdData {
        name?: string;
        email?: string;
        promo_code?: string;
        product_code?: any;
        contact_no?: any;
        client_id?: any;
    }

    const data = product;
    const [active, setActive] = useState(0);
    const [promoBox, setPromoBox] = useState(false);
    const [promoCodeInput, setPromoCodeInput] = useState("");
    const [error, setError] = useState("");
    const [initialPromoCode, setInitialPromoCode] = useState(true);

    const [appliedPromoCode, setAppliedPromoCode] = useState(aPromoCode);
    const [appliedPromoCodeData, setAppliedPromoCodeData] = useState({
        discount: 0,
        offer_name: "",
    });
    const [appliedPromoCodeDataWhenApiFail, setAppliedPromoCodeDataWhenApiFail] =
        useState({
            discount: 0,
            offer_name: "",
        });
    const [discountedPrice, setDiscountedPrice] = useState(0);
    const [codeList, setCodeList] = useState<any[]>([]);
    const [orderData, setOrderData] = useState<any>({});
    const [loader, setLoader] = useState(false);

    var ImageData = JSON.parse(color? MAP_FEATURES_FOR_REDUTECH : MAP_FEATURES);
    const MainWidth = window.innerWidth;
    const MapFeatures = MainWidth > 600 ? ImageData.desktop : ImageData.mobile;
    // Api call to check prom code
    const checkPromoCode = (value: any) => {
        setLoader(true);
        return fetch(`${PAGES_URL}promos/${value}/valid`, {
            headers: {
                accept: "application/json",
                "Content-Type": "application/json",
            },
            referrer: `${PAGES_URL}/pages/7HAJCW?eventId=cta-click&action=payment-init`,
            referrerPolicy: "strict-origin-when-cross-origin",
            method: "POST",
            mode: "cors",
            credentials: "include",
            body: `{"product_id":${data.id}}`,
        })
            .then((res) => {
                if (res.status == 200) {
                    setLoader(false);
                    setPromoBox(false);
                    setError("");
                    return res.json();
                } else {
                    setError("Please enter a valid Promo Code.");
                    setInitialPromoCode(false);
                    setAppliedPromoCodeData({
                        discount: 0,
                        offer_name: "",
                    });
                    setLoader(false);
                }
            })
            .then((data) => {
                if (data.is_valid) {
                    setAppliedPromoCodeDataWhenApiFail(data.promo_code);
                    if (aPromoCode == value) {
                        setInitialPromoCode(true);
                        return setAppliedPromoCodeData(data.promo_code);
                    }
                    setInitialPromoCode(false);
                    setAppliedPromoCodeData(data.promo_code);
                }
                setLoader(false);
            })
            .catch((e) => {
                setInitialPromoCode(false);
                setAppliedPromoCodeData({
                    discount: 0,
                    offer_name: "",
                });
                setLoader(false);
            });
    };

    useEffect(() => {
        const createOrder = (data: any) => {
            return fetch(`${PAGES_URL}/external-order`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(data),
            })
                .then((res) => {
                    return res.json();
                })
                .then((order) => {
                    setOrderData(order);
                });
        };

        const userDataForOrder: orderIdData = {
            name: userData?.name,
            email: userData?.email,
            promo_code: appliedPromoCodeData?.offer_name,
            product_code: product?.product_code,
            contact_no: userData?.phone_no,
            client_id:clientId ?? "sa"
        };

        if (userDataForOrder.promo_code === "") {
            delete userDataForOrder?.promo_code;
        }

        if (product?.product_code.length === 0) {
            delete userDataForOrder.product_code;
        }

        if (userData?.phone_no.length === 0) {
            delete userDataForOrder.contact_no;
        }

        if (userData?.phone_no.length === 10) {
            userDataForOrder.contact_no = "91" + userData?.phone_no;
        }

        createOrder(userDataForOrder);
    }, [appliedPromoCodeData]);

    // calling api to check promo code which is available at page load
    useEffect(() => {
        checkPromoCode(appliedPromoCode);
        if (orderIdInUrl) {
            const orderInfo = {
                email: "",
                order: {
                    amount: 0,
                    amount_due: 0,
                    amount_paid: 0,
                    attempts: 0,
                    created_at: 0,
                    currency: "INR",
                    entity: "order",
                    id: orderIdInUrl,
                    notes: [],
                    offer_id: null,
                    receipt: null,
                    status: "created"
                }
            }
            color? makePayment2(orderData, userData, emailData, setOpen): makePayment(orderData, userData, setOpen);
        }
    }, []);

    const handleProceed = () => {
      color? makePayment2(orderData, userData, emailData, setOpen): makePayment(orderData, userData, setOpen);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (active === MapFeatures.length - 1) setActive(0);
            else {
                setActive((active) => active + 1);
                return;
            }
        }, 3000);

        return () => {
            clearInterval(timeout);
        };
    }, [active]);

    // Calling api to check promocode while manually entering or clicking on available promo codes
    const handleApply = (data: any) => {
        if (data?.offer_name !== "") {
            setError("");
            checkPromoCode(data?.offer_name);
        } else {
            setError("Please enter a promo code");
        }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (active === MapFeatures.length - 1) setActive(0);
            else {
                setActive((active) => active + 0);
                return;
            }
        }, 3000);

        return () => {
            clearInterval(timeout);
        };
    }, [active]);

    useEffect(() => {
        setError("");
    }, [promoCodeInput]);

    useEffect(() => {
        if (promoCodes?.promo_codes) setCodeList([...promoCodes?.promo_codes]);
        else setCodeList([...promoCodes]);
    }, [promoCodes]);

    return (
        <div onClick={(e) => e.stopPropagation()} className={"promo-modal"}>
            <div className={"promo-modal-body"}>
                <AiFillCloseCircle
                    onClick={() => setOpen(0)}
                    color={"gray"}
                    className={"promo-cross"}
                />
                <div className={"promo-image-section"}>
                    <img
                        className={"promo-header-image"}
                        src={`https://d2b560u3hlgvw0.cloudfront.net/${data.image}`}
                    />
                    <div>
                        <p className={"promoLabel"}>{data?.product_label}</p>
                        <p className={"productSubLabel"}>{data?.description}</p>
                    </div>
                </div>

                <div className={"promo-image-details"}>
                    <div className={"promo-desc"} id="imgslider">
                        <div className={"promo-carousel"}>
                            <img alt="images" src={MapFeatures[active]}/>
                            <div className={"promo-arrow-container"}>
                                <IoIosArrowBack
                                    className={"promo-arrow-icon"}
                                    onClick={() =>
                                        active > 0 && active <= MapFeatures.length - 1
                                            ? setActive(active - 1)
                                            : () => {
                                            }
                                    }
                                />
                                <IoIosArrowForward
                                    className={"promo-arrow-icon"}
                                    onClick={() =>
                                        active >= 0 && active < MapFeatures.length - 1
                                            ? setActive(active + 1)
                                            : () => {
                                            }
                                    }
                                />
                            </div>
                            <div className={"carouserDots"}>
                                {MapFeatures.map((_, index) => (
                                    <>
                                        {active === index ? (
                                            <RiCheckboxBlankCircleFill className={"carouselDot"}/>
                                        ) : (
                                            <RiCheckboxBlankCircleLine
                                                onClick={() => setActive(index)}
                                                className={"carouselDot"}
                                            />
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Changing content of model in between main screen and offer screen */}
                    {loader ? (
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <GridLoader color={color? color :`#8667DE`}/>
                        </div>
                    ) : promoBox ? (
                        <div className="detail">
                            <header>
                                <IoIosArrowRoundBack
                                    size={"3rem"}
                                    onClick={() => {
                                        setPromoBox(!promoBox);
                                        setPromoCodeInput("");
                                        const checkbox = document.getElementById(
                                            "promoCode"
                                        ) as HTMLInputElement;
                                        checkbox.value = "";
                                        if (error !== "") {
                                            checkPromoCode(
                                                appliedPromoCodeDataWhenApiFail.offer_name
                                            );
                                        }
                                    }}
                                />
                                <p>Cart</p>
                            </header>

                            <section>
                                <img src="/blackDiscount.png" alt=""/>
                                <h3 style={{fontSize: "2rem"}}>Available Offers</h3>
                            </section>

                            <div className="customPromo">
                                <input
                                    style={{border:`2px solid ${color}`, outlineColor:color} }
                                    id="promoCode"
                                    placeholder="Enter Promo Code"
                                    className="promoInput"
                                    onChange={(e) => setPromoCodeInput(e.target.value)}
                                    value={promoCodeInput}
                                />
                                {/* <label>{error}</label> */}
                                <h4 style={{backgroundColor:backgroundColor}}
                                    onClick={() => {
                                        handleApply({offer_name: promoCodeInput});
                                    }}
                                >
                                    APPLY
                                </h4>
                            </div>

                            <p className="error">{error}</p>

                            <div className="promoCodes">
                                {typeof promoCodes !== "undefined" &&
                                promoCodes.length > 0 &&
                                promoCodes?.map(
                                    (code: any, index: number) =>
                                        !code.is_hidden && (
                                            <article key={index}>
                                                <h4>{code?.offer_name}</h4>
                                                <p>
                                                    <img alt="" src="/tag.png"/>
                                                    {code?.description}
                                                </p>
                                                <hr/>
                                                <div>
                                                    <h6>
                                                        You will save ₹{" "}
                                                        {(
                                                            (Number(product.discounted_price) *
                                                                Number(code?.discount)) /
                                                            100
                                                        ).toFixed(2)}{" "}
                                                        with this code
                                                    </h6>
                                                    <h4 onClick={() => handleApply(code)}>
                                                        {appliedPromoCodeData?.offer_name ===
                                                        code?.offer_name
                                                            ? "APPLIED"
                                                            : "APPLY"}
                                                    </h4>
                                                </div>
                                            </article>
                                        )
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className={"promo-details"}>
                            <div className={"promo-image-section-2"}>
                                <img
                                    className={"promo-header-image"}
                                    src={`https://d2b560u3hlgvw0.cloudfront.net/${data.image}`}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: 80,
                                        justifyContent: "center",
                                    }}
                                >
                                    <span style={{color:color}} className={"promoLabel"}>{data?.product_label}</span>
                                    <span className={"productSubLabel"}>{data?.description}</span>
                                </div>
                            </div>

                            <h3 style={{marginTop: 10, marginBottom: 20}}>Offers</h3>
                            <>
                                <div
                                    style={{marginTop: 15, marginBottom: 15}}
                                    className={"promocode"}
                                >
                                    {color? "" :<img alt="" src={discount}/>}
                                    <div>
                                        <div className={"promo-offer-container"}>
                                            <h5>
                                                {Number(appliedPromoCodeData?.discount) > 0
                                                    ? `Code applied `
                                                    : "No Code Applied"}
                                                {initialPromoCode
                                                    ? ""
                                                    : appliedPromoCodeData?.offer_name}
                                            </h5>
                                            <p style={{color:color}}>
                                                - ₹{" "}
                                                {Number(appliedPromoCodeData?.discount) > 0
                                                    ? (
                                                        (Number(product.discounted_price) *
                                                            Number(appliedPromoCodeData?.discount)) /
                                                        100
                                                    ).toFixed(2)
                                                    : 0}
                                            </p>
                                        </div>

                                        <div className={"promo-offer-container"}>
                                            <h6  >
                                                Save ₹{" "}
                                                {Number(appliedPromoCodeData?.discount) > 0
                                                    ? (
                                                        (Number(product.discounted_price) *
                                                            Number(appliedPromoCodeData?.discount)) /
                                                        100
                                                    ).toFixed(2)
                                                    : 0}
                                            </h6>
                                            <a style={{color:color}} onClick={() => setPromoBox(true)}>Change offer</a>
                                        </div>
                                    </div>
                                </div>
                            </>
                            <h3 style={{marginTop: 20}}>Summary</h3>
                            <div className={"summaryContainer"}>
                                <div className={"summaryRow"}>
                                    <p>Product Total</p>
                                    <p className={"price"}>+ ₹ {data?.actual_price}</p>
                                </div>
                                <div className={"summaryRow"}>
                                    <p>Offer</p>
                                    <p className={"price"}>- ₹ {data?.actual_price - data?.discounted_price}</p>
                                </div>

                                <div className={"summaryRow"}>
                                    <p>Discount</p>
                                    <p className={"price"}>
                                        {" "}
                                        - ₹{" "}
                                        {Number(appliedPromoCodeData?.discount) > 0
                                            ? (
                                                (Number(product.discounted_price) *
                                                    Number(appliedPromoCodeData?.discount)) /
                                                100
                                            ).toFixed(2)
                                            : 0}
                                    </p>
                                </div>

                                <div className={"summaryRow"}>
                                    <h3>Grand Total</h3>
                                    <h3 className={"priceTotal"}>
                                        {" "}
                                        ₹{" "}
                                        {Number(appliedPromoCodeData?.discount) > 0
                                            ? (
                                                Number(data?.discounted_price) -
                                                (Number(product.discounted_price) *
                                                    Number(appliedPromoCodeData?.discount)) /
                                                100
                                            ).toFixed(2)
                                            : Number(data?.discounted_price).toFixed(2)}
                                    </h3>
                                </div>
                            </div>

                            <div className={"payRow"}>
                                <div>
                                    <h5>Pay now</h5>
                                    <p>
                                        Total ₹
                                        {Number(appliedPromoCodeData?.discount) > 0
                                            ? (
                                                Number(data?.discounted_price) -
                                                (Number(product.discounted_price) *
                                                    Number(appliedPromoCodeData?.discount)) /
                                                100
                                            ).toFixed(2)
                                            : Number(data?.discounted_price).toFixed(2)}
                                    </p>
                                </div>
                                <h3 style={{backgroundColor:backgroundColor}} onClick={handleProceed}>PROCEED</h3>
                            </div>
                            <img
                                style={{marginTop: 80}}
                                alt=""
                                src={payment}
                                className=""
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PromocodeModal;
