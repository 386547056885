// @ts-nocheck
import React, { useContext, useRef } from "react";
import { Drawer, Row } from "antd";
import BottomButtons from "../../../../../ReusableComponents/BottomButtons";
import SettingsForm from "./SettingsForm/SettingsForm";
import TranslatedText from "../../../../../translations/TranslatedText";
import QuizzContext, {
  IQuizzBuilderContext
} from "../../../../../QuizzContext";
import { patchElement } from "../../../../reducer/actions";
import styles from "./drawer.module.css";

// applies delete and edit capabilities
export default function SettingsDrawer(props: any) {
  const { closeDrawer, showDrawer, toolboxData, inputData } = props,
    { dispatch, updateCorrectAnswer, quizCorrectAnswer, quizTypeName } = useContext(QuizzContext) as IQuizzBuilderContext,
    formRef = useRef() as any;
  function onFormSubmit(e: Event) {
    e.preventDefault();
    // gets the form ref from child
    const { validateFields } = formRef.current.props.form;
    validateFields((err: any, values: any) => {
      if(quizTypeName === "universal_quiz"){
        const correctAnswer = quizCorrectAnswer[inputData.id];
        const isCorrectOptionValid = values.options.some((option: any) => option.value === correctAnswer.answer);
        var valueArr = values.options.map(function(item){ return item.value });
        var isDuplicate = valueArr.some(function(item, idx){ 
          return valueArr.indexOf(item) != idx 
        });
        if(!correctAnswer || !isCorrectOptionValid) {
          alert("Please select correct answer")
          return
        }
        if(isDuplicate) {
          alert("Options values should be unique")
          return
        }
      }
      if (!err) {
        // adds to reducer
        dispatch(patchElement(inputData.id, values));
        // close drawer had no errors
        closeDrawer();
      }
    });
  }
  return (
    <Drawer
      title={<TranslatedText id={toolboxData.name} />}
      onClose={closeDrawer}
      visible={showDrawer}
      destroyOnClose
      className={styles.settingsForm}
    >
      <Row style={{ height: "90%", overflowY: "auto" }}>
        <SettingsForm
          wrappedComponentRef={formRef}
          toolboxData={toolboxData}
          inputData={inputData}
          quizTypeName={quizTypeName}
          updateCorrectAnswer={updateCorrectAnswer}
          quizCorrectAnswer={quizCorrectAnswer}
        />
      </Row>
      <Row
        type="flex"
        justify="space-between"
        align="bottom"
        style={{ height: "10%" }}
      >
        <BottomButtons onClose={closeDrawer} onSubmit={onFormSubmit} />
      </Row>
    </Drawer>
  );
}
