import React, {FC, useState} from 'react';
import styled from 'styled-components';
import {BsFillQuestionCircleFill} from 'react-icons/bs';

import Flex from '../../../components/Flex';
import Toast from '../../../components/Toast';
import Button from '../../../components/Button';
import useTest from '../../../../hooks/useTest';
import {HiOutlineArrowRight} from 'react-icons/hi';
import Copy from '../../../../assets/analysis/copy.svg';
import SocialShare from '../../../components/SocialShare';
import LockIcon from '../../../../assets/test/lock_icon.png';
import {ReportMetadataResponse,} from '../../../../utils/types';
import {useCurrentProfile} from '../../../../hooks/useProfile';
import {copyToClipboard} from '../../../../utils/helperFunctions';
import CalendarIcon from '../../../../assets/test/calendar_icon.png';
import {
    DICEBAR_URL,
    PHRASE_TOOLTIP,
    REPORT_SHARE_TEXT,
} from '../../../../utils/constants';

import './userDetails.css';

const UserDetailsNew: FC<{
    reportMeta: ReportMetadataResponse;
    data?: any;
    test2?: any;
    testId: string;
    authenticate: boolean;
    bookingLink: string;
}> = ({reportMeta, testId, authenticate, bookingLink, test2, data}) => {
    const {data: test, isLoading} = useTest(testId);
    const {data: currentProfile} = useCurrentProfile(
        !isLoading && authenticate,
    );


    const [showToast, setShowToast] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [toastText, setToastText] = useState<string>('');

    const copyLink = (link: string) => {
        copyToClipboard(link);
        setToastText('The test link has been copied.');
        setShowToast(true);
        setSuccess(true);
    };

    const getShareText = () => {
        return test
            ? REPORT_SHARE_TEXT.replace(
                '{reportUrl}',
                test2?.report_link ? test2?.report_link : data?.report_link,
            ).replace('{testUrl}', test?.test_link)
            : '';
    };

    const redirectToBooking = () => {
        window.open(`${bookingLink}`, '_blank');
    };

    return (
        <DetailsContainer column>
            {reportMeta && (
                <>
                    <ROW>
                        <div style={{display: 'flex'}}>
                            <Heading>Phrase:</Heading>
                            <Value>{reportMeta?.phrase}</Value>
                        </div>
                        <div>
                            <img
                                alt=''
                                className='avatar'
                                src={
                                    reportMeta?.avatar
                                        ? reportMeta?.avatar
                                        : 'https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
                                }
                            />
                            <span className='phrase-tooltip' data-tooltip={PHRASE_TOOLTIP}>
								<BsFillQuestionCircleFill className='info-icon '/>
							</span>
                        </div>
                    </ROW>
                    <Divider/>
                    <ROW>
                        <div style={{display: 'flex'}}>
                            <Heading>Type Code :</Heading>
                            <Value>{reportMeta?.type_code}</Value>
                        </div>
                    </ROW>
                    <Divider/>
                </>
            )}
            {test2?.report_link ? null :
                <>
                    <Heading> Share:</Heading>
                    <SocialShare
                        url={test2?.report_link ? test2?.report_link : data?.report_link}
                        title={getShareText()}
                        bgColor='#F2F2F2'
                    />
                    <br/>
                    <br/>
                    <br/>
                    <Flex style={{marginBottom: '2.4rem'}}>
                        <ReportLink>{test2?.report_link ? test2?.report_link : data?.report_link}</ReportLink>
                        <CopyButton
                            onClick={() => copyLink(test2?.report_link ? test2?.report_link : data?.report_link)}
                            test={test}>
                            <img src={Copy} alt='Copy Link'/>
                        </CopyButton>
                    </Flex>
                </>
            }
            <br/>

            {showToast && (
                <Toast
                    onClose={(showToast) => setShowToast(showToast)}
                    success={success}
                >
                    {toastText}
                </Toast>
            )}
            {/* {bookingLink && (
        <>
          <div
            style={{
              display: "flex",
              margin: "0 auto",
              marginTop: "60px",
              alignItems: "center",
              width: "fit-content",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
              src={LockIcon}
              style={{ marginBottom: "20px", width: "35px", height: "35px" }}
              alt="lock_icon"
            />
            <ScrollText>
              You've unlocked a <FreeText>1 on 1 session</FreeText> with our
              mentor
            </ScrollText>
          </div>
          <StyledButton onClick={() => redirectToBooking()}>
            <img
              src={CalendarIcon}
              style={{ width: "15px", marginRight: "10px" }}
              alt="calendar_icon"
            />
            <span style={{ marginRight: "10px" }}>Book a session</span>
            <HiOutlineArrowRight />
          </StyledButton>
        </>
      )} */}
        </DetailsContainer>
    );
};

export default UserDetailsNew;

const DetailsContainer = styled(Flex)`
	flex: 1;
	width: 50%;
	background: ${({theme}) => theme.colors.white};
	padding: 3.2rem 5.3rem;
	border-radius: 10px;
	@media only screen and (max-width: 1024px) {
		width: 100%;
		padding: 1rem;
	}
`;

const ScrollText = styled.div`
	font-weight: bold;
	font-size: 1.6rem;
	color: ${(props) => props.theme.colors.grey[500]};
	text-align: center;

	@media only screen and (max-width: 1024px) {
		font-weight: normal;
		text-align: center;
	}
`;

const FreeText = styled.span`
	color: ${(props) => props.theme.colors.blue[500]};
	margin: 0 5px;
`;

const StyledButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 2rem;
	margin-top: 25px;

	@media only screen and (max-width: 1024px) {
		margin: 3.2rem auto;
	}
`;

const ROW = styled(Flex)`
	align-items: center;
	justify-content: space-between;

	img {
		width: 72px;
		height: 72px;
		margin-right: 4.5rem;
		margin-bottom: 2rem;
		object-fit: cover;
		@media only screen and (max-width: 1024px) {
			width: 7rem;
			height: 7rem;
			margin-right: 1rem;
			margin-bottom: 1rem;
		}
	}
`;

const UserDetailsContainer = styled(Flex)`
	font-weight: 600;
	font-size: 36px;
	line-height: 150%;
	color: ${({theme}) => theme.colors.grey[400]};
	div {
		color: ${({theme}) => theme.colors.grey[600]};
	}
	@media only screen and (max-width: 1024px) {
		font-size: 2.4rem;
		margin-left: 2rem;
	}
`;

const Divider = styled(Flex)`
	width: 100%;
	height: 2px;
	background: ${({theme}) => theme.colors.grey[200]};
`;

const Value = styled(Flex)`
	font-weight: 500;
	font-size: 20px;
	margin: 2.4rem 0;
	color: ${({theme}) => theme.colors.grey[600]};
	@media only screen and (max-width: 1024px) {
		font-size: 1.6rem;
	}
`;

const Heading = styled(Flex)`
	font-weight: 500;
	line-height: 30px;
	font-size: 20px;
	color: ${({theme}) => theme.colors.grey[400]};
	width: 15rem;
	margin: 2.4rem 0;

	@media only screen and (max-width: 1024px) {
		font-size: 1.6rem;
		width: 10rem;
	}
`;

const ReportLink = styled(Flex)`
	background: #f9f9f9;
	border-radius: 1rem;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	line-height: 2.2rem;
	height: 100%;
	padding: 1.8rem;
	overflow: hidden;
	white-space: nowrap;
	font-weight: normal;
	font-size: 1.6rem;
	align-items: center;
	color: ${({theme}) => theme.colors.grey[400]};
	@media only screen and (max-width: 1024px) {
		padding: 0.8rem;
		font-size: 1.2rem;
		line-height: 1.8rem;
	}
`;

const CopyButton = styled(Button)`
	width: max-content;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	padding: 1rem 1.5rem;
	background-color: ${(props) => props.test?.test_configs?.theme?.primary_color || props.theme.colors.blue[500]};
	@media only screen and (max-width: 1024px) {
		padding: 0.8rem;
		height: auto;
		img {
			width: 1.4rem;
			height: 1.4rem;
		}
	}
`;
