// @ts-nocheck
import { Radio } from "antd";
import React, { useContext, useEffect } from "react";
import {
  addDimension,
  addScoringCode,
} from "../../reducer/actions";
import QuizzContext, { IQuizzBuilderContext } from "../../../QuizzContext";
import Creatable from "react-select/creatable";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "#fff" : "blue",
  }),
};

export default function DimentionsScoringCodeRadioInput(props: any) {
  const { dispatch } = useContext(QuizzContext) as IQuizzBuilderContext;
  useEffect(() => {
    if (!props.defaultValue) {
      dispatch(addScoringCode(props.id, "N"));
    }
  }, []);  

  return (
    <>
      <label htmlFor="dim">Select a Trait</label>
      <Creatable
        id="dim"
        onChange={(v) => {
          v && dispatch(addDimension(props.id, v.value));
          if (!props.dimOptions.includes(v)) {
            var arr = props.dimOptions;
            var o = { label: v.value, value: v.value };
            arr.push(o);
            props.setDimOptions(arr);
          }
        }}
        options={props.dimOptions}
        defaultInputValue={props?.defaultValue}
        defaultValue={props?.defaultValue}
        styles={customStyles}
      />

      <label style={{ marginTop: "20px", display: "block" }}>
        Select a Scoring Code
      </label>
      <div>
        <Radio.Group
          onChange={(v) => dispatch(addScoringCode(props.id, v.target.value))}
          defaultValue={props?.scoringCode || "N"}
        >
          <Radio value="N">Normal</Radio>
          <Radio value="R">Reverse</Radio>
        </Radio.Group>
      </div>
    </>
  );
}
