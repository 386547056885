import AskiitiansHackathonNavBar from "./AskiitiansHackthonNavBar";
import { Redirect, Route, Switch } from "react-router-dom";
import React, { useState } from "react";
import styled from "styled-components";
import Flex from "../components/Flex";
import RiasecTestReport from "./report_pages/RiasecTestReport";
import AskiitiansHackathonReportPage from "./AskiitiansHackathonReportPage";


export default function AskiitiansHackathonReport({ test, testId, isFetched }) {
    const [reportName, setReportName] = useState<any>();

    return (
        <MainContainer className="container">
            <AskiitiansHackathonNavBar setReportName={setReportName} />
            <Container>
                <Content>
                    <AskiitiansHackathonReportPage reportName={reportName} test={test} />
                </Content>
            </Container>
        </MainContainer>
    )
}

const MainContainer = styled.div`
     padding:0px;
     @media(max-width:992px){
      padding:0px !important;
   }
`;

const Container = styled(Flex)`
  height: calc(100vh - 80px);
`;

const Content = styled(Flex)`
  width: calc(100% - 256px);
  margin-left: 256px;
  padding:20px 25px;
  padding-right:0px;
  overflow: auto !important;
  background-color: ${(props) => props.theme.colors.grey[50]};
  
  @media(max-width:992px){
      width:100%;
      margin-left: 0px;
   }
   @media(max-width:768px){
      padding:0px;
   }
`;
