import React, { useState, useEffect, useCallback, useRef } from "react";
import "./Instructionquizmain.css";
import "./successpopbox.css";
import CustModal from "./CustModal";
import { Button, Empty, Form } from "antd";
import QuizzContext, {
  getDefaultContext,
  IQuizzContext
} from "../../../../../src/QuizzContext";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar"; 
import ClipLoader from "react-spinners/ClipLoader";
import renderHTML from 'react-render-html';
import UniversalQuizPopUp from "../../../../ui/components/popups/UniversalQuizPopUp";
import { API_ENDPOINT, LOCALHOST, PROCESSOR_URL, STRIDE_PROFILE_LINK, PROFILE_NAVIGATION_CTA, PROCESSOR_S3_URL } from "../../../../utils/constants";
const succssful_img = require("../../../../assets/Group 34421.png");
const animationData = require("../../../../assets/Check-mark-completed.json");
const failed_img = require("../../../../assets/failed_popup_dark_version.png")

const RenderOptions = ({question,options, ...props}:any) => {
  let optionValue = 64;
  const { selectedAnswers, setSelectValue, setChangeColor, handleOptionClick, changeColor, selectValue, type } = props;

  useEffect(() => {
    if(selectedAnswers[question.id]) {
      setSelectValue(selectedAnswers[question.id]);
      setChangeColor(true);
    }
  },[setSelectValue, setChangeColor, selectedAnswers, question.id]);

  const handleOptionClickLocal = useCallback((poQ, q, val, e) => {
    handleOptionClick(poQ, q, val, e);
  }, [handleOptionClick]);
  
  const renderOptions = options.map((perOptionQuiz, _index) => {
    optionValue = optionValue + 1;
    return (
      <button
        className="option_coponent_container_quiz "
        key={Math.random()}
        onClick={((e) => handleOptionClickLocal(perOptionQuiz, question, perOptionQuiz?.value, e))}
      >
        <div
          className={
            changeColor && selectValue === perOptionQuiz?.value
            ? "option_blue_part_container_color "
            : "option_blue_part_container"
          }
        >
          <h6
            className={
              changeColor &&
              selectValue === perOptionQuiz?.value
              ? "option_head_text_quiz_color"
              : "option_head_text_quiz "
            }
          >
            {String.fromCharCode(optionValue)}
          </h6>
          <div
            className={
              changeColor &&
              selectValue === perOptionQuiz?.value
              ? "option_white_part_container_color"
              : "option_white_part_container"
            }
          >
            <p className="option_ans_text_quiz">
              { (type === "ImageRadioButtons") ?
              <img style={{"maxHeight":"45px"}} src={`${PROCESSOR_S3_URL}${perOptionQuiz.image}`} alt={perOptionQuiz.value} /> : <>{perOptionQuiz.value}</>}
            </p>
          </div>
        </div>
      </button>
    );
  })
      
  if(options) {
    return (
      <>
        {options.length > 0 ? renderOptions : null}
      </>
    );
  }
    
  return null;
}

const RenderQuestions = ({questions, ...props}) => {
  const {
    setContainerType,
    questionNo,
    actualQuestionsCollection,
    setActualQuestionNo,
    totalActualQuestions,
    selectedAnswers,
    setSelectValue,
    setChangeColor,
    handleOptionClick,
    changeColor,
    selectValue
  } = props;
  const [questionNumber, setQuestionNumber] = useState(0);

  useEffect(() => {
    setContainerType(questions[questionNo - 1]?.element);

    if(questions[questionNo - 1]?.element != "LabelText"){
      let questionId = questions[questionNo - 1].id as string;
      //@ts-ignore
      const newQN = actualQuestionsCollection.indexOf(questionId) + 1
      setQuestionNumber(newQN);
      setActualQuestionNo(newQN);
    }
  }, [questions, questionNo, actualQuestionsCollection, setContainerType]);
  
  return (
    <>
    { questions.length > 0 ? 
      <div key={Math.random()}>
        { questions[questionNo - 1]?.element != "LabelText" ?   
        <div className="question_head_timer_container">
          <h6 className="question_head_container">
            Question 
            <span className="yellow_quiz_text">{questionNumber}</span>
            <span className="out_of_text_quiz">/{totalActualQuestions}</span>
          </h6>
        </div> 
        : 
        <div className="question_head_timer_container1" style={{visibility: 'hidden'}}>
          <h6 className="question_head_container">
            Question 
            <span className="yellow_quiz_text">{questionNumber}</span>
            <span className="out_of_text_quiz">/{totalActualQuestions}</span>
          </h6>
        </div>} 
        <div className="question_title_quiz"> 
          {renderHTML(questions[questionNo - 1]?.questions.en)}
        </div>
        
        { questions[questionNo - 1]?.element != "LabelText" ? 
          <div className="option_container_main_quiz">
          { questions[questionNo - 1]?.options && questions[questionNo - 1]?.options != null ?  
            <RenderOptions 
              question={questions[questionNo - 1]} 
              options={questions[questionNo - 1]?.options}
              selectedAnswers={selectedAnswers} 
              setSelectValue={setSelectValue} 
              setChangeColor={setChangeColor} 
              handleOptionClick={handleOptionClick} 
              changeColor={changeColor} 
              selectValue={selectValue}
              type={questions[questionNo - 1]?.element}
            /> : null
          }
          </div> : null
        }
      </div> : null
    }
    </>
  );
}

function InstructionQuizMain(props) {
  const [changeColor, setChangeColor] = useState(false);
  const [selectValue, setSelectValue] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questionNo, setQuestionNo] = useState(1);
  const [renderPopUp, setRenderPopUp] = useState(false);
  const [submitNow, setSubmitNow] = useState(false);
  const [finalQuestionNo, setFinalQuestionNo] = useState(1);
  const [actualQuestionsCollection, setActualQuestionsCollection] = useState([]);
  const [actualQuestionNo, setActualQuestionNo] = useState(0);
  const [totalActualQuestions, setTotalActualQuestions] = useState(0);
  const [resultType, setResultType] = useState("passed")
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [quizStartTime, setQuizStartTime] = useState(null) as any;
  const [quizEndTime, setQuizEndTime] = useState(null) as any;
  const [selectedAnswers,setSelectedAnswers] = useState({})
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [pathName, setPathName] = useState( window.location.pathname)
  const [id, setId] = useState(pathName.split("/")[pathName.split("/").length - 1])
  const { form, data, submitButton, ...rest } = props
  const  contextValue = getDefaultContext(rest as IQuizzContext);
  const [reportId, setReportId] = useState(null);
  let [loading, setLoading] = useState(true);
  let [loaderVisible, setLoaderVisible] = useState(true);
  const [containerType, setContainerType] = useState("LabelText");
  const selectedAnswersProxy = useRef(selectedAnswers);
  
  let questionNumber = 0;
  const [isAppFlow, setIsAppFlow] = useState(false);

  useEffect(() => {
    // get params from query string
    const urlParams = new URLSearchParams(window.location.search);
    const flow = urlParams.get('flow');
    if (flow === 'app') {
        setIsAppFlow(true);
    }
  }, []);

  useEffect(() => {
    // handleLoadingScreen();
    setQuizStartTime(new Date())
  },[])

  useEffect(() => {
    if(props.timeUp){
      setQuizEndTime(new Date())
      handleQuizSubmit()
    }

  },[props?.timeUp])

  useEffect(() => {
  },[containerType])
    
  useEffect(() => {
    setTotalActualQuestions(actualQuestionsCollection.length)
  },[actualQuestionsCollection])
    
  useEffect(() => {
    if(data.length > 0){
      let questions = [] as any;
      data.forEach(function(item:any){
        if(item?.element != "LabelText"){ 
          questions.push(item?.id)
        }
      })
      setActualQuestionsCollection(questions);
      setTotalQuestions(data.length);
    }
  },[data]);


  useEffect(() => {
    selectedAnswersProxy.current = selectedAnswers;
  }, [selectedAnswers, selectedAnswersProxy]);
  
  
  const handleQuizSubmit = useCallback(() => {
    setLoading(true)
    setLoaderVisible((v) => !v)
    setQuizEndTime(new Date());
    const answers = selectedAnswersProxy.current;
    let endTime = new Date() as any;
    if(quizEndTime != null){
      endTime = quizEndTime;
    }
    let timeTaken : any = endTime - quizStartTime; 
    timeTaken = new Date(timeTaken).toISOString().substr(11, 8);
    let formData = {
      "quiz_id": id,
      "quiz_response": answers,
      "time_taken": timeTaken
    }
    fetch(`${API_ENDPOINT}quiz/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData),
      credentials: "include"
    })
    .then(res=>{
      if(res.status === 200){
        return res.json()
      }
      else{
        return Promise.reject(500)
      }
    })
    .then(data=>{
      setReportId(data.data.data)
      handleProceed(data.data.data)
    })
    .catch(err=>{
      alert("Failed to submit the quiz. Please try again.")
    })
  }, [quizStartTime, quizEndTime, id, selectedAnswersProxy]);
  
  
  const handleNext = useCallback(()=> {
    const value = questionNo + 1;      
    // setActualQuestionNo(value)
    if(value <= totalQuestions){
      setQuestionNo(value)
      setSelectValue(null)
      setChangeColor(false)
      
      if (value > finalQuestionNo){
        setFinalQuestionNo(value)
      }
    }else{
      if(props.sampleQuestion){
        props.onSubmit();
        return
      }
      if(window.parent.location == window.location){
        handleQuizSubmit()
      }else{
        setSubmitNow(true)
        window.parent.postMessage(JSON.stringify({code: "show popup"}), "*");
      }
    }
  }, [questionNo, totalQuestions, finalQuestionNo, props.sampleQuestion, props.onSubmit, handleQuizSubmit]);

  const getPercentage = useCallback((): any=>{
    if(renderPopUp || questionNo === totalQuestions){
      return 99
    }
    return (((questionNo) / totalQuestions) * 100).toFixed(0)
  }, [renderPopUp, questionNo, totalQuestions]);

  const handlePrev = useCallback(()=>{
    const value = questionNo - 1
    if(value>=1){
      setQuestionNo(value);
    }
  }, [questionNo]);

  const onAnswerSelect =  useCallback(async (e) => {
    e.stopPropagation()
    await (new Promise((resolve, _reject) => setTimeout(resolve, 500)));
    // const { form } = props;
    handleNext();
  }, [props, handleNext]);

    
  const handleOptionClick = useCallback((option, question, value, e) => {
    if (value === selectValue || selectValue === null) {
      setChangeColor(!changeColor);
    }
    setSelectValue(value);
    // let objectToStore = {
    //   [question.id] : option.value
    // };
    setSelectedAnswers({...selectedAnswers, [question.id] : option.value});
    onAnswerSelect(e);
  }, [selectValue, changeColor, onAnswerSelect, selectedAnswers]);
        
  const handleProceed = (reportIdReceived) => {
    fetch(`${API_ENDPOINT}quiz/report/${reportIdReceived}`, {credentials: "include"})
      .then(res=>res.json())
      .then(data=>{
        if(data?.data?.content?.score <= 50){
          setResultType("failed")
        }else{
          setResultType("passed")
        }
        if(data?.data?.content?.correct_answers_count){
          setCorrectAnswer(data.data?.content?.correct_answers_count)
        }
        setLoading(false)
        setLoaderVisible(!loaderVisible)
        setIsModalOpen(true)
        
      })
      .catch(err=>{
        console.log("Failed to get report data. Please try again.")
      });
  }
        
  return (
    <>            
      <div className="react-quizzes-quizz">
        {
          props.quizTemplateName === 'aptitude-quiz' && !props.sampleQuestion ? 
          <>
            <div 
              style={{
                width: "100px",
                position: "absolute",
                top: "0",
                left: "50%",
                transform: "translate(-50%, -50%)",
                boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
                borderRadius: "50%",
              }}
            >
              <CircularProgressbar 
                background={true}
                value={parseInt(getPercentage())}
                text={`${parseInt(getPercentage())}%`}
                backgroundPadding={6}
                styles={{
                  path: {
                    color: `#ffffff`,
                    strokeLinecap: 'round',
                    stroke: "#1273B7"
                  },
                  text: {
                    fill: "#333333",
                    fontSize: '24px',
                    fontWeight: 500,
                    fontFamily: "Poppins"
                  },
                  trail: {
                    stroke: "#ffffff"
                    
                  },
                  background: {
                    fill: "#ffffff"
                  },
                }}
              />
            </div>
            <div className={containerType != "LabelText" ? "quiz_blue_container" : "orange_label_container"}>
            {data.length > 0 ? 
              <RenderQuestions 
                questions={data}
                setContainerType={setContainerType}
                questionNo={questionNo}
                actualQuestionsCollection={actualQuestionsCollection}
                setActualQuestionNo={setActualQuestionNo}
                totalActualQuestions={totalActualQuestions}
                selectedAnswers={selectedAnswers}
                setSelectValue={setSelectValue}
                setChangeColor={setChangeColor}
                handleOptionClick={(o, q, v, e) => handleOptionClick(o, q, v, e)}
                changeColor={changeColor}
                selectValue={selectValue}
              /> : null}
              {/* {questions}             */}
              <div className="next_btn_container">
                { questionNo === 1 ?  
                  <button className={containerType != "LabelText" ? "intro_orange_btn_option" : "orange_label_next_btn"} onClick={()=> handleNext()}>
                    Next
                  </button> : null
                }
                { data.length > questionNo &&  questionNo > 1 ? 
                  <>
                    <button
                      className={containerType != "LabelText" ? "prev_btn_quiz same_btn_quiz" : "orange_label_prev_btn_quiz same_btn_quiz"}
                      onClick={handlePrev}
                    >
                      Prev
                    </button>
                    <button
                    className={containerType != "LabelText" ? "next_btn_quiz same_btn_quiz" : "orange_label_next_btn_quiz same_btn_quiz"}
                    onClick={handleNext}
                    >
                      Next
                    </button>
                  </> : null 
                }
                { data.length === questionNo ?  
                  <button className={containerType != "LabelText" ? "intro_orange_btn_option" : ""} onClick={()=> handleQuizSubmit()}>
                    {loaderVisible ? "Submit" :  <ClipLoader color="white" loading={loading} size={30} />}
                  </button> : null
                }
                <CustModal
                  hideCloseButton={true}
                  open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                >
                  <div className="pop_box_sucessfull_container">
                    <div className="sucessfull_img_container">
                      <img
                        src={resultType === "passed" ? succssful_img.default : failed_img.default}
                        alt={resultType === "passed" ? "sucessfull_img_" : "failed_img"}
                        className="sucessfull_img_self"
                      />
                    </div>
                    <h2 className="test_completed_text">Test Completed</h2>
                    {correctAnswer ?
                      <p className="text_succes_extra_content">
                        You attempted{" "}
                        <span className="sucess_extra_content_highlight">
                          {totalActualQuestions} questions
                        </span>{" "}
                        and from that{" "}
                        <span className="ans_extra_conetent_yellow">{correctAnswer} answers</span>{" "}
                        are correct
                      </p> :
                      <p className="text_succes_extra_content">
                        Your report is being generated, and will be ready shortly
                      </p>
                    }
                    <div className="success-btn-wrapper" style={{ justifyContent: 'center', gap: '15px' }}>
                      <button
                        className="done_btn"
                        onClick={() => {
                          setIsModalOpen(false)
                          window.location.replace(`${LOCALHOST}quiz/report/${reportId}`)
                          const report_url = `${LOCALHOST}quiz/report/${reportId}${window.location.search}`
                          const callback_url = `${API_ENDPOINT}quiz/report/${reportId}`
                          window.parent.postMessage(JSON.stringify(
                            { code: "quiz_submitted", "report_url": report_url, callback_url: callback_url, report_id: reportId }),
                             "*"
                          );
                        }}
                      >
                        VIEW REPORT
                      </button>
                      {isAppFlow &&
                      <button
                        className="profile_navigation_btn"
                        onClick={() => {
                          setIsModalOpen(false)
                          window.location.replace(`${STRIDE_PROFILE_LINK}`)
                        }}
                      >
                        {PROFILE_NAVIGATION_CTA}
                      </button>}
                    </div>
                  </div>
                </CustModal>
              </div>
            </div>
          </>
          : null
        }
      </div>
    </>
  );
}


export default InstructionQuizMain;
