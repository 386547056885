import React from 'react';
import useCards from '../../hooks/useCards';
import { Container } from '../components/Container';
import { CountCard, CountContainer } from '../components/CountCard';

const AdminHome = () => {
  const { data: cards } = useCards();

  // const [dateRange, setDateRange] = useState<string[]>();

  return (
    <Container column>
      {/* <Header align='center' justify='space-between'>
        <DateRangeInput
          onChange={(range) => setDateRange(range)}
          value={dateRange}
        />
      </Header> */}
      <CountContainer style={{ margin: '3rem 0' }}>
        <CountCard column align='center' justify='center'>
          Total Reports
          <span className='count'>{cards?.report_count || '-'}</span>
        </CountCard>
        <CountCard column align='center' justify='center' color='green'>
          Total Tests
          <span className='count'>{cards?.test_count || '-'}</span>
        </CountCard>
        <CountCard column align='center' justify='center'>
          Average Rating
          <span className='count'>{cards?.average_rating || '-'}</span>
        </CountCard>
      </CountContainer>
    </Container>
  );
};

export default AdminHome;
