import React, { useRef } from "react";

export default function SampleQuestionExplainationInput(props){
    return (
    <div
    style={{
        marginTop: "10px",
    }}>
        <label>
            Explain why?
        </label>
        <br />
        <input
        value={props.defaultValue ? props.defaultValue : ""}
        onChange={(e)=>{
            props.onChange(e);
        }}
        type="text"
        />
    </div>
    )
}