import React from "react";
import "./ModalContainer.css";
interface Props {
  children: any;
  setOpen(data: any): any;
}
function ModalContainer({ children, setOpen }: Props) {
  return (
    <div className="modalContainer" onClick={() => setOpen(0)}>
      {children}
    </div>
  );
}

export default ModalContainer;
