import React from 'react'
import DummyProfileImage from "../../../assets/images/dummy-profile.png";

export default function PromiseSection() {
  return (
    <>
    <div className="our-promise-section">
    <div className="container-md-2">
      <div className="page-title">
        <h2>Our Promise</h2>
      </div>
      <div className="our-grid">
        <div className="our-grid-items">
          <div className="profile-image">
            <img src={"https://navigate.strideahead.in/static/igniter_build/static/media/Piyush.8330f970b7652df660a4.jpg"} alt="DummyProfileImage" />
          </div>
          <div className="profile-details">
            <p>Piyush Gupta</p>
            <span>
            Co-founder Stride Ahead
            </span>
          </div>
        </div>
        <div className="our-grid-items">
          <div className="child-text-style">
            <p>
              We are aware of how difficult it can be to land your
              first professional job after graduating. We are here
              to help in this situation. For many years, we have
              helped inspire and mentor young people from all over
              the world as they take their first career move.
            </p>
            <p>
              We can assist you in this process as well. Stride
              Ahead is a comprehensive platform that guarantees to
              hold your hand throughout your career, from the moment
              you delve deep into finding a aptitude for yourself to
              the moment you land the job of your dreams.
            </p>
            <p>
              We guarantee that our MAP Cohort will prepare
              you for the job market and allow you to fully enter
              into the management field.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}
