import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT, PAGINATION_LIMIT } from '../utils/constants';
import { APIError, PaginationData, Report } from '../utils/types';


const useReportList = (
  testId?: number,
  page = 1,
  search?: string,
  from_?: string,
  to?: string,
  report_status?:number,
  limit = PAGINATION_LIMIT,
) => {
  const fetchReportList = async (
    page: number,
    limit: number,
    testId?: number,
    search?: string,
    from_?: string,
    to?: string,
    report_status?:number
  ) => {
    try {
      const {
        data: { data },
      } = await axios.get(`${API_ENDPOINT}test/${testId}/report`, {
        params: { page, limit, search, from_, to,
          report_status 
        },
      });
      return data;
    } catch (error) {
      throw error.response.data;
    }
  };

  return useQuery<
    {
      reports: Report[];
      pagination_data: PaginationData;
      test_display_id: string;
      owner_name: string;
    },
    AxiosResponse<APIError>
  >(
    ['reportList', testId, page, search, from_, to,
    report_status
  ],
    () => fetchReportList(page, limit, testId, search, from_, to, 
      report_status
      ),
    {
      retry: false,
    }
  );
};

export default useReportList;
