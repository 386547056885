import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DimensionCard, ScoreCard } from './dimensionQuizReportUtils';
import { useParams } from 'react-router-dom';
import { useAnalysisReport } from '../../../hooks/useAnalysisReport';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// @ts-ignore
import GaugeChart from 'react-gauge-chart'
import Laughing from "../../../assets/images/feedback_icon/laughing.svg"
import Smile from "../../../assets/images/feedback_icon/smile.svg"
import Neutral from "../../../assets/images/feedback_icon/neutral.svg"
import Frown from "../../../assets/images/feedback_icon/frown.svg"
import Angry from "../../../assets/images/feedback_icon/angry.svg"
import ShareIcon from "../../assets/images/share.svg"
import StrideLogo from '../../../assets/images/HeaderLogo.svg'
import BannerImg from '../../../assets/images/Bannerimg.png'
import Aptitude from '../../../assets/images/Aptitude.svg'
import Aptitude2 from '../../../assets/images/Aptitude2.svg'
import Toast from '../../components/Toast';
import moment from 'moment';
import { API_ENDPOINT } from '../../../utils/constants';
import './DimensionsTestReport.css'
import RadarDiagram2 from './RadarDiagram2';
import styled from "styled-components";
import { fixRequestBody } from "http-proxy-middleware";
import { REPORT_PAGE_URL } from "../../../utils/constants";
import axios from "axios";
import CalculatingPage from '../CalculatingPage';

const iconBox = () => {
    return (
        <div className="icon_image">
            <img src={Aptitude} alt='iconImage' />
        </div>
    )
}
const iconBox2 = () => {
    return (
        <div className="icon_image_accordion">
            <img src={Aptitude2} alt='iconImage' />
        </div>
    )
}
const scoreCapsule = (score) => {
    if (score > 75) {
        return (
            <div className='bigCapsule capsule-high'>
                High
            </div>
        )
    } else if (score > 35 && score < 76) {
        return (
            <div className="bigCapsule capsule-mid">
                Medium
            </div>
        )
    } else return (
        <div className='bigCapsule capsule-low'>
            Low
        </div>
    )
}

const icon = require('../../../assets/test/dim_quiz_report_icon.png')
const blueIcon = require('../../../assets/test/dim_quiz_report_icon_blue.png')

// let gaugeColor = '';

export default function AptituteTestReportPage({ test, testId, isFetched }) {
    const { token } = useParams<{ token: string }>();
    const [rating, setRating] = useState('');
    const [feedBack, setFeedBack] = useState<boolean>(false);
    const [selected, setSelected] = useState(0)
    const [showFeedBack, setShowFeedBack] = useState<boolean>();
    const [gaugeColor, setGaugeColor] = useState('')
    const [comment, setComment] = useState<string>('');
    const [hideFeedBack, setHideFeedBack] = useState<boolean>()
    const [toastText, setToastText] = useState<string>("");
    const [showToast, setShowToast] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);
    const [feedbackToggle, setFeedbackToggle] = useState(false)
    // Todo : Toggle function for the Accordion Component
    const toogle = (index: any) => {
        setSelected(index)
    }

    const customLogo = test?.logo_image;
    const { data, data: reportMeta } = useAnalysisReport(token);

    const reportData = reportMeta?.report_metadata;


    useEffect(() => {
        if (test) {
            console.log('test 109', test)

        }
    })

    // useEffect(() => {
    //     console.log('hideFeedBack', hideFeedBack)
    // }, [hideFeedBack])

    // useEffect(() => {
    //     console.log('showFeedBack1', showFeedBack)
    // }, [showFeedBack])



    useEffect(() => {
        if (data) {
            //@ts-ignore
            setShowFeedBack(data?.feedback_shared)
            console.log('line 124', showFeedBack)
        }

    }, [data])

    console.log('data', data)
    //@ts-ignore
    const reportDate = moment(data?.report_date).format('Do MMMM YYYY')


    // //Gauge Chart
    useEffect(() => {
        if (reportData?.percentage) {
            if (reportData?.percentage >= 76) {
                setGaugeColor("#24A84A");
                setGaugeColor("#24A84A");
            } else if (reportData?.percentage > 35 && reportData?.percentage <= 75) {
                setGaugeColor("#FCAE17");
            } else {
                setGaugeColor("#EE3323");
            }
        }
    }, [reportData?.percentage]);

    useEffect(() => {
        // Simulate loading data from an API
        setTimeout(() => {
            setIsLoading(false);
        }, 5100);
    }, []);

    const FeedBackSend = async () => {
        const feedBackData = {
            name: data?.name,
            email: data?.email,
            //@ts-ignore
            report_id: data?.id,
            rating: rating,
            comment: comment
        }
        try {
            const data = await axios.post(`${API_ENDPOINT}feedback/${testId}`, feedBackData)
            console.log('data', data)
            if (data?.data?.success) {
                setSuccess(true);
                setShowToast(true);
                setToastText(data?.data?.message);
                setShowFeedBack(true);

            } else {
                setSuccess(false);
                setShowToast(true);
                setToastText("Fail To Share Your FeedBack Message, Please Try Again")
            }
        } catch (error) {
            setSuccess(false);
            setShowToast(true);
            setToastText(error?.message)
        }

    }


    // Todo : Function Returning the circular progressBar
    const cirleProgress = (percentage) => {

        let setColor = "#000"
        if (percentage > 76) {
            setColor = "#24A84A";
            setColor = "#24A84A";
        } else if (percentage > 35 && percentage <= 75) {
            setColor = "#FCAE17";
        } else {
            setColor = "#EE3323"
        }
        return (
            <div className='circle-container'>
                <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                        rotation: 0.85,
                        strokeLinecap: 'round',
                        textSize: '22px',
                        pathTransitionDuration: 0.8,
                        pathColor: `${setColor}`,
                        textColor: '#192A46',
                        trailColor: '#d6d6d6',
                    })}
                />
            </div>
        )
    }

    return (
        <>
            {isLoading
                ?
                ""
                // <CalculatingPage />
                :
                <div className=" container MainContainer1">
                    <div className='main-container '>
                        <header className='strideLogo'>
                            <img src={StrideLogo} alt="StrideLogo" />
                        </header>
                        <div className="assessment_details">
                            <div className="left-container">
                                <h2>{test?.test_name}</h2>
                                <h3>{data?.name}</h3>
                                <hr id='line' />
                                <div className="assessment_details-extend">
                                    <h4 className='ade-text'>Test ID : <span className='datas'>{testId}</span></h4>
                                    {reportDate === null || reportDate === undefined || reportDate === "" ?
                                        "" : <h4 className='ade-text'>Test Date : <span
                                            className='datas'>{reportDate}</span></h4>
                                    }

                                    {test.test_time === null || test.test_time === undefined || test.test_time === "" ?
                                        "" : <h4 className='ade-text'>Allotted Time : <span
                                            className='datas'> {test?.allotted_time ?? "--"}</span></h4>

                                    }
                                    {test.time_taken === null || test.time_taken === undefined || test.time_taken === "" ?
                                        "" : <h4 className='ade-text'>Time taken to Complete : <span
                                            className='datas'> {reportData?.time_taken_to_complete ?? "--"}</span></h4>
                                    }
                                </div>
                            </div>
                            <div className="right-container">
                                {/* Your score is : {reportData?.percentage} */}
                                {/*<GaugeChart*/}
                                {/*    id="gauge-chart1"*/}
                                {/*    nrOfLevels={420}*/}
                                {/*    arcsLength={[0.3, 0.4, 0.3]}*/}
                                {/*    colors={['#EE3323', '#FCAE17', '#24A84A']}*/}
                                {/*    percent={reportData?.percentage / 100}*/}
                                {/*    animateDuration={2000}*/}
                                {/*    arcPadding={0.02}*/}
                                {/*    needleColor="#1d2532c8"*/}
                                {/*    textColor='#192A46'*/}
                                {/*    // style={*/}
                                {/*    //     {*/}
                                {/*    //         marginRight:`${window.innerWidth > 768 ?? "-180"}`*/}
                                {/*    //     }*/}
                                {/*    // }*/}
                                {/*/>*/}
                                <GaugeChart1
                                    //@ts-ignore
                                    style={{ "--percentage": `${reportData?.percentage}`, "--fill": `${gaugeColor}` }}
                                > <GaugeDiv>
                                        <GaugeChartPercentage>{reportData?.percentage}<Sub style={{ color: "#8E9EBA" }}>%</Sub></GaugeChartPercentage>
                                        <GougeChartScore>Your Score</GougeChartScore>
                                    </GaugeDiv>
                                </GaugeChart1>
                            </div>
                        </div>
                        <hr id='line2' />
                        <div className='section2'>


                            <h2>{test?.test_display_label}</h2>
                            <p className='definition'>{test?.test_type_description}</p>
                        </div>
                        <hr id='line2' />
                        <div className="section3">
                            <h2>Skill Profile - An Overview</h2>
                            <p className='definition'>This chart gives an overview of your performance in each of
                                the {reportData?.dimensions?.length} dimensions of {test?.test_display_label}</p>

                            <div className="radar-container">
                                <RadarDiagram2 />
                                {/* <RadarNew /> */}
                            </div>

                            <div className='radar-desc'>
                                <div className="average">
                                    <div className="hex" id='avg'></div>
                                    <span className='radarNotation'>Average</span>
                                </div>
                                <div className="top-candidates">
                                    <div className="hex" id='topp'></div>
                                    <span className='radarNotation'>Top Candidates</span>
                                </div>
                                <div className="your-performance">
                                    <div className="hex" id='yourp'></div>
                                    <span className='radarNotation'>Your Performance</span>
                                </div>
                            </div>

                        </div>
                        <hr id='line2' />

                        <div className="section4">
                            <h2>Score Indicator</h2>
                            <p className='definition'>Your score is put into a specific category as per the following
                                criteria</p>
                            <div className="indicators row gap-lg-5 m-0">
                                <div className="block1 col-lg-3 col-md-8">
                                    <span className='dot' id="lowDot"></span>
                                    <h4>Low (0 - 35%)</h4>
                                </div>
                                <div className="block2 col-lg-3 col-md-8">
                                    <span className='dot' id="midDot"></span>
                                    <h4>Medium (36 - 75%)</h4>

                                </div>
                                <div className="block3 col-lg-3 col-md-8">
                                    <span className='dot' id="highDot"></span>
                                    <h4>High (76 - 100%)</h4>

                                </div>
                            </div>
                        </div>

                        <hr id='line2' />

                        <div className="section5">
                            <h2>Your Absolute Strengths</h2>
                            <p className='definition'>Areas where you excel at</p>
                            <div className='containers'>
                                <div className="row w-100">
                                    {
                                        reportData?.dimensions?.sort((a, b) => b.percentage - a.percentage)?.filter((value) => value?.percentage > 75).map((item, index) => (
                                            <div className="col-lg-6 flex-grow-1">
                                                <div className="right_container w-100">
                                                    <div className="heading">
                                                        <div className='left_portion'>
                                                            {iconBox()}
                                                            <div id='trait'>
                                                                <h4>{item?.name}</h4>
                                                                <span id='capsule'>High</span>
                                                            </div>
                                                        </div>
                                                        <div className='right_portion'>
                                                            <div className='circle_'>
                                                                {cirleProgress(item.percentage)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className='definition2'>{item.definition}</p>
                                                    <h5>This means that</h5>
                                                    <ul>
                                                        {item?.more_details?.map((items: any, index: any) => (
                                                            <li
                                                                key={index}
                                                            >{items}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <hr id='line2' />

                        <div className="section6">
                            <h2>Your Overall Strengths and Improvement Areas</h2>
                            <p className='definitionSmall'>Detailed breakdown of your performance in each of the dimensions of
                                {test?.test_display_label}</p>

                            <div className='accordion'>
                                {
                                    reportData?.dimensions?.sort((a, b) => b.percentage - a.percentage)?.filter((value) => value?.percentage < 76)?.map((item, index) => (
                                        <div className='item' key={index} onClick={() => toogle(index)}>
                                            <div
                                                className={selected !== index ? 'accordion-closed' :
                                                    'accordion-hide'}>
                                                <div className="inlineBlock">
                                                    <div className="left-part">
                                                        {iconBox()}
                                                        <h3 id='accordion-title'>{item.name}</h3>
                                                    </div>
                                                    <div className="right-part2">
                                                        <h3 id='accordion-score'>Your score is : <span
                                                            id='accordion-percentage'>{item.percentage}%</span></h3>
                                                        <div className='capsule-container'>
                                                            {scoreCapsule(item.percentage)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={
                                                selected === index ? 'accordion-show ' :
                                                    'accordion-hide'}>
                                                <div className="inlineBlock2">
                                                    <div className="left-part">
                                                        {iconBox2()}
                                                        <div className='left-inside'>
                                                            <h3 id='accordion-title'>{item?.name}</h3>
                                                            <div id='accordion-capsule'>{scoreCapsule(item?.percentage)}</div>
                                                        </div>
                                                    </div>
                                                    <div className="right-part">
                                                        <div className='circle_accordion'>
                                                            {cirleProgress(item?.percentage)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-content">
                                                    <p id='accordion-p'>{item?.definition}</p>
                                                    <h5>This means that</h5>
                                                    <ul>
                                                        {item?.more_details?.map((items: any, index: any) => (
                                                            <li
                                                                key={index}
                                                            >{items}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    )
                                }
                            </div>

                        </div>
                        <hr id='line2' />

                        <div className="section7">
                            <h2>Tips To Improve</h2>
                            <p id='improveList'>These are the areas where you need significant improvement.</p>
                            <ul id='improvement'>
                                {reportData?.tips_to_improve?.map((item: any, index: any) => (
                                    <li
                                        key={index}
                                    >{item}</li>
                                ))}
                            </ul>

                            <div className="bannerImage">

                                <div className="bannerLeft">
                                    <h2 id='bannerTxt'>Don’t delay and take the next step now!</h2>
                                    <p className='definition3' id='bannerPara'>Book a session with our expert mentors today and
                                        get a systematic career pathway tailored for you to stride ahead!</p>
                                    <a href={REPORT_PAGE_URL}>
                                        <button id='bannerBtn'>Get started right away!</button>
                                    </a>
                                </div>
                                <div className="bannerRight">
                                    <img src={BannerImg} alt="bannerImg" className='bannerImg' />
                                </div>


                            </div>

                            <h3>Disclaimer</h3>
                            <p className='definition3'>Psychometric tests are based on highly standardized methodology and
                                scientific rigor, which makes them extremely generalizable, however, in no way does it provide
                                an exhaustive persona around a person nor do we claim absolute reliability, the test has its
                                limitations to define a broad concept like personality.</p>
                            <p className='definition3' id="lastPara">The results thus obtained are for an overall understanding
                                of the self to facilitate better decision-making. For increasing the validity and further
                                consideration of dimensional factors, a mentor consultation for interpreting the assessment and
                                specifying career pathways is recommended.</p>

                        </div>
                        {/* {hideFeedBack && ()
                        
                        
                        } */}
                        {/* {showFeedBack === false ? <p>show</p> : <p>hide</p>} */}

                        {(
                            <>
                                {!showFeedBack ? (!feedBack ?
                                    <FeedbackDiv className="text-center">
                                        <h2>Feel free to drop us your feedback</h2>
                                        <p>How was your overall experience?</p>
                                        <RatingDiv>
                                            <img src={Laughing} onClick={() => {
                                                setRating('5');
                                                // setFeedbackToggle(true)
                                                setFeedBack(true);

                                            }} />
                                            <img src={Smile} onClick={() => {
                                                setRating('4');
                                                setFeedBack(true);
                                            }} />
                                            <img src={Neutral} onClick={() => {
                                                setRating('3');
                                                setFeedBack(true);
                                            }} />
                                            <img src={Frown} onClick={() => {
                                                setRating('2');
                                                setFeedBack(true);
                                            }} />
                                            <img src={Angry} onClick={() => {
                                                setRating('1');
                                                setFeedBack(true);
                                            }} />
                                        </RatingDiv>
                                    </FeedbackDiv>
                                    :
                                    <FeedBackMessageDiv>
                                        <h2 style={{ marginTop: "0px" }}>How can we improve?</h2>
                                        <MessageInput>
                                            <div className="form-floating">
                                                <textarea className="form-control" value={comment}
                                                    onChange={(e: any) => setComment(e.target.value)}
                                                    placeholder="Write your feedback"
                                                    id="floatingTextarea"></textarea>
                                                <label htmlFor="floatingTextarea">Write your feedback</label>
                                            </div>
                                        </MessageInput>
                                        <FeedBackButton onClick={() => {
                                            FeedBackSend();
                                            setFeedBack(false);
                                        }}>Share feedback</FeedBackButton>
                                    </FeedBackMessageDiv>
                                ) : <></>}
                            </>
                        )}

                        {
                            showToast &&
                            <Toast onClose={(showToast) => setShowToast(showToast)} success={success}>
                                {toastText}
                            </Toast>
                        }


                        <hr id='line2' />

                        <div className="copyright">
                            <div className='left_portion'>
                                <span>© copyright 2022 | Stride Ahead. All rights reserved.</span>
                            </div>
                            <div className='right_portion'>
                                info@strideshead.in
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    );
}


const GaugeChart1 = styled.div`
  width: 300px;
  height: 150px;
  position: relative;
  text-align: center;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  border-radius: 150px 150px 0 0;
  overflow: hidden;
  color: var(--fill);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing : border-box;
  
  @media(min-width:769px){
  width:400px;
  height:200px;
}

  &:before, &:after{
    content: '';
    width: 300px;
    height: 150px;
    border:50px solid var(--fill);
    border-top: none;
    position: absolute;
    transform-origin :50%  0% 0;
    border-radius: 0 0 300px 300px ;
    box-sizing : border-box;
    left: 0;
    top: 100%;
    
    @media(min-width:769px) and (max-width:992px){
     width:400px;
     height:200px;
}
    
     @media(min-width:993px){
     width:400px;
     height:200px;
}

  }
  &:before {
    border-color:rgba(0,0,0,.15);
    transform: rotate(180deg);
  }
  &:after{
      z-index: 3;
     animation : 1s fillGraphAnimation ease-in;
    transform: rotate( calc( 1deg * ( var(--percentage) * 1.8 ) ) );
  }
  &:hover{
     &:after{
        opacity: .8;
        cursor: pointer;
      }
  }
}


@keyframes fillAnimation{
  0%{transform : rotate(-45deg);}
  50%{transform: rotate(135deg);}
}

@keyframes fillGraphAnimation{
  0%{transform: rotate(0deg);}
  50%{transform: rotate(180deg);}
}
`;

const FeedBackMessageDiv = styled.div`
    background: transparent ;
    border-radius: 10px;
    padding:30px;
@media(max-width:768px){
   padding:15px;
  }
`;
const MessageInput = styled.div`

  textarea{
    resize: none;
    background: transparent ;
    height:100px !important;
    border: 1px solid rgba(29, 43, 53, 0.4) !important;
    border-radius: 10px !important;
    padding-top: 20px !important;
    font-size:14px;
}
  }
  label{
    color: #1d2b35d6;
    font-weight: 400;
    font-size: 16px;
@media(max-width:768px){
   font-size: 12px;
}    
  }
  .form-control:focus{
    background: transparent ;
   border: 1px solid rgba(29, 43, 53, 0.4) !important;
   border-radius: 10px !important;
  }

`;
const FeedBackButton = styled.button`
      background: #1273B7;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      border:none;
      padding: 13px 21px;
      color: #FFFFFF;
      margin-top:30px;
@media(max-width:768px){
   font-size: 12px;
   margin-top:15px;
   padding: 8px 15px;
}     
`;

const FeedbackDiv = styled.div`
   width:100%;
   background: #f9f9f9;
   border-radius: 10px;
   padding-top: 10px;
   padding-bottom:30px; 
   p{
   font-weight: 600;
   font-size: 16px;
   line-height: 27px;
   color: #4F4F4F;
@media(max-width:768px){
   font-size: 14px;
   line-height: 20px;
  }
}
   
`;

const RatingDiv = styled.div`
   img{
    margin:5px;
    opacity: 0.5;
    cursor: pointer;
    &:hover {
    opacity: 1;
  }
@media(max-width:768px){
   width:30px;
   height:30px;
  }
   }
`;

const GaugeDiv = styled.div`
   display: flex;
  flex-direction:column;
`;

const GaugeChartPercentage = styled.div`
font-weight: 600;
font-size: 60px;
line-height: 90px;
text-align: center;
color: #192A46;
@media(max-width:768px){
font-weight: 600;
font-size: 40px;
line-height: 60px;
}

// @media(min-width:769px) and (max-width:992px){
// font-weight: 600;
// font-size: 50px;
// line-height: 30px;
// }
`;

const Sub = styled.sub`
  font-size:35px;
  bottom:0px
  @media(max-width:768px){
   font-size: 20px;
`;

const GougeChartScore = styled.div`
font-weight: 400;
font-size: 20px;
line-height: 30px;
text-align: center;
color: #8E9EBA;

@media(max-width:768px){
font-weight: 400;
font-size: 16px;
line-height: 20px;   
}

// @media(min-width:769px) and (max-width:992px){
// font-weight: 400;
// font-size: 18px;
// line-height: 25px;
}
 
`;