import {data} from "../../../data";
import styles from "../../../styles/collaborators.module.css";
import React from "react";
import styled from "styled-components";


const {Content, title} = data.collaboratorsSection;
const RedutechCollaborator = () => {
    return (
        <PaddingDiv>
            <div className="container-md">
                <div className={styles.mainContainer}>
                    <h1 style={{color: "#8B292A"}}>{title}</h1>
                    <Divider>
                        <hr/>
                    </Divider>
                    <div className={styles.cardContainer}>
                        {Content.map((item, index) => (
                            <div key={index} className={styles.card}>
                                <img src={item}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </PaddingDiv>
    );
};
export default RedutechCollaborator;

const PaddingDiv = styled.div`
   padding: 0px 0px 120px;
`;

const Divider =  styled.div`
   width:100%;
   display:flex;
   justify-content:center;
   align-items: center;
   
   hr{
    width: 150px;
    height: 4px;
    border-radius: 50%;
    background: #8B292A;
    opacity: 1;
    border: none;
    margin-top:20px;
    margin-bottom:40px;
   }
`;