import React, { FC } from 'react';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import styled from 'styled-components';
import Warning from '../../../assets/test/warning.svg';
import { slideDown } from '../../../styles/animations';
import { TestDetail } from '../../../utils/types';
import Flex from '../Flex';
import Modal, { CloseButton } from '../Modal';

const SubmitTest: FC<{ onClose: any; onSubmit: any, test?: TestDetail }> = ({
  onClose,
  onSubmit,
  test,
}) => {
  const title = test?.test_configs?.submit_popup_title || "Are you sure?"
  const description = test?.test_configs?.submit_popup_description || "If you proceed, you will not be able to change your answers."
  const forwardText = test?.test_configs?.submit_popup_forward_text || "Yes, Continue"
  const backwardText = test?.test_configs?.submit_popup_backward_text || "Go Back"
  const WarningImage = test?.test_configs?.submit_popup_image || Warning
  return (
    <Modal onClose={() => onClose(false)} width='60rem'>
      <Container column onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={() => onClose(false)} />
        <img src={WarningImage} alt='Warning' />
        <Message>{title}</Message>
        <Text>
          {description}
        </Text>
        <Flex>
          <GoBackContainer>
            <GoBack onClick={() => onClose(false)}>
              <HiOutlineArrowLeft />
              {backwardText}
            </GoBack>
          </GoBackContainer>
          <ContinueContainer>
            <Continue test={test} onClick={() => onSubmit()}>{forwardText}</Continue>
          </ContinueContainer>
        </Flex>
      </Container>
    </Modal>
  );
};

export default SubmitTest;

const Container = styled(Flex)`
  width: 37rem;
  height: fit-content;
  padding: 2rem;
  background-color: #fff;
  border-radius: 4px;
  animation: ${slideDown} 0.4s ease-out;
  text-align: center;
  img {
    width: 17rem;
    margin: auto;
    margin-bottom: 1.2rem;
  }
`;

const GoBackContainer = styled(Flex)`
  width: 20rem;
  flex: 1;
  background: none;
  border: 0px;
  color: ${(props) => props.theme.colors.grey[500]};
`;

const ContinueContainer = styled(Flex)`
  width: 20rem;
  float: right;
  flex: 1;
  flex-direction: row-reverse;
`;

const Continue = styled(Flex)`
  height: 3rem;
  background-color: ${(props) => props.test?.test_configs?.theme?.primary_color || props.theme.colors.blue[500]};
  color: ${(props) => props.theme.colors.white};
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
`;

const Message = styled(Flex)`
  font-weight: 600;
  font-size: 2.6rem;
  line-height: 39px;
  justify-content: center;
`;

const Text = styled(Flex)`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 0rem 5rem;
  color: ${(props) => props.theme.colors.grey[500]};
  margin: 0 auto;
  margin-bottom: 2rem;
`;

const GoBack = styled(Flex)`
  color: ${(props) => props.theme.colors.grey[500]};
  background-color: ${(props) => props.theme.colors.white};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  flex: 1;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  svg {
    font-weight: bold;
    margin-right: 5px;
    display: inline;
    vertical-align: middle;
    height: auto;
  }
`;
