import dayjs from 'dayjs';
import { ratings } from './constants';

export const capitalize = (text: string = '') => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const trimUserToken = (token: string) => {
  return '... ' + token.slice(-10);
};

export const convertToIST = (date: string): string => {
  return dayjs(date)
    .add(5, 'hour')
    .add(30, 'minutes')
    .format('DD/MM/YYYY HH:mm');
};

export const convertToUTC = (date: string): string => {
  return dayjs(date)
    .subtract(5, 'hour')
    .subtract(30, 'minutes')
    .format('YYYY-MM-DD HH:mm:ss');
};

export const getDate = (date: string): string => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const formatDate = (date: string | undefined): string => {
  return dayjs(date).format('YYYY-MM-DD HH:mm');
};

export const copyToClipboard = (text: string) => {
  var textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

export const getRatingIcon = (averageRating: number) => {
  const rating = Math.round(averageRating);
  const matchedRating = ratings.filter((rate) => rate.rating === rating);
  return matchedRating[0].icon;
};
