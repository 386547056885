import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { TraitPercentage } from '../../utils/types';
import Flex from './Flex';
interface IProps {
  trait?: TraitPercentage;
  color: string;
  size?: number;
  innerSize?: number;
  showOnMobile?: boolean;
}

const Donut: FC<IProps> = ({
  trait,
  color = 'grey',
  size,
  innerSize,
  showOnMobile = false,
}) => {
  return trait ? (
    <OuterContainer
      percentage={trait.percentage}
      color={color}
      size={size}
      showOnMobile={showOnMobile}
    >
      <InnerContainer column justify='center' size={innerSize}>
        <Percentage>{trait.percentage}%</Percentage>
        <Name>{trait.name.toUpperCase()}</Name>
      </InnerContainer>
    </OuterContainer>
  ) : (
    <></>
  );
};

export default Donut;

const OuterContainer = styled(Flex)<{
  percentage?: number;
  color: string;
  size?: number;
  showOnMobile: boolean;
}>`
  border-radius: 50%;
  font-weight: 600;
  font-size: 2rem;
  line-height: 150%;

  background-color: ${({ theme }) => theme.colors.report['white']};

  ${({ percentage, color, theme }) =>
    color &&
    percentage &&
    css`
      background-image: conic-gradient(
        ${theme.colors.report[color]} 0 ${percentage * 3.6 + 1}deg,
        ${theme.colors.report['white']} 0
      );
    `};
  ${({ color, theme }) =>
    color &&
    css`
      color: ${theme.colors.report[color]};
    `};
  ${({ size }) =>
    css`
      min-height: ${size || '25'}rem;
      min-width: ${size || '25'}rem;
    `};

  text-align: center;
  align-items: center;

  justify-content: center;

  @media screen and (max-width: 1024px) {
    ${({ showOnMobile }) =>
      css`
        display: ${showOnMobile ? 'inline' : 'none'};
      `}
  }
`;

const InnerContainer = styled(Flex)<{
  size?: number;
}>`
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
  transform: matrix(1, -0.01, -0.01, 1, 0, 0);

  text-align: center;
  align-items: center;
  ${({ size }) =>
    css`
      height: ${size || '21'}rem;
      width: ${size || '21'}rem;
    `}
  @media screen and (max-width: 1024px) {
    margin: 1rem;
  }
`;

const Name = styled(Flex)`
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  align-items: center;
`;

const Percentage = styled(Name)`
  font-size: 3.6rem;
`;
