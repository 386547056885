import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import useForm from "../../hooks/useForm";
import {
  TEST_DETAIL_VALIDATION_SCHEMA,
  API_ENDPOINT,
  STRIDE_ID_AUTH,
} from "../../utils/constants";
import { APIError, FetchResponse, FormFields } from "../../utils/types";
import Flex from "../components/Flex";
import Separator from "../components/Separator";
import StrideImg from "../../assets/stride_img.png";
import StrideLogo from "../../assets/stride_logo.svg";
import validator from "@rjsf/validator-ajv6";
import Form from "@rjsf/core";
import _ from "lodash";
import { useWindowDimensions } from "./report_pages/utils";
import Navbar from "../components/Navbar";

const RegisterPage = () => {
  const history: any = useHistory();
  const location: any = useLocation();
  const params: any = useParams();
  const [isLoading, setLoading] = useState(false);
  const [getData, setGetData] = useState<any>({});
  const [disable, setDisable] = useState(true);
  const [message, setMessage] = useState({ type: "", message: "" });
  const [alertStyle, setAlertStyle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [totalData, setTotalData] = useState();
  const { width } = useWindowDimensions();
  const response_id = location?.state?.detail;

  console.log(params?.responseId);
  const actionOnSubmit2 = async (data: {
    [key in FormFields]?: string | string[];
  }): Promise<APIError[] | void> => {
    setLoading(true);
    try {
      const postData = {
        response_id: params?.responseId,
        response: formData,
      };
      await axios
        .post<FetchResponse<{ message: string }>>(
          API_ENDPOINT + `form/${params?.formId}/save`,
          postData
        )
        .then(() => {
          window.location.href = getData?.data?.redirect_url;
        });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const errors = error.response.data.error as APIError[];
      return errors;
    }
  };

  const { formData, setFormData, handleChange, handleBlur, handleSubmit } =
    useForm({
      actions: { onSubmit: "" },
      validatorSchema: TEST_DETAIL_VALIDATION_SCHEMA,
      handleValidationOnBlur: true,
    });

  const getdata = () => {
    fetch(`${API_ENDPOINT}form/${params?.formId}`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setGetData(data);
      })
      .catch((err) => {
        setMessage({ type: "error", message: "Fail to fetch " });
      });
  };

  const getPreSetData = () => {
    fetch(`${API_ENDPOINT}form_responses?response_id=${params?.responseId}`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setFormData(data?.data);
      })
      .catch((err) => {
        setMessage({ type: "error", message: "Fail to fetch" });
      });
  };

  const autoSave = async () => {
    const postData = {
      response_id: params?.responseId,
      response: formData,
    };
    await axios.post<FetchResponse<{ message: string }>>(
      API_ENDPOINT + `form/${params?.formId}/save`,
      postData
    );
  };

  const authApi = async () => {
    await axios
      .get<FetchResponse<{ message: string }>>(`${STRIDE_ID_AUTH}me`)
      .then((res: any) => {
        axios
          .post(API_ENDPOINT + `form/${params?.formId}/proceed`, {
            name: res.data?.data?.first_name,
            email: res.data?.data?.email,
          })
          .then((resp: any) => {})
          .catch((error: any) => {
            if (error?.response?.status === 412) {
              getData?.data?.redirect_url &&
                window.location.replace(getData?.data?.redirect_url);
            } else {
              setMessage({ type: "error", message: "Fail to fetch " });
            }
          });
      });
  };

  useEffect(() => {
    try {
      fetch(`${STRIDE_ID_AUTH}me`, {
        credentials: "include",
      }).then((res) => {
        if (res?.status === 401) {
          history.push({
            pathname: `/form/${params?.formId}`,
          });
        }
      });

      authApi();
    } catch (e) {
      console.log("err");
    }
  }, [getData]);

  const apiJsonData = getData?.data?.form_schema;
  const uiSchema = getData?.data?.ui_schema;
  const dummyData2 = _.cloneDeep(apiJsonData);
  useEffect(() => {
    if (apiJsonData) {
      Promise.all(
        Object.entries(dummyData2?.properties).map(([field, property]: any) => {
          if (field === "state") {
          } else {
            if (property["apiUrl"]) {
              return axios
                .get(`${property["apiUrl"]}`)
                .then((res) => {
                  const key = Object.keys(res.data.data);
                  property["enum"] = res.data.data[`${key}`];
                })
                .catch((err) => {
                  console.log("err");
                });
            }
          }
        })
      ).then((res) => {
        setTotalData(dummyData2);
      });
    }
  }, [getData]);

  useEffect(() => {
    axios
      .get(
        `https://auth.strideahead.in/profile/states/options?country=${formData?.country}`
      )
      .then((res: any) => {
        dummyData2.properties.state["enum"] = res.data?.data?.states;
        setTotalData((prev: any) => {
          return {
            ...prev,
            properties: {
              ...prev?.properties,
              state: {
                ...prev?.properties.state,
                enum: res.data?.data?.states,
              },
            },
          };
        });
      })
      .catch((err) => {
        console.log("error");
      });
  }, [formData?.country]);

  useEffect(() => {
    getPreSetData();
  }, []);

  useEffect(() => {
    getdata();
  }, []);
  useEffect(() => {
    if (false) {
      setDisable(false);
    } else if (true) {
      setTimeout(() => {
        setDisable(false);
      }, 100);
    }
  }, [getData]);

  useEffect(() => {
    message.message ? setShowAlert(true) : setShowAlert(false);
  }, [message]);

  useEffect(() => {
    message?.type === "success" ? setAlertStyle(true) : setAlertStyle(false);
  }, [message]);

  return (
    <Container>
      <Navbar />
      <Sidebar align="center" justify="center">
        <div className="">{/*<img src={StrideLogo}/>*/}</div>

        <SubContent1></SubContent1>
        <VideoText className="video-text">
          This might be your opportunity to launch your career through this
          exclusive management program and to find out if you’re eligible we
          just need your basic details and will evaluate your aptitude through
          our Management Aptitude Assessment. Know your Score and start your
          journey by filling out this form!
        </VideoText>

        {!getData?.data?.banner_image ? (
          <ImagesDiv>
            <img src={StrideImg} alt="Auth Graphic" />
          </ImagesDiv>
        ) : (
          <>
            <iframe
              width="500px"
              height="400px"
              src={getData?.data?.banner_video}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </>
        )}
        {width > 600 && (
          <SubContentFooter>© copyright, 2022 Stride Ahead</SubContentFooter>
        )}
      </Sidebar>
      <Content>
        <Title>Register Your Account</Title>
        <SubTitle>
          For the purpose of industry regulations, we need your details
        </SubTitle>
        <Separator />

        {totalData && (
          <Formdiv>
            {showAlert && (
              <div
                className={
                  alertStyle ? "alert alert-success " : "alert alert-danger"
                }
                role="alert"
              >
                {message?.message}
              </div>
            )}
            <StyledForm
              schema={totalData}
              formData={formData}
              validator={validator}
              liveValidate={false}
              uiSchema={uiSchema}
              onChange={(e: any) => setFormData(e.formData)}
              onSubmit={(e: any) => actionOnSubmit2(e)}
              onBlur={(e: any) => autoSave()}
            />
          </Formdiv>
        )}
      </Content>
      {width < 600 && (
        <SubContentFooter>© copyright, 2022 Stride Ahead</SubContentFooter>
      )}
    </Container>
  );
};

export default RegisterPage;

const StyledForm = styled(Form)`
  label {
    font-family: "Poppins";
    font-size: 16px;
    margin-top: 1rem;
  }
  select {
    font-family: "Poppins";
    border: 1.5px solid #938ca9 !important;
    font-size: 16px;
    border-radius: 10px !important;
    line-height: 40px;
    width: 100%;
  }
  input {
    font-family: "Poppins";
    border: 1.5px solid #938ca9 !important;
    border-radius: 10px !important;
    font-size: 16px;
    line-height: 40px;
    width: 100%;
  }

  input[type="checkbox"] {
    margin: 0 10px;
  }

  button {
    font-family: "Poppins";
    width: 100%;
    border: none;
    padding: 15px 30px;
    background: linear-gradient(135deg, #5330ba 0%, #8e2596 100%);
    border-radius: 10px !important;
    margin-top: 3rem;
    font-size: 20px;
    color: white;
  }
  button:hover {
    color: #fff !important;
  }
`;
const Formdiv = styled.div`
  height: 60vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Container = styled(Flex)`
  min-height: 100vh;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 600px) {
    width: 100%;
    /* background: red; */
  }
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #352958;
  @media (max-width: 600px) {
    font-size: 20px;
    /* background: red; */
  }
`;

const SubTitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #938ca9;
  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 12px;
    /* background: red; */
  }
`;

const SubContent1 = styled.p`
    width:70%
    margin-top: -30px;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #938CA9; 

    @media (max-width: 600px) {
        width:100%
		font-size: 12px;
        line-height: 20px;
        margin-top: 10px;
		/* background: red; */
	}
`;

const SubContentFooter = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #938ca9;
  @media (max-width: 600px) {
    /* position: absolute; */
    bottom: -10px;
    font-size: 14px;
  }
`;
const Sidebar = styled(Flex)`
  width: 45%;
  background: #f2effb;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  iframe {
    width: 80%;
    height: 43%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    border-radius: 20px;
  }

  img {
    width: 100%;
    max-width: 42rem;
  }

  @media (max-width: 1024px) {
    // height: 8rem;
    width: 100%;
    padding: 1rem 0;

    a {
      height: 100%;
      cursor: pointer;

      img {
        width: auto;
        height: 100%;
      }
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    background: #ffff;
    height: auto;
    padding: 2rem 2rem;

    iframe {
      margin-top: 4rem;
    }
  }
`;

const Content = styled.div`
  width: 55%;
  padding: 8rem 10rem;
  flex-grow: 1;
  height: 100vh;
  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 2rem 2rem;
  }
`;

const ImagesDiv = styled.div`
  width: 50%;
`;

const VideoText = styled.p`
    font-size: 1.6rem;
    color: #352958;
    width: 80%;   
    margin-top:20px;
    margin-bottom:20px;
    font-weight: 600px;

    @media (max-width: 2000px) {
        font-size: 2rem;
        // background-color:red;
        margin-top:0px;
        margin-bottom:0px;
        font-weight: 600px;
    }
    @media (max-width: 1500px) {    
        font-size: 1.6rem;
        color: #352958;
        width: 80%;   
        margin-top:20px;
        margin-bottom:10px;
        font-weight: 600px;
    }    
    @media (max-width: 600px) {
		font-size: 1.4rem;
        margin-top:20px
        font-weight: 600px;
	}
`;
