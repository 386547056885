import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import useForm from "../../hooks/useForm";
import {
  API_ENDPOINT,
  LOGIN_FORM_VALIDATION_SCHEMA,
  STRIDE_ID_AUTH,
  REGISTER_LINK,
} from "../../utils/constants";
import { APIError, FetchResponse, FormFields } from "../../utils/types";
import Button from "../components/Button";
import Flex from "../components/Flex";
import FormStatus from "../components/FormStatus";
import GoogleAuth from "../components/GoogleAuth";
import Input from "../components/Input";
import Separator from "../components/Separator";
import { Text1Css } from "../components/Typography";
import userImg from "../../assets/InputBox/user.png";
import emialImg from "../../assets/InputBox/email.png";
import StrideLogo from "../../assets/stride_logo.svg";
import StrideImg from "../../assets/stride_img.png";
import { useWindowDimensions } from "./report_pages/utils";

const LoginPageCohort = () => {
  const history: any = useHistory();
  const params: any = useParams();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const email = urlSearchParams.get("email");
  const [message, setMessage] = useState({ type: "", message: "" });
  const [alertStyle, setAlertStyle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [getData, setGetData] = useState<any>({});
  const [googleLoginResponse, setGoogleLoginResponse] = useState<{
    status: "success" | "fail";
    message: string;
  }>();
  const { width } = useWindowDimensions();
  const actionOnSubmit = async (data: {
    [key in FormFields]?: string;
  }): Promise<APIError[] | void> => {
    setLoading(true);
    try {
      const resp = await axios.post<FetchResponse<{ message: string }>>(
        `${STRIDE_ID_AUTH}login?activity_id=210`,
        data
      );
      setLoading(false);
      const re: any = resp.data.data;
      if (re) {
        authApi();
      } else {
        setMessage({ type: "error", message: "Login fail, Please try again" });
      }
    } catch (error: any) {
      setLoading(false);
      const errors = error.response.data.error as APIError[];
      return errors;
    }
  };

  const getdata = () => {
    fetch(`${API_ENDPOINT}form/${params?.formId}`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setGetData(data);
      })
      .catch((err) => {
        setMessage({ type: "error", message: "Fail to fetch " });
      });
  };

  const authApi = async () => {
    await axios
      .get<FetchResponse<{ message: string }>>(`${STRIDE_ID_AUTH}me`)
      .then((res: any) => {
        axios
          .post(API_ENDPOINT + `form/${params?.formId}/proceed`, {
            name: res.data?.data?.first_name,
            email: res.data?.data?.email,
          })
          .then((resp: any) => {
            if (res.data?.data?.is_phone_verified === true) {
              history.push({
                pathname: `/form/${params?.formId}/${resp?.data?.data?.resposne_id}`,
                state: {
                  detail: resp?.data?.data?.resposne_id,
                  is_phone_verified: res.data?.data?.is_phone_verified,
                },
              });
            } else {
              if (
                res.data?.data?.is_phone_verified === false ||
                res.data?.data?.is_phone_verified == null
              ) {
                history.push({
                  pathname: `/form/${params?.formId}/verification`,
                  state: {
                    detail: res.data?.data?.phone_no,
                    response_id: resp?.data?.data?.resposne_id,
                    is_phone_verified: res.data?.data?.is_phone_verified,
                  },
                });
              } else setMessage({ type: "error", message: "Invalid User" });
            }
          })
          .catch((error: any) => {
            if (error?.response?.status === 412) {
              getData?.data?.redirect_url &&
                window.location.replace(getData?.data?.redirect_url);
            } else {
              setMessage({
                type: "error",
                message: error?.response?.data?.error[0],
              });
            }
          });
      });
  };

  const {
    formData,
    setFormData,
    formSubmitResponse,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm({
    actions: { onSubmit: actionOnSubmit },
    validatorSchema: LOGIN_FORM_VALIDATION_SCHEMA,
    handleValidationOnBlur: true,
  });

  const handleCallBackResponse = async (response) => {
    console.log(response, "google");
    const { email, familyName, givenName } = response.profileObj;
    const tokenId = response.tokenId;

    fetch(STRIDE_ID_AUTH + "google-sign-up?source=MAP&activity_id=210", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        first_name: givenName,
        last_name: familyName,
        email: email,
        google_auth_token: tokenId,
      }),
    })
      .then((res: any) => res.json())
      .then((data) => {
        if (data?.data?.google_auth_token_verification_status) {
          authApi();
        } else {
          setMessage({
            type: "error",
            message: "Login fail, Please try again",
          });
        }
      });
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleForgotPassword = () => {
    history.push("/reset_password");
  };

  useEffect(() => {
    if (email) {
      setFormData((formData) => ({
        ...formData,
        email: { value: email, error: "" },
      }));
    }
  }, [email, setFormData, history]);

  useEffect(() => {
    authApi();
  }, [getData]);

  const isButtonDisabled = () => {
    return !(
      formData.email?.value !== "" &&
      formData.email?.error === "" &&
      formData.password?.value !== "" &&
      formData.password?.error === "" &&
      !isLoading
    );
  };

  useEffect(() => {
    message.message ? setShowAlert(true) : setShowAlert(false);
  }, [message]);

  useEffect(() => {
    message?.type === "success" ? setAlertStyle(true) : setAlertStyle(false);
  }, [message]);
  return (
    <Container>
      <Sidebar align="center" justify="center">
        <div className="pt-5">
          <img src={StrideLogo} />
        </div>
        <VideoText className="video-text">
          This might be your opportunity to launch your career through this
          exclusive management program and to find out if you’re eligible we
          just need your basic details and will evaluate your aptitude through
          our Management Aptitude Assessment. Know your Score and start your
          journey by filling out this form!
        </VideoText>
        {getData?.data?.banner_image ? (
          <img src={StrideImg} alt="Auth Graphic" />
        ) : (
          <iframe
            width="500px"
            height="400px"
            src={getData?.data?.banner_video}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
        {width > 600 && (
          <SubContentFooter>© copyright, 2022 Stride Ahead</SubContentFooter>
        )}
      </Sidebar>
      <Content>
        <Title>Sign In Your Account</Title>
        <SubTitle>
          For the purpose of industry regulations, we need your details
        </SubTitle>
        <Separator />
        {formSubmitResponse && (
          <FormStatus align="center" className={formSubmitResponse.status}>
            {formSubmitResponse.message}
          </FormStatus>
        )}
        {googleLoginResponse && (
          <FormStatus align="center" className={googleLoginResponse.status}>
            {googleLoginResponse.message}
          </FormStatus>
        )}
        {showAlert && (
          <div
            className={
              alertStyle ? "alert alert-success " : "alert alert-danger"
            }
            role="alert"
          >
            {message?.message}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <FormCont>
            <StyleInput
              label="Email address"
              name="email"
              type="email"
              placeholder="Enter email address"
              value={formData?.email?.value}
              error={formData?.email?.error}
              required
              autoFocus
              onChange={handleChange}
              onBlur={handleBlur}
              showSuccessIndicator
              icon={<ImageComp src={emialImg} />}
            />
            <StyleInput
              label="Password"
              name="password"
              type="password"
              placeholder="Enter password"
              value={formData?.password?.value}
              error={formData?.password?.error}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              showSuccessIndicator={false}
              icon={<ImageComp src={userImg} />}
            />
          </FormCont>
          <Flex justify="flex-end">
            <ForgotPassword onClick={handleForgotPassword}>
              Forgot Password?
            </ForgotPassword>
          </Flex>
          <StyledButton
            type="submit"
            disabled={isButtonDisabled()}
            isLoading={isLoading}
          >
            Sign In
          </StyledButton>
        </form>
        <a className="text-white" href={REGISTER_LINK}>
          <StyledButton>Sign Up</StyledButton>
        </a>

        <GoogleAuth
          text="Sign in with Google"
          onSuccess={handleCallBackResponse}
          onFailure={(error) => {
            if (error.error === "access_denied") {
              setGoogleLoginResponse({
                status: "fail",
                message: "Please allow access to login successfully",
              });
            }
          }}
        />
      </Content>
    </Container>
  );
};

export default LoginPageCohort;

const Container = styled(Flex)`
  max-height: 100vh;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 600px) {
    width: 100%;
    /* background: red; */
  }
`;

const StyledButton = styled(Button)`
  margin-top: 2rem;
  background: linear-gradient(135deg, #5330ba 0%, #8e2596 100%);
  a {
    width: inherit;
    height: inherit;
    text-decoration: none;
    color: #ffff;
  }
`;

const ForgotPassword = styled.span`
  ${Text1Css}
  font-weight: 600;
  color: #8e2596;
  cursor: pointer;
`;

const FormCont = styled.div`
  margin-top: 14px;
  @media (max-width: 600px) {
    margin-top: 0px;
  }
`;

const StyleInput = styled(Input)`
  border: 1.5px solid #938ca9 !importent;
  border-radius: 20px !importent;
  width: 100%;
`;
const ImageComp = styled.img`
  height: 22px;
  width: 22px;
  position: absolute;
  top: 42px;
  right: 10px;
`;
const Title = styled.h1`
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #352958;
  @media (max-width: 600px) {
    font-size: 20px;
    /* background: red; */
  }
`;

const SubTitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #938ca9;
  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 12px;
    /* background: red; */
  }
`;

const VideoText = styled.p`
    font-size: 1.6rem;
    color: #352958;
    width: 80%;   
    margin-top:20px;
    margin-bottom:20px;
    font-weight: 600px;

    @media (max-width: 2000px) {
        font-size: 2rem;
        // background-color:red;
        margin-top:0px;
        margin-bottom:0px;
        font-weight: 600px;
    }
    @media (max-width: 1500px) {    
        font-size: 1.6rem;
        color: #352958;
        width: 80%;   
        margin-top:20px;
        margin-bottom:20px;
        font-weight: 600px;
    }    
    @media (max-width: 600px) {
		font-size: 1.4rem;
        margin-top:20px
        font-weight: 600px;
	}
       
   
    
`;

const Sidebar = styled(Flex)`
  width: 45%;
  height: 100vh;
  background: #f2effb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  iframe {
    width: 80%;
    height: 43%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    border-radius: 20px;
  }

  img {
    width: 100%;
    max-width: 42rem;
  }

  @media (max-width: 1024px) {
    // height: 8rem;
    width: 100%;
    padding: 1rem 0;

    a {
      height: 100%;
      cursor: pointer;

      img {
        width: auto;
        height: 100%;
      }
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    background: #ffff;
    height: auto;
    padding: 2rem 2rem;
    iframe {
      margin-top: 15px;
    }
  }
`;
const Content = styled.div`
  width: 55%;
  padding: 1rem 10rem;
  flex-grow: 1;

  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 2rem 2rem;
  }
`;
const SubContentFooter = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #938ca9;
  @media (max-width: 600px) {
    /* position: absolute; */
    bottom: -10px;
    font-size: 14px;
  }
`;
