// @ts-nocheck
import { Select, Input } from 'antd';
import React, { useContext, useEffect, useRef } from 'react';
import { addAskIITiansSubject, addAskIITiansChapter } from "../../reducer/actions";
import QuizzContext, { IQuizzBuilderContext } from '../../../QuizzContext';

export default function AskIITiansSubjectSelectInput(props: any){
    const { Option } = Select;
    const { dispatch } = useContext(QuizzContext) as IQuizzBuilderContext;
    useEffect(() => {
      console.log('AskIITiansSubjectSelectInput', props);
      if(!props.defaultValue.subject){
        dispatch(addAskIITiansSubject(props.id, 'p'));
      }
      if(!props.defaultValue.chapter){
        dispatch(addAskIITiansChapter(props.id, ''));
      }
    }, [])
    return (
      <>
        <div style={{marginBottom: '10px'}}>Select a Subject for given question</div>
        <Select
          onSelect={(value) => {
            dispatch(addAskIITiansSubject(props.id, value));
          }}
          defaultValue={props.defaultValue.subject ? props.defaultValue.subject : "p"}
        >
          <Option value="p">Physics</Option>
          <Option value="c">Chemistry</Option>
          <Option value="m">Mathematics</Option>
          <Option value="b">Biology</Option>
          <Option value="a">Aptitude</Option>
        </Select>
        <div style={{marginBottom: '10px', marginTop: '10px'}}>Name the Chapter for given question</div>
        <Input
          onChange={(e) => {
            dispatch(addAskIITiansChapter(props.id, e.target.value));
          }} 
          defaultValue={props.defaultValue.chapter ? props.defaultValue.chapter : ""} 
        />
      </>
    );
}