import {
    FacebookShareButton,
    EmailShareButton,
    InstapaperShareButton,
} from "react-share";
import React, {useLayoutEffect, useRef} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import {getTraitColor, getTraitName} from "../raisecQuizReportUtils";

import gmailIcon from "../../../../assets/gmail_icon.png";
import facebookIcon from "../../../../assets/facebook_icon.png";
import styled from "styled-components";

am4core.useTheme(am4themes_animated);

export const TraitChart = ({reportData, width, flow}: any) => {
    const chart = useRef(null) as any;
    var res = [] as any;
    for (let i in reportData?.percentage) {
        if (reportData?.percentage[i] > 0) {
            let temp = {};
            temp["trait"] = getTraitName(i);
            // @ts-ignore
            temp["score"] = Math.round(+(reportData?.percentage[i] / Object?.values(reportData?.percentage)?.reduce((total:any, value:any)=>total+=value,0)) * 100) + "%";
            temp["color"] = am4core.color(getTraitColor(i));
            res.push(temp);
        }
    }

    const chartRadius = width <= 700 ? 130 : width <= 1300 ? 150 : 200;
    const FontSize =  width <= 768 ? "10px" : "14px"

    useLayoutEffect(() => {
        let x = am4core.create("chartDiv", am4charts.PieChart);
        x.data = res;
        var series = x.series.push(new am4charts.PieSeries());
        series.dataFields.value = "score";
        series.ticks.template.disabled = true;
        series.alignLabels = false;
        series.labels.template.radius = am4core.percent(-80);
        series.labels.template.relativeRotation = 90;
        series.labels.template.fill = am4core.color("white");
        series.slices.template.propertyFields.fill = "color";
        series.dataFields.category = "trait";
        series.radius = chartRadius;
        series.labels.template.text = "{trait}: {score}";
        series.colors.step = 5;
        series.width = 300;
        series.fontSize = FontSize;
        series.hiddenState.properties.endAngle = 0;
        series._chart.logo.disabled = true;
        chart.current = x;

        return () => {
            x.dispose();
        };
    }, [reportData, width]);

    return (
        <>
            <ChartDiv id="chartDiv"></ChartDiv>
        </>
    );
};


const ChartDiv = styled.div`
   width:100%;
   height:450px;
   
   @media(max-width:768px){
      height:345px;
   }
   
   @media(min-width:769px) and (max-width: 992px){
      width:345px;
   }
`;