import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import useTest from '../../hooks/useTest';
import StrideTestPage from "./test_start_pages/StrideTestPage";
import CustomTestStartPage from "./test_start_pages/CustomTestStartPage";
import StrideCohortTestPage from "./test_start_pages/StrideCohortTestPage";
import WeAceDefaultTestStartPage from './test_start_pages/WeAceDefaultTestStartPage';

const TestAuth = ({location}) => {
    const {testId} = useParams<{ testId: string }>();
    const [component, setComponent] = useState<any>();
    const {isLoading, data: test} = useTest(testId);
    const currentUrlParams = new URLSearchParams(window.location.search);
    const headers = currentUrlParams.get("refresh_token")
    // const [headers,setHeaders] = useState<any>();


    function setComponentFunction(pageName){
        switch (pageName) {
            case "Stride PCA Default" :
            default:
                console.log("Hola")
                setComponent(<StrideTestPage  testId={testId} test={test}  isLoading={isLoading}  location={location} headers={headers}/>);
                break;

            case "WeAce Default Test Page" :
            case "WeAce Raking Test Page":
            case "Default Single Question Test Page":
                setComponent(<WeAceDefaultTestStartPage  testId={testId} test={test}  isLoading={isLoading} location={location}/>);
                break;

            case "Amuzi":
                setComponent(<CustomTestStartPage  testId={testId}   test={test} isLoading={isLoading}
                                                   location={location}/>);
                break;

            case "Stride Cohort Default" :
                setComponent(<StrideCohortTestPage  testId={testId}   test={test} isLoading={isLoading}
                                                    location={location}/>);
                break;
        }
    }
    useEffect(() => {
        // setHeaders(currentUrlParams.get('refresh_token'))
        test?.test_page?.name && setComponentFunction(test?.test_page?.name)
        console.log(test?.test_page?.name)

        // if( currentUrlParams.get('refresh_token')){
        //     setHeaders(currentUrlParams.get('refresh_token'))
        // }
    }, [test?.test_page?.name]);

    return (
        <React.Fragment>
            {component}
        </React.Fragment>
    )
};
export default TestAuth;
