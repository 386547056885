import styled from "styled-components";
import React, { FC, useEffect, useState } from "react";

import Flex from "../../../components/Flex";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import { slideDown } from "../../../../styles/animations";
import {ReportMetadata, ReportMetadataResponse, TestDetail} from "../../../../utils/types";

import "./strong.css";
import { BsFillQuestionCircleFill } from "react-icons/bs";

const StrongSuit: FC<{ reportMeta: ReportMetadata, test: TestDetail }> = ({
  reportMeta, test
}) => {
  const [showSummary, setShowSummary] = useState<boolean>(false);

  const getWidth = () => {
    const { innerWidth: width } = window;
    return width;
  };

  useEffect(() => {
    window.addEventListener("resize", getWidth);
    setWindowWidth(getWidth());
  }, []);

  const [windowWidth, setWindowWidth] = useState(getWidth());
  const [active, setActive] = useState(-1);
  const [activeLimitation, setActiveLimitation] = useState(-1);

  const handleClick = (data: any) => {
    if (active == data) {
      setActive(-1);
      return;
    }
    setActive(data);
  };

  const handleClickLimitation = (data: any) => {
    if (activeLimitation === data) {
      setActiveLimitation(-1);
      return;
    }
    setActiveLimitation(data);
  };

  return (
    <Container column>
      <Heading>Your Strong suit</Heading>
      <StrengthContainer>
        {reportMeta?.strengths?.map((strength, index) => {
          return (
            <ImageContainer column key={`${strength.name}-${index}`}>
              <div
                className="show_desc"
                onClick={() => windowWidth < 768 && handleClick(index)}
              >
                <BsFillQuestionCircleFill className="info-icon" />
              </div>
              <div
                className={`card-details-container ${
                  active === index ? "show" : ""
                }`}
              >
                <img
                  src={strength?.image}
                  alt={strength?.name}
                  className="image"
                />
                <span>{strength?.name}</span>
                {active === index && (
                  <p className="strength-desc">{strength?.description}</p>
                )}
                {windowWidth > 768 && (
                  <p className="strength-desc">{strength?.description}</p>
                )}
              </div>
            </ImageContainer>
          );
        })}
      </StrengthContainer>
      <br />
      <Heading>Focus areas</Heading>
      <StrengthContainer>
        {reportMeta?.limitations?.map((limitation, index) => {
          return (
            <ImageContainer column key={`${limitation.name}-${index}`}>
              <div
                className="show_desc"
                onClick={() =>
                  windowWidth < 768 && handleClickLimitation(index)
                }
              >
                <BsFillQuestionCircleFill className="info-icon" />
              </div>
              <div
                className={`card-details-container ${
                  activeLimitation === index ? "show" : ""
                }`}
              >
                <img src={limitation.image} alt={limitation.name} />
                <span>{limitation.name}</span>
                {activeLimitation === index && (
                  <p className="strength-desc">{limitation?.description}</p>
                )}
                {windowWidth > 768 && (
                  <p className="strength-desc">{limitation?.description}</p>
                )}
              </div>
            </ImageContainer>
          );
        })}
      </StrengthContainer>
      <br />
      <Heading style={{ width: "100%" }}>Key Takeaways</Heading>
      <MantrasContainer>
        {reportMeta?.mantras?.map((mantra, index) => {
          return (
            <Mantra column key={`${mantra}-${index}`} test={test}>
              <Quotes>“</Quotes>
              <span>{mantra}</span>
            </Mantra>
          );
        })}
      </MantrasContainer>
    </Container>
  );
};

export default StrongSuit;

const Container = styled(Flex)`
  flex: 1;
  width: 100%;
  margin: 6rem 0;
  border-radius: 10px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin: 1.6rem 0;
    padding: 1.2rem;
    background: transparent;
  }
`;

const Value = styled(Flex)`
  font-weight: 500;
  font-size: 2rem;
  line-height: 150%;
  margin: 1.6rem 0;
  color: ${({ theme }) => theme.colors.grey[600]};
  @media only screen and (max-width: 1024px) {
    font-size: 1.4rem;
  }
`;

const ImageContainer = styled(Flex)`
  flex: 1;
  padding: 1rem;
  background: #fff;
  position: relative;
  aspect-ratio: 6/5;
  margin-top: 1.6rem;
  margin-right: 2rem;
  border-radius: 5px;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  img {
    width: 50%;
    height: 50%;
    margin: 0 auto;
    object-fit: contain;
  }

  span {
    margin: 2rem 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 2rem;
    text-align: left;
    text-align: center;
  }
  @media only screen and (max-width: 1024px) {
    flex: 1 1 100%;
    margin: 0.8rem 1.2rem 0.8rem 0;
    flex-direction: row;
    width: 50%;
    align-items: center;
    img {
      height: 64px;
      width: 64px;
      margin: 0 auto;
    }
    span {
      margin: 2rem 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 2rem;
      text-align: left;
      text-align: center;
    }
  }
`;

const Heading = styled(Flex)`
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: 37px;
  margin: 1.6rem 0 2rem 0;

  @media only screen and (max-width: 1024px) {
    font-size: 1.6rem;
  }
`;

const StrengthContainer = styled(Flex)`
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.report["blue"]};
  @media only screen and (max-width: 1024px) {
  }
`;

const MantrasContainer = styled(Flex)``;

const Mantra = styled(Flex)`
  flex: 1;
  width: 100%;
  position: relative;
  background: ${(props) =>
		props.test?.test_configs?.theme?.primary_color ||
		props.theme.colors.blue[500]};
  padding: 3.6rem 2rem;
  border-radius: 5px;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.1rem;

  text-align: center;

  color: ${({ theme }) => theme.colors.white};
  &:first-child {
    margin-right: 1.6rem;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 1.1rem;
    line-height: 1.3rem;
    padding: 1rem;
    width: 45%;
    &:first-child {
      margin-right: 1rem;
    }
  }
`;

const Quotes = styled(Flex)`
  top: 70%;
  opacity: 0.5;
  font-weight: 600;
  font-size: 15rem;
  text-align: center;
  position: absolute;

  color: rgba(255, 255, 255, 0.75);

  justify-content: center;
  @media only screen and (max-width: 1024px) {
    top: 16%;
    font-size: 4rem;
    position: relative;
  }
`;

const SummaryContainer = styled(Value)`
  height: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #555555;

  position: relative;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;

  :before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }

  :after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
`;
