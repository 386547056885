import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Flex from './Flex';
import { Text2Css } from './Typography';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  ${Text2Css}
`;

export const THead = styled.thead`
  background-color: ${(props) => props.theme.colors.grey[100]};
  position: sticky;
  top: 0;
  z-index: 1;
`;
export const TBody = styled.tbody`
  background-color: ${(props) => props.theme.colors.white};
`;
export const TR = styled.tr`
  text-align: left;
  border: 1px solid ${(props) => props.theme.colors.grey[200]};
`;
export const TH = styled.th<{ centered?: boolean }>`
  ${(props) => props.centered && `text-align: center`};
  color: ${(props) => props.theme.colors.grey[600]};
  height: 4rem;
  text-align: center;
`;
export const TD = styled.td<{ maxWidth?: string; centered?: boolean; background?: string;color?:string }>`
  width: ${(props) => props.maxWidth || '10rem'};
  height: 5rem;
  ${(props) => props.centered && `text-align: center`};
  color: ${(props) => props.color||props.theme.colors.grey[500]};
  text-align: center;
  justify-content: center;
  background-color: ${(props) => props.background || ''};
`;

interface PaginationProps {
  currentPage: number;
  nextPageChangeHandler?: any;
  prevPageChangeHandler?: any;
  prevPage: boolean;
  nextPage: boolean;
}

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  nextPageChangeHandler,
  prevPageChangeHandler,
  prevPage,
  nextPage,
}) => {
  return (
    <PaginationContainer align='center'>
      <button
        className={`left ${!prevPage && 'disabled'}`}
        disabled={!prevPageChangeHandler}
        onClick={prevPageChangeHandler}
      >
        Previous
      </button>
      <button className='current-page' disabled>
        {currentPage}
      </button>
      <button
        className={`right ${!nextPage && 'disabled'}`}
        disabled={!nextPageChangeHandler}
        onClick={nextPageChangeHandler}
      >
        Next
      </button>
    </PaginationContainer>
  );
};

const PaginationContainer = styled(Flex)`
  padding: 1.6rem 0rem;
  ${Text2Css}

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    height: 3.5rem;
    padding: 0;
    font-size: 1.6rem;
    padding: 1rem;
    color: ${(props) => props.theme.colors.blue[500]};
    background-color: #fff;
    border: 0.5px solid ${(props) => props.theme.colors.grey[200]};
    cursor: pointer;

    :disabled {
      cursor: not-allowed;
    }

    :not(:disabled):hover {
      filter: invert(8%);
    }

    &.current-page {
      border-left: 0px;
      border-right: 0px;
    }

    &.left {
      border-radius: 4px 0 0 4px;
    }

    &.right {
      border-radius: 0 4px 4px 0;
    }

    &.disabled {
      color: ${(props) => props.theme.colors.grey[400]};
    }
  }
`;

export const TableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  margin: 2rem 0rem;
`;

export const CopyButton = styled(Flex)<{ disabled?: boolean }>`
  cursor: pointer;

  color: ${(props) => props.theme.colors.grey[500]};
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${(props) => props.theme.colors.red[500]};
    `}
  img {
    height: 2.8rem;
    weight: 2.8rem;
    color: ${(props) => props.theme.colors.grey[500]};
    background: ${(props) => props.theme.colors.grey[100]};
    padding: 5px;
    border-radius: 0px 4px 4px 0px;
  }
`;
