import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT, PAGINATION_LIMIT } from '../utils/constants';
import { APIError, PaginationData, Test } from '../utils/types';

const useTestList = (
  userId?: string,
  page = 1,
  search?: string,
  from_?: string,
  to?: string,
  limit = PAGINATION_LIMIT
) => {
  const fetchTestList = async (
    page: number,
    limit: number,
    userId?: string,
    search?: string,
    from_?: string,
    to?: string
  ) => {
    try {
      const {
        data: { data },
      } = await axios.get(`${API_ENDPOINT}user/${userId}/test`, {
        params: { page, limit, search, from_, to },
      });
      return data;
    } catch (error) {
      throw error.response.data;
    }
  };

  return useQuery<
    { tests: Test[]; pagination_data: PaginationData },
    AxiosResponse<APIError>
  >(
    ['testList', userId, page, search, from_, to],
    () => fetchTestList(page, limit, userId, search, from_, to),
    {
      retry: false,
    }
  );
};

export default useTestList;
