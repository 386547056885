import React, { useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentProfile } from '../../hooks/useProfile';
import AuthScreenContainer from '../containers/AuthScreenContainer';

const UnauthenticatedApp = () => {
  const { isLoading, isError } = useCurrentProfile();
  const history = useHistory();

  useEffect(() => {
    if (!isLoading && !isError) {
      history.push('/');
    }
  }, [isLoading, isError, history]);

  return (
    <Fragment>
      <AuthScreenContainer />
    </Fragment>
  );
};

export default UnauthenticatedApp;
