import axios from 'axios';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { IoIosDocument } from 'react-icons/io';
import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CopyLink from '../../assets/copy.png';
import { useTestCards } from '../../hooks/useCards';
import useTestList from '../../hooks/useTestsList';
import { API_ENDPOINT } from '../../utils/constants';
import {
  copyToClipboard,
  formatDate,
  getRatingIcon,
} from '../../utils/helperFunctions';
import Button from '../components/Button';
import { Container } from '../components/Container';
import { CountCard, CountContainer } from '../components/CountCard';
import DateRangeInput from '../components/DateRangeInput';
import Flex from '../components/Flex';
import Header from '../components/Header';
import Loading from '../components/Loading';
import SearchBox from '../components/SearchBox';
import {
  APIError,
  FetchResponse,
  FormFields,
  IOption,
  TestPage,
  ReportPage,
  TestPageConfigs,
  ReportPageConfigs,
  keyValue
} from '../../utils/types';
import {
  CopyButton,
  Pagination,
  Table,
  TableContainer,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from '../components/Table';
import Toast from '../components/Toast';
import ToggleSwitch from '../components/ToggleSwitch';
import AddIcon from "../../assets/add.png";
import {useCurrentProfile} from "../../hooks/useProfile";
import TableEditButton, { Action } from '../components/TableEditButton';
import Edit from '../../assets/edit.png';
import Separator from '../components/Separator';
import AddTest from '../components/popups/AddTest';

const TestList = () => {
  const history = useHistory();

  const { userId } = useParams<{ userId: string }>();
  const { data: currentProfile } = useCurrentProfile();
  const [showToast, setShowToast] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [dateRange, setDateRange] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [showModal, setShowModal] = useState(false);

  const actionOnSubmit = async (id:number,
): Promise<APIError[] | void> => {

  try {
    const data  = await axios.put<FetchResponse<{ message: string }>>(
        API_ENDPOINT + `update-questions/${id}`,
    );
    setToastText('Questions updated successfully')
    setSuccess(true);
    setShowToast(true);
  }
    catch (error: any) {
      const errors = error.response.data.error as APIError[];
      setToastText('Some error occured while updating questions')
      setSuccess(false);
      setShowToast(true);
      return errors;
    }
  };

  const queryClient = useQueryClient();

  const { isLoading, isSuccess, data } = useTestList(
    userId,
    page,
    search,
    dateRange[0],
    dateRange[1]
  );
    const setToast = (success: boolean) => {

        setShowModal(false);
        setToastText(
            `${
                success ? 'Your test has been created successfully' : 'Please try again'
            }.`
        );
        setSuccess(success);
        setShowToast(true);
    };
  const copyTestLink = (testLink: string) => {
    copyToClipboard(testLink);
    setToastText('The test link has been copied.');
    setShowToast(true);
    setSuccess(true);
  };

  const disableTest = async (id: number, status: number): Promise<void> => {
    try {
      const postData = { action: status === 1 ? 0 : 1 };
      await axios.patch<FetchResponse<{ message: string }>>(
        API_ENDPOINT + 'test/' + id,
        postData
      );
      queryClient.invalidateQueries(['testList']);
      queryClient.invalidateQueries(['testCards']);
      setToastText(
        `The test has been ${status === 1 ? 'disabled' : 'enabled'}.`
      );
      setSuccess(true);
      setShowToast(true);
    } catch (error) {
      const message = error.response.data.message;
      setToastText(message);
      setSuccess(false);
      setShowToast(true);
    }
  };

  const { data: testCards } = useTestCards(userId);

  const openTestReports = (test_display_id: string) => {
    history.push(`/report/${test_display_id}`);
  };

  const renderCount = () => {
    return (
      <CountContainer>
        <CountCard column align='center' justify='center'>
          Total Reports
          <span className='count'>{testCards?.report_count || '-'}</span>
        </CountCard>
        <CountCard column align='center' justify='center' color='green'>
          Active Tests
          <span className='count'>{testCards?.active_test || '-'}</span>
        </CountCard>
        <CountCard
          column
          align='center'
          justify='center'
          className='last-child'
        >
          Inactive Tests
          <span className='count'>{testCards?.inactive_test || '-'}</span>
        </CountCard>
      </CountContainer>
    );
  };

  const renderTestList = () => {
    if (isSuccess) {
      return (
        data &&
        data.tests?.map((test, index) => (
          <StyledTR key={index}>
            <TD centered maxWidth='18%'>
              {test.name}
            </TD>
            <TD centered maxWidth='18%'>
              {test.test_display_label}
            </TD>
            <TD centered maxWidth='18%'>
              {test.test_type}
            </TD>
            <TD centered maxWidth='18%'>
              <Flex style={{ justifyContent: 'center' }}>
                {dayjs(test.expiry).diff(dayjs(), 's') > 0 ? (
                  <>
                    <StyledLink
                      href={test.link}
                      target='_blank'
                    >
                      {test.test_display_id}
                    </StyledLink>
                    <CopyButton onClick={() => copyTestLink(test.link)}>
                      <img src={CopyLink} alt='Copy Link' />
                    </CopyButton>
                  </>
                ) : (
                  <DisabledLink>{test.test_display_id}</DisabledLink>
                )}
              </Flex>
            </TD>
            <TD centered maxWidth='18%'>
              {formatDate(test.created)}
            </TD>
            <TD centered maxWidth='18%'>
              {formatDate(test.expiry)}
            </TD>
            <TD centered maxWidth='10%'>
              <ToggleSwitch
                checked={test.status === 1}
                onClick={() => disableTest(test.id, test.status)}
              />
            </TD>
            <TD centered maxWidth='8%'>
              {test.average_rating ? (
                <img
                  src={`${process.env.PUBLIC_URL}/feedback/${getRatingIcon(
                    test.average_rating
                  )}.svg`}
                  style={{ height: '3rem' }}
                />
              ) : (
                '-'
              )}
            </TD>
            <TD centered maxWidth='10%'>
              <ReportButton
                onClick={() => openTestReports(test.test_display_id)}
                disabled={!test.report_count}
              >
                {test.report_count === 0
                  ? 'No Reports'
                  : `${test.report_count} Report`}
                {test.report_count > 1 ? 's' : ''}
                {test.report_count === 0 ? '' : <IoIosDocument />}
              </ReportButton>
            </TD>
            <TD centered maxWidth='5%' style={{ position: 'relative' }}>
              <TableEditButton>
              <Action
                justify='center'
                align='center'
                onClick={() => {
                  console.log(test?.id)
                  actionOnSubmit(test?.id)
                }}
              >
                <img src={Edit} alt='Edit' /> <span>Update Questions</span>
              </Action>
            </TableEditButton>
            </TD>
          </StyledTR>
        ))
      );
    }
  };

  return (
    <Container column>
        {currentProfile?.role == "superadmin" ||
        currentProfile?.role == "admin" ? (
            <StyledButton
                onClick={() => {
                    setShowModal(true);
                }}
            >
                Add New Test
                <img src={AddIcon} alt="Add New Test" />
            </StyledButton>
        ) : null}
      {isLoading && <Loading />}
      <Header align='center' justify='space-between'>
        <DateRangeInput
          onChange={(range) => setDateRange(range)}
          value={dateRange}
        />
        <SearchBox
          placeholder='Search Tests by Name, ID'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Header>
      {renderCount()}
      <TableContainer>
        <Table>
          <THead>
            <StyledTR>
              <TH centered>Test Name</TH>
              <TH centered>Test Display Label</TH>
              <TH centered>Test Type</TH>
              <TH centered>Test ID</TH>
              <TH centered>Starting Time</TH>
              <TH centered>Expiry Time</TH>
              <TH centered>Active</TH>
              <TH centered>Feedback</TH>
              <TH centered>Reports</TH>
              <TH centered>Tools</TH>
            </StyledTR>
          </THead>
          <TBody>{renderTestList()}</TBody>
        </Table>
      </TableContainer>
      {!isLoading && data?.pagination_data && (
        <Pagination
          currentPage={page}
          prevPage={data?.pagination_data.page_prev}
          nextPage={data?.pagination_data.page_next}
          nextPageChangeHandler={
            data?.pagination_data.page_next
              ? function () {
                  setPage((currentPage) => currentPage + 1);
                }
              : undefined
          }
          prevPageChangeHandler={
            data?.pagination_data.page_prev
              ? function () {
                  setPage((currentPage) => currentPage - 1);
                }
              : undefined
          }
        />
      )}
        {showModal?
            (<AddTest
                onClose={(showModal) => setShowModal(showModal)}
                onSuccess={(success) => setToast(success)}
            />) : null
        }
      {showToast && (
        <Toast
          onClose={(showToast) => setShowToast(showToast)}
          success={success}
        >
          {toastText}
        </Toast>
      )}
    </Container>
  );
};

export default TestList;

const StyledTR = styled(TR)`
  border: 1px solid ${(props) => props.theme.colors.grey[200]};
  border-radius: 2px;
`;

const ReportButton = styled(Button)`
  color: ${(props) => props.theme.colors.white};
  font-weight: normal;
  width: 12rem;
  height: 3.5rem;

  svg {
    font-size: 2rem;
    margin-right: 1rem;
    vertical-align: bottom;
  }
`;

const ToolsButton = styled(Button)`
  // color: ${(props) => props.theme.colors.white};
  font-weight: normal;
  width: 12rem;
  height: 3.5rem;

  svg {
    font-size: 2rem;
    margin-right: 1rem;
    vertical-align: bottom;
  }
`;

const StyledLink = styled.a`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.blue[500]};
  background-color: ${(props) => props.theme.colors.grey[50]};
  padding: 0.4rem 0.6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  &:visited {
    color: ${(props) => props.theme.colors.blue[500]};
  }
  &:hover {
    text-decoration: none;
  }

  border-radius: 4px 0px 0px 4px;
`;

const StyledButton = styled(Button)`
  border-radius: 2px;
  width: 20%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.blue[500]};
  box-shadow: 0px 4px 4px ${({ theme }) => theme.colors.grey[100]};
  margin-top: 2rem;
  margin-left: 0rem;

  img {
    margin-left: 2rem;
  }
`;

const DisabledLink = styled(Flex)`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.grey[500]};
  background-color: ${(props) => props.theme.colors.grey[50]};
  padding: 0.4rem 0.6rem;
  border-radius: 4px;
  &:visited {
    color: ${(props) => props.theme.colors.blue[500]};
  }

  border-radius: 4px 0px 0px 4px;
`;

const StyledSeparator = styled(Separator)`
  margin: 0;
`;
