import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import LoaderImage from "../../../assets/Loaders/WeAceLoader.gif";
import { useAnalysisReport } from "../../../hooks/useAnalysisReport";
import Flex from "../../components/Flex";
import Loading from "../../components/Loading";
import Navbar from "../../components/Navbar";
import Footer from ".././Footer";
import "./weacereportpage.css";

import { Button } from "@material-ui/core";
import "./stridepac.css";

const WeAceReportPage = ({ test, testId, isFetched }) => {
  const { token } = useParams<{ token: string }>();
  const customLogo = test?.logo_image;
  const {
    data: reportMeta,
    isSuccess,
    isLoading,
  } = useAnalysisReport(token, true, 5);
  const currentUrlParams = new URLSearchParams(window.location.search);
  function downloadPDF() {
    const linkSource = `data:application/pdf;base64,${reportMeta?.report_metadata}`;
    const downloadLink = document.createElement("a");
    const fileName = `${reportMeta?.name}_${test.test_name}`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    reportMeta?.report_metadata && downloadLink.click();
  }
  console.log(testId);
  useEffect(() => {
    if (currentUrlParams.get("download_now") == "1") {
      downloadPDF();
    }
  }, [reportMeta]);
  return (
    <Container column>
      {!isLoading ? (
        isSuccess ? (
          reportMeta?.report_metadata ? (
            <React.Fragment>
              <Navbar
                authenticate={false}
                homepage="https://we-ace.com/"
                customLogo={customLogo}
              />

              <Content column>
                <div className="main_content_container">
                  <h1
                    style={{
                      fontSize: "3rem",
                      color: `${test?.test_configs?.theme?.primary_color}`,
                      textAlign: "center",
                    }}
                  >
                    Congratulations on completing{" "}
                    {test?.test_display_label
                      ? test?.test_display_label
                      : test?.test_name}
                  </h1>
                  <br></br>
                    <Button
                      style={{
                        minHeight: "45px",
                        color: "#fff",
                        fontSize: "1.2rem",
                        width: '300px',
                        marginTop: '30px',
                        backgroundColor: `${test?.test_configs?.theme?.primary_color}`,
                      }}
                      variant="contained"
                      onClick={() => {
                        downloadPDF();
                      }}
                    >
                      Download Report
                    </Button>
                  <div>
                    <Button
                      style={{
                        minHeight: "45px",
                        color: `${test?.test_configs?.theme?.primary_color}`,
                        width: "300px",
                        fontSize: "1.2rem",
                        marginTop: "1rem",
                      }}
                      variant="outlined"
                      onClick={() =>
                        (window.parent.location.href =
                          "https://we-ace.com/employee/training/list")
                      }
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </Content>
            </React.Fragment>
          ) : // null)
          isFetched ? (
            <Loading LoaderImage={LoaderImage} />
          ) : null
        ) : (
          <Loading LoaderImage={LoaderImage} />
        )
      ) : // null)}
      isFetched ? (
        <Loading LoaderImage={LoaderImage} />
      ) : null}
    </Container>
  );
};

export default WeAceReportPage;

const Container = styled(Flex)`
  width: 100%;
  background: ${({ theme }) => theme.colors.blue[50]};
  font-family: Ubuntu;
  @media only screen and (max-width: 1024px) {
    background: ${({ theme }) => theme.colors.grey[50]};
    padding: 1.2rem;
  }
`;

const Content = styled(Flex)`
  width: 100%;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;
