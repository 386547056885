import React, { useState, useEffect, memo } from "react";
import "./timercontent.css";
const timer_img = require("../../assets/timer.png");



interface props {
  time: string;

  onCompletion: any;
  onContainer: string;
}

export function TimerContent2({ time, onCompletion, onContainer }: props) {

  const timeArray: string[] = time.split(':');
  const [hours, setHours] = useState<number>(+timeArray[0]);
  const [minutes, setMinutes] = useState<number>(+timeArray[1]);
  const [seconds, setSeconds] = useState<number>(+timeArray[2]);
  console.log('15', time)
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes === 0) {
          if (hours === 0) {
            clearInterval(myInterval);
            onCompletion();
          } else {
            setHours(hours - 1);
            setMinutes(59);
            setSeconds(59);
          }
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [hours, minutes, seconds]);

  const getClass = () => {
    return hours === 0 ? (minutes < 5 ? 'blink' : '') : '';
  };


  return (
    <h6 className={onContainer === "questionContainer" ? "question_blue_container" : "question_timer_container"}>
      <img src={timer_img.default} alt="timer" className="timer_img" />
      <span className="out_of_title_text">
        {minutes < 10 ? `0${minutes}` : minutes}
        :
        {seconds < 10 ? `0${seconds}` : seconds}
      </span>
    </h6>
  );
}


export const TimerContent = memo(TimerContent2)