import React from "react";
import RoutesContainer from "./ui/containers/RoutesContainer";
export { default as QuizzBuilder } from "./QuizzBuilder/FormBuilder";
export { default as Quiz } from "./Quiz/Quiz";

function App() {
  return <RoutesContainer />;
}

export default App;
