import React, { useContext } from "react";
import { toggleSampleQuestion } from "../../reducer/actions";
import QuizzContext, { IQuizzBuilderContext } from "../../../QuizzContext";
import { Switch } from 'antd';

// applies delete and edit capabilities
export default (props: any) => {
  const { dispatch } = useContext(QuizzContext) as IQuizzBuilderContext;

  return (
    <>
        Sample Question:{" "}
        <Switch
          checked={props.checked}
          onChange={(value) => {
            dispatch(toggleSampleQuestion(props.id, value));
          }}
        />
    </>
  );
};
