import {IOption, ValidationSchema} from "./types";

export const TOAST_DELAY: number = 5000;

export const RADIO_OPTIONS_AUTH_TYPE: IOption[] = [
    {
        label: "Open Test",
        value: "0",
    },
    {
        label: "Authorized Test",
        value: "1",
    },
    {
        label: "Third Party Test",
        value: "2",
    },
];

export const RADIO_OPTIONS_TIMED_ASSESSMENT: IOption[] = [
    {
        label: "Yes",
        value: "1",
    },
    {
        label: "No",
        value: "0",
    },
];

export const ADD_TEST_PAGE_VALIDATION_SCHEMA: ValidationSchema = {
    test_name: {
        name: 'Test Name',
        required: true,
    },
    test_display_label: {
        name: 'Test Label',
        required: true,
    },
    test_type: {
        name: 'Test Type ',
        required: true,
    }
};

export const ADD_REPORT_PAGE_VALIDATION_SCHEMA: ValidationSchema = {
    test_name: {
        name: 'Test Name',
        required: true,
    },
    test_display_label: {
        name: 'Test Label',
        required: true,
    },
    test_type: {
        name: 'Test Type ',
        required: true,
    }
};


export const ADD_TEST_VALIDATION_SCHEMA: ValidationSchema = {
    test_name: {
        name: "Test Name",
        required: true,
    },
    test_display_label: {
        name: "Test Display Label",
        required: true,
    },
    test_type: {
        name: "Test Type",
        required: true,
    },
    test_auth: {
        name: "Authorized Test",
        required: true,
    },
    test_expiry: {
        name: "Expiry Time",
        required: true,
    },
    no_of_questions: {
        name: "Number of Questions per page",
        required: true,
    },
    logo_image: {
        name: "Optional logo image",
        required: false,
    },
};

export const TEST_DETAIL_VALIDATION_SCHEMA: ValidationSchema = {
    first_name: {
        name: "First Name",
        required: true,
    },
    last_name: {
        name: "Last Name",
        required: true,
    },
    email: {
        name: "Email",
        required: true,
        regex:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        errorText: "Please enter a valid email address",
    },
    phone_no: {
        name: "Phone Number",
        required: true,
        regex: /^[789]\d{9}$/,
        errorText: "Please enter a valid phone number",
    },
};

export const LOGIN_FORM_VALIDATION_SCHEMA: ValidationSchema = {
    email: {
        name: 'Email address',
        required: true,
        regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        errorText: 'Please enter a valid email address',
    },
    password: {
        name: 'Password',
        required: true,
    },
};


export const REGISTER_FORM_VALIDATION_SCHEMA: ValidationSchema = {
    first_name: {
        name: 'First name',
        required: true,
    },
    last_name: {
        name: 'Last name',
        required: true,
    },
    password: {
        name: 'Password',
        required: true,
    },
    email: {
        name: 'Email address',
        required: true,
        errorText: 'Please enter a valid email address',
    },
    phone_no: {
        name: "Phone Number",
        required: true,
        regex: /^[6789]\d{9}$/,
        errorText: "Please enter a valid phone number",
    },
};

export const ratings = [
    {
        icon: "pleased",
        rating: 5,
    },
    {
        icon: "happy",
        rating: 4,
    },
    {
        icon: "neutral",
        rating: 3,
    },
    {
        icon: "sad",
        rating: 2,
    },
    {
        icon: "angry",
        rating: 1,
    },
];

export const PAGINATION_LIMIT = 20;
export const IMAGE_LIST = {
    "desktop": ["https://d2b560u3hlgvw0.cloudfront.net/IYH2DB_Group%2029.jpg", "https://d2b560u3hlgvw0.cloudfront.net/87RVWY_Group%2030.jpg", "https://d2b560u3hlgvw0.cloudfront.net/MG3N8R_Features_1.png"],
    "mobile": ["https://d2b560u3hlgvw0.cloudfront.net/TT3XD8_Group%2031.jpg", "https://d2b560u3hlgvw0.cloudfront.net/965JOK_Screenshots%20(1).png", "https://d2b560u3hlgvw0.cloudfront.net/3CIVK4_Screenshots2.png", "https://d2b560u3hlgvw0.cloudfront.net/TD88BV_Screenshots2-1.png"]
}
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT as string;
// export const API_ENDPOINT='https://tests-backend.staging.strideahead.in/';
export const LOCALHOST = process.env.REACT_APP_LOCALHOST

export const STRIDE_ID = process.env.REACT_APP_LOGIN_URL as string;
export const STRIDEAHEAD = process.env.REACT_APP_STRIDEAHEAD as string;
export const DICEBAR_URL = process.env.REACT_APP_DICEBAR_URL as string;
export const PROCESSOR_URL = process.env.REACT_APP_PROCESSOR_URL as string;
export const S3_URL = process.env.REACT_APP_S3_URL as string;
export const PROCESSOR_S3_URL = "https://d1dhn4cz1wmlqo.cloudfront.net/";
export const STRIDE_ID_AUTH = process.env.REACT_APP_AUTH_URL as string;
export const DISCORD_LINK = process.env.REACT_APP_DISCORD_URL as string;
export const PAGES_URL = process.env.REACT_APP_PAGES_BASE_URL as string;
export const MANAGEMENT_COHOT_PRODUCT_ID = process.env.REACT_APP_MANAGEMENT_COHOR_PRODUCT_ID as string;
export const MAP_FEATURES = process.env.REACT_APP_MAP_FEATURES as any;
export const MAP_FEATURES_FOR_REDUTECH = process.env.REACT_APP_MAP_FEATURES_FOR_REDUTECH as any;
export const MAP_REDIRECT_URL = process.env.REACT_APP_MAP_REDIRECT_URL as string;
export const LOW_PROMOCODE = process.env.REACT_APP_MAP_LOW_PROMOCODE as string;
export const LOW_MEDIUM_PROMOCODE = process.env.REACT_APP_MAP_LOW_MEDIUM_PROMOCODE as string;
export const HIGH_PROMOCODE = process.env.REACT_APP_MAP_HIGH_PROMOCODE as string;
export const HIGH_MEDIUM_PROMOCODE = process.env.REACT_APP_MAP_HIGH_MEDIUM_PROMOCODE as string;
export const BRAINWAVE_UUID = process.env.REACT_APP_BRAINWAVE_UUID as string;
export const IGNITER_REDIRECT_URL_0 = process.env
    .REACT_APP_IGNITER_REPORT_URL_0 as string;
export const IGNITER_REDIRECT_URL_1 = process.env
    .REACT_APP_IGNITER_REPORT_URL_1 as string;
export const IGNITER_REDIRECT_URL_2 = process.env
    .REACT_APP_IGNITER_REPORT_URL_2 as string;
export const IGNITER_REPORT_CTA_0 = process.env
    .REACT_APP_IGNITER_REPORT_CTA_0 as string;
export const IGNITER_REPORT_CTA_1 = process.env
    .REACT_APP_IGNITER_REPORT_CTA_1 as string;
export const IGNITER_REPORT_CTA_2 = process.env
    .REACT_APP_IGNITER_REPORT_CTA_2 as string;
export const STRIDE_PROFILE_LINK = process.env
    .REACT_APP_STRIDE_PROFILE_LINK as string;
export const PROFILE_NAVIGATION_CTA = process.env
    .REACT_APP_PROFILE_NAVIGATION_CTA as string;
export const CAREER_TOOLTIP = process.env
    .REACT_APP_CAREER_CLUSTER_TOOLTIP as string;
export const PHRASE_TOOLTIP = process.env.REACT_APP_PHRSE_TOOLTIP as string;
export const DWEC_TERMS_AND_CONDITION = process.env
    .REACT_APP_DWEC_TERMS_AND_CONDITION as string;
export const STRIDE_TERMS_AND_CONDITION = process.env
    .REACT_APP_STRIDE_TERMS_AND_CONDITION as string;
export const REPORT_SHARE_TEXT =
    "I still can't believe my career persona! I just found out from a flash 12-minute assessment: {reportUrl}.";

export const HIGH_PERCENTAGE = process.env
    .REACT_APP_QUIZ_REPORT_HIGH_PERCENTAGE as string;
export const MEDIUM_PERCENTAGE = process.env
    .REACT_APP_QUIZ_REPORT_MEDIUM_PERCENTAGE as string;
export const LOW_PERCENTAGE = process.env
    .REACT_APP_QUIZ_REPORT_LOW_PERCENTAGE as string;
export const REPORT_PAGE_BOOKING_LINK =
    process.env.REACT_APP_REPORT_PAGE_BOOKING_LINK;
export const GOOGLE_CLIENT_ID = process.env
    .REACT_APP_GOOGLE_CLIENT_ID as string;
export const REGISTER_LINK = process.env
    .REACT_APP_REGISTER_LINK as string;

export const RAZORPAY_LINK = process.env
    .REACT_APP_RAZORPAY_URL as string;

export const RAZORPAY_KEY = process.env
    .REACT_APP_RAZORPAY_KEY as string;
export const SHARE_LINK = process.env
    .REACT_APP_SHARE_LINK as string;
export const CALENDER_LINK = process.env
    .REACT_APP_CALENDER_LINK as string;
export const BOOKING_LINK = process.env
    .REACT_APP_BOOK_TICKET_URL as string;

export const REPORT_PAGE_URL = process.env.REACT_APP_REPORT_PAGE_URL as string;
export const GENERIC_COUNTDOWN_PAGE = process.env.REACT_APP_GENERIC_COUNTDOWN_PAGE as string;

export const RED_COLOR =  process.env.REACT_APP_RED_COLOR_CODE as string;
export const YELLOW_COLOR =  process.env.REACT_APP_YELLOW_COLOR_CODE as string;
export const GREEN_COLOR =  process.env.REACT_APP_GREEN_COLOR_CODE as string;
export const REDUTECH_REPORT_PAGE_PRODUCT_ID =  process.env.REACT_APP_REDUTECH_REPORT_PAGE_PRODUCT_ID as string;
