// @ts-nocheck
import React, { FC } from 'react';
import { DateTimePicker, Application } from 'react-rainbow-components';
import styled, { css } from 'styled-components';
import { shake } from '../../styles/animations';
import { theme } from '../../styles/theme';
import { Text1Css } from './Typography';

interface IProps {
  name: string;
  label?: string;
  error?: string;
  showSuccessIndicator?: boolean;
  showSearchIndicator?: boolean;
  placeholder?: string;
  required?: boolean;
  value?: string;
  onChange?: any;
  minDate?: Date;
}
const DateTimeInput: FC<IProps> = ({
  label,
  name,
  placeholder,
  required,
  error,
  value,
  onChange,
  showSuccessIndicator = false,
  showSearchIndicator = false,
  minDate,
  ...rest
}) => {
  return (
    <Container error={error} name={name}>
      {label && (
        <label htmlFor={name}>
          {label}
          {required && ' *'}
        </label>
      )}
      <Application theme={theme}>
        <DateTimePicker
          id={name}
          className={`${error && 'error'}`}
          formatStyle='small'
          onChange={onChange}
          value={value}
          minDate={minDate}
        />
      </Application>
      {error && <span className='error'>{error}</span>}
    </Container>
  );
};

export default DateTimeInput;

const Container = styled.div<{
  error?: string;
  name: string;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 1rem;

  ${Text1Css}

  /* Shake if there is an error */
  ${({ error }) =>
    error &&
    css`
      animation: ${shake} 0.7s ease-out;
    `}

  label {
    color: ${({ theme }) => theme.colors.grey[500]};
    font-weight: normal;
  }

  * {
    font-size: 1.6rem !important;
  }

  input,
  input:focus,
  input:active {
    width: 100%;
    height: 5rem;
    padding: 1.2rem;
    margin-top: 0.5rem;
    outline: none;
    font-size: inherit;
    border: 1px solid ${({ theme }) => theme.colors.grey[500]};

    /* Focused state styles */
    &.focused {
      box-shadow: 0 0 2px ${({ theme }) => theme.colors.blue[500]};
      border-color: ${({ theme }) => theme.colors.blue[500]};
    }

    /* Error state styles */
    &.error {
      box-shadow: 0 0 2px ${({ theme }) => theme.colors.red};
      border-color: ${({ theme }) => theme.colors.red[500]};
    }

    border-radius: 0.6rem;
    color: ${({ theme }) => theme.colors.grey[500]};
    transition: 0.1s;
  }

  span.error {
    margin-top: 0.8rem;
    margin-left: 0.4rem;
    color: ${({ theme }) => theme.colors.red[500]};
  }

  svg {
    display: none;
  }
`;
