import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { API_ENDPOINT } from "../utils/constants";
import { APIError, TestDetail } from "../utils/types";

//const useTest = (testId: string) => {
//  const fetchTest = async (testId: string) => {
//    try {
//      const {
//        data: { data },
//      } = await axios.get(`${API_ENDPOINT}test/${testId}`);
//      console.log("dataaaaaaaaaaaa", data);
//      return data;
//    } catch (error) {
//      console.log(error);
//      throw error.response.data;
//    }
//  };

//  return useQuery<TestDetail, AxiosResponse<APIError>>(
//    ["test", testId],
//    () => fetchTest(testId),
//    {
//      refetchOnWindowFocus: false,
//      refetchOnReconnect: false,
//      retry: false,
//    }
//  );
//};

//export default useTest;

const useTest = (testId: string) => {
  const fetchTest = async (testId: string) => {
    try {
      const {
        data: { data },
      } = await axios.get(`${API_ENDPOINT}test/${testId}`);

      // Recursively replace URLs in the data object
      const replaceUrls = (obj: any): any => {
        if (typeof obj !== "object" || obj === null) {
          return obj;
        }

        if (Array.isArray(obj)) {
          return obj.map((item) => replaceUrls(item));
        }

        return Object.fromEntries(
          Object.entries(obj).map(([key, value]) => {
            if (
              typeof value === "string" &&
              value.includes("https://d2ny4i6ieyrjfu.cloudfront.net")
            ) {
              return [
                key,
                value.replace(
                  "https://d2ny4i6ieyrjfu.cloudfront.net",
                  "https://d1dhn4cz1wmlqo.cloudfront.net"
                ),
              ];
            } else {
              return [key, replaceUrls(value)];
            }
          })
        );
      };

      const newData = replaceUrls(data);
      return newData;
    } catch (error) {
      console.log(error);
      throw error.response.data;
    }
  };

  return useQuery<TestDetail, AxiosResponse<APIError>>(
    ["test", testId],
    () => fetchTest(testId),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
};

export default useTest;
