// @ts-nocheck
import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import manojLogo from "../../../../src/assets/mentors/amity.png";
import jagmohanMentor from "../../../../src/assets/mentors/Cdr. Jagmohan Bhogal.png";
import dhairyaMentor from "../../../../src/assets/mentors/dhairyaMentor.png";
import deeptiLogo2 from "../../../../src/assets/mentors/QCI-Logo 1.png";
import aryaMentor from "../../../../src/assets/mentors/Harish.jpeg";
import {
    default as sajalLogo2,
    default as sumitLogo2,
} from "../../../../src/assets/mentors/chegg.png";
import vijayMentor from "../../../../src/assets/mentors/vijay.png";
import nilayLogo1 from "../../../../src/assets/mentors/Indian_Navy_logo.png";
import ashok from "../../../../src/assets/mentors/ashok.png";
import piyushMentor from "../../../../src/assets/mentors/piyush.png";
import piyushLogo from "../../../../src/assets/mentors/VeriSmart.png";
import piyushLogo3 from "../../../../src/assets/mentors/byju's.png";
import {
    default as piyushLogo1,
    default as rohanLogo1,
} from "../../../../src/assets/mentors/DTU.png";
import sumitLogo1 from "../../../../src/assets/mentors/dell.png";
import rohanLogo from "../../../../src/assets/mentors/sail.png";
import rohanLogo2 from "../../../../src/assets/mentors/bhart.png";
import AbhinavMentor from "../../../../src/assets/mentors/Abhinav.png";
import divyaMentor from "../../../../src/assets/mentors/Virendra.jpeg";
import sajalLogo1 from "../../../../src/assets/mentors/P&G_logo.png";
import MaryMentor from "../../../../src/assets/mentors/Mary.png";
import whit from "../../../../src/assets/mentors/startup.png";
import sumitMentor from "../../../../src/assets/mentors/SumitAgrawal_Pic.png";
import sumitMicrosoft from "../../../../src/assets/mentors/microsoft.png";
import logoXlri from "../../../../src/assets/mentors/logo-xlri.svg";
import AbhinavMentorLogo from "../../../../src/assets/mentors/ISB.jpg";
import AbhinavMentorLogo2 from "../../../../src/assets/mentors/telstar.png";
import dhairyaMentorlogo from "../../../../src/assets/mentors/kpmg.png";
import dhairyaMentorlogo2 from "../../../../src/assets/mentors/josh talks.png";
import styled from "styled-components";

export default function RedutechExpertSwipe() {
    const [screenWidth, setScreenWidth] = useState(0);
    const [readMore, setReadMore] = useState(-1);
    useEffect(() => {
        let width = window.innerWidth;
        setScreenWidth(width);
    }, []);
    const PAGES_S3_URL = "https://d2b560u3hlgvw0.cloudfront.net";
    const settings = {
        dots: false,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 324,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
        ],
    };

    function SampleNextArrow(props) {
        const {className, style, onClick} = props;
        return (
            <div className="same-slider-class-arrow right-arrow" onClick={onClick}>
                <svg
                    width="11"
                    height="15"
                    viewBox="0 0 11 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.81836 1.53516L8.28296 9L1.81836 16.4646"
                        stroke="white"
                        stroke-width="3"
                        stroke-linecap="round"
                    />
                </svg>
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const {className, style, onClick} = props;
        return (
            <div className="same-slider-class-arrow left-arrow" onClick={onClick}>
                <svg
                    width="11"
                    height="15"
                    viewBox="0 0 11 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.2793 1.53516L2.8147 9L9.2793 16.4646"
                        stroke="white"
                        stroke-width="3"
                        stroke-linecap="round"
                    />
                </svg>
            </div>
        );
    }

    const allMetorItems = [
        {
            mentorImg: AbhinavMentor,
            mentorName: "Abhinav Mahadevan",
            desc: "Delivery Manager with a track record of successful multi-million dollar project implementations for 14 years",
            mentorSubName:
                "Agile Coach- Telstra | MBA from ISB, Seasoned IT Program ",
            logoImgMentor: [AbhinavMentorLogo, AbhinavMentorLogo2],
        },
        {
            mentorImg: MaryMentor,
            mentorName: "Mary Tressa Gabriel",
            desc: "Supporting individuals in obtaining  entry-level project management positions.",
            mentorSubName:
                "Project Manager at Dell | Tools like: S-Suite|ASANA|Notion",
            logoImgMentor: [sumitLogo1, sumitLogo2],
        },

        {
            mentorImg: piyushMentor,
            mentorName: "Piyush Gupta",
            desc: "Founder, Career Mentor, and Product Management Expert with 7+ years running tech-driven businesses, Co-founder verismart.ai",
            mentorSubName: "Co-founder Stride Ahead",
            logoImgMentor: [piyushLogo, piyushLogo1, piyushLogo3],
        },
        {
            mentorImg: dhairyaMentor,
            mentorName: "Dhairya Gangwani",
            desc: "Guides and mentors leaders, entrepreneurs, industry experts, & Brands with building a personal brand for themselves on LinkedIn and generating more leads as well.",
            mentorSubName: "Career Coach | Public Speaker | Personal Branding",
            logoImgMentor: [dhairyaMentorlogo, dhairyaMentorlogo2],
        },
        {
            mentorImg: aryaMentor,
            mentorName: "Dr. Harish Kumar Dwivedi",
            mentorSubName: "Mentor, UPStartup\n" +
                "Ex Director, Amity School of Applied Sciences,\n" +
                "Professor & Incharge, Maharishi University of Information Technology (MUIT), Lucknow\n",
            desc: "",
            logoImgMentor: [whit, manojLogo],
        },
        {
            mentorImg: divyaMentor,
            mentorName: "Virendra Nath grover",
            desc: "",
            mentorSubName: "Chief Engineer (HRD & Knowledge Management) at KORUS\n" +
                "Prof and HoD (Training) in Engineering College (2010 to 2012)\n" +
                "Worked at SAIL, Alloy Steels Plant Durgapur\n",
            logoImgMentor: [rohanLogo, rohanLogo2],
        },
        {
            mentorImg: vijayMentor,
            mentorName: "Vijay Kumar Gupta",
            desc: "13+ Years of Management Training and 30+ Years of Managerial Experience",
            mentorSubName: "Management Trainer | Motivational Speaker",
            logoImgMentor: [ashok],
        },
        {
            mentorImg: jagmohanMentor,
            mentorName: "Jagmohan Bhogal",
            desc: "Emotional Intelligence Trainer and Coach,Ex Navy and Quality council of India",
            mentorSubName: "Founder ThinkInfluence Academy",
            logoImgMentor: [nilayLogo1, deeptiLogo2],
        },
        {
            mentorImg: sumitMentor,
            mentorName: "Sumit Agarwal",
            desc: "Helped 7000+ MBA students with acing their careers into new heights",
            mentorSubName:
                "Director at Chrolochem pvt ltd | Ex Microsoft and P&G | Mba from XLRI",
            logoImgMentor: [sumitMicrosoft, sajalLogo1 ,logoXlri],
        },
    ];

    return (
        <div className="classess-banner2">
            <div className="container-md">
                <div className="classess-page-title">
                    <h1> Learn from the <span style={{textDecoration: "line-through"}}>Top</span> Relevant 1%</h1>
                    <p className="text-white">
                        Our Mentors are handpicked and thoroughly vetted to ensure you get the best mentorship.Here are
                        a few of our reputed and highly qualified Industry
                    </p>
                </div>
                <div className="slider-section-design">
                    <Slider {...settings}>
                        {allMetorItems?.map((perMentorItems, index) => {
                            return (
                                <div key={index}>
                                    <div className="slider-section-card">
                                        <div className="slider-image">
                                            <img src={perMentorItems.mentorImg} alt="CardImage"/>
                                        </div>
                                        <div className="slider-card-details">
                                            <h5>{perMentorItems.mentorName}</h5>
                                            <p>{perMentorItems.mentorSubName}</p>
                                            {screenWidth > 786 ? (
                                                <span>
                          {readMore === index ? (
                              <>
                                  {perMentorItems.desc}{" "}
                                  <a
                                      onClick={() => setReadMore(-1)}
                                      style={{
                                          fontSize: "12px",
                                          color: "rgb(15, 11, 203)",
                                          fontWeight: "700",
                                      }}
                                  >
                                      ...Read less
                                  </a>
                              </>
                          ) : (
                              <>
                                  {perMentorItems?.desc
                                      ?.split(" ")
                                      ?.slice(0, 8)
                                      ?.join(" ")}{" "}
                                  {perMentorItems?.desc?.split(" ").length > 8 ? (
                                      <a
                                          onClick={() => setReadMore(index)}
                                          style={{
                                              fontSize: "12px",
                                              color: "rgb(15, 11, 203)",
                                              fontWeight: "700",
                                          }}
                                      >
                                          ...Read more
                                      </a>
                                  ) : (
                                      ""
                                  )}
                              </>
                          )}
                        </span>
                                            ) : (
                                                <span>
                          {readMore === index ? (
                              <>
                                  {perMentorItems.desc}{" "}
                                  <a
                                      onClick={() => setReadMore(-1)}
                                      style={{
                                          fontSize: "12px",
                                          color: "rgb(15, 11, 203)",
                                          fontWeight: "700",
                                      }}
                                  >
                                      ...Read less
                                  </a>
                              </>
                          ) : (
                              <>
                                  {perMentorItems?.desc
                                      ?.split(" ")
                                      ?.slice(0, 8)
                                      ?.join(" ")}{" "}
                                  {perMentorItems?.desc?.split(" ").length > 8 ? (
                                      <a
                                          onClick={() => setReadMore(index)}
                                          style={{
                                              fontSize: "12px",
                                              color: "rgb(15, 11, 203)",
                                              fontWeight: "700",
                                          }}
                                      >
                                          ...Read more
                                      </a>
                                  ) : (
                                      ""
                                  )}
                              </>
                          )}
                        </span>
                                            )}
                                            {/* <span>{perMentorItems?.desc}</span> */}
                                            <div className="slider-card-image-alignment">
                                                {perMentorItems.logoImgMentor?.map((img) => (
                                                    <div>
                                                        <img src={img} alt="GoogleIcon"/>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
                <StyledButton className="d-flex justify-content-center">
                    <a href="https://railedutech.com/mentors.php " target="_blank">
                        View more
                    </a>
                </StyledButton>
            </div>
        </div>
    );
}

const StyledButton = styled.div`
 margin-top:40px;
 text-align:center;
  @media(max-width:768px){
     margin-top:90px;
   }
 a{
   background: linear-gradient(90.67deg, #8B292A 24.23%, #CB5657 127.76%);
   border-radius: 10px;
   padding: 20px 50px;
   color: #FFFFFF;
   border:none;
   font-weight: 600;
   font-size: 28px;
   letter-spacing: 0.02em;
   color: #F2EFFB !important;
   text-decoration:none;
   
   @media(max-width:768px){
     padding: 22px 30px;
     font-size:20px;
     width:100%;
   }
 }
`;
