import styles from "../../../styles/getCertificate.module.css";
import { IoMdCube } from "react-icons/io";
import React from "react";
import { data } from "../../../data";
import DotsIcon from "../../../assets/getCertified/dots.png";
import BottomIcon from "../../../assets/getCertified/bottom.png";
import TopIcon from "../../../assets/getCertified/top.png";
import CertificateImage from "../../../assets/images/certificate.png";
import VectoreImage from "../../../assets/images/new-vectore.svg";
import certificate from "../../../assets/getCertified/certificate.png";

const { content, title, desc, button } = data.getCertificateSection;

const GetCertificate = ({ setOpen }: any) => {
  return (
    <>
      <div className={styles.certificateAllContentAlignment}>
        <div className="container-md">
          <div className={styles.sectionTitle}>
            <h1> Get Certificate Signed by ISB, XLRI, DTU and IIT Alumni </h1>
          </div>
          <div className={styles.cerGrid}>
            <div className={styles.cerGridItems}>
              <div className={styles.allTextIconContentAlignment}>
                <div className={styles.iconTextGrid}>
                  {content.map((row, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className={styles.logo}>
                          <IoMdCube className={styles.IoMdCube_image} />
                        </div>
                        <div className={styles.iconTextGridItems}>
                          <p>{row.title}</p>
                          <span>{row.description}</span>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={styles.cerGridItems}>
              <div className={styles.CertificateImageStyle}>
                <img src={CertificateImage} alt="CertificateImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GetCertificate;
