import {
    MAP_REDIRECT_URL,
    RAZORPAY_LINK,
    RAZORPAY_KEY, API_ENDPOINT, PAGES_URL
} from "../utils/constants";
import ModalContainer from "../../src/ui/components/DetailModal/ModalContainer";
import Modal from "../../src/ui/components/Modal/Modal";
import Modal2 from "../../src/ui/components/Modal/Modal2";
import Logo from '../assets/redutech_logo.svg'
import {useEffect, useState} from "react";
import axios from "axios";

export const makePayment = async (data: any, userData: any, setOpen: any) => {
    // return
    const res = await initializeRazorpay();
    if (!res) {
        alert("Razorpay SDK Failed to load");
        return;
    }
    var options = {
        key: RAZORPAY_KEY,
        name: "Stride Ahead",
        currency: "INR",
        amount: data.order.amount,
        order_id: data.order.id,
        image: "/Section1/logo.svg",
        handler: function (res: any) {
            // window.location.href = MAP_REDIRECT_URL;
            if (res.razorpay_order_id) {
                <ModalContainer setOpen={setOpen(4)}>
                    <Modal setOpen={setOpen(4)}/>
                </ModalContainer>;
            }
        },

        prefill: {
            name: userData?.name,
            email: userData?.email,
            contact: userData?.phone_no,
        },
    };

    // @ts-ignore
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
};
export const makePayment2 = async (data: any, userData: any, emailData: any, setOpen: any) => {
    // return
    const res = await initializeRazorpay();
    if (!res) {
        alert("Razorpay SDK Failed to load");
        return;
    }
    var options = {
        key: "rzp_live_XSJAWfHSoqGAJI",
        name: "Redutech",
        currency: "INR",
        amount: data.order.amount,
        order_id: data.order.id,
        image: Logo,
        handler: function (res: any) {

            if (res.razorpay_order_id) {
                setOpen(5)

            console.log(res,"main")
                sendEmail();
            }
        },

        prefill: {
            name: userData?.name,
            email: userData?.email,
            contact: userData?.phone_no,
        },
    };

    // @ts-ignore
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    const sendEmail = () => {
        return fetch(`${PAGES_URL}send-email`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(emailData),
        }).then((res) => {
            if (res?.status === 200) {
                console.log("success")
            } else {
                console.warn("erro")
            }
        }).catch((error) => {
            console.warn("erro")
        })
    }
};

const initializeRazorpay = () => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = RAZORPAY_LINK;

        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };

        document.body.appendChild(script);
    });
};



