import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAnalysisReport } from '../../../hooks/useAnalysisReport';
import { useProduct } from '../../../hooks/useProduct';
import {
    IGNITER_REDIRECT_URL_0,
    IGNITER_REDIRECT_URL_1,
    IGNITER_REDIRECT_URL_2,
    IGNITER_REPORT_CTA_0,
    IGNITER_REPORT_CTA_1,
    IGNITER_REPORT_CTA_2,
    REDUTECH_REPORT_PAGE_PRODUCT_ID
} from '../../../utils/constants';
import Flex from '../../components/Flex';
import Loading from '../../components/Loading';
import ProgramFor from "../../components/Report Section/ProgramFor";
import RecommendSection from "../../components/Report Section/RecommendSection2";
import RedutechCollaborator from "../../components/Report Section/RedutechCollaborator";
import RedutechExpertSwipe from "../../components/Report Section/RedutechExpertSwipe";
import { RedutechFAQ } from "../../components/Report Section/RedutechFaq";
import RedutechFooter from "../../components/Report Section/RedutechFooter";
import RedutechGetCertificate from "../../components/Report Section/RedutechGetCertificate";
import ReduTechHeroSection from "../../components/Report Section/RedutechHeroSection";
import RedutechPaymentSection from "../../components/Report Section/RedutechPaymentSection";
import RedutechStudentSwipe from "../../components/Report Section/RedutechStudentSwipe";
import RedutechThreeMonthReport from "../../components/Report Section/RedutechThreeMonthReport";
import WhatYouWillGet from "../../components/Report Section/WhatYouWillGet";
import RedutechCapsule from '../../components/Report Section/RedutechCapsule';
import RedutechChart from '../../components/Report Section/RedutechChart';
import { StickyButton } from '../../components/StickyButton';
import Toast from "../../components/Toast";
import ErrorFallback from '.././ErrorFallback';
import CalculatingPage2 from '../CalculatingPage2';
import './stridepac.css';
import { Helmet } from 'react-helmet';
import fav from "../../../../src/assets/redutech_logo.c8266a92.svg"

const tagManagerArgs = {
    gtmId: 'G-SBM961SS5B'
}

TagManager.initialize(tagManagerArgs)

const RedutechReportPage2 = ({test, testId, isFetched}) => {
    const {token} = useParams<{ token: string }>();
    const [showModalNo, setShowModalNo] = useState<any>(0);
    const [promoCodes, setPromoCodes] = useState<any>({});
    const [isSticky, setIsSticky] = useState<boolean>(false);
    const [courseType, setCourseType] = useState<any>('');
    const [courseType2, setCourseType2] = useState<any>('');
    const [CTADetails, setCTADetails] = useState({});
    const [toastText, setToastText] = useState<string>("");
    const [showToast, setShowToast] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const customLogo = test?.logo_image;
    const {data: reportMeta, isSuccess, isLoading} = useAnalysisReport(token);
    const [loading, setLoading] = useState<boolean>(false);
    const {data: productId} = useProduct('53');
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();

    useEffect(() => {
        setCTADetails(URLProvider(query?.get('cta_type')));
    }, []);

    useEffect(() => {
        if (reportMeta?.report_metadata?.recommended_course === "CC") {
            setCourseType("Cloud solution architect")
            setCourseType2("Cloud solution architect")
        } else if (reportMeta?.report_metadata?.recommended_course === "CS") {
            setCourseType("Cyber Security")
            setCourseType2("Cyber Security")
        } else if (reportMeta?.report_metadata?.recommended_course === "BDA") {
            setCourseType("Big Data Analytics")
            setCourseType2("Big Data Analytics")
        }
    }, [reportMeta?.report_metadata?.recommended_course])


    const URLProvider = (cta_type) => {
        switch (cta_type) {
            case '0':
                return {
                    url: IGNITER_REDIRECT_URL_0,
                    text: IGNITER_REPORT_CTA_0,
                };
            case '1':
                return {
                    url: IGNITER_REDIRECT_URL_1,
                    text: IGNITER_REPORT_CTA_1,
                };
            case '2':
                return {
                    url: IGNITER_REDIRECT_URL_2,
                    text: IGNITER_REPORT_CTA_2,
                };
            default:
                return {
                    url: IGNITER_REDIRECT_URL_0,
                    text: IGNITER_REPORT_CTA_0,
                };
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(true);
        }, 5000);
    }, []);

    

    return (
        
        <Container column>
            <Helmet>
        <title>Assessment Report</title>
        <link rel="icon" href={fav} />
      </Helmet>
            {!isLoading ? (
                    isSuccess ? (
                        reportMeta?.report_metadata && loading ? (
                                <React.Fragment>
                                    <div className="bg-white">
                                        <ReduTechHeroSection name={reportMeta?.name}/>
                                    </div>
                                    <div className="bg-white">
                                        <RecommendSection reportMeta={reportMeta} />
                                    </div>
                                    <div className="bg-white">
                                        <RedutechCapsule reportMeta={reportMeta}/>
                                    </div>
                                    <div className="bg-white">
                                        <RedutechChart reportMeta={reportMeta}/>
                                    </div>
                                    <div className="bg-white">
                                        <ProgramFor setCourseType={setCourseType} reportMeta={reportMeta}/>
                                    </div>
                                    <RedutechExpertSwipe/>
                                    <div className="bg-white">
                                        <RedutechCollaborator/>
                                    </div>
                                    <RedutechGetCertificate/>
                                    <div className="bg-white">
                                        <WhatYouWillGet/>
                                    </div>
                                    <div style={{backgroundColor: "#FFF7F7"}}>
                                        <RedutechPaymentSection
                                            setSuccess={setSuccess}
                                            setShowToast={setShowToast}
                                            setToastText={setToastText}
                                            courseType={courseType}
                                            product={productId}
                                            setOpen={1}
                                            ApptitudeData={reportMeta}
                                            discount={productId?.discounted_price}
                                        />
                                    </div>
                                    <RedutechStudentSwipe/>
                                    <div className="bg-white">
                                        <RedutechFAQ/>
                                    </div>
                                    <RedutechFooter/>
                                </React.Fragment>
                            ) : // null)
                            isFetched ? (
                                // <CalculatingPage2/>
                                <></>
                            ) : null
                    ) : (
                        <ErrorFallback
                            error='500'
                            showNavbar={false}
                            url={`/test/${testId}`}
                        />
                    )
                ) : // null)}
                isFetched ? (
                    <Loading/>
                ) : null}
            {
                showToast &&
                <Toast onClose={(showToast) => setShowToast(showToast)} success={success}>
                    {toastText}
                </Toast>
            }
            <StickyButton isVisible={isSticky}/>
        </Container>
    );
};

export default RedutechReportPage2;

const Container = styled(Flex)`
	width: 100%;
	background: ${({theme}) => theme.colors.blue[50]};
	font-family: Poppins;
	@media only screen and (max-width: 1024px) {
		background: ${({theme}) => theme.colors.grey[50]};
	}
`;

const Content = styled(Flex)`
	max-width: 1250px;
	margin: 1.5rem auto;
	margin-top: 6rem;
	@media only screen and (max-width: 1024px) {
		width: 100%;
	}
`;

// const DetailAndSummaryContainer = styled(Flex)`
// 	width: 100%;
// 	@media only screen and (max-width: 1024px) {
// 		flex-direction: column;
// 	}
// `;
