import React, { useEffect, useState } from "react";
import Footer from ".././Footer";
import styled from "styled-components";
import Flex from "../../components/Flex";
import Traits from ".././analysis/Traits";
import Navbar from "../../components/Navbar";
import { useLocation, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import ErrorFallback from ".././ErrorFallback";
import TestDetails from ".././analysis/TestDetails";
import UserDetails from ".././analysis/UserDetails";
import {
  BOOKING_LINK,
  IGNITER_REDIRECT_URL_0,
  IGNITER_REDIRECT_URL_1,
  IGNITER_REDIRECT_URL_2,
  IGNITER_REPORT_CTA_0,
  IGNITER_REPORT_CTA_1,
  IGNITER_REPORT_CTA_2,
  REPORT_PAGE_BOOKING_LINK,
} from "../../../utils/constants";
import { useAnalysisReport } from "../../../hooks/useAnalysisReport";
import { useProduct } from "../../../hooks/useProduct";
import CareerAndPersonalities from ".././analysis/CareerAndPersonalities";
import { MANAGEMENT_COHOT_PRODUCT_ID } from "../../../utils/constants";
import "./stridepac.css";
import ReportSummary from "./summary/reportSummary";
import UserDetailsNew from "./userDetails/userDetails";
import StrongSuit from "./strongSuit/stringSuit";
import HeaderMain from "../../components/Header section/HeaderMain";
import AboutSection from "../../components/About section/AboutSection";
import WorkMain from "../../components/Work section/WorkMain";
import LeadSquaredForm from "../../components/forms/LeadSquaredForm";
import CustomLoader from "../../../assets/customLoader.gif";

import ChartImage from "../../../assets/images/chart.svg";

import Slider from "react-slick";
import { ResizeButton } from "@amcharts/amcharts4/core";
import ReportApptitude from "../../components/Report Section/ReportApptitude";
import ThreeMonthReport from "../../components/Report Section/ThreeMonthReport";
import CareerSection from "../../components/Report Section/CareerSection";
import PromiseSection from "../../components/Report Section/PromiseSection";
import PaymentSection from "../../components/Report Section/PaymentSection";
import TakeMeSection from "../../components/Report Section/TakeMeSection";
import HeroSection from "../../components/Report Section/HeroSection";
import ProgramDetail from "../../components/Report Section/ProgramDetail";
import MentorSwiper from "../../components/Report Section/MentorSwiper";
import Collaborator from "../../components/Report Section/Collaborator";
import GetCertificate from "../../components/Report Section/GetCertificate";
import { Testimonials } from "../../components/Report Section/Testimonials";
import { FAQ } from "../../components/Report Section/Faq";
import FooterSection from "../../components/Report Section/FooterSection";
import ExpertSwipe from "../../components/Report Section/ExpertSwipe";
import StudentSwipe from "../../components/Report Section/StudentSwipe";
import CalculatingPage from "../CalculatingPage";
import flags from "react-phone-number-input/flags";
import { StickyButton } from "../../components/StickyButton";

const ManagmentReport = ({ test, testId, isFetched }) => {
  const { token } = useParams<{ token: string }>();
  const [showModalNo, setShowModalNo] = useState<any>(0);
  const [promoCodes, setPromoCodes] = useState<any>({});
  const [isSticky, setIsSticky] = useState<boolean>(false);

  const [CTADetails, setCTADetails] = useState({});
  const customLogo = test?.logo_image;
  const { data: reportMeta, isSuccess, isLoading } = useAnalysisReport(token);
  const [loading, setLoading] = useState<boolean>(false);
  const { data: productId } = useProduct(MANAGEMENT_COHOT_PRODUCT_ID);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const replaceUrls = (obj: any): any => {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => replaceUrls(item));
    }

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        if (
          typeof value === "string" &&
          value.includes("https://d2ny4i6ieyrjfu.cloudfront.net")
        ) {
          return [
            key,
            value.replace(
              "https://d2ny4i6ieyrjfu.cloudfront.net",
              "https://d1dhn4cz1wmlqo.cloudfront.net"
            ),
          ];
        } else {
          return [key, replaceUrls(value)];
        }
      })
    );
  };

  const newData = replaceUrls(reportMeta);

  useEffect(() => {
    setCTADetails(URLProvider(query?.get("cta_type")));
  }, []);

  const URLProvider = (cta_type) => {
    switch (cta_type) {
      case "0":
        return {
          url: IGNITER_REDIRECT_URL_0,
          text: IGNITER_REPORT_CTA_0,
        };
      case "1":
        return {
          url: IGNITER_REDIRECT_URL_1,
          text: IGNITER_REPORT_CTA_1,
        };
      case "2":
        return {
          url: IGNITER_REDIRECT_URL_2,
          text: IGNITER_REPORT_CTA_2,
        };
      default:
        return {
          url: IGNITER_REDIRECT_URL_0,
          text: IGNITER_REPORT_CTA_0,
        };
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 5000);
  }, []);

  return (
    <Container column>
      {!isLoading ? (
        isSuccess ? (
          newData?.report_metadata && loading ? (
            <React.Fragment>
              <HeroSection ApptitudeData={newData?.report_metadata} />
              <ReportApptitude ApptitudeData={newData?.report_metadata} />
              <TakeMeSection
                ApptitudeData={newData?.report_metadata}
                showSticky={(e) => setIsSticky(e)}
              />
              <ThreeMonthReport />
              <PromiseSection />
              <CareerSection />
              <ProgramDetail />
              {/* <MentorSwiper /> */}
              <ExpertSwipe />

              <Collaborator />
              <GetCertificate />
              {/* <Testimonials/> */}
              <StudentSwipe />

              <PaymentSection
                product={productId}
                setOpen={1}
                ApptitudeData={newData}
                discount={productId?.discounted_price}
              />

              <FAQ />
              <FooterSection />
            </React.Fragment>
          ) : // null)
          isFetched ? (
            <CalculatingPage />
          ) : null
        ) : (
          <ErrorFallback
            error="500"
            showNavbar={false}
            url={`/test/${testId}`}
          />
        )
      ) : // null)}
      isFetched ? (
        <Loading />
      ) : null}

      <StickyButton isVisible={isSticky} />
    </Container>
  );
};

export default ManagmentReport;

const Container = styled(Flex)`
  width: 100%;
  background: ${({ theme }) => theme.colors.blue[50]};
  font-family: Poppins;
  @media only screen and (max-width: 1024px) {
    background: ${({ theme }) => theme.colors.grey[50]};
  }
`;

const Content = styled(Flex)`
  max-width: 1250px;
  margin: 1.5rem auto;
  margin-top: 6rem;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const DetailAndSummaryContainer = styled(Flex)`
  width: 100%;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
