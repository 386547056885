import React from "react";
import "./workmain.css";
import OneWorkPart from "./OneWorkPart";
import {IGNITER_REDIRECT_URL_0, PROCESSOR_URL} from "../../../utils/constants"
const tell_img = require("../../../assets/IgniterLpQuiz/work1.png");
const tell_img1 = require("../../../assets/IgniterLpQuiz/work2.png");
const tell_img2 = require("../../../assets/IgniterLpQuiz/work3.png");
const tell_img3 = require("../../../assets/IgniterLpQuiz/work4.png");
const tell_img4 = require("../../../assets/IgniterLpQuiz/work5.png");
const lock_img = require("../../../assets/IgniterLpQuiz/lock.png");
const first_dash = require("../../../assets/IgniterLpQuiz/dashed1.png");
const sec_dash = require("../../../assets/IgniterLpQuiz/dashed2.png");
const third_dash = require("../../../assets/IgniterLpQuiz/dashed3.png");
const four_dash = require("../../../assets/IgniterLpQuiz/dashed4.png");

function WorkMain(props) {
  
  function handleClickEvent (){
    window.open(props.CTADetails.url,"_SELF")
  }
  
  return (
    <div className="work_main_container">
    <div className="res_work_container">
    <div className="work_under_section_contianer">
    <h6 className="title_main_text">How it works</h6>
    <div className="work_line" />
    
    {/* 1 */}
    <div className="dashed_line_container">
    <img src={first_dash.default} alt="fd_img" className="first_dash" />
    <div className="work_content_container">
    <div className="work_left_side_container">
    <OneWorkPart
    imgName={tell_img.default}
    stepName="Step 1"
    stepTitle="Tell us more about yourself"
    stepExtraText=" Help us understand yourself and your requirements better by creating
    your profile with details such as your background, interest areas,
    achievements, etc."
    />
    </div>
    <div className="work_right_side_container"></div>
    </div>
    
    {/* 2 */}
    
    <div className="work_content_container">
    <div className="work_left_side_container"></div>
    <div className="work_right_side_container">
    <OneWorkPart
    imgName={tell_img1.default}
    stepName="Step 2"
    stepTitle="Discover yourself through AI-powered assessments"
    stepExtraText="Get unique data-driven insights about your personality, career values, interests and competencies along with top recommended career options according to your profile."
    />
    </div>
    </div>
    <img src={sec_dash.default} alt="fd_img" className="sec_dash" />
    </div>
    {/* 3 */}
    
    <div className="dashed_line_container">
    <img src={third_dash.default} alt="fd_img" className="third_dash" />
    <div className="work_content_container">
    <div className="work_left_side_container">
    <OneWorkPart
    imgName={tell_img2.default}
    stepName="Step 3"
    stepTitle="We finalize a mentor for you based on your profile"
    stepExtraText="We analyze your requirements and based on your overall profile and career interests we schedule a session over zoom/google meet with a relevant industry professional"
    />
    </div>
    <div className="work_right_side_container"></div>
    </div>
    
    {/* 4 */}
    <div className="work_content_container">
    <div className="work_left_side_container"></div>
    <div className="work_right_side_container">
    <OneWorkPart
    imgName={tell_img3.default}
    stepName="Step 4"
    stepTitle="Get 1:1 personalized mentorship live over call by Industry experts"
    stepExtraText="The mentors help to clarify all your queries and guide you to make informed career decisions. They also help you with domain specific profile building, resume building, interview preparation, and other sessions as per your requirement."
    />
    </div>
    </div>
    <img src={four_dash.default} alt="fd_img" className="four_dash" />
    </div>
    
    {/* 5 */}
    
    <div className="work_content_container">
    <div className="work_left_side_container">
    <OneWorkPart
    imgName={tell_img4.default}
    stepName="Step 5"
    stepTitle="'StrideAhead' with renewed confidence on your career journey"
    stepExtraText="Work on the best-in-class actionable insights provided by our experts and see notable difference in your profile that gets you closer to your career goals."
    />
    </div>
    <div className="work_right_side_container"></div>
    </div>
    
    <div className="btn_center">
    <button className="lock_img_btn" onClick={handleClickEvent}>
    <img
    src={lock_img.default}
    alt="lock"
    className="lock_img img-fluid"
    />
    {props.CTADetails.text}
    </button>
    </div>
    </div>
    </div>
    </div>
    );
  }
  
  export default WorkMain;
  