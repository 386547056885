import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Aptitude3 from '../../../assets/images/Aptitude3.svg'
import Aptitude4 from '../../../assets/images/Aptitude4.svg'



export default function RedutechCapsule({ reportMeta }: any) {
    const [dataType, setDataType] = useState<any>();

    const [selected, setSelected] = useState(0)

    const toogle = (index: any) => {
        setSelected(index)
    }

    const iconBox = (item) => {
        return (

            <div className="icon_image">
                <img src={item.title === "On-the-Job Behaviours" ? Aptitude4 : Aptitude3} alt='iconImage' />
            </div>
        )
    }
    const iconBox2 = (item) => {
        return (
            <div className="icon_image_accordion" style={{ backgroundColor: "rgba(139, 41, 42, 0.15)" }}>
                <img src={item.title === "On-the-Job Behaviours" ? Aptitude4 : Aptitude3} alt='iconImage' />
            </div>
        )
    }
    const scoreCapsule = (score) => {
        if (score > 75) {
            return (
                <div className='bigCapsule capsule-high'>
                    High
                </div>
            )
        } else if (score > 35 && score < 76) {
            return (
                <div className="bigCapsule capsule-mid">
                    Medium
                </div>
            )
        } else return (
            <div className='bigCapsule capsule-low'>
                Low
            </div>
        )
    }

    const cirleProgress = (percentage) => {

        let setColor = "#000"
        if (percentage > 76) {
            setColor = "#24A84A";
            setColor = "#24A84A";
        } else if (percentage > 35 && percentage <= 75) {
            setColor = "#FCAE17";
        } else {
            setColor = "#EE3323"
        }
        return (
            <div className='circle-container'>
                <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                        rotation: 0.85,
                        strokeLinecap: 'round',
                        textSize: '22px',
                        pathTransitionDuration: 0.8,
                        pathColor: `${setColor}`,
                        textColor: '#192A46',
                        trailColor: '#d6d6d6',
                    })}
                />
            </div>
        )
    }
    const data = {
        data1: [
            {
                title: "On-the-Job Behaviours",
                sub_title: "A big data analytics professional is able to",
                content: [
                    "Extract meaningful insights from data by cleaning, transforming, and structuring it appropriately.",
                    "Use statistical and mathematical techniques to analyze data and make informed decisions based on evidence and logic.",
                    "Identify potential errors or inconsistencies in data and take appropriate steps to correct them. ",

                ]
            }, {
                title: "Personality Features",
                sub_title: "",
                content: [" Pattern Recognition: Seeking meaningful patterns and connections in data to form decisions. ",
                    "Technical proficiency: Comfortable with coding, data manipulation, and statistical analysis tools.",
                    "Critical  Thinking: Ability to identify business challenges and use data to develop solutions."]
            }
        ],
        data2: [
            {
                title: "On-the-Job Behaviours",
                sub_title: "A cloud computing professional is able to",
                content: [
                    "Implement new solutions in a dynamic and rapidly evolving environment.",
                    "Find ways to streamline operations, automate processes, and improve system efficiency,resulting in better cost management and enhanced overall performance.",
                    "Demonstrate empathy towards customer requirements and possess excellent communication skills to ensure the success of the cloud computing project. ",

                ]
            }, {
                title: "Personality Features",
                sub_title: "",
                content: [
                    "Learning Agility: Embracing the ability to quickly adapt and scale to changing business needs. ",
                    "Technological acumen: Familiarity with cloud platforms, infrastructure, and services to support operations.",
                    "Innovation and Novelty: Pursuing new and efficient ways to leverage cloud technology for competitive advantage."]
            }
        ],
        data3: [
            {
                title: "On-the-Job Behaviours",
                sub_title: "A cyber security professional is able to",
                content: [
                    "Indulge in creative thought to find the most effective solutions to protect against cyber threats.",
                    "Practice honesty, transparency, and commitment while protecting sensitive data while following industry standards and regulations.",
                    "Identify small changes that could indicate a security breach and proactively take the necessary steps to prevent cyber attacks from occurring.",

                ]
            }, {
                title: "Personality Features",
                sub_title: "",
                content: [" Attention to detail: A keen eye for identifying vulnerabilities and developing robust security measures.",
                    "Problem-solving mindset: Ability to identify and solve complex security challenges through software.",
                    "Technical proficiency: Strong knowledge of programming languages and network security protocols to develop effective solutions."]
            }
        ]
    }


    const reportData = {
        "dimensions": [
            {
                "name": "On-the-Job Behaviours",
                "percentage": 23.53,
                "relative_percentage": 19.05,
                "more_details": [""]
            },
            {
                "name": "Personality Features",
                "percentage": 25.0,
                "relative_percentage": 19.05,
                "more_details": [""]
            }
        ]
    }

    useEffect(() => {
        if (reportMeta?.report_metadata?.recommended_course === "CC") {
            setDataType(data?.data2)
        } else if (reportMeta?.report_metadata?.recommended_course === "CS") {
            setDataType(data?.data3)
        } else if (reportMeta?.report_metadata?.recommended_course === "BDA") {
            setDataType(data?.data1)
        }
    }, [reportMeta?.report_metadata?.recommended_course])




    return (
        <div className="container-md">
            <div className='accordion'>
                {
                    dataType?.map((item, index) => (

                        <div className='item' key={index} onClick={() => toogle(index)}>
                            <div
                                className={selected !== index ? 'accordion-closed' :
                                    'accordion-hide'}>
                                <div className="inlineBlock">
                                    <div className="left-part">
                                        {iconBox(item)}
                                        <h3 id='accordion-title'>{item?.title}</h3>
                                    </div>
                                    {/* <div className="right-part2">
                            <h3 id='accordion-score'>Your score is : <span
                                id='accordion-percentage'>{item.percentage}%</span></h3>
                            <div className='capsule-container'>
                                {scoreCapsule(item.percentage)}
                            </div>
                        </div> */}
                                </div>
                            </div>

                            <div className={
                                selected === index ? 'accordion-show ' :
                                    'accordion-hide'}>
                                <div className="inlineBlock2">
                                    <div className="left-part">
                                        {iconBox2(item)}
                                        <div className='left-inside'>
                                            <h3 id='accordion-title'>{item?.title}</h3>
                                            {/* <div id='accordion-capsule'>{scoreCapsule(item?.percentage)}</div> */}
                                        </div>
                                    </div>
                                    {/* <div className="right-part">
                            <div className='circle_accordion'>
                                {cirleProgress(item?.percentage)}
                            </div>
                        </div> */}
                                </div>
                                <div className="accordion-content">
                                    {/* <p id='accordion-p'>{item?.definition}</p> */}
                                    <h5>{item?.sub_title}</h5>
                                    <ul>
                                        {item?.content?.map((items: any, index: any) => (
                                            <li
                                                key={index}
                                            >{items}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                    )
                }
            </div>
        </div >
    )
}
