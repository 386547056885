import {  useEffect, useMemo } from "react";
import React, { useState } from 'react'
import ReactQuill, { Quill } from "react-quill";

export default function EmployabilityQuizMetaForm(props){
    const headings = ["For score above 66%", "For score between 33% and 66%", "For score below 33%"]

    return (
        <div>
            <label>Quiz Meta</label>
            {props.quizMeta.map((element: any, index: any) => (          
            <div className="form-inline" key={index}>
                <br />
                <div style={{border: "3px solid black", padding: "10px"}}>
                <div>
                {headings[index]}
                </div>
                <h3>Title </h3>
                <input type="text" name="title" value={props.quizMeta[index].title} onChange={(e: any) => {
                let newFormValues: Array<any> = [...props.quizMeta];
                // @ts-ignore: Object is possibly 'null'.
                newFormValues[index] = {...newFormValues[index], title: e.target.value};
                props.onChange(newFormValues);
                }}
                />
                <h3>Description </h3>
                <input type="text-area" name="description" value={props.quizMeta[index].description} onChange={(e: any) => {
                let newFormValues: Array<any> = [...props.quizMeta];
                // @ts-ignore: Object is possibly 'null'.
                newFormValues[index] = {...newFormValues[index], description: e.target.value};
                props.onChange(newFormValues);
                }}/>
                <h3>Image </h3>
                <input type="file" name="image"  onChange={(e: any) => {
                props.imageHandler(e, index)
                }}/>
                </div>
            </div>
            ))} 

            </div>
    )
}