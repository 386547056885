// @ts-nocheck
import { Quiz } from "../../../App";
import animationData from "../../../assets/Success-animation.json";
import React, { useEffect } from "react";

export default function RandomQuiz(props: any) {

  const renderPopUp = props.renderPopUp;
  const submitNow = props.submitNow;
  const quizTypeName = props.quizTypeName;
  const questionNo = props.questionNo;
  const finalQuestionNo = props.finalQuestionNo;
  const handleNext = props.handleNext;
  const handlePrev = props.handlePrev;
  const handleQuizSubmit = props.handleQuizSubmit;
  const quizQuestions = props.quizQuestions;
  const getPercentage = props.getPercentage;

  useEffect(()=>{
    let bar = document.querySelector(".progress-bar > div > div") as any;
    if(questionNo===1 && bar){
      bar.style.border = "none";
    }
  })
  
  if(renderPopUp){
    let text;
    text = document.querySelector("#quiz-progess-section > div.progress-bar > div > div > span") as any;
    text.style.color = "#ffffff"
  }
  return (
    <>
    <div id="content">
        <Quiz 
          data={quizQuestions}
          onSubmit={handleQuizSubmit}
          questionNo={questionNo}
          handleNext={handleNext}
          renderPopUp={renderPopUp}
          submitNow={submitNow}
          quizTypeName={quizTypeName}
        />
        </div>
    </>
  )
}