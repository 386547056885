import axios from 'axios';
import React, { FC, Fragment, useState } from 'react';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { RouteComponentProps, useLocation, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Open from '../../assets/analysis/open.svg';
import Report from '../../assets/test/test-report.svg';
import { useCurrentProfile } from '../../hooks/useProfile';
import {
  API_ENDPOINT,
  ratings,
  REPORT_SHARE_TEXT,
  STRIDEAHEAD,
  DISCORD_LINK
} from '../../utils/constants';
import { copyToClipboard } from '../../utils/helperFunctions';
import { APIError, FetchResponse, ReportLinkState } from '../../utils/types';
import Button from '../components/Button';
import Flex from '../components/Flex';
import SocialShare from '../components/SocialShare';
import Toast from '../components/Toast';
import Footer from './Footer';
import ErrorFallback from './ErrorFallback';
import { useEffect } from 'react';
import useTest from '../../hooks/useTest';
import Navbar from '../components/Navbar';

import Lottie from "react-lottie";
import LockIcon from "../../assets/test/lock_icon.png";
import ClockIcon from "../../assets/test/clock_icon.png";
import animationData from "../../assets/Down-arrows.json";
import CalendarIcon from "../../assets/test/calendar_icon.png";

const TestFeedback: FC<{
  props: RouteComponentProps<{}, any, ReportLinkState | any>;
}> = () => {
  const location = useLocation<ReportLinkState>();
  const { testId } = useParams<{ testId: string }>();
  const { reportId } = useParams<{ reportId: string }>();

  const { data: currentProfile } = useCurrentProfile();

  const { data: test } = useTest(testId);

  const [feedback, setFeedback] = useState<string>('');
  const [selectedRating, setSelectedRating] = useState<number>();
  const [success, setSuccess] = useState<boolean>(false);

  const [showToast, setShowToast] = useState<boolean>(false);
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>('');

  const copyLink = (link: string) => {
    copyToClipboard(link);
    setToastText('The test link has been copied.');
    setShowToast(true);
    setSuccessToast(true);
  };

  const redirectToStride = () => {
    window.open(`${STRIDEAHEAD}`, '_blank');
  };

  const redirectToBooking = () => {
    if (test?.redirect_url){
      window.open(test?.redirect_url)
    }
    window.open(
      `http://bookings.strideahead.in/talk-to-mentor?utm_source=pca&utm_medium=cta-thank-you`,
      "_blank"
    );
  };

  const redirectToDiscord = () => {
    window.open(`${DISCORD_LINK}`, '_blank');
  };

  const submitFeedback = async (): Promise<APIError[] | void> => {
    try {
      const postData = {
        name: currentProfile?.name || location.state.name,
        email: currentProfile?.email || location.state.email,
        report_id: reportId,
        rating: selectedRating,
        comment: feedback,
      };

      await axios.post<FetchResponse<{ message: string }>>(
        API_ENDPOINT + 'feedback/' + testId,
        postData
      );
      setSuccess(true);
    } catch (error:any) {
      const errors =
        error.response && (error.response.data.error as APIError[]);
      return errors;
    }
  };

  const getShareText = () => {
    return test
      ? REPORT_SHARE_TEXT.replace('{reportUrl}', location.state.reportLink)
      : '';
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const renderThankYouSection = () => {
    return (
      <>
        <ThankYouContainer>
          <TextContainer column>
            <ThankYouText>Thank You</ThankYouText>
            <ReportText>
              Your personalized personality profile will be available at the
              link below shortly
            </ReportText>
            {location.state.reportLink && (
              <Flex style={{ width: "100%" }} justify="center">
                <ReportLink onClick={() => copyLink(location.state.reportLink)}>
                  {location.state.reportLink}
                </ReportLink>
                <OpenReportButton
                  onClick={() => window.open(location.state.reportLink)}
                >
                  <img src={Open} alt="Open Link" />
                  Open
                </OpenReportButton>
              </Flex>
            )}
            <ReportText>Share the report with your friends</ReportText>
            <SocialShare
              url={location.state.reportLink}
              title={getShareText()}
            />
          </TextContainer>
          <ReportImage>
            <img src={Report} alt="Report" />
          </ReportImage>
        </ThankYouContainer>
        <ThankYouContainerBottom>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={80}
            width={80}
          />
          <div
            style={{
              display: "flex",
              margin: "0 auto",
              padding: "20px",
              borderRadius: "15px",
              alignItems: "center",
              width: "fit-content",
              backgroundColor: "#fff",
              justifyContent: "center",
            }}
          >
            <img
              src={LockIcon}
              style={{ width: '25px', height: '25px', marginRight: "10px" }}
              alt="lock_icon"
            />
            <ScrollText>
              You've unlocked a <FreeText>1 on 1 session</FreeText> with our mentor
            </ScrollText>
          </div>
        </ThankYouContainerBottom>
      </>
    );
  };

  const renderFeaturesContainer = () => {
    return (
      <FeaturesContainer>
        <WebsiteConatiner column>
          <div>Dive deeper into your traits and abilities with us</div>
          <StyledButton onClick={() => redirectToBooking()}>
            <img
              src={CalendarIcon}
              style={{ width: "15px", marginRight: "10px" }}
              alt="calendar_icon"
            />
            <span style={{ marginRight: "10px" }}>Book a session</span>
            <HiOutlineArrowRight />
          </StyledButton>
        </WebsiteConatiner>
        <Features>
          <Feature column>
            <img
              src={`${process.env.PUBLIC_URL}/features/mentorship.svg`}
              alt='mentorship'
            />
            <div>{['Data driven', <br />, ' Mentorship']}</div>
          </Feature>
          <Feature column>
            <img
              src={`${process.env.PUBLIC_URL}/features/masterclass.svg`}
              alt='masterclass'
            />
            <div>{['Teasers & ', <br />, ' Masterclasses']}</div>
          </Feature>
          <Feature column>
            <img
              src={`${process.env.PUBLIC_URL}/features/content-access.svg`}
              alt='content-access'
            />
            <div>{['Access to carefully', <br />, 'curated content']}</div>
          </Feature>
          <Feature column>
            <img
              src={`${process.env.PUBLIC_URL}/features/community.svg`}
              alt='community'
            />
            <StyledButton
              style={{
                padding: "0 2rem",
              }}
              onClick={() => redirectToDiscord()}
            >
              Access To Community
            </StyledButton>
          </Feature>
        </Features>
      </FeaturesContainer>
    );
  };

  const renderFeedbackContainer = () => {
    return (
      <FeedbackContainer>
        <Feedback column>
          <h2>Feel free to drop us your feedback</h2>
          <h3>How was your overall experience?</h3>
          <Rating submit={success}>
            {ratings.map((rating, index) => {
              return (
                <img
                  key={`${rating.rating}-${index}`}
                  src={`${process.env.PUBLIC_URL}/feedback/${rating.icon}.svg`}
                  alt={rating.icon}
                  onClick={() => !success && setSelectedRating(rating.rating)}
                  className={selectedRating === rating.rating ? 'selected' : ''}
                />
              );
            })}
          </Rating>
          {success ? (
            <SuccessContainer>
              Thank you. Your feedback has been well received.
            </SuccessContainer>
          ) : (
            <Fragment>
              <h3>How can we improve?</h3>
              <FeedbackInput
                placeholder='Write your feedback'
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              ></FeedbackInput>
              <FeedbackButton onClick={() => submitFeedback()}>
                Share Feedback
              </FeedbackButton>
            </Fragment>
          )}
        </Feedback>
      </FeedbackContainer>
    );
  };

  const returnFeedbackComponent = () => {
    const customLogo = test?.logo_image;
    if (location.state) {
      return (
        <>
          <Navbar homepage="https://strideahead.in" customLogo={customLogo} />
          <Container column>
            {renderThankYouSection()}
            {renderFeaturesContainer()}
            {renderFeedbackContainer()}
            <Footer />
            {showToast && (
              <Toast
                onClose={(showToast) => setShowToast(showToast)}
                success={successToast}
              >
                {toastText}
              </Toast>
            )}
          </Container>
        </>
      );
    } else {
      return <ErrorFallback error='500' showNavbar={false} />;
    }
  };

  return returnFeedbackComponent();
};

export default TestFeedback;

const Container = styled(Flex)`
  width: 100%;
  font-family: Poppins;
  margin-top: 6rem;
`;

const ThankYouContainer = styled(Flex)`
  background-color: rgba(254, 243, 203, 0.4);
  width: 100%;
  padding: 5rem 10rem 0 10rem;
  @media only screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 5rem 3.2rem 0 3.2rem;
    background: #fef3cb;
  }
`;

const ThankYouContainerBottom = styled.div`
  background-color: rgba(254, 243, 203, 0.4);
  padding: 20px 0;
  width: 100%;

  @media only screen and (max-width: 1024px) {
    background: #fef3cb;
  }
`;

const TextContainer = styled(Flex)`
  width: 40%;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    align-items: center;
    width: 100%;
  }
`;

const ThankYouText = styled(Flex)`
  font-weight: 600;
  font-size: 6rem;
  line-height: 100%;
  color: ${(props) => props.theme.colors.blue[500]};
  margin-bottom: 2.5rem;

  @media only screen and (max-width: 1024px) {
    font-size: 4rem;
    line-height: 150%;
    margin: 3.2rem 0 0.2rem;
  }
`;

const FreeText = styled.span`
  color: ${(props) => props.theme.colors.blue[500]};
  margin: 0 5px;
`;

const ReportText = styled(Flex)`
  font-weight: 500;
  font-size: 2rem;
  line-height: 150%;
  color: ${(props) => props.theme.colors.grey[500]};
  padding: 1.5rem 0;

  @media only screen and (max-width: 1024px) {
    font-weight: normal;
    margin: 0 4rem 1.6rem 4rem;
    text-align: center;
  }
`;

const ScrollText = styled.div`
  font-weight: bold;
  font-size: 2rem;
  line-height: 150%;
  color: ${(props) => props.theme.colors.grey[500]};

  @media only screen and (max-width: 1024px) {
    font-weight: normal;
    text-align: center;
  }
`;

const ReportImage = styled(Flex)`
  width: 60%;
  justify-content: right;
  margin-left: 10rem;
  img {
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-left: 0rem;
  }
`;

const FeedbackContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.grey[100]};
  color: ${(props) => props.theme.colors.grey[500]};
  padding: 5rem 3.2rem;
  @media only screen and (max-width: 1024px) {
    text-align: center;
    padding: 5rem 3.2rem;
  }
`;

const Feedback = styled(Flex)`
  width: 70%;
  margin: auto;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: -10px -10px 15px rgba(0, 0, 0, 0.02),
    10px 10px 15px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  padding: 3rem;
  h2 {
    font-weight: 600;
    font-size: 3rem;
    line-height: 150%;
    margin-bottom: 3rem;
  }
  h3 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 150%;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    h2 {
      font-size: 2rem;
      margin-bottom: 1.6rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
`;

const FeedbackButton = styled(Button)`
  width: 20%;
  padding: 0.8rem 1rem;
  margin: 2rem 0;
  height: 5rem;
  @media only screen and (max-width: 1024px) {
    width: max-content;
    justify-content: center;
    margin: auto;
  }
`;

const FeedbackInput = styled.textarea`
  resize: none;
  height: 13rem;
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.colors.grey[200]};
  box-sizing: border-box;
  border-radius: 5px;
  margin: 2rem 0;

  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  font-family: Poppins;
  color: ${(props) => props.theme.colors.grey[400]};
  &:focus-visible {
    border: 1px solid ${(props) => props.theme.colors.grey[200]};
  }
  @media only screen and (max-width: 1024px) {
    font-size: 1.5rem;
    height: 10rem;
  }
`;

const SuccessContainer = styled(Flex)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  background: ${(props) => props.theme.colors.green[100]};

  border: 1px solid ${(props) => props.theme.colors.green[400]};
  border-radius: 4px;
  padding: 13px 32px;
  width: max-content;
  @media only screen and (max-width: 1024px) {
    padding: 10px;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
  }
`;

const Rating = styled(Flex)<{ submit: boolean }>`
  margin: 3rem 0;
  img {
    cursor: pointer;
    margin-right: 2rem;
    height: 5rem;
    width: 5rem;
    opacity: 0.5;
    &.selected {
      opacity: 1;
    }
    ${({ submit }) =>
      !submit &&
      css`
        &:hover {
          opacity: 1;
        }
      `}
  }

  @media only screen and (max-width: 1024px) {
    justify-content: center;
    margin: 1.6rem 0;
    img {
      height: 3rem;
      width: 3rem;
      margin: 0 1rem;
    }
  }
`;

const FeaturesContainer = styled(Flex)`
  width: 100%;
  padding: 10rem 0;
  background-color: ${(props) => props.theme.colors.white};
  @media only screen and (max-width: 1024px) {
    align-items: center;
    width: 100%;
    background-color: ${(props) => props.theme.colors.blue[300]};
    margin: 0;
    flex-direction: column;
    padding: 3rem 0;
  }
`;

const WebsiteConatiner = styled(Flex)`
  background-color: ${(props) => props.theme.colors.blue[300]};
  color: ${(props) => props.theme.colors.blue[500]};
  padding: 10rem;
  border-radius: 1rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 40%;
  div {
    font-weight: 600;
    font-size: 6rem;
    line-height: 121.5%;
    color: ${(props) => props.theme.colors.grey[600]};
  }
  @media only screen and (max-width: 1024px) {
    padding: 5rem;
    text-align: center;
    width: 100%;
    div {
      font-size: 3rem;
      line-height: 150%;
    }
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  width: max-content;
  padding: 2rem;
  margin: 4rem 0;
  @media only screen and (max-width: 1024px) {
    margin: 3.2rem auto;
  }
`;

const Features = styled(Flex)`
  justify-content: center;
  width: 60%;
  flex-wrap: wrap;
  padding: 2rem 12rem;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding: 2rem;
    text-align: center;
  }
`;

const Feature = styled(Flex)`
  flex: 1 1 40%;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-size: 18px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.grey[600]};
  padding: 3rem;
  margin: 2.5rem;
  img {
    width: 7rem;
  }
  div {
    margin-top: 1.5rem;
  }

  @media only screen and (max-width: 1024px) {
    font-weight: 600;
    img {
      margin: auto;
    }
  }
`;

const ReportLink = styled(Flex)`
  background: #f9f9f9;
  border-radius: 1rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  line-height: 2.2rem;
  height: 100%;
  padding: 1.8rem;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
  font-size: 1.6rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey[400]};
  @media only screen and (max-width: 1024px) {
    padding: 1rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.grey[500]};
  }
`;

const OpenReportButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 150px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 1rem 1.5rem;
  animation: .6s jump ease infinite alternate;
  @keyframes jump {
    0% {
      transform: scale(1);
      box-shadow: 0 1px 2px rgba(0,0,0,.15);
    }
    100% {
      transform: scale(1.1);
      box-shadow: 0 4px 20px rgba(0,0,0,.1);
    }
  }
  @media only screen and (max-width: 1920px) {
    padding: 0.8rem;
    height: auto;
    width: 170px;
    justify-content : center;
    align-items : center;
    text-align : center;
    img {
      width: 1.4rem;
      height: 1.4rem;
    }
  @media only screen and (max-width: 1024px) {
    padding: 0.8rem;
    height: auto;
    width: 150px;
    justify-content : center;
    align-items : center;
    font-size : 10px;
    text-align : center;
    img {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
   @media only screen and (max-width: 768px) {
    padding: 0.5rem;
    height: auto;
    width: 150px;
    justify-content : center;
    width : 80px
    font-size : 10px;
    text-align : center;
  }
  @media only screen and (max-width: 425px) {
    width : 150px;
    width: 150px;
    justify-content : center;
    font-size : 10px;
    text-align : center;
  }
`;
