import React, { useEffect, useRef, useState } from "react";
import { LOCALHOST, PROCESSOR_URL } from "../../utils/constants";
import UniversalQuiz from "./quiz_pages/UniversalQuizDefault";
import RandomQuiz from "./quiz_pages/RandomQuiz";
import UniversalQuizHandler from "./UniversalQuizHandler";
import EmployabilityQuizPage from "./quiz_pages/EmployabilityQuizPage";

export default function QuizPage(props: any) {
  const [quizQuestions, setQuizQuestions] = useState<any>([]);
  const [sampleQuestions, setSampleQuestions] = useState<any>([]);
  const [quizMeta, setQuizMeta] = useState<any>({});
  const [questionNo, setQuestionNo] = useState<number>(1);
  const [finalQuestionNo, setFinalQuestionNo] = useState<number>(1);
  const [renderPopUp, setRenderPopUp] = useState<boolean>(false);
  const [submitNow, setSubmitNow] = useState<boolean>(false);
  const [quizTypeName, setQuizTypeName] = useState<string>();
  const [quizName, setQuizName] = useState<string>();
  const [quizLabel, setQuizLabel] = useState<string>();
  const [quizDescription, setQuizDescription] = useState<string>();
  const [preQuizDescription, setPreQuizDescription] = useState<string>();
  const [quizPageTemplate, setQuizPageTemplate] = useState<string>();
  const [quizDuration, setQuizDuration] = useState<any>();
  const [quizStartTime, setQuizStartTime] = useState<any>(false);

  const pathname: string = window.location.pathname
  const id = pathname.split("/")[pathname.split("/").length - 1]

  const getQuizData = (id)=>{ 
    fetch(`${PROCESSOR_URL}quiz/${id}`)
    .then(res=>res.json())
    .then(data=>{
      const realQuestions = data['question_list'].filter(question=>{
        return !question.sampleQuestion
      });
      const fakeQuestions = data['question_list'].filter(question=>{
        return question.sampleQuestion
      });
      const quizMeta = data['meta'];
      setQuizQuestions(realQuestions);
      setSampleQuestions(fakeQuestions);
      setQuizName(data["name"]);
      setQuizLabel(data["label"]);
      setQuizDescription(data["description"])
      setPreQuizDescription(data["pre_quiz_description"])
      setQuizTypeName(data['quiz_type_name'])
      setQuizPageTemplate(data['quiz_template_name'])
      setQuizMeta(quizMeta)
      setQuizDuration(data['duration'])
    })
    .catch(err=>{
      alert("Failed to get quiz data. Please try again.")
    })
  }

  useEffect(() => {
    getQuizData(id)
  }, [])
 
 
  const renderQuizPage = ()=>{
    switch(quizTypeName){
      case "universal_quiz":
      case "riasec_quiz":
      case "dimensions_quiz":
        return <UniversalQuizHandler
                  renderPopUp={renderPopUp}
                  quizDuration={quizDuration}
                  submitNow={submitNow}
                  setSubmitNow ={setSubmitNow}
                  setRenderPopUp={setRenderPopUp}
                  quizName={quizName}
                  quizLabel={quizLabel}
                  quizDescription={quizDescription}
                  preQuizDescription={preQuizDescription}
                  quizTypeName={quizTypeName}
                  questionNo={questionNo}
                  setQuestionNo={setQuestionNo}
                  finalQuestionNo={finalQuestionNo}
                  quizQuestions={quizQuestions}
                  quizMeta={quizMeta}
                  sampleQuestions={sampleQuestions}
                  quizPageTemplate={quizPageTemplate}
                />
      case "employability_quiz":
        return <EmployabilityQuizPage
                  renderPopUp={renderPopUp}
                  submitNow={submitNow}
                  quizTypeName={quizTypeName}
                  questionNo={questionNo}
                  finalQuestionNo={finalQuestionNo}
                  quizQuestions={quizQuestions}
                />
      case "random_quiz":
        return <RandomQuiz
                renderPopUp={renderPopUp}
                submitNow={submitNow}
                quizTypeName={quizTypeName}
                questionNo={questionNo}
                finalQuestionNo={finalQuestionNo}
                quizQuestions={quizQuestions}
               />
      default:
        return null;
    }
  }
  return renderQuizPage()
}