import React, { FC } from 'react';
import styled from 'styled-components';
import Flex from './Flex';
import { Text1Css } from './Typography';

interface IProps {
  labels?: string[];
  checked: boolean;
  onClick: any;
}

const ToggleSwitch: FC<IProps> = ({ labels, checked, onClick }) => {
  return (
    <Container onClick={onClick} align='center'>
      {labels && <span className={checked ? '' : 'active'}>{labels[0]}</span>}
      <ToggleContainer align='center' checked={checked}>
        <ToggleHandle checked={checked} />
      </ToggleContainer>
      {labels && <span className={checked ? 'active' : ''}>{labels[1]}</span>}
    </Container>
  );
};

export default ToggleSwitch;

const Container = styled(Flex)`
  ${Text1Css}
  color: ${(props) => props.theme.colors.grey[300]};
  cursor: pointer;
  justify-content: center;
  .active {
    font-weight: 500;
    color: ${(props) => props.theme.colors.green[400]};
    transition: 0.1s;
  }
`;

const ToggleContainer = styled(Flex)<{ checked: boolean }>`
  width: 3.5rem;
  height: 2rem;
  padding: 0 0.3rem;
  margin: 0 1rem;
  border-radius: 4rem;
  background-color: ${(props) =>
    props.checked
      ? props.theme.colors.green[400]
      : props.theme.colors.grey[300]};
  transition: 0.1s;
`;

const ToggleHandle = styled.div<{ checked: boolean }>`
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 1rem;
  background-color: #fff;
  transform: translateX(${(props) => (props.checked ? '100%' : '0')});
  /* margin: ${(props) => (props.checked ? 'auto' : '0')}; */
  transition: 0.1s ease-in;
`;
