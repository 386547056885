import React, { useState } from 'react';
import { useParams } from 'react-router';
import styled, { css } from 'styled-components';
import useReportResponse from '../../../hooks/useReportResponse';
import useTestQuestions from '../../../hooks/useTestQuestions';
import { Container } from '../../components/Container';
import { formatDate } from '../../../utils/helperFunctions';
import Back from '../../components/Back';
import Flex from '../../components/Flex';
import Loading from '../../components/Loading';
import { Pagination } from '../../components/Table';

const AptitudeReportResponse = () => {
  const { reportId } = useParams<{ reportId: string }>();
  const { testId } = useParams<{ testId: string }>();


  const [page, setPage] = useState(1);

  const { data: response } = useReportResponse(reportId);

  const { isLoading, data: questions } = useTestQuestions(testId, page);

  const getSelectedAnswer = (id: number) => {
    const index = getQuestionIndexInAnswers(id);
    if (index === undefined) return;
    return response?.report_response[index];
  };

  const getQuestionIndexInAnswers = (id: number) => {
    return response?.report_response?.findIndex((answer) => {
      return answer.id === id;
    });
  };

  const renderQuestions = () => {
    return (
      response &&
      questions?.questions?.map(({ id, question, options }, index) => {
        return (
          <QuestionCard column key={`${id}-${index}`}>
            <QuestionCount>{`Question ${
              index + 1 + (page - 1) * questions.pagination_data.page_size
            }`}</QuestionCount>
            <Question className='d-flex justify-content-center text-center'>
									{' '}
									<div
										dangerouslySetInnerHTML={{ __html: question }}
										></div>{' '}
						</Question>
            {options.map(({ label, value }, index) => {
              return (
                <OptionContainer
                  key={`${id}-${index}`}
                  selected={getSelectedAnswer(id)?.answer === value}
                >
                  <QuestionLabel>
                    <Option
                      type='radio'
                      value={label}
                      checked={getSelectedAnswer(id)?.answer === value}
                    />
                    <span>{label}</span>
                  </QuestionLabel>
                </OptionContainer>
              );
            })}
          </QuestionCard>
        );
      })
    );
  };

  return (
    <Container column>
      {isLoading && <Loading />}
      <Back label='Back to reports' />
      <ReportDetailContainer style={{ marginTop: '1.5rem' }}>
        <ReportDetail>
          <label>Report ID:</label>
          {response?.id}
        </ReportDetail>
        <ReportDetail>
          <label>Name:</label>
          {response?.name}
        </ReportDetail>
        <ReportDetail>
          <label>Email:</label>
          {response?.email}
        </ReportDetail>
        <ReportDetail>
          <label>Generated On:</label>
          {formatDate(response?.generated_on)}
        </ReportDetail>
      </ReportDetailContainer>
      <QuestionContainer column>{renderQuestions()}</QuestionContainer>
      {!isLoading && questions?.pagination_data && (
        <Pagination
          currentPage={page}
          prevPage={questions?.pagination_data.page_prev}
          nextPage={questions?.pagination_data.page_next}
          nextPageChangeHandler={
            questions?.pagination_data.page_next
              ? function () {
                  setPage((currentPage) => currentPage + 1);
                }
              : undefined
          }
          prevPageChangeHandler={
            questions?.pagination_data.page_prev
              ? function () {
                  setPage((currentPage) => currentPage - 1);
                }
              : undefined
          }
        />
      )}
    </Container>
  );
};

export default AptitudeReportResponse;

const ReportDetail = styled(Flex)`
  font-size: 1.6rem;
  padding-top: 0.6rem;
  margin-right: 3.5rem;
  label {
    font-weight: 600;
    margin-right: 1rem;
  }
`;

const ReportDetailContainer = styled(Flex)`
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey[200]};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1rem;
  h2 {
    font-size: 2rem;
    font-weight: 600;
  }
`;

const QuestionContainer = styled(Flex)`
  width: 100%;
  margin: auto;
  margin-top: 1.6rem;
`;

const QuestionCard = styled(Flex)`
  padding: 2rem;
  border-left: 5px solid ${(props) => props.theme.colors.white};
  margin-bottom: 0.5rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 2px;
`;

const QuestionCount = styled(Flex)`
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 195%;
  color: ${(props) => props.theme.colors.grey[400]};
`;

const Question = styled(Flex)`
  font-weight: 600;
  font-size: 2.6rem;
  line-height: 100%;
  color: ${(props) => props.theme.colors.grey[600]};
  margin-bottom: 2.5rem;
  flex: 1;
`;

const OptionContainer = styled(Flex)<{ selected?: boolean }>`
  border: 1px solid ${(props) => props.theme.colors.grey[200]};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
  ${({ selected }) =>
    selected &&
    css`
      border-color: ${(props) => props.theme.colors.green[400]};
      label {
        color: ${(props) => props.theme.colors.green[400]};
      }
      input {
        border-color: ${(props) => props.theme.colors.green[400]};
        background: ${(props) => props.theme.colors.green[400]};
      }
    `}
`;
const Option = styled.input`
  width: 15px;
  height: 15px;
  &:before {
    background: red;
    border: 2px solid red;
  }
`;

const QuestionLabel = styled.label`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-left: 1rem;
  color: ${(props) => props.theme.colors.grey[500]};
  width: 100%;
  cursor: pointer;
  span {
    margin-left: 1rem;
  }
`;
