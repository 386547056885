// @ts-nocheck
import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import styled from "styled-components";
import BrainwaveList from "../components/BrainwaveList";
import Flex from "../components/Flex";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

const BrainwaveAdminDashboard = () => {
  const history = useHistory();
  useEffect(() => {
    history.push("/brainwave");
  });
  return (
    <Container>
      <Navbar />
      <Sidebar />
      <Content>
        <Switch>
          <Route path="/brainwave" component={BrainwaveList} />
          <Route path="*">
            <Redirect to="/404" />
          </Route>
        </Switch>
      </Content>
    </Container>
  );
};

export default BrainwaveAdminDashboard;

const Container = styled(Flex)`
  height: 100vh;
`;

const Content = styled(Flex)`
  width: calc(100% - 26rem);
  margin-top: 6rem;
  margin-left: 22rem;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.grey[50]};
`;
function useNavigate() {
  throw new Error("Function not implemented.");
}
