import react from 'react';
import styled from "styled-components";
import Logo from '../../../assets/redutech_logo.svg'
const ReduTechHeroSection = ({ name }) => {
  return (
    <div className="container-md">
      <MainHeader className="d-flex justify-content-between">
        <UserName>
          Hi, {name}
        </UserName>
        <img src={Logo} />
      </MainHeader>
    </div>
  )
}

export default ReduTechHeroSection;

const MainHeader = styled.div`
      background-color:#333333;
      padding:72px;
      margin-top:100px;
      position: relative;
       @media (max-width: 768px){
        padding:20px;
        margin-top:50px;
     }
      img{
        position: absolute;
        top:-40%;
        right:3%;

     @media (max-width: 768px){
       
    width: 30%;
    top: -11%;
    right: 0%;

     }
      }
`;

const UserName = styled.h4`
    font-weight: 600;
    font-size: 44px;
    color: #FFFFFF;
  @media (max-width: 768px){
     font-size: 22px;
  }
`;