import React from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Flex from './Flex';

const Back = ({ label }) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Container onClick={() => goBack()}>
      <BackArrow /> {label}
    </Container>
  );
};

export default Back;

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey[200]};
  border-radius: 4px;
  width: max-content;
  height: 4rem;
  padding: 1rem;
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.blue[500]};
  font-weight: 600;
  margin-top: 1rem;
  cursor: pointer;
`;

const BackArrow = styled(IoMdArrowBack)`
  margin-right: 0.8rem;
  margin-top: 0.2rem;
`;
