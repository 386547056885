import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Copy from '../../../assets/analysis/copy.svg';
import { HiOutlineArrowRight } from 'react-icons/hi';
import LockIcon from '../../../assets/test/lock_icon.png';
import CalendarIcon from '../../../assets/test/calendar_icon.png';
import { useCurrentProfile } from '../../../hooks/useProfile';
import useTest from '../../../hooks/useTest';
import { DICEBAR_URL, REPORT_SHARE_TEXT } from '../../../utils/constants';
import { copyToClipboard } from '../../../utils/helperFunctions';
import { ReportMetadataResponse } from '../../../utils/types';
import Button from '../../components/Button';
import Flex from '../../components/Flex';
import SocialShare from '../../components/SocialShare';
import Toast from '../../components/Toast';

const UserDetails: FC<{
	reportMeta: ReportMetadataResponse;
	testId: string;
	authenticate: boolean;
	bookingLink: string;
}> = ({ reportMeta, testId, authenticate, bookingLink }) => {
	const { data: test, isLoading } = useTest(testId);
	const { data: currentProfile } = useCurrentProfile(
		!isLoading && authenticate,
	);

	const [showToast, setShowToast] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	const [toastText, setToastText] = useState<string>('');

	const copyLink = (link: string) => {
		copyToClipboard(link);
		setToastText('The test link has been copied.');
		setShowToast(true);
		setSuccess(true);
	};

	const getShareText = () => {
		return test
			? REPORT_SHARE_TEXT.replace(
					'{reportUrl}',
					reportMeta.report_link,
			  ).replace('{testUrl}', test.test_link)
			: '';
	};

	const redirectToBooking = () => {
		window.open(`${bookingLink}`, '_blank');
	};

	return (
		<DetailsContainer column>
			<ROW>
				<img
					src={
						currentProfile?.profile_pic ||
						DICEBAR_URL + reportMeta?.name + '.svg'
					}
					alt={reportMeta?.name}
				/>
				<UserDetailsContainer column>
					Hi,
					<div>{reportMeta?.name}</div>
				</UserDetailsContainer>
			</ROW>
			<Divider />
			{reportMeta?.report_metadata && (
				<>
					<ROW>
						<Heading>Type:</Heading>
						<Value>{reportMeta?.report_metadata.phrase}</Value>
					</ROW>
					<Divider />
				</>
			)}
			<Heading> Share:</Heading>
			<Flex style={{ marginBottom: '2.4rem' }}>
				<ReportLink>{reportMeta.report_link}</ReportLink>
				<CopyButton onClick={() => copyLink(reportMeta.report_link)}>
					<img src={Copy} alt='Copy Link' />
				</CopyButton>
			</Flex>
			<SocialShare
				url={reportMeta.report_link}
				title={getShareText()}
				bgColor='#F2F2F2'
			/>
			{showToast && (
				<Toast
					onClose={(showToast) => setShowToast(showToast)}
					success={success}
				>
					{toastText}
				</Toast>
			)}
			{bookingLink && (
				<>
					<div
						style={{
							display: 'flex',
							margin: '0 auto',
							marginTop: '60px',
							alignItems: 'center',
							width: 'fit-content',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<img
							src={LockIcon}
							style={{ marginBottom: '20px', width: '35px', height: '35px' }}
							alt='lock_icon'
						/>
						<ScrollText>
							You've unlocked a <FreeText>1 on 1 session</FreeText> with our
							mentor
						</ScrollText>
					</div>
					<StyledButton onClick={() => redirectToBooking()}>
						<img
							src={CalendarIcon}
							style={{ width: '15px', marginRight: '10px' }}
							alt='calendar_icon'
						/>
						<span style={{ marginRight: '10px' }}>Book a session</span>
						<HiOutlineArrowRight />
					</StyledButton>
				</>
			)}
		</DetailsContainer>
	);
};

export default UserDetails;

const DetailsContainer = styled(Flex)`
	flex: 1;
	width: 50%;
	// background: ${({ theme }) => theme.colors.white};
	padding: 3.2rem 5.3rem;
	border-radius: 10px;
	@media only screen and (max-width: 1024px) {
		width: 100%;
		padding: 1rem;
	}
`;

const ScrollText = styled.div`
	font-weight: bold;
	font-size: 1.6rem;
	color: ${(props) => props.theme.colors.grey[500]};
	text-align: center;

	@media only screen and (max-width: 1024px) {
		font-weight: normal;
		text-align: center;
	}
`;

const FreeText = styled.span`
	color: ${(props) => props.theme.colors.blue[500]};
	margin: 0 5px;
`;

const StyledButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 2rem;
	margin-top: 25px;

	@media only screen and (max-width: 1024px) {
		margin: 3.2rem auto;
	}
`;

const ROW = styled(Flex)`
	img {
		width: 10rem;
		height: 10rem;
		margin-right: 7.5rem;
		margin-bottom: 2rem;
		@media only screen and (max-width: 1024px) {
			width: 7rem;
			height: 7rem;
			margin-right: 1rem;
			margin-bottom: 1rem;
		}
	}
`;

const UserDetailsContainer = styled(Flex)`
	font-weight: 600;
	font-size: 36px;
	line-height: 150%;
	color: ${({ theme }) => theme.colors.grey[400]};
	div {
		color: ${({ theme }) => theme.colors.grey[600]};
	}
	@media only screen and (max-width: 1024px) {
		font-size: 2.4rem;
		margin-left: 2rem;
	}
`;

const Divider = styled(Flex)`
	width: 100%;
	height: 2px;
	background: ${({ theme }) => theme.colors.grey[200]};
`;

const Value = styled(Flex)`
	font-weight: 500;
	font-size: 2.5rem;
	margin: 2.4rem 0;
	color: ${({ theme }) => theme.colors.grey[600]};
	@media only screen and (max-width: 1024px) {
		font-size: 1.6rem;
	}
`;

const Heading = styled(Flex)`
	font-weight: 500;
	font-size: 2.5rem;
	color: ${({ theme }) => theme.colors.grey[400]};
	width: 15rem;
	margin: 2.4rem 0;

	@media only screen and (max-width: 1024px) {
		font-size: 1.6rem;
		width: 10rem;
	}
`;

const ReportLink = styled(Flex)`
	background: #f9f9f9;
	border-radius: 1rem;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	line-height: 2.2rem;
	height: 100%;
	padding: 1.8rem;
	overflow: hidden;
	white-space: nowrap;
	font-weight: normal;
	font-size: 1.6rem;
	align-items: center;
	color: ${({ theme }) => theme.colors.grey[400]};
	@media only screen and (max-width: 1024px) {
		padding: 0.8rem;
		font-size: 1.2rem;
		line-height: 1.8rem;
	}
`;

const CopyButton = styled(Button)`
	width: max-content;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	padding: 1rem 1.5rem;
	@media only screen and (max-width: 1024px) {
		padding: 0.8rem;
		height: auto;
		img {
			width: 1.4rem;
			height: 1.4rem;
		}
	}
`;
