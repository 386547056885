import styles from "../../../styles/Footer.module.css";
import { TiLocationOutline } from "react-icons/ti";
import LoGo from "../../../../src/assets/logo.png";
import LocationIcon from "../../../../src/assets/images/primary-location.svg";
import LocationGrayIcon from "../../../../src/assets/images/gray-location.svg";
const FooterSection = () => {
  return (
    <>
      {/* <footer className={styles.footerContainer}>
        <div className={styles.StrideAhead}>
          <img src={LoGo} alt="LoGo"/>
        </div>
        <div className={styles.Intro}>
          <div className={styles.row}>
            <TiLocationOutline className={styles.GrLocation} />
            <p className={styles.purple}>
              M3M Cosmopolitan, 10th Floor, Sector 66, Gurugram, Harayana 122001
            </p>
          </div>
          <div className={styles.termsContainer}>
            <p className={styles.light}>
              <a href="#faq">FAQ</a>
            </p>
            <p className={styles.light}>
              <a href="https://strideahead.in/privacy-policy/">
                Privacy policy
              </a>
            </p>
            <p className={styles.light}>
              <a href="https://strideahead.in/terms-of-service/">
                Terms & conditions
              </a>
            </p>
          </div>
        </div>
        <hr />
        <div className={styles.ending}>
          <p>
            © Copyright 2021-{new Date().getFullYear()} | Stride Ahead (Assist 2
            Path Tech Pvt Ltd).
          </p>
          <p>All rights reserved.</p>
        </div>
      </footer>
      <div className={styles.desktopCopyRights}>
        <hr />
        <p>
          © Copyright 2021-{new Date().getFullYear()} | Stride Ahead (Assist 2
          Path Tech Pvt Ltd).
        </p>
      </div> */}
      <div className={styles.footerDesign}>
        <div className="container-md">
          <div className={styles.footerContentAlignment}>
            <div className={styles.footerLogo}>
              <img src={LoGo} alt="LoGo" />
            </div>
            <div className={styles.locationContentAlignment}>
              <div>
                <img src={LocationIcon} alt="LocationIcon" />
              </div>
              <div>
                <p>
                  M3M Cosmopolitan, 10th Floor, Sector 66, Gurugram, Haryana-
                  122001
                </p>
              </div>
            </div>
            <div className={styles.grayLocationAlignment}>
              <div>
                <img src={LocationGrayIcon} alt="LocationGrayIcon" />
              </div>
              <div>
                <p>Fumes co-working NSP, New Delhi - 110034</p>
              </div>
            </div>
            <div className={styles.footerLinkTextAlignment}>
              <a href="#faq">FAQs</a>
              <a>Privacy policy</a>
              <a>Terms & Conditions</a>
            </div>
          </div>
        </div>
        <div className={styles.footerCopyrightContent}>
          <p>
            Copyright 2021-2022 | Stride Ahead (Assist 2 Path Tech Pvt ltd) .
            All rights reserved.{" "}
          </p>
        </div>
      </div>
    </>
  );
};
export default FooterSection;
