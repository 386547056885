// @ts-nocheck
import React from 'react';
import { Route, Link } from 'react-router-dom';
import styled from 'styled-components';
import AuthGraphic from '../../assets/auth_graphic.png';
import StrideAheadLogo from '../../assets/stride_ahead_logo.svg';
import { useMedia } from '../../hooks/useMedia';
import Flex from '../components/Flex';
import LoginPage from '../pages/LoginPage';

const AuthScreenContainer = () => {
  const renderLogo = useMedia(
    ['(max-width: 1024px)'],
    [
      <Link to='/'>
        <img src={StrideAheadLogo} alt='Stride Ahead Logo' />
      </Link>,
    ],
    <img src={AuthGraphic} alt='Auth Graphic' />
  );

  return (
    <Container>
      <Sidebar align='center' justify='center'>
        {renderLogo}
      </Sidebar>
      <Content align='center' justify='center'>
        <Route path='/login' component={LoginPage} />
      </Content>
    </Container>
  );
};

export default AuthScreenContainer;

const Container = styled(Flex)`
  min-height: 100vh;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Sidebar = styled(Flex)`
  width: 60%;

  img {
    width: 80%;
  }

  @media (max-width: 1024px) {
    height: 8rem;
    width: 100%;
    padding: 1rem 0;

    a {
      height: 100%;
      cursor: pointer;

      img {
        width: auto;
        height: 100%;
      }
    }
  }
`;

const Content = styled(Flex)`
  width: 40%;
  padding: 2rem 0;
  flex-grow: 1;

  @media (max-width: 1024px) {
    width: 80%;
  }
`;
