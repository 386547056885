import React, { FC, useState } from "react";
import styled from "styled-components";
import { slideDown } from "../../../styles/animations";
import { ReportMetadataResponse } from "../../../utils/types";
import Button from "../../components/Button";
import Flex from "../../components/Flex";
import Modal from "../../components/Modal";

const Summary: FC<{ reportMeta: ReportMetadataResponse }> = ({
  reportMeta,
}) => {
  const [showSummary, setShowSummary] = useState<boolean>(false);
  return (
    <Container column>
      <Heading style={{ margin: "0" }}>Summary:</Heading>
      <SummaryContainer>
        {reportMeta?.report_metadata.description}
      </SummaryContainer>
      <KnowMoreButton onClick={() => setShowSummary(true)}>
        Know more
      </KnowMoreButton>
      <Heading>Strengths:</Heading>
      <StrengthContainer>
        {reportMeta?.report_metadata.strengths.map((strength, index) => {
          return (
            <ImageContainer column key={`${strength.name}-${index}`}>
              <img src={strength.image} alt={strength.name} />
              <span>{strength.name}</span>
            </ImageContainer>
          );
        })}
      </StrengthContainer>
      <Heading>Key focus areas:</Heading>
      <StrengthContainer>
        {reportMeta?.report_metadata.limitations.map((limitation, index) => {
          return (
            <ImageContainer column key={`${limitation.name}-${index}`}>
              <img src={limitation.image} alt={limitation.name} />
              <span>{limitation.name}</span>
            </ImageContainer>
          );
        })}
      </StrengthContainer>
      <Heading style={{ width: "100%" }}>Two Mantras to live by:</Heading>
      <MantrasContainer>
        {reportMeta?.report_metadata.mantras.map((mantra, index) => {
          return (
            <Mantra column key={`${mantra}-${index}`}>
              <Quotes>“</Quotes>
              <span>{mantra}</span>
            </Mantra>
          );
        })}
      </MantrasContainer>
      {showSummary && (
        <Modal onClose={(showModal) => setShowSummary(showModal)}>
          <SummaryModal column>
            <h2>Summary</h2>
            <div>{reportMeta?.report_metadata.description}</div>
          </SummaryModal>
        </Modal>
      )}
    </Container>
  );
};

export default Summary;

const Container = styled(Flex)`
  margin-left: 1.6rem;
  flex: 1;
  width: 50%;
  background: ${({ theme }) => theme.colors.white};
  padding: 3.2rem 5.3rem;
  border-radius: 10px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin: 1.6rem 0;
    padding: 1.2rem;
  }
`;

const SummaryModal = styled(Flex)`
  width: 100rem;
  height: fit-content;
  padding: 4rem;
  background-color: #fff;
  border-radius: 1rem;
  animation: ${slideDown} 0.4s ease-out;
  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-weight: 600;
  }
  div {
    font-family: Roboto;
    font-size: 2rem;
    line-height: 150%;
  }

  @media only screen and (max-width: 1024px) {
    width: 90%;
    padding: 3rem;
    h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }
    div {
      font-family: Roboto;
      font-size: 1.5rem;
      line-height: 150%;
    }
  }
`;

const Value = styled(Flex)`
  font-weight: 500;
  font-size: 2rem;
  line-height: 150%;
  margin: 1.6rem 0;
  color: ${({ theme }) => theme.colors.grey[600]};
  @media only screen and (max-width: 1024px) {
    font-size: 1.4rem;
  }
`;

const KnowMoreButton = styled(Button)`
  width: max-content;
  padding: 1rem;
  height: auto;
  background: #c1eafb;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.blue[500]};
  font-weight: normal;
  @media only screen and (max-width: 1024px) {
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
  }
`;

const ImageContainer = styled(Flex)`
  background: #f8faff;
  border-radius: 5px;
  flex: 1;
  padding: auto;
  margin-right: 2rem;
  padding: 1rem;
  margin-top: 1.6rem;

  img {
    height: 5rem;
    width: 5rem;
    margin: 0 auto;
  }

  span {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: left;
    margin: auto;
    width: 100%;
    text-align: center;
  }
  @media only screen and (max-width: 1024px) {
    flex: 1 1 40%;
    margin: 0.8rem 1.2rem 0.8rem 0;
    flex-direction: row;
    width: 50%;
    align-items: center;
    background: #edf6fd;
    color: ${({ theme }) => theme.colors.blue[500]};
    img {
      height: 3rem;
      width: 3rem;
      margin: 0;
    }
    span {
      font-size: 1.3rem;
    }
  }
`;

const Heading = styled(Flex)`
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.grey[400]};

  margin: 1.6rem 0 0;

  @media only screen and (max-width: 1024px) {
    font-size: 1.6rem;
  }
`;

const StrengthContainer = styled(Flex)`
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.report["blue"]};
  @media only screen and (max-width: 1024px) {
  }
`;

const MantrasContainer = styled(Flex)``;

const Mantra = styled(Flex)`
  flex: 1;
  width: 100%;
  background: ${({ theme }) => theme.colors.blue[500]};
  padding: 3.6rem 2rem;
  border-radius: 5px;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.1rem;

  text-align: center;

  color: ${({ theme }) => theme.colors.white};
  &:first-child {
    margin-right: 1.6rem;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 1.1rem;
    line-height: 1.3rem;
    padding: 2rem 0.6rem 0.8rem 0.6rem;
    width: 45%;
    &:first-child {
      margin-right: 1rem;
    }
  }
`;

const Quotes = styled(Flex)`
  font-weight: 600;
  font-size: 6rem;
  text-align: center;

  color: rgba(255, 255, 255, 0.75);

  justify-content: center;
  @media only screen and (max-width: 1024px) {
    font-size: 4rem;
  }
`;

const SummaryContainer = styled(Value)`
  height: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #555555;

  position: relative;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;

  :before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }

  :after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
`;
