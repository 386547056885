// @ts-nocheck
import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import { RiArrowDownSFill, RiCloseLine } from 'react-icons/ri';
import {
  ButtonIcon,
  DatePickerModal,
  Application,
} from 'react-rainbow-components';
import styled from 'styled-components';
import { Text1Css } from './Typography';
import Calendar from '../../assets/calendar.png';
import { getDate } from '../../utils/helperFunctions';
import { theme } from '../../styles/theme';

interface props {
  name?: string;
  label?: string;
  placeholder?: string;
  value?: any;
  onChange: any;
  className?: string;
  required?: boolean;
  error?: string;
}
const DateRangeInput: FC<props> = ({
  label,
  name,
  placeholder,
  required,
  error,
  value,
  onChange,
  className,
  ...rest
}) => {
  const [data, setData] = useState<any>({
    value: [],
    isOpen: false,
    dates: '',
  });
  function formatDates(dates) {
    if (dates && dates.length > 1) {
      const startDay = dayjs(dates[0]).format('MMMM DD,YYYY');
      const endDay = dayjs(dates[1]).format('MMMM DD,YYYY');
      return `${startDay} - ${endDay}`;
    }
    return '';
  }

  function handleDatePickerChange(date) {
    const formatedDate = formatDates(date);
    if (date.length > 1) {
      setData({
        value: date,
        isOpen: false,
        dates: formatedDate,
      });
      onChange(date.map((d) => getDate(d)));
    } else {
      setData({
        value: date,
        isOpen: true,
        dates: formatedDate,
      });
    }
  }

  return (
    <Container>
      {label && (
        <label htmlFor={name}>
          {label}
          {required && ' *'}
        </label>
      )}
      <img src={Calendar} alt='Calendar' />
      <span>{formatDates(value)}</span>
      <Button icon={<RiCloseLine />} onClick={() => onChange([])} />
      <Button
        icon={<RiArrowDownSFill />}
        onClick={() =>
          setData({
            isOpen: true,
            value: null,
          })
        }
      />
      <Application theme={theme}>
        <DatePickerModal
          isOpen={data.isOpen}
          value={data.value}
          variant='double'
          selectionType='range'
          onChange={handleDatePickerChange}
          onRequestClose={() => setData({ isOpen: false, dates: data.dates })}
        />
      </Application>
      {error && <span className='error'>{error}</span>}
    </Container>
  );
};

export default DateRangeInput;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  height: 3.2rem;
  border: 1px solid ${({ theme }) => theme.colors.grey[200]};
  padding: 5px;
  background: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.grey[400]};

  ${Text1Css}

  * {
    font-size: 1.6rem !important;
  }

  img {
    width: 24px;
    margin: 0px 8px;
  }

  span {
    min-width: 22rem;
    width: max-content;
    &.error {
      margin-top: 0.8rem;
      margin-left: 0.4rem;
      color: ${({ theme }) => theme.colors.red[500]};
    }
  }

  svg {
    display: none;
  }
`;

const Button = styled(ButtonIcon)`
  line-height: inherit;
  height: auto;
  &:focus {
    box-shadow: none;
    width: 20px;
    float: right;
  }
  svg,
  svg:active {
    display: inline !important;
    color: ${({ theme }) => theme.colors.grey[500]};
    width: 9rem !important;
    height: 9rem !important;
  }
`;
