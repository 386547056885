import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { QuizzBuilder } from "../../../App";
import { PROCESSOR_URL } from "../../../utils/constants";
import QuizDetailsForm from "./QuizDetailsForm";
import QuizMetaForm from "./QuizMetaForm";


function MultiStepForm(props: any) {
    const [step, setStep] = useState(1);
    const [timedQuiz, setTimedQuiz] = useState(false);
    const [quizDuration, setQuizDurration] = useState("");
    const [quizTemplate, setQuizTemplate] = useState(0);
    const [quizTemplateName, setQuizTemplateName] = useState('');
    const [reportTemplate, setReportTemplate] = useState(0);
    const [quizName, setQuizName] = useState("");
    const [quizLabel, setQuizLabel] = useState("");
    const [quizDescription, setQuizDescription] = useState("");
    const [preQuizDescription, setPreQuizDescription] = useState("");
    const [quizType, setQuizType] = useState();
    const [quizTypeName, setQuizTypeName] = useState("");
    const [collectLead, setCollectLead] = useState(false);
    const [quizQuestionList, setQuizQuestionList] = useState<any[]>([]);
    const [quizMeta, setQuizMeta] = useState({})
    const [quizCorrectAnswer, setQuizCorrectAnswer] = useState({})

    useEffect(()=>{
    if(Object.keys(props.formdata).length !== 0){
        setQuizName(props.formdata.name)
        setQuizLabel(props.formdata.label)
        setQuizDescription(props.formdata.description)
        setPreQuizDescription(props.formdata.pre_quiz_description)
        setQuizType(props.formdata.quiz_type)
        setCollectLead(props.formdata.collect_lead)
        setQuizQuestionList(props.formdata.question_list)
        setQuizMeta(props.formdata.meta)
        // Bugfix for correct answer error in previous commits
        const correctAnswers = Object.assign(props.formdata.meta.correct_answers || {}, props.formdata.meta.corect_answers || {})
        setQuizCorrectAnswer(correctAnswers)
        setQuizTypeName(props.formdata.quiz_type_name)
        setQuizTemplate(props.formdata.quiz_page_template)
        setQuizTemplateName(props.formdata.quiz_template_name)
        setReportTemplate(props.formdata.report_page_template)
        setQuizDurration(props.formdata.duration)
        setTimedQuiz(props.formdata.duration ? true : false)
      }
    }, [props.formdata])
    
    const updateCorrectAnswer = (id, value)=>{
      let newData = {...quizCorrectAnswer}
      newData[id] = value
      setQuizCorrectAnswer(newData)
      setQuizMeta({...quizMeta, "correct_answers": newData, "corect_answers": newData})
    }

    const saveQuiz = (formdata: any)=>{
      fetch(`${PROCESSOR_URL}quiz/`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        credentials: 'include',
        body: JSON.stringify(formdata)
      })
      .then(res=>{
        if(res.status == 201){
          props.setModalVisiblity(false)
          props.setDataFetched(false)
        }
        else if(res.status == 400){
          return res.json()
        }
        else{
          return Promise.reject(res.status)
        }
      })
      .then(data=>{
        if(data) {
          setStep(1)
          let message = `For ${Object.keys(data)[0]}: ${data[Object.keys(data)[0]]}`
          alert(message)
        }
      })
      .catch(err=>{
        console.log(err)
        alert("Something went wrong while saving the quiz, please try again later.")
      })
    }
  
    const updateQuiz = (formdata: any)=>{
      fetch(`${PROCESSOR_URL}quiz/${props.quizId}`, {
        method: "PUT",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata)
      })
      .then(res=>{
        if(res.status == 200){
          props.resetQuizData()
          props.setModalVisiblity(false)
          props.setDataFetched(false)
        }
        else if(res.status == 400){
          return res.json()
        }
        else{
          return Promise.reject(res.status)
        }
      })
      .then(data=>{
        if(data) {
          setStep(1)
          let message = data ? `For ${Object.keys(data)[0]}: ${data[Object.keys(data)[0]]}` : null
          alert(message)
        }
      })
      .catch(err=>{
        console.log(err)
        alert("Something went wrong while updating the quiz, please try again later.")
      })
    }

    const handleSubmit = ()=>{
      let formdata = {
        "name": quizName,
        "label": quizLabel,
        "description": quizDescription,
        "pre_quiz_description": preQuizDescription,
        "collect_lead": collectLead,
        "quiz_type": quizType,
        "question_list": quizQuestionList,
        "quiz_page_template": quizTemplate,
        "report_page_template": reportTemplate,
        "duration": timedQuiz ? quizDuration : null,
        "meta": quizMeta
      }
      if(props.formType==="add"){
        saveQuiz(formdata)
      }
      else if(props.formType==="edit"){
        updateQuiz(formdata)
      }
    }
  
    const handleChange = (input: any, value: any)=>{
      if(input==="name"){
        setQuizName(value)
      }
      else if(input==="description"){
        setQuizDescription(value)
      }
      else if(input==="pre_quiz_description"){
        setPreQuizDescription(value)
      }
      else if(input==="collectLead"){
        setCollectLead(value)
      }
      else if(input==="quizTypeName"){
        setQuizTypeName(value)
      }
      else if(input==="resetQuizQuestions"){
        setQuizQuestionList(value)
      }
      else if(input==="quizTemplate"){
        setQuizTemplate(value)
      }
      else if(input==="quizTemplateName"){
        setQuizTemplateName(value)
      }
      else if(input==="reportTemplate"){
        setReportTemplate(value)
      }
      else if(input==="isTimed"){
        setTimedQuiz(value)
      }
      else if(input==="quizDuration"){
        setQuizDurration(value)
      }
      else if(input==="label"){
        setQuizLabel(value)
      }
      else{
        setQuizType(value)
      }
    }

    let formToRender;
    switch(step){
      case 1:
        formToRender = <QuizDetailsForm 
        onChange={handleChange}
        quizName={quizName}
        quizLabel={quizLabel}
        quizDescription={quizDescription}
        preQuizDescription={preQuizDescription}
        quizType={quizType}
        quizTemplate={quizTemplate}
        reportTemplate={reportTemplate}
        collectLead={collectLead}
        timedQuiz={timedQuiz}
        quizDuration={quizDuration}
        />;
        break;
      case 2:
        formToRender = <QuizzBuilder
        initialValue={quizQuestionList}
        quizTypeName={quizTypeName}
        templateTypeName={quizTemplateName}
        updateCorrectAnswer={updateCorrectAnswer}
        onChange={setQuizQuestionList}
        quizCorrectAnswer={quizCorrectAnswer}
        >
        </QuizzBuilder>;
        break;
      case 3:
        formToRender = <QuizMetaForm
        quizTypeName={quizTypeName}
        onChange={setQuizMeta}
        quizMeta={quizMeta}
        questions={quizQuestionList}
        />;
        break;
      default:
        formToRender = <QuizDetailsForm 
        onChange={handleChange}
        quizName={quizName}
        quizType={quizType}
        />;
        break;
    }
    
    const handleNextClick = ()=>{
      if(step === 3){
        handleSubmit()
      }
      else if (step<1 || step>3){
        setStep(1)
      }
      else if(step === 1 && (!quizName || !quizType)){
        if(!quizName){
          alert("Please enter a quiz name");
        }
        if(!quizType){
          alert("Please select a quiz type")
        }
        if(!quizTemplate){
          alert("Please select a quiz template")
        }
        if(!reportTemplate){
          alert("Please select a report template")
        }
      }
      else if(step===2 && quizTypeName === "dimensions_quiz"){
        var flag = true;
        quizQuestionList.map((item, index) => {
          if(!item.trait){
            flag = false;
            alert(`Please select a Dimension Trait for question: ${index+1}`); 
          }
        })
        if(flag) {
          setStep(step+1);
        }
      }
      else{
        setStep(step+1)
      }
    }

    const handlePrevClick = ()=>{
      if(step<=1 || step>3){
        setStep(1)
      }else{
        setStep(step-1)
      }
    }
    
    return (
    <Modal
        width={"80%"}
        title="Quiz Form"
        style={{ top: 20 }}
        visible={props.modalVisiblity}
        onCancel={()=>{
          setStep(1)
          props.resetQuizData()
          props.setModalVisiblity(false)
          }
        }
        destroyOnClose={true}
        footer={[
          <Button disabled={step===1?true:false} onClick={handlePrevClick}>
            Previous
          </Button>,
          <Button onClick={handleNextClick}>
            {(step===3 || step===2 && quizTypeName!=="random_quiz") ? "Submit" : "Next"}
          </Button>
        ]}
        >

        <div id="multiStepForm">
            {formToRender}
        </div>
    </Modal>
    )
}

export default MultiStepForm