import React from 'react';
import ProgressBar from "@ramonak/react-progress-bar";

export default function CustomProgressBar(props){
    return (
        <>
            <div 
                style={{display: props.showPrev ? "block" : "none"}}
                onClick={props.handlePrev} className="left-arrow">
                <img src="https://strideahead.in/wp-content/uploads/2021/10/Back.png" alt="" />
            </div>

            <div style={{display: props.showNext ? "block" : "none"}}
                onClick={props.handleNext} className="right-arrow">
                <img src="https://strideahead.in/wp-content/uploads/2021/10/Forward.png" alt="" />
            </div>

            <ProgressBar
            margin="0 auto"
            width="90%"
            className="progress-bar"
            borderRadius="100px"
            height="28px"
            bgColor="#1273B7"
            labelColor="#1273b7"
            completed={parseInt(props.percentage)}
            />
        </>
    )
}