const colors = {
  red: {
    500: '#EB5757',
    400: '#ED6262',
    300: '#F7B7B7',
    100: '#FFEBEB',
  },
  orange: '#F2994A',
  yellow: {
    500: '#EFCF1B',
    400: '#F8E478',
    100: '#FFEB9A',
  },
  green: {
    600: '#00AB66',
    500: '#5BC044',
    400: '#27AE60',
    300: '#C3F3D7',
    200: '#D7EBD3',
    100: '#BEF8D6',
  },
  blue: {
    500: '#1273B7',
    300: '#BCE7FF',
    200: '#C2D6FC',
    100: '#F3FFF9',
    50: '#F4F7FC',
  },
  grey: {
    600: '#333333',
    500: '#4F4F4F',
    400: '#828282',
    300: '#BDBDBD',
    200: '#E0E0E0',
    100: '#F2F2F2',
    50: '#F9F9F9',
  },
  white: '#ffffff',
  report: {
    blue: '#325D79',
    green: '#61A187',
    pink: '#F1828D',
    yellow: '#F1BC71',
    grey: '#BDBDBD',
    white: '#EFEFFF',
  },
  footer: {
    black: '#1C252E',
  },
  text:{
    black:'#000000',
  }
};

export const light = {
  colors,
};

export const theme = {
  rainbow: {
    palette: {
      brand: '#1273B7',
    },
  },
};
