import React from "react";
import { FC, Fragment, useState, useEffect, createContext } from 'react';
import { IoMdLock } from 'react-icons/io';
import styled from 'styled-components';
import Lock from '../../../assets/test/lock.svg';
import { slideDown } from '../../../styles/animations';
import { TestDetail } from "../../../utils/types";
import Button from '../Button';
import Flex from '../Flex';
import Modal from '../Modal';




const TestTermsAndConditions: FC<{
  onSubmit: any;
  link: any;
  test?: TestDetail;
  buttonText?: string;

}> = ({ onSubmit, link, test, buttonText }) => {
  const [agree, setAgree] = useState(false);

  const terms_and_conditions_image = test?.test_configs?.terms_and_conditions_image ?
    test?.test_configs?.terms_and_conditions_image : Lock

  useEffect(() => {
    console.log(buttonText, "24")
  })
  useEffect(() => {
    console.log(test, "31")
  })



  return (
    <Modal width='60rem'>
      <Container column onClick={(e) => e.stopPropagation()}>
        <img src={terms_and_conditions_image} alt='Lock' />
        <Fragment>
          <RadioContainer>
            <Label>
              <Radio
                type='radio'
                value='agree'
                onChange={() => setAgree(true)}
              />
              <span>
                I consent to the{' '}
                <a href={link} target='_blank' rel="noreferrer">
                  terms and conditions
                </a>
              </span>
            </Label>
          </RadioContainer>
          <AddButton test={test} disabled={!agree} onClick={() => onSubmit()}>
            {buttonText || 'Proceed To Test'}
          </AddButton>
        </Fragment>
        <GDPRContainer>
          <IoMdLock />
          Compliant with the GDPR
        </GDPRContainer>
      </Container>
    </Modal>

    // <div style={{ display: "flex", flexDirection: 'column', gap: 10, justifyContent: "center" }}>
    //   <img src={terms_and_conditions_image} alt='Lock' />
    //   <Fragment>
    //     <RadioContainer>
    //       <Label>
    //         <Radio
    //           type='radio'
    //           value='agree'
    //           onChange={() => setAgree(true)}
    //         />
    //         <span>
    //           I consent to the{' '}
    //           <a href={link} target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}>
    //             terms and conditions
    //           </a>
    //         </span>
    //       </Label>
    //     </RadioContainer>
    //     <AddButton test={test} disabled={!agree} onClick={(e) => {
    //       console.log('TestTermsAndConditions', e)
    //       onSubmit(e)
    //     }}>
    //       {buttonText || 'Proceed To Test'}
    //     </AddButton>
    //   </Fragment>

    // </div>

    // <Modal width='60rem'>
    //   <Container column onClick={(e) => e.stopPropagation()}>
    // <>
    //   {buttonText && <div style={{ display: "flex", flexDirection: 'column', gap: 10, justifyContent: "center" }}>
    //     <img src={terms_and_conditions_image} alt='Lock' />
    //     <Fragment>
    //       <RadioContainer>
    //         <Label>
    //           <Radio
    //             type='radio'
    //             value='agree'
    //             onChange={() => setAgree(true)}
    //           />
    //           <span>
    //             {' '}I consent to the{' '}
    //             <a href={link} target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}>
    //               terms and conditions
    //             </a>
    //           </span>
    //         </Label>
    //       </RadioContainer>
    //       <AddButton test={test} disabled={!agree} onClick={(e) => {
    //         console.log('89', e)
    //         onSubmit(e)
    //       }}>
    //         {buttonText || 'Proceed To Test'}
    //       </AddButton>
    //     </Fragment>

    //   </div>}
    // </>
    //   </Container>
    // </Modal>
  );
};

export default TestTermsAndConditions;

const Container = styled(Flex)`
      height: fit-content;
      max-height: 90%;
      overflow: auto;
      padding: 4.3rem;
      padding: 4.3rem;
      background-color: #fff;
      border-radius: 4px;
      animation: ${slideDown} 0.4s ease-out;
      img {
        width: 30rem;
      margin: auto;
      margin-bottom: 2.5rem;
  }
      @media only screen and (max-width: 1024px) {
        padding: 2.2rem;
      width: 90%;
      img {
        width: 20rem;
      margin: auto;
      margin-bottom: 2.5rem;
    }
  }
      `;

const AddButton = styled(Button)`
     width: 100%;
  height: 5rem;
  background-color: ${(props) => props.test?.test_configs?.theme?.bg_color || props.theme.colors.blue[500]};
  color : ${(props) => props.theme.colors.white};
  border-radius: 2px;
  cursor: pointer;
  margin-top: 3rem;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  @media only screen and (max-width: 425px) {
      height: fit-content;
      text-align : center;
  }
  @media (max-width:767px){
    margin-top: 2rem;
    padding: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  @media (min-width:767px) and (max-width:991px){
    height: 4rem;
    margin-top: 2rem;
    padding: 1rem;
    font-size: 1.4rem;
  }
  @media(min-width:991px){
    height: 4rem;
    font-size: 1.4rem;
    margin-top: 2.5rem;
    font-weight: 600;
    letter-spacing: .5px;
  }
`;

const GDPRContainer = styled(Flex)`
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 150%;
      margin: 1.5rem auto;
      color: ${(props) => props.theme.colors.grey[400]};
      align-items: center;
      svg {
        margin - right: 1rem;
  }
      @media only screen and (max-width: 1024px) {
        font - size: 1.2rem;
  }
      `;

const RadioContainer = styled(Flex)`
      border: 1px solid ${(props) => props.theme.colors.grey[200]};
      box-sizing: border-box;
      border-radius: 4px;
      padding: 1rem;
      margin-bottom: 1rem;
     
      `;

const Radio = styled.input`
      width: 15px;
      height: 15px;
      margin-right: 1rem;
      &:checked {
        color: red;
  }
      @media (min-width:767px){
        width: 10px;
      height: 10px;
  }
      `;

const Label = styled.label`
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      margin-left: 1rem;
      color: ${(props) => props.theme.colors.grey[500]};
      width: 100%;
      cursor: pointer;
      span {
        margin - left: 1rem;
  }
      @media only screen and (max-width: 1024px) {
        font - size: 1.2rem;
  }
      `;