import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import CopyLink from '../../assets/copy.png';
import SendMail from '../../assets/mail.svg';
import Responses from '../../assets/responses.svg';
import useReportList from '../../hooks/useReportList';
import useTest from '../../hooks/useTest';
import { API_ENDPOINT } from '../../utils/constants';
import { formatDate, getRatingIcon } from '../../utils/helperFunctions';
import { APIError, Feedback, FetchResponse } from '../../utils/types';
import Back from '../components/Back';
import Export from '../components/Export';
import { Container } from '../components/Container';
import DateRangeInput from '../components/DateRangeInput';
import Flex from '../components/Flex';
import Header from '../components/Header';
import Loading from '../components/Loading';
import FeedbackPopup from '../components/popups/FeedbackPopup';
import SearchBox from '../components/SearchBox';
import Separator from '../components/Separator';
import { AxiosResponse, AxiosError } from 'axios'
import {
  CopyButton,
  Pagination,
  Table,
  TableContainer,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from '../components/Table';
import TableEditButton, { Action } from '../components/TableEditButton';
import ReportStatus from '../components/ReportStatus';
import Toast from '../components/Toast';
import { message } from 'antd';

const ReportList = () => {
  const history = useHistory();
  const flag = process.env.REACT_APP_FLAG_TO_REDIRECT_TO_REPORT_PAGE 
  const { testId } = useParams<{ testId: string }>();

  const [showToast, setShowToast] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>('');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>('');
  const [dateRange, setDateRange] = useState<string[]>([]);
  const [reportStatus, setReportStatus] = useState<number>(0);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<Feedback>();
  const [loader,setLoader] = useState<boolean>(false);

  const { data: test } = useTest(testId);

  const { isLoading, isSuccess, data } = useReportList(
    test?.id,
    page,
    search,
    dateRange[0],
    dateRange[1],
    reportStatus,
  );

  const copyReportLink = (reportLink?: string) => {
    if (reportLink) {
      var textField = document.createElement('textarea');
      textField.innerText = reportLink;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      setToastText('The report link has been copied.');
      setShowToast(true);
      setSuccess(true);
    }
  };

  const openResponses = (id: number) => {
    history.push(`/report/${testId}/${id}`);
  };

  const sendMail = async (id: number): Promise<APIError[] | void> => {
    try {
      await axios.get<FetchResponse<{ message: string }>>(
        API_ENDPOINT + 'send-mail/' + id
      );
      setToastText(`Please check your inbox for the link.`);
      setSuccess(true);
      setShowToast(true);
    } catch (error) {
      const errors = error.response.data.error as APIError[];
      setToastText(error.response.data.error[0]);
      setSuccess(false);
      setShowToast(true);
      return errors;
    }
  };

  const generateReport = async (id: number): Promise<APIError[] | string|void> => {
    try {
      const data = await axios.get<FetchResponse<{data:string}>,string>(
        API_ENDPOINT + 'regenerate-report/' + id
      );
      if (flag === 'true') {
        window.open(data["data"]["data"]['report_link']);
      }
      setToastText('Report generated successfully')
      setSuccess(true);
      setShowToast(true);
      console.log(data)
    } catch (error) {
      const errors = error.response.data.error as APIError[];
      setToastText('Some error occured while generating report')
      setSuccess(false);
      setShowToast(true);
      return errors;
    }
  };


  const fetchAllReport = async (test_id: string) => {
    try {
      setLoader(true)
      let url = `${API_ENDPOINT}test/${test_id}/report?page=0`
      if (dateRange.length !=0){
        url = url + `&searcy=&from_=${dateRange[0]}&to=${dateRange[1]}`
      }
      if (reportStatus != 0){
        url = url + `&report_status=${reportStatus}`
      }
      const {
        data: { data },
        status: status
      } = await axios.get(url)
      if(status === 200){
        setLoader(false);
      }
      if(data?.reports.length === 0){
        return
      }
      let head = Object.keys(data?.reports[0]);
      const val = data?.reports;
    let csvContent:string = '';
    csvContent += head.join(',')+ "\n";
      val.map((newArray:string)=>{
        let temp:any[] = Object.values(newArray);
        let feedback:string[] = Object.values(temp[7]);
        if(temp[1] === 0){
          temp[1] = "Form Filled"
        }
        else if(temp[1] === 1){
          temp[1] = "Test Started"
        }
        else{
          temp[1] = 'Test Completed'
        }
        console.log(typeof(temp[1]))
        temp.pop();
        console.log(temp)
        if (feedback.length === 0){
            csvContent += temp.join(',')+",null"+ "\n" ;
        }
        else{
            csvContent += temp.join(',')+feedback.join(':')+ "\n";
        }
      })
    const file = new Blob([csvContent as BlobPart], {type: 'data:text/csv;charset=utf-8,'});
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = test?.test_name+"_report.csv";
    document.body.appendChild(element);
    element.click()
    document.body.removeChild(element);
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  };

  useEffect(() => {}, [dateRange, search, page, reportStatus]);

  const renderReportList = () => {
    if (isSuccess) {
      console.log(data?.reports)
      return data?.reports.map((report, index) => (
        <StyledTR key={index}>
          <TD centered maxWidth='16%'>
            <Flex style={{ justifyContent: 'center' }}>
              {report?.report_link ? (
                <StyledLink onClick={() => window.open(report.report_link)}>
                  {report.id}
                </StyledLink>
              ) : (
                <DisabledLink>{report.id}</DisabledLink>
              )}
              <CopyButton onClick={() => copyReportLink(report.report_link)}>
                <img src={CopyLink} alt='Copy Link' />
              </CopyButton>
            </Flex>
          </TD>
          <TD centered maxWidth='22%'>
            {report.name}
          </TD>
          <TD centered maxWidth='22%'>
            {report.email}
          </TD>
          <TD centered maxWidth='20%'>
            {report?.phone_no}
          </TD>

          {report?.report_status===0?<TD centered maxWidth='10%' background='#0068a5' color='black'>
            Form Filled
          </TD>:report?.report_status===1?<TD centered maxWidth='10%' background='#ffc107'color='black' >
            Test Started
          </TD>:report?.report_status===2?<TD centered maxWidth='10%' background='rgb(39, 174, 96)' color='black'>
            Test Completed
          </TD>:<TD centered maxWidth='10%'>
          </TD>}

          <TD centered maxWidth='20%'>
            {formatDate(report.generated_on)}
          </TD>
          <TD centered maxWidth='18%'>
            {report?.feedback?.rating ? (
              <img
                src={`${process.env.PUBLIC_URL}/feedback/${getRatingIcon(
                  report?.feedback?.rating
                )}.svg`}
                alt={getRatingIcon(report?.feedback?.rating)}
                style={{ height: '3rem', cursor: 'pointer' }}
                onClick={() => {
                  setFeedback(report.feedback);
                  setShowFeedback(true);
                }}
              />
            ) : (
              '-'
            )}
          </TD>
          <TD centered maxWidth='5%' style={{ position: 'relative' }}>
            <TableEditButton>
              <Action
                justify='center'
                align='center'
                onClick={() => {
                  openResponses(report.id);
                }}
              >
                <img src={Responses} alt='Responses' /> <span style={{marginRight:'34px'}}>Responses</span>
              </Action>
              <StyledSeparator />
              <Action
                justify='center'
                align='center'
                onClick={() => {
                  sendMail(report.id);
                }}
              >
                <img src={SendMail} alt='Re-Send Email' />
                <span style={{marginRight:'15px'}}>Re-Send&nbsp;Email</span>
              </Action>
              <Action
                justify='center'
                align='center'
                onClick={() => {
                  generateReport(report.id)
                }}
              >
                <img src={SendMail} alt='Re-Send Email' />
                <span>Generate Report</span>
              </Action>
            </TableEditButton>
          </TD>
        </StyledTR>
      ));
    }
  };

  return (
    <Container column>
      {isLoading && <Loading />}
      {loader && <Loading/>}
      <Back label='Back to tests' />
      <Header align='center' justify='space-between'>
        <DateRangeInput
          onChange={(range) => setDateRange(range)}
          value={dateRange}
        />
        <SearchBox
          placeholder='Search Reports by Name, Email and Report ID'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Header>
      <ReportDetailContainer>
        {/* <h2>Reports</h2> */}
        <ReportDetail>
          <label>Owner :</label>
          {data?.owner_name}
        </ReportDetail>
        <ReportDetail>
          <label>Test ID :</label>
          {data?.test_display_id}
        </ReportDetail>
        <ReportDownload>
        <Export label='Download Reports' test_id={test?.id} func={fetchAllReport}/>
        </ReportDownload>
      </ReportDetailContainer>
      <TableContainer>
        <Table>
          <THead>
            <StyledTR>
              <TH>Report ID</TH>
              <TH>Name</TH>
              <TH>Email</TH>
              <TH>Phone No.</TH>
              <TH><ReportStatus>
              <Action
                justify='center'
                align='center'
                onClick={()=>setReportStatus(3)}
              >
                <span style={{marginRight:'34px'}}>Form Filled</span>
              </Action>
              <StyledSeparator />
              <Action
                justify='center'
                align='center'
                onClick={()=>setReportStatus(1)}

              >
                <span style={{marginRight:'15px'}}>Test Started</span>
              </Action>
              <Action
                justify='center'
                align='center'
                onClick={()=>setReportStatus(2)}
              >
                <span>Test Completed</span>
              </Action>
              <Action
                justify='center'
                align='center'
                onClick={()=>setReportStatus(0)}
              >
                <span>All</span>
              </Action>
            </ReportStatus></TH>
              <TH>Generated On</TH>
              <TH>Feedback</TH>
              <TH></TH>
            </StyledTR>
          </THead>
          <TBody>{renderReportList()}</TBody>
        </Table>
      </TableContainer>
      {!isLoading && data?.pagination_data && (
        <Pagination
          currentPage={page}
          prevPage={data.pagination_data.page_prev}
          nextPage={data.pagination_data.page_next}
          nextPageChangeHandler={
            data?.pagination_data.page_next
              ? function () {
                  setPage((currentPage) => currentPage + 1);
                }
              : undefined
          }
          prevPageChangeHandler={
            data?.pagination_data.page_prev
              ? function () {
                  setPage((currentPage) => currentPage - 1);
                }
              : undefined
          }
        />
      )}
      {showToast && (
        <Toast
          onClose={(showToast) => setShowToast(showToast)}
          success={success}
        >
          {toastText}
        </Toast>
      )}
      {showFeedback && feedback && (
        <FeedbackPopup
          onClose={(showFeedback) => setShowFeedback(showFeedback)}
          feedback={feedback}
        />
      )}
    </Container>
  );
};

export default ReportList;

const ReportDetail = styled(Flex)`
  margin-right: 8rem;
  font-size: 1.6rem;
  padding-top: 0.6rem;
  label {
    font-weight: 600;
    margin-right: 1rem;
  }
`;
const ReportDetailContainer = styled(Flex)`
  display:flex;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey[200]};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1rem;
  h2 {
    font-size: 2rem;
    font-weight: 600;
  }
`;

const ReportDownload = styled(Flex)`
margin-left: auto;
font-size: 1.6rem;
label {
  font-weight: 600;
  margin-right: 1rem;
}
  }`;

const StyledTR = styled(TR)`
  border: 1px solid ${(props) => props.theme.colors.grey[200]};
  border-radius: 2px;
`;

const StyledSeparator = styled(Separator)`
  margin: 0;
`;

const StyledLink = styled(Flex)`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.blue[500]};
  background-color: ${(props) => props.theme.colors.grey[50]};
  padding: 0.4rem 0.6rem;
  &:visited {
    color: ${(props) => props.theme.colors.blue[500]};
  }

  border-radius: 4px 0px 0px 4px;
`;

const DisabledLink = styled(Flex)`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.grey[500]};
  background-color: ${(props) => props.theme.colors.grey[50]};
  padding: 0.4rem 0.6rem;
  &:visited {
    color: ${(props) => props.theme.colors.blue[500]};
  }

  border-radius: 4px 0px 0px 4px;
`;
