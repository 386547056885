// @ts-nocheck
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import NewProfileImage from "../../../../src/assets/images/new-profile-image.svg";
import StarIcon from "../../../../src/assets/images/star.svg";
import { data } from "../../../data";
import { VideoPlayer } from "./videoPlayer";
import styled from "styled-components";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="same-slider-class-arrow right-arrow" onClick={onClick} style={{ border: "3px solid rgba(40, 30, 66, 0.1)" }}>
            <svg width="11" height="15" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.81836 1.94434L8.28296 9.40918L1.81836 16.8738" stroke="#8667DE" stroke-width="3" stroke-linecap="round" />
            </svg>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="same-slider-class-arrow left-arrow" onClick={onClick} style={{ border: "3px solid rgba(40, 30, 66, 0.1)" }}>
            <svg width="11" height="15" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.2832 1.94434L2.8186 9.40918L9.2832 16.8738" stroke="#8667DE" stroke-width="3" stroke-linecap="round" />
            </svg>
        </div>
    );
}
export default function RedutechStudentSwipe() {
    const content = data.testimonialsSection;
    const [screenWidth, setScreenWidth] = useState(0);
    const [readMore, setReadMore] = useState(-1);
    useEffect(() => {
        let width = window.innerWidth;
        setScreenWidth(width);
    }, []);
    const settings = {
        dots: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 324,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
        ],
    };

    return (
        <div className="see-section-alignment" >
            <div className="container-md">
                <div className="page-title2 pb-5" >
                    <h3 style={{color:"#8B292A"}}>Testimonials</h3>
                    {/* <p style={{color:"#3D3636"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                </div>
                <Divider>
                    <hr/>
                </Divider>
                <div className="see-slider-section"   >
                    <Slider {...settings}>
                        {content
                            ?.sort((a, b) => (a.id > b.id ? 1 : -1))
                            .map((item, index) => {
                                return (
                                    <div>
                                        <div className="first-card"  >
                                            <div className="card-profile-image-alignment" >
                                                {item.src !== "" && <img src={item.src} alt="NewProfileImage" />}
                                                {/* <img src={NewProfileImage} alt="NewProfileImage" /> */}

                                            </div>
                                            <div className="new-text" >
                                                {item.desc == "" ?
                                                    <div style={{ margin: "0", display: "flex", justifyContent: "center"}}>
                                                        <VideoPlayer
                                                            type="normal"
                                                            width={screenWidth > 786 ? "320px" : "65vw"}
                                                            height={screenWidth > 786 ? "175px" : "65vw"}
                                                            url={item.url}
                                                        />
                                                    </div>
                                                    :
                                                    screenWidth > 786 ? (
                                                        <p>
                                                            {readMore === index ? (
                                                                <>
                                                                    {item.desc} <span onClick={() => setReadMore(-1)} style={{fontSize:"12px",color:"#8B292A"}} >...Read less</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {item?.desc?.split(" ")?.slice(0, 30)?.join(" ")}{" "}
                                                                    {item?.desc?.split(" ").length > 30 ? <span onClick={() => setReadMore(index)}  style={{fontSize:"12px",color:"#8B292A"}}>...Read more</span> : ""}
                                                                </>
                                                            )}
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            {readMore === index ? (
                                                                <>
                                                                    {item.desc} <span onClick={() => setReadMore(-1)}  style={{fontSize:"12px",color:"#8B292A"}}>...Read less</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {item?.desc?.split(" ")?.slice(0, 40)?.join(" ")}{" "}
                                                                    {item?.desc?.split(" ").length > 40 ? <span onClick={() => setReadMore(index)}  style={{fontSize:"12px",color:"#8B292A"}}>...Read more</span> : ""}
                                                                </>
                                                            )}
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="sec-card" style={{backgroundColor:"rgba(253, 78, 58, 0.06)"}}>
                                            <div className="text-s">
                                                <h4>{item?.name}</h4>
                                                <p>{item?.role}</p>
                                            </div>
                                            <div className="rating-alignment">
                                                <img src={StarIcon} alt="StarIcon" />
                                                <img src={StarIcon} alt="StarIcon" />
                                                <img src={StarIcon} alt="StarIcon" />
                                                <img src={StarIcon} alt="StarIcon" />
                                                <img src={StarIcon} alt="StarIcon" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

const Divider = styled.div`
   width:100%;
   display:flex;
   justify-content:center;
   align-items: center;
   
   hr{
    width: 150px;
    height: 4px;
    border-radius: 50%;
    background: #8B292A;
    opacity: 1;
    border: none;
    margin-top:0px;
    margin-bottom:40px;
   }
`;