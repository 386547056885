import { Button, Tooltip } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import ErrorFallback from "../pages/ErrorFallback";
import { useCurrentProfile } from "../../hooks/useProfile";
import { API_ENDPOINT, BRAINWAVE_UUID, PAGES_URL } from "../../utils/constants";
import axios from "axios";
import { BiRefresh } from "react-icons/bi";
import Toast from "../components/Toast";

export default function BrainwaveList(props: any) {
  const [rows, setRows] = useState<object[]>([]);
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);

  const [showToast, setShowToast] = useState<boolean>(false);
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("");

  useEffect(() => {
    getList(page);
  }, [page]);

  const renderActionButtons = (params: any) => {
    const showReportButton = params.row.showReportButton;
    return (
      <>
        {showReportButton && (
          <strong>
            <Button
              className="btn-stride-primary"
              id={params.id}
              variant="contained"
              size="medium"
              style={{ marginLeft: 3, paddingLeft: 6, paddingRight: 6, fontSize: "9px" }}
              onClick={() => {
                window.open(params.row.openButtonRedirectLink, '_blank');                
              }}
            >
              Open
            </Button>
            <Button
              className="btn-stride-primary"
              id={params.id}
              variant="contained"
              size="medium"
              style={{ marginLeft: 3, paddingLeft: 6, paddingRight: 6, fontSize: "9px" }}
              onClick={() => {
                sendReport(params.row.email);
              }}
            >
              Send Report
            </Button>
            <Button
              className="btn-stride-primary"
              id={params.id}
              variant="contained"
              size="medium"
              style={{ marginLeft: 3, paddingLeft: 6, paddingRight: 6, fontSize: "9px" }}
              onClick={() => {
                downloadReport(params.row.email, params.row.product_uuid, params.row.order_status);
              }}
            >
              Download
            </Button>
          </strong>
        )}
      </>
    );
  };

  function downloadReport(email: any, productUuid: any, order_status: any) {
    const reportType = order_status == "completed" ? "detailed_report" : "summary_report"
    axios
      .post(`${API_ENDPOINT}tucareer/report`, {email: email, report_type: reportType, uuid: productUuid}, { responseType: 'blob' })
      .then((res) => {
        if (res.status === 200) {
          const href = URL.createObjectURL(res.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'report.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        } else {
          setSuccessToast(false);
          setToastText("Could not download the report.");
        }
      })
      .catch((err) => {
        setSuccessToast(false);
        setToastText("Something went wrong while downloading the report.");
        alert(err);
      });
  }

  function sendReport(email: any) {
    axios
      .post(`${API_ENDPOINT}send-brainwave-report`, { email: email })
      .then((res) => {
        try {
          setShowToast(true);
          if (res.data.data.status === "Success") {
            setSuccessToast(true);
            setToastText("Report sent successfully");
          }
        } catch {
          setSuccessToast(false);
          setToastText("Error occured while sending report");
        }
      })
      .catch((err) => {
        setSuccessToast(false);
        setToastText("Error occured while sending report");
        alert("Failed to send brainwave report data");
      });
  }

  const renderTestStatus = (params: any) => {
    const test_status = params.row.test_status;
    return (
      <>
        {test_status}
        <Button
          className="btn-stride-primary"
          id={params.id}
          variant="contained"
          size="small"
          style={{ marginLeft: 16, fontSize: "20px", minWidth: "20px" }}
          onClick={() => {
            params.row.test_status = "Loading...";
            getTestStatus(params.row.email).then((res) => {
              var newData = rows;
              newData.filter((row: any) => {
                if (row.email === params.row.email) {
                  row.test_status = res.assessment_status;
                  row.showReportButton = row.test_status === "completed";
                  row.uuid = res.uuid;
                  row.openButtonRedirectLink = row.showReportButton ? `${res.thank_you_page_url}?uuid=${row.uuid}` : ""
                }
              });
              setRows([...newData]);
            });
          }}
        >
          <BiRefresh /> 
        </Button>
      </>
    );
  };

  const renderEmailColumn = (params: any) => {
    const email = params.row.email;
    return (
      <Tooltip
        title={<p style={{ fontSize: "15px", margin: "5px" }}>{email}</p>}
      >
        <div>{email}</div>
      </Tooltip>
    );
  };

  const renderProductColumn = (params: any) => {
    const product = params.row.product_name;
    return (
      <Tooltip
        title={<p style={{ fontSize: "15px", margin: "5px" }}>{product}</p>}
      >
        <div>{product}</div>
      </Tooltip>
    );
  };

  const columns = [
    { field: "serial_no", headerName: "#", width: 70 },
    {
      field: "product_name",
      headerName: "Product Name",
      flex: 1,
      renderCell: renderProductColumn,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: renderEmailColumn,
    },
    {
      field: "order_status",
      headerName: "Order Status",
      flex: 0.6,
      editable: false,
    },
    {
      field: "test_status",
      headerName: "Test Status",
      flex: 0.8,
      editable: false,
      renderCell: renderTestStatus,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      editable: false,
      disableClickEventBubbling: true,
      renderCell: renderActionButtons,
    },
  ];

  const getList = (page = 1) => {
    // fetch page data
    fetch(`${API_ENDPOINT}brainwave?page=${page}`, {
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        const list_data = responseJson["data"]["result"];
        const list_count = responseJson["data"]["result_count"];

      setRowCount(list_count);
        // populating email and other static data

        let order_payload: object[] = [];
        for (let index = 0; index < list_data.length; index++) {
          list_data[index]["test_status"] = "Loading...";
          list_data[index]["order_status"] = "Loading...";
          list_data[index]["serial_no"] = page * 10 + index + 1;
          order_payload.push({
            email: list_data[index]["email"],
            uuid: list_data[index]["product_uuid"],
          });
        }
        setRows(list_data);

        // populating test_status data
        for (let index = 0; index < list_data.length; index++) {
          let email = list_data[index]["email"];
          // email_data.push(email);
          getTestStatus(email).then((status) => {
            let test_status = status.assessment_status;
            list_data[index]["test_status"] = test_status;
            list_data[index]["showReportButton"] = test_status === "completed";
            list_data[index]["uuid"] = status.uuid;
            list_data[index]["openButtonRedirectLink"] = list_data[index]["showReportButton"] ? `${status.thank_you_page_url}?uuid=${status.uuid}` : "";
            setRows([...list_data]);
          });
        }

        getOrderStatus(order_payload).then((res) => {
          for (let index = 0; index < list_data.length; index++) {
            list_data[index]["order_status"] = res.data[index]["paid"]
              ? "completed"
              : "incomplete";
          }
        });
      });
  };

  function getTestStatus(email: string) {
    return fetch(`${API_ENDPOINT}tucareer/status`, {
      method: "POST",
      body: JSON.stringify({ email: email }),
    })
      .then((response) => response.json())
      .then((res) => res);
  }

  function getOrderStatus(order_payload) {
    return axios
      .post(`${PAGES_URL}check-order-status`, {
        payload: order_payload,
      })
      .then((response) => response);
  }
  const { data: currentProfile } = useCurrentProfile();

  switch (currentProfile?.role) {
    case "superadmin":
    case "admin":
    case "brainwave-admin":
      return (
        <div id="quizListSection" style={{ height: 400, width: "100%" }}>
          {/* <Button
                onClick={() => {
                  console.log("create test code");
                }}
                className="btn-stride-primary"
                variant="contained"
              >
                Create Test Code
              </Button> */}
          <DataGrid
            className="quizListTable"
            paginationMode={"server"}
            columns={columns}
            rows={rows}
            rowCount={rowCount}
            onPageChange={(page) => getList(page)}
            pageSize={10}
            autoHeight={true}
            disableSelectionOnClick
          />
          {showToast && (
            <Toast
              onClose={(showToast) => setShowToast(showToast)}
              success={successToast}
            >
              {toastText}
            </Toast>
          )}
        </div>
      );
    default:
      return <ErrorFallback error="401" />;
  }
}
