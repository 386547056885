import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT } from '../utils/constants';
import { APIError, TestType } from '../utils/types';

const useTestTypes = () => {
  const fetchTestTypes = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${API_ENDPOINT}test-type`);
      return data;
    } catch (error) {
      throw error.response.error;
    }
  };

  return useQuery<TestType[], AxiosResponse<APIError>>(
    ['test_type'],
    fetchTestTypes,
    {
      retry: false,
    }
  );
};

export default useTestTypes;
