import { Quiz } from "../../../App";
import Timer from "../../components/Timer";
import React, { useEffect, useState } from "react";
import AptitudeQuizInstructions from "./AptitudeQuizInstructions";
import AptitudeQuizSampleQuestion from "./AptitudeQuizSampleQuestions";
import 'react-circular-progressbar/dist/styles.css';
import IntroMain from './Intro/IntroMain'
import IntroMainTwo from './Intro/IntroMainTwo'
import { TimerContent } from '../../components/TimerContent'
import '../../components/timercontent.css'
import InstructionQuizMain from "./Intro/InstructionQuizMain";
const instruction_img = require("../../../assets/Group 34417.png")
const vector_img = require("../../../../src/assets/Vector1.png")

export default function AptitudeQuizPage(props: any) {
  const submitNow = props.submitNow;
  const quizQuestions = props.quizQuestions;
  const sampleQuestions = props.sampleQuestions;
  const quizMeta = props.quizMeta;
  const [instructionNo, setInstructionNo] = useState(1);
  const [timeUp, setTimeUp] = useState(false);
  const questionCounterStyles = {
    textAlign: "left",
    color: "#4F4F4F",
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: "500",
    fontFamily: "Poppins",
    fontStyle: "normal",
    paddingTop: "82px",
    paddingBottom: "32px",
    margin: "0",
  }


  const changeInstructionNo = (operationToPerform: string) => {
    if (operationToPerform === "increment") {
      setInstructionNo(instructionNo + 1);
    }
    if (operationToPerform === "decrement") {
      setInstructionNo(instructionNo - 1);
    }
  }


  const changeTimeUpState = (stateToChangeInto) => {
    setTimeUp(stateToChangeInto)
  }

  return (
    <div className="aptitude-quiz-page-wrapper">
      {/* <img src={vector_img.default} className="vector_img" alt="vector" /> */}
      {props.instructionsRead && props.sampleQuestionsRead ?
        null : <div className="instruction_phone_box_img">
          <img src={instruction_img.default} alt="instruction" className="instruction" />
        </div>}

      <div
        id="aptitude-quiz-content"
        style={{
          position: "relative",
          width: "810px",
          height: "fit-content",
          maxWidth: "60%",
          background: "#fff",
          // margin: "0 auto",
          // textAlign: "center",
          boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.16)",
          borderRadius: "20px",
          paddingTop: "40px",
        }}
      >
        {props.instructionsRead && props.sampleQuestionsRead ?
          <>

            <div
              className="timer_content"


            >

              {
                props.quizDuration ?
                  <TimerContent
                    // @ts-ignore: Object is possibly 'null'.
                    onContainer="questionContainer"
                    time={props.quizDuration}
                    onCompletion={() => {
                      setTimeUp(true);
                    }}
                  /> : null
              }

            </div>


            {/* <Quiz
      questionCounterStyles={questionCounterStyles}
      quizTemplateName={"aptitude-quiz"}
      data={quizQuestions}
      timeUp={timeUp}
      quizDuration={props.quizDuration}
      submitNow={submitNow}
      showNextPrevButtons={true}
      /> */}
            {/* {
        instructionNo === 1 && <IntroMain 
        questionCounterStyles={questionCounterStyles}
        quizTemplateName={"aptitude-quiz"}
        data={quizQuestions}
        timeUp={timeUp}
        quizDuration={props.quizDuration}
        submitNow={submitNow}
        showNextPrevButtons={true}
        time={props.quizDuration}
        changeInstructionNo={changeInstructionNo}
          changeTimeUpState={changeTimeUpState}
      /> 
      }

      {instructionNo === 2 &&  <IntroMainTwo
      questionCounterStyles={questionCounterStyles}
      quizTemplateName={"aptitude-quiz"}
      data={quizQuestions}
      timeUp={timeUp}
      quizDuration={props.quizDuration}
      submitNow={submitNow}
      showNextPrevButtons={true}
      time={props.quizDuration}
      changeInstructionNo={changeInstructionNo}
      changeTimeUpState={changeTimeUpState}
      />} */}

            {
              <InstructionQuizMain
                questionCounterStyles={questionCounterStyles}
                quizTemplateName={"aptitude-quiz"}
                data={quizQuestions}
                timeUp={timeUp}
                quizDuration={props.quizDuration}
                submitNow={submitNow}
                showNextPrevButtons={true}
                time={props.quizDuration}
                changeInstructionNo={changeInstructionNo}
                changeTimeUpState={changeTimeUpState}
              />
            }

          </>
          :
          !props.instructionsRead ?
            <>
              <AptitudeQuizInstructions
                quizName={props.quizName}
                quizLabel={props.quizLabel}
                quizDescription={props.quizDescription}
                preQuizDescription={props.preQuizDescription}
                quizDuration={props.quizDuration}
                totalQuestions={
                  quizQuestions.filter(question => !['HeaderText', 'LabelText'].includes(question['element'])).length
                }
                totalSampleQuestions={sampleQuestions.length}
                setInstructionsRead={props.setInstructionsRead}
              />
            </>
            :
            !props.sampleQuestionsRead && props.instructionsRead ?
              <AptitudeQuizSampleQuestion
                questionCounterStyles={questionCounterStyles}
                sampleQuestions={sampleQuestions}
                quizMeta={quizMeta}
                setSampleQuestionsRead={props.setSampleQuestionsRead}
              />
              :
              "error"
        }
      </div>
    </div>
  )
}
