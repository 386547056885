import React, {useEffect, useState} from 'react';
import { RiDashboardLine, RiGroupLine, RiUser3Line } from 'react-icons/ri';
import { BsFileText } from 'react-icons/bs';
import { GiBrain } from 'react-icons/gi'
import {NavLink, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import { useCurrentProfile } from '../../hooks/useProfile';
import Button from './Button';
import Flex from './Flex';
import AddTest from './popups/AddTest';
import Separator from './Separator';
import AddIcon from '../../assets/add.png';
import Toast from '../components/Toast';
import ErrorFallback from '../pages/ErrorFallback';

const Sidebar = () => {
    const { data: currentProfile } = useCurrentProfile();

    const renderNavItemsBasedOnUserType = () => {
        switch (currentProfile?.role) {
            case 'superadmin': {
                // @ts-ignore
                return (
                    <NavItemsContainer column>
                        <NavItem exact to='/'>
                            <RiDashboardLine />
                            Dashboard
                        </NavItem>
                        <StyledSeparator />
                        <NavItem exact to='/admin'>
                            <RiUser3Line />
                            Admin List
                        </NavItem>
                        <NavItem to={`/admin/${currentProfile.user_id}/test`}>
                            <BsFileText />
                            My Tests
                        </NavItem>
                        <StyledSeparator />
                        <NavItem to={`/quiz`}>
                            <BsFileText />
                            Quiz Builder
                        </NavItem>
                        <StyledSeparator />
                        <NavItem to={`/brainwave`}>
                            <GiBrain />
                            Brainwave
                        </NavItem>
                        <StyledSeparator />
                        <NavItem to="/test-page"   >
                            <BsFileText />
                            Test Page
                        </NavItem>
                        <StyledSeparator />
                        <NavItem to={`/report-page`}  >
                            <BsFileText />
                            Report Page
                        </NavItem>
                        <StyledSeparator />

                    </NavItemsContainer>
                );
            }
            case 'admin':
            case 'client':
                return (
                    <NavItemsContainer column>
                        <NavItem exact to='/'>
                            <RiDashboardLine />
                            Dashboard
                        </NavItem>
                        <NavItem to={`/admin/${currentProfile.user_id}/test`}>
                            <RiGroupLine />
                            Tests
                        </NavItem>
                        <StyledSeparator />
                        <NavItem to={`/quiz`}>
                            <BsFileText />
                            Quiz Builder
                        </NavItem>
                        <StyledSeparator />
                        <NavItem to={`/brainwave`}>
                            <GiBrain />
                            Brainwave
                        </NavItem>
                        <StyledSeparator />
                        <NavItem  to={`/test-page`} >
                            <BsFileText />
                            Test Page
                        </NavItem>
                        <StyledSeparator />
                        <NavItem to={`/report-page`}   >
                            <BsFileText />
                            Report Page
                        </NavItem>
                        <StyledSeparator />
                    </NavItemsContainer>
                );
            case 'brainwave-admin':
                return (
                    <NavItemsContainer column>
                        <NavItem to={`/brainwave`} >
                            <GiBrain />
                            Brainwave
                        </NavItem>
                    </NavItemsContainer>
                );
            default:
                return (
                    <ErrorFallback error='401' />
                );
        }
    };

    return (
        <Container column>
            {renderNavItemsBasedOnUserType()}
        </Container>
    );
};

export default Sidebar;

const StyledButton = styled(Button)`
  border-radius: 2px;
  width: 70%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.blue[500]};
  box-shadow: 0px 4px 4px ${({ theme }) => theme.colors.grey[100]};
  margin-top: 2rem;
  margin-left: 3rem;

  img {
    margin-left: 2rem;
  }
`;

const Container = styled(Flex)`
  height: 100%;
  width: 22rem;
  margin-top: 6rem;
  position: fixed;
  background-color: #fff;
  box-shadow: 0 1px 1rem ${({ theme }) => theme.colors.grey[100]};
  z-index: 4;
`;

const StyledSeparator = styled(Separator)`
  margin: 0;
`;

const NavItemsContainer = styled(Flex)`
  width: 100%;
`;




const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 5rem;
  width: 100%;
  padding-left: 3rem;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: 'Poppins';
  color: ${({ theme }) => theme.colors.grey[400]};
  text-decoration: none;

  &.active {
    color: ${({ theme }) => theme.colors.blue[500]};
  }
  &:hover {
    text-decoration: none;
  }

  svg {
    font-size: 2rem;
    margin-right: 1rem;
  }
`;