import React, { FC } from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../styles/animations';
import Flex from './Flex';
import { IoMdClose } from 'react-icons/io';

const Modal: FC<{ onClose?: any; width?: string }> = ({
  onClose,
  width,
  children,
}) => {
  return (
    <Container
      align='center'
      justify='center'
      onClick={() => onClose && onClose(false)}
    >
      {children}
    </Container>
  );
};

export default Modal;

export const CloseButton: FC<{ onClick: any }> = ({ onClick }) => {
  return (
    <Close onClick={() => onClick(false)}>
      <IoMdClose />
    </Close>
  );
};

const Container = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  animation: ${fadeIn} 0.4s;
`;

const Close = styled(Flex)`
  font-size: 3rem;
  cursor: pointer;
  flex-direction: row-reverse;
`;
