import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { HiOutlineArrowRight } from "react-icons/hi";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import AcceptTasks from "../../../assets/test/accept-tasks.svg";
import RemoteTeam from "../../../assets/test/remote-team.svg";
import Report from "../../../assets/test/report.svg";
import Welcome from "../../../assets/test/welcome.svg";
import { useCurrentProfile } from "../../../hooks/useProfile";
import useTest from "../../../hooks/useTest";
import { zoom } from "../../../styles/animations";
import {
  API_ENDPOINT,
  STRIDE_TERMS_AND_CONDITION,
} from "../../../utils/constants";
import { capitalize } from "../../../utils/helperFunctions";
import {
  APIError,
  CurrentUserProfile,
  FetchResponse,
} from "../../../utils/types";
import Button from "../../components/Button";
import Flex from "../../components/Flex";
import Loading from "../../components/Loading";
import Navbar from "../../components/Navbar";
import TestLogin from "../../components/popups/TestLogin";
import TestTermsAndConditions from "../../components/popups/TestTermsAndConditions";
import ErrorFallback from "../../pages/ErrorFallback";

const StrideCohortTestPage = ({ location, testId, test, isLoading }) => {
  const history = useHistory();
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  // const [responseKey,setResponseKey] = useState<any>(null)
  const currentUrlParams = new URLSearchParams(window.location.search);
  const checkAuth =
    test?.test_configs?.check_auth == null
      ? true
      : test?.test_configs?.check_auth;

  const [showConcent, setShowConcent] = useState<boolean>(true);
  const [isAuth, setIsAuth] = useState<any>(false);
  const [userDetails, setDetails] = useState<CurrentUserProfile>({
    name: "",
    email: "",
    phone_no: "",
    response_key: "",
  });

  const { data: currentProfile, isSuccess } = useCurrentProfile(
    !isLoading && checkAuth
  );

  setTimeout(() => {
    setShowLoader(true);
  }, 100);

  useEffect(() => {
    const fname = currentUrlParams.get("fname");
    const lname = currentUrlParams.get("lname");
    const email = currentUrlParams.get("email");
    const phoneNo = currentUrlParams.get("phone_no");
    if (currentUrlParams.get("response_key")) {
      setUserDetails({
        name: "name",
        email: "email",
        phone_no: "phone_no",
        response_key: currentUrlParams.get("response_key"),
      });
      setIsAuth(true);
    }
    if (
      fname &&
      lname &&
      email &&
      phoneNo &&
      test?.auth_status !== 1 &&
      !isLoading
    ) {
      setUserDetails({
        name: fname + " " + lname,
        email: email,
        phone_no: phoneNo,
      });
    }
  }, [isLoading]);

  const setUserDetails = (value) => {
    setLoggedIn(true);
    setDetails({ ...value });
  };

  const proceedToTest = async (): Promise<APIError[] | void> => {
    try {
      const postData = {
        name: currentProfile?.name || userDetails.name,
        email: currentProfile?.email || userDetails.email,
        phone_no: currentProfile?.phone_no || userDetails.phone_no,
        form_response_key: userDetails?.response_key,
      };
      const url = new URL(API_ENDPOINT + "test/" + testId + "/proceed");
      // if (responseKey){
      // 	postData.form_response_key = responseKey
      // }
      const urlSearchParams = new URLSearchParams(history.location.search);
      url.search = urlSearchParams.toString();
      const { data: response } = await axios.post<
        FetchResponse<{ report_id: string }>
      >(url.href, postData);
      history.push({
        pathname: `/test/${testId}/${response.data.report_id}`,
        search: `${location.search}`,
        state: userDetails,
      });
    } catch (error) {
      const errors = error?.response?.data?.error as APIError[];
      return errors;
    }
  };

  const renderWelcomeMessage = () => {
    let newUrlSearchParams = new URLSearchParams(window.location.search);
    const urlName = newUrlSearchParams.get("fname");
    if (isSuccess || isLoggedIn) {
      return (
        <WelcomeContainer test={test}>
          <WelcomeContent test={test}>
            <TestDetail column>
              <WelcomeMessage column test={test}>
                Hi&nbsp;
                {capitalize(
                  urlName
                    ? urlName
                    : currentProfile
                    ? currentProfile?.name
                    : userDetails.name
                )}
                <br />
                <span>
                  Welcome to{" "}
                  {test?.test_display_label
                    ? test?.test_display_label
                    : test?.test_name}
                </span>
              </WelcomeMessage>
              <TestDescription>{test?.description}</TestDescription>
            </TestDetail>
            <TestImage justify="center">
              <img
                src={test?.banner_image || Welcome}
                alt="Complete your test"
              />
            </TestImage>
          </WelcomeContent>
        </WelcomeContainer>
      );
    }
  };

  const renderInstructions = () => {
    return (
      <InstructionContainer column test={test}>
        <h2>Instructions:</h2>
        <Instruction>
          {test?.instructions ? (
            <>
              <List>
                <br />
                <div dangerouslySetInnerHTML={{ __html: test.instructions }} />
              </List>
            </>
          ) : (
            <List>
              <ListItem>
                In the following questions, choose the option that is best
                suited to you. There are no right or wrong answers.
              </ListItem>
              <ListItem>
                Answer the questions quickly, do not over-analyse them.
              </ListItem>
              <ListItem>
                Answer the questions as{" "}
                <span className="highlight">the way you are</span> and{" "}
                <span className="highlight">
                  not the way you’d like to be seen by others
                </span>
                .
              </ListItem>
            </List>
          )}
        </Instruction>
      </InstructionContainer>
    );
  };

  const renderTestSteps = () => {
    return (
      <StepsContainer column test={test}>
        <StepDescription test={test}>
          <Steps column test={test}>
            <img src={AcceptTasks} alt="Complete your test" />
            <Count align="center" test={test}>
              1
            </Count>
            <Step test={test}>
              Complete your <span className="highlight">test</span>
            </Step>
          </Steps>
          <Steps column test={test}>
            <img src={Report} alt="Get your digital report" />
            <Count align="center" test={test}>
              2
            </Count>
            <Step test={test}>
              Get your digital <span className="highlight">report</span>
            </Step>
          </Steps>
          <Steps column test={test}>
            <img src={RemoteTeam} alt="Get mentored by industry expert" />
            <Count align="center test={test}">3</Count>
            <Step test={test}>
              Get <span className="highlight">Upskilled</span> and{" "}
              <span className="highlight">Hired</span> by industry expert
            </Step>
          </Steps>
        </StepDescription>
        <Flex style={{ position: "relative", top: "-30%" }}>
          <Line style={{ marginRight: "7.5rem" }} />
          <Line style={{ marginLeft: "7.5rem" }} />
        </Flex>
      </StepsContainer>
    );
  };

  const renderFooter = () => {
    return (
      <Footer>
        <ProceedContainer>
          <ProceedButton test={test} onClick={() => proceedToTest()}>
            Proceed To Questions <HiOutlineArrowRight />
          </ProceedButton>
        </ProceedContainer>
      </Footer>
    );
  };

  if (!isLoading === showLoader) {
    if (test && dayjs(test?.test_expiry).diff(dayjs()) > 0) {
      const customLogo = test?.logo_image;
      return (
        <React.Fragment>
          <Navbar
            homepage="https://strideahead.in"
            customLogo={customLogo}
            authenticate={checkAuth}
          />
          <Container column test={test}>
            {renderInstructions()}
            {renderWelcomeMessage()}
            {renderTestSteps()}
            {isAuth && renderFooter()}
            {!isAuth && (
              <TestLogin
                test={test}
                setIsAuth={setIsAuth}
                checkAuth={checkAuth}
                isClosed={test?.auth_status === 1}
                onSubmit={(value) => {
                  setUserDetails(value);
                  currentUrlParams.set("form", "submitted");
                  history.push(
                    window.location.pathname + "?" + currentUrlParams.toString()
                  );
                }}
                testId={testId}
                showLoginButton={checkAuth}
              />
            )}
            {isAuth && showConcent && (
              <TestTermsAndConditions
                test={test}
                onSubmit={() => {
                  setShowConcent(false);
                }}
                link={
                  test.test_configs?.terms_and_conditions_link
                    ? test.test_configs?.terms_and_conditions_link
                    : STRIDE_TERMS_AND_CONDITION
                }
              />
            )}
          </Container>
        </React.Fragment>
      );
    } else {
      return <ErrorFallback error="404" showNavbar={false} />;
    }
  } else {
    return (
      <>
        {/* @ts-ignore */}
        {<Loading />}
      </>
    );
  }
};

export default StrideCohortTestPage;

const Container = styled(Flex)`
  font-family: Poppins;
  width: 100%;
  margin-top: 6rem;
  background-color: ${(props) => props.theme.colors.blue[100]};
  height: auto;
  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    background: ${(props) => props.theme.colors.white};
  }
`;

const TestImage = styled(Flex)`
  flex: 1;
  padding: 5rem 3rem 3rem 7rem;
  width: 50%;
  img {
    width: 60rem;
    animation: ${zoom} 5s linear infinite;
    position: relative;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding: 6rem 2rem 2rem 2rem;
  }
`;

const TestDetail = styled(Flex)`
  margin: auto;
  flex: 1;
  width: 50%;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin: 0 auto 5rem;
  }
`;
const WelcomeContainer = styled(Flex)`
  background-color: ${(props) =>
    props.test?.test_configs?.theme?.bg_color || props.theme.colors.blue[500]};
  width: 110%;
  position: relative;
  left: -5%;
  top: 10%;
  transform: rotate(-1deg);
  @media only screen and (max-width: 1024px) {
    transform: rotate(-2deg);
  }
`;

const WelcomeContent = styled(Flex)`
  width: 80%;
  margin: auto;
  margin-top: 0px;
  transform: rotate(1deg);
  height: 60rem;
  color: ${(props) =>
    props.test?.test_configs?.theme?.banner_primary_color ||
    props.theme.colors.white};
  img {
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    margin-left: 0;
    width: 100%;
    padding: 0 5rem;
    text-align: center;
    height: auto;

    transform: rotate(2deg);
    img {
      width: inherit;
      margin: 1rem 0;
      height: 26rem;
    }
  }
`;

const WelcomeMessage = styled(Flex)`
  font-weight: 600;
  font-size: 4rem;
  line-height: 150%;
  margin: 1rem 0;
  span {
    color: ${(props) =>
      props.test?.test_configs?.theme?.banner_secondary_color ||
      props.theme.colors.yellow[500]};
  }

  @media only screen and (max-width: 1024px) {
    font-size: 2.6rem;
    margin: auto;
  }
`;

const TestDescription = styled(Flex)`
  font-weight: normal;
  font-size: 2.5rem;
  line-height: 120%;
  @media only screen and (max-width: 1024px) {
    margin: auto;
  }
`;
const InstructionContainer = styled(Flex)`
  width: 80%;
  background-color: ${(props) =>
    props.test?.test_configs?.theme?.bg_color || props.theme.colors.blue[500]};
  margin: 40px auto 12rem;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: ${(props) =>
    props.test?.test_configs?.theme?.primary_color || props.theme.colors.white};
  padding: 5rem 6rem;
  h2 {
    font-weight: 500;
    font-size: 3.4rem;
    color: ${(props) =>
      props.test?.test_configs?.theme?.primary_color ||
      props.theme.colors.white};
  }
  @media only screen and (max-width: 1024px) {
    padding: 3rem;
    h2 {
      font-weight: 500;
      font-size: 3rem;
    }
  }
`;

const Instruction = styled(Flex)``;

const List = styled.ul`
  display: block;
  margin: 0 1.5rem;
  font-size: 2.3rem;
  line-height: 130%;
  padding-left: 2rem;

  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    font-size: 2rem;
    margin: 0 1rem;
  }
`;

const ListItem = styled.li`
  padding: 1.5rem;
  .highlight {
    font-weight: bold;
  }
  @media only screen and (max-width: 1024px) {
    padding: 1.5rem;
    h2 {
      font-weight: 500;
      font-size: 3rem;
    }
  }
`;

const StepsContainer = styled(Flex)`
  width: 90%;
  margin: 20rem auto;
  max-width: 1250px;
  color: ${(props) =>
    props.test?.test_configs?.theme?.primary_color ||
    props.theme.colors.blue[500]};
  padding: 0 4rem;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 0;
    margin-bottom: 8rem;
  }
`;

const StepDescription = styled(Flex)`
  font-weight: 600;
  font-size: 3rem;
  line-height: 4rem;
  width: 100%;
  margin-top: 2.3rem;
  div {
    justify-content: center;
    text-align: center;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Steps = styled(Flex)`
  width: 33%;
  text-align: center;
  margin: auto;
  z-index: 1;

  img {
    margin: auto;
    width: 100%;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin: 1rem 0;
    img {
      width: 30rem;
    }
  }
`;

const Step = styled.div<{ test?: any }>`
  margin: 1rem 5rem;
  height: 10rem;
  color: ${(props) =>
    props.test?.test_configs?.theme?.primary_color ||
    props.theme.colors.blue[500]};
  .highlight {
    color: ${(props) =>
      props.test?.test_configs?.theme?.secondary_color ||
      props.theme.colors.yellow[500]};
  }
  @media only screen and (max-width: 1024px) {
    height: auto;
  }
`;
const Count = styled(Flex)`
  background-color: ${(props) =>
    props.test?.test_configs?.theme?.bg_color || props.theme.colors.blue[500]};
  border-radius: 50%;
  color: ${(props) =>
    props.test?.test_configs?.theme?.primary_color || props.theme.colors.white};
  width: 4rem;
  height: 4rem;
  line-height: 3rem;
  font-size: 2rem;
  font-weight: normal;
  margin: 4rem auto 2rem;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    margin: 2rem auto 0rem;
  }
`;

const Footer = styled(Flex)`
  justify-content: end;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 6.8rem;
  z-index: 10;
  background: ${(props) => props.theme.colors.white};
`;

const ProceedContainer = styled(Flex)`
  width: auto
  float: right;
  flex: 1;
  flex-direction: row-reverse;
  @media only screen and (max-width: 1024px) {
    justify-content: center;
  }
  
`;

const ProceedButton = styled(Button)`
  font-family: Poppins;
  background-color: ${(props) =>
    props.test?.test_configs?.theme?.bg_color || props.theme.colors.blue[500]};
  width: auto;
  color: ${(props) =>
    props.test?.test_configs?.theme?.primary_color || props.theme.colors.white};
  margin auto 4rem;
  padding: 1rem 2rem;
  border-radius: 4px;
  height: auto;
  svg{
    vertical-align: bottom;
    width: 25px;
    font-weight: bold;
    margin-left: 10px;
  }
  @media only screen and (max-width: 1024px) {
    margin auto 0;
  }
`;

const Line = styled(Flex)`
  border: 1px dashed #4281d5;
  height: 0px;
  width: 20%;
  margin: auto;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;
