import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useAnalysisReport } from '../../../hooks/useAnalysisReport';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useWindowDimensions } from './utils';
import moment from 'moment';
import StrideLogo from '../../../assets/images/HeaderLogo.svg'
import BannerImg from '../../../assets/images/Bannerimg.png'
import Aptitude from '../../../assets/images/Aptitude.svg'
import Aptitude2 from '../../../assets/images/Aptitude2.svg'

import './RiasecTestReport.css'
import styled from "styled-components";
import { TraitChart } from "./raisecReportQuizExtended/TraitChart";
import { getTraitColor, getTraitName } from "./raisecQuizReportUtils";
import * as am4core from "@amcharts/amcharts4/core";
import { REPORT_PAGE_URL } from '../../../utils/constants'


export default function GrowthCircleRiasecTestReport({ reportData2, test, testId, isFetched }: any) {
    // console.log('reportData2', reportData2)
    // console.log('test 22', test)

    const { token } = useParams<{ token: string }>();
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const [selected, setSelected] = useState(0);
    const [auth, setAuth] = useState<boolean>(false);

    const [flow, setFlow] = useState<any>();
    const query = useQuery();

    useEffect(() => {
        setFlow(query?.get('flow'));
    }, []);

    const { width } = useWindowDimensions();
    // Todo : Toggle function for the Accordion Component
    const toogle = (index: any) => {
        setSelected(index)
    }

    useEffect(() => {
        if (!reportData2) {
            setAuth(true);
        }
    }, [])

    const { data, data: reportMeta } = useAnalysisReport(token, false, 5, auth);


    // @ts-ignore
    // console.log(data?.report_date, "h")
    // @ts-ignore
    const reportDate = moment(test?.report_date).format('Do MMMM YYYY')
    // console.log('reportDate', reportDate)
    const reportData = reportData2 ? reportData2 : reportMeta?.report_metadata;
    // console.log({ reportData }, "line 51")
    console.log(test.logo_image, "line 51")
    let res = [] as any;
    for (let i in reportData?.percentage) {
        if (reportData?.percentage[i] > 0) {
            let temp = {};
            temp["trait"] = getTraitName(i);
            // @ts-ignore
            temp["score"] = Math.round(+(reportData?.percentage[i] / (Object?.values(reportData?.percentage)?.reduce((total: any, value: any) => total += value, 0))) * 100);
            temp["color"] = am4core.color(getTraitColor(i));
            res.push(temp);
        }
    }

    // console.log({ res }, "res")

    // Todo : Function Returning the circular progressBar
    const cirleProgress = (percentage) => {

        let setColor = "#000"
        if (percentage > 76) {
            setColor = "#24A84A";
            setColor = "#24A84A";
        } else if (percentage > 35 && percentage <= 75) {
            setColor = "#FCAE17";
        } else {
            setColor = "#EE3323"
        }
        return (
            <div className='circle-container'>
                <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                        rotation: 0.85,
                        strokeLinecap: 'round',
                        textSize: '22px',
                        pathTransitionDuration: 0.8,
                        pathColor: `${setColor}`,
                        textColor: '#192A46',
                        trailColor: '#d6d6d6',
                    })}
                />
            </div>
        )
    }

    return (
        <div className=" container MainContainer1">
            <div className='main-container '>
                {reportData2 ? null :
                    <header className='strideLogo'>
                        <img src={`https://d1dhn4cz1wmlqo.cloudfront.net/${test.logo_image}`} alt="StrideLogo" />
                    </header>
                }
                <div className="assessment_details">
                    <div className="left-container">
                        {/* <h2>{test?.test_display_label}</h2> */}
                        <h2>{test?.test_name}</h2>
                        <h3>{test?.name ? test?.name : data?.name}</h3>
                        <hr id='line' />
                        <div className="assessment_details-extend">
                            <h4 className='ade-text'>Test ID : <span className='datas'>{testId}</span></h4>
                            {reportDate === null || reportDate === undefined || reportDate === ""
                                ? ""
                                :
                                <h4 className='ade-text'>Test Date : <span
                                    className='datas'>{reportDate}</span></h4>
                                // <h4 className='ade-text'>Test Date : <span
                                //     className='datas'>{test?.created_at?.slice(0, 10) ?? "--"}</span></h4>
                            }

                            {test.test_time === null || test.test_time === undefined || test.test_time === "" ?
                                "" :
                                <h4 className='ade-text'>Allotted Time : <span
                                    className='datas'> {test?.allotted_time ?? "--"}</span></h4>
                            }

                            {test.time_taken === null || test.time_taken === undefined || test.time_taken === "" ?
                                "" :
                                <h4 className='ade-text'>Time taken to Complete : <span
                                    className='datas'> {reportData?.time_taken_to_complete ?? "--"}</span></h4>
                            }
                        </div>
                    </div>
                    <div className="right-container">
                        {/* Your score is : {reportData?.percentage} */}

                        <TraitChart reportData={reportData} width={width} flow={flow} />

                    </div>
                </div>
                <div className="section8">
                    <h2>Your Summary Code</h2>
                    <p className='definitionSmall2'>{reportData?.type_code}</p>

                    <div className='accordion'>
                        {
                            reportData?.traits_detail?.map((item, index) => (
                                <div className='item' key={index} onClick={() => toogle(index)}>
                                    <div
                                        className={selected !== index ? 'accordion-closed' :
                                            'accordion-hide'}>
                                        <div className="inlineBlock">
                                            <div className="left-part">
                                                <div className="icon_image">
                                                    <img src={item?.logo} alt='iconImage' />
                                                </div>
                                                <p id='accordion-title'>{item?.title}</p>
                                            </div>
                                            <div className="right-part2">
                                                <h3 id='accordion-score'>Your score is : <span
                                                    id='accordion-percentage'>{res[index]?.score}%</span></h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={
                                        selected === index ? 'accordion-show ' :
                                            'accordion-hide'}>
                                        <div className="inlineBlock2">
                                            <div className="left-part">
                                                <div className="icon_image">
                                                    <img src={item?.logo} alt='iconImage' />
                                                </div>
                                                <div className='left-inside'>
                                                    <p id='accordion-title'>{item?.title}</p>
                                                </div>
                                            </div>
                                            <div className="right-part">
                                                <div className='circle_accordion'>
                                                    {cirleProgress(res[index]?.score)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-content">
                                            <AccordionDescription
                                                id='accordion-p'>{item?.description}</AccordionDescription>
                                            <SomeoneWho>Someone who</SomeoneWho>
                                            <SomeoneWhoUl>
                                                {item?.someone_who?.map((items: any, index: any) => (
                                                    <li
                                                        key={index}
                                                    >{items}</li>
                                                ))}
                                            </SomeoneWhoUl>
                                        </div>
                                        <RowDiv className="row ">
                                            <div className="col-lg-6 col-sm-12 col-md-12">
                                                <Avoid>
                                                    <AvoidTitle>Avoid</AvoidTitle>
                                                    <ul>
                                                        {item?.avoid?.map((av: any, index: any) => (
                                                            <li key={index}>{av}</li>
                                                        ))}
                                                    </ul>
                                                </Avoid>
                                            </div>
                                            <div className="col-lg-6 col-sm-12 col-md-12">
                                                <Values>
                                                    <AvoidTitle>Values</AvoidTitle>
                                                    <ul>
                                                        {item?.values?.map((av: any, index: any) => (
                                                            <li key={index}>{av}</li>
                                                        ))}
                                                    </ul>
                                                </Values>
                                            </div>
                                        </RowDiv>
                                        {/* {
                                            reportData2 ? null : */}
                                                <ClusterDiv style={{ display: flow ? "none" : "block" }}>
                                                    <SomeoneWho>Interested career cluster</SomeoneWho>
                                                    <RowDiv className="row">
                                                        {
                                                            item?.interest_areas_clusters?.map((cluster) => (
                                                                <div className="col-2 flex-grow-1" key={Math.random()}>
                                                                    <ClusterIcon
                                                                        className="justify-content-center align-items-center">
                                                                        <img src={cluster?.icon} alt='iconImage' />
                                                                    </ClusterIcon>
                                                                    <Desc>
                                                                        {cluster?.description}
                                                                    </Desc>
                                                                </div>
                                                            ))
                                                        }
                                                    </RowDiv>
                                                </ClusterDiv>
                                        {/* } */}
                                    </div>
                                </div>
                            )
                            )
                        }
                    </div>

                </div>

                {reportData2 ? null : <hr id='line2' />}

                {reportData2 ? null :
                    <div className="section7">
                        {/*<h2>Tips To Improve</h2>*/}
                        {/*<p id='improveList'>These are the areas where you need significant improvement.</p>*/}
                        {/*<ul id='improvement'>*/}
                        {/*    {reportData?.tips_to_improve?.map((item: any, index: any) => (*/}
                        {/*        <li*/}
                        {/*            key={index}*/}
                        {/*        >{item}</li>*/}
                        {/*    ))}*/}
                        {/*</ul>*/}

                        <div className="bannerImage">

                            <div className="bannerLeft">
                                <h2 id='bannerTxt'>Don’t delay and take the next step now!</h2>
                                <p className='definition3' id='bannerPara'>Book a session with our expert mentors today
                                    and
                                    get a systematic career pathway tailored for you to stride ahead!</p>
                                <a href={REPORT_PAGE_URL}>
                                    <button id='bannerBtn'>Get started right away!</button>
                                </a>
                            </div>
                            <div className="bannerRight">
                                <img src={BannerImg} alt="bannerImg" className='bannerImg' />
                            </div>


                        </div>

                        <h3>Disclaimer</h3>
                        <p className='definition3'>Psychometric tests are based on highly standardized methodology and
                            scientific rigor, which makes them extremely generalizable, however, in no way does it
                            provide
                            an exhaustive persona around a person nor do we claim absolute reliability, the test has its
                            limitations to define a broad concept like personality.</p>
                        <p className='definition3' id="lastPara">The results thus obtained are for an overall
                            understanding
                            of the self to facilitate better decision-making. For increasing the validity and further
                            consideration of dimensional factors, a mentor consultation for interpreting the assessment
                            and
                            specifying career pathways is recommended.</p>

                    </div>}
                <hr id='line2' />

                {/* {reportData2 ? null :
                    <div className="copyright">
                        <div className='left_portion'>
                            <span>© copyright 2022 | Stride Ahead. All rights reserved.</span>
                        </div>
                        <div className='right_portion'>
                            info@strideshead.in
                        </div>
                    </div>
                } */}
            </div>
        </div>
    );
}

const AccordionDescription = styled.p`
   font-weight: 400 !important;
   font-size: 18px !important;
   line-height: 34px !important;
   color: #192A46;
   margin-top:10px;
   
   @media(max-width:768px){
   font-size:12px !important;
   line-height: 24px !important;
   }
 
`;

const SomeoneWho = styled.h5`
   margin-left:0px;
   font-weight: 600;
   font-size: 22px !important;
   line-height: 33px;
   color: #192A46;
   
   @media(max-width:768px){
   font-size:14px !important;
   }
   @media(min-width:769px) and (max-width: 992px){
      margin-left:20px !important;
   }
   
`;
const SomeoneWhoUl = styled.ul`
   font-weight: 400;
   font-size: 18px !important;
   line-height: 30px; 
   color: #192A46 !important;
    
    @media(max-width:768px){
     padding-left:15px;
     font-size: 12px !important;
     line-height: 24px; 
  }
   @media(min-width:769px) and (max-width: 992px){
      margin-left:20px !important;
   }

`;

const Avoid = styled.div`
  width: 100%;
  height:250px;
  padding:20px;
  background: transparent;
  border-radius: 15px;
  
  ul{
   padding:0px
   font-weight: 400;
   font-size: 18px;
   line-height: 30px;
   color: #192A46;
    @media(max-width:768px){
     padding-left:15px;
  }
  }
  @media(max-width:768px){
   padding:15px;
   height:auto;
  }
`;
const Values = styled.div`
  width: 100%;
  height:250px;
  padding:20px;
  background: transparent;
  border-radius: 15px;
  
  ul{
   font-weight: 400;
   font-size: 18px;
   line-height: 30px;
   color: #192A46;
   padding:0px;
   @media(max-width:768px){
     padding-left:15px;
  }
  }
  
  @media(max-width:768px){
   height:auto;
   padding:15px;
   margin-top:10px !important;
  }
  
   @media(min-width:769px) and (max-width: 992px){
      margin-top:10px !important;
   }
`;

const AvoidTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #1A64D6;
  margin-bottom:10px;
  @media(max-width:768px){
   margin-bottom:8px;
   font-size: 16px;
   line-height: 24px;
  }
`;

const RowDiv = styled.div`
   padding:0px 30px 30px;
    @media(max-width:768px){
     padding:0px 20px 20px; 
  }
`;

const ClusterDiv = styled.div` 
    padding:0px 30px 10px;
    @media(max-width:768px){
     padding:0px 20px 7px;
  }
`;

const ClusterIcon = styled.div`
    width:100%;
    display:flex;
    margin-top:20px;
    margin-bottom:10px;
    img{
    height:60px;
    
    @media(max-width:768px){
     height:30px;
    }
  }
    `;

const Desc = styled.p`
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    text-align: center;
    color: #192A46;
    @media(max-width:768px){
    font-size: 10px !important;
    line-height: 16px !important;
    }
`;
