// @ts-nocheck
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import styled from 'styled-components';
import Flex from '../components/Flex';
import TestAuth from './TestAuth';
import TestScreen from './TestScreen';
import TestFeedback from './TestFeedback';
import AnalysisReport from './AnalysisReport';

const TestApp = () => {
  const renderAppComponent = () => {
    return (
      <Container>
        <Content>
          <Switch>
            <Route path='/test/:testId' exact component={TestAuth} />;
            <Route
              path='/test/:testId/:reportId'
              exact
              component={TestScreen}
            />
            <Route
              path='/feedback/:testId/:reportId'
              exact
              component={TestFeedback}
            />
            <Route
              path='/analysis/:testId/:token'
              exact
              component={AnalysisReport}
            />
            <Route path='*'>
              <Redirect to='/404' />
            </Route>
          </Switch>
        </Content>
      </Container>
    );
  };

  return <>{renderAppComponent()}</>;
};

export default TestApp;

const Container = styled(Flex)`
  min-height: 100vh;
`;

const Content = styled(Flex)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.grey[50]};
`;
