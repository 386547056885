import styled from 'styled-components';
import Flex from './Flex';

const Header = styled(Flex)`
  width: 100%;
  padding: 0;
  margin: 1.5rem 0rem;
`;

export default Header;
