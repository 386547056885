import {  useEffect, useMemo } from "react";
import React, { useState } from 'react'
import ReactQuill, { Quill } from "react-quill";
import { PROCESSOR_URL, S3_URL } from "../../../utils/constants";
import RandomQuizMetaForm from "./quiz_meta_forms/RandomQuizReport";
import RiasecQuizMetaForm from "./quiz_meta_forms/RiasecQuizMetaForm";
import UniversalQuizMetaForm from "./quiz_meta_forms/UniversalQuizMetaForm";
import DimensionsQuizMetaForm from "./quiz_meta_forms/DimensionsQuizMetaForm";
import EmployabilityQuizMetaForm from "./quiz_meta_forms/EmployabilityQuizMetaForm";

const QuizMetaForm = (props: any) => {
    let addFormFields = () => {
        props.onChange([...props.quizMeta, { }])
      }
    
    let removeFormFields = (i: any) => {
        let newFormValues = [...props.quizMeta];
        newFormValues.splice(i, 1);
        props.onChange(newFormValues)
    }
    
    const uploadFiles = (uploadFileObj: any, index: number) => {
      const formdata = new FormData()
      formdata.append("file", uploadFileObj)
      fetch(`${PROCESSOR_URL}quiz/upload`, {
        method: "POST",
        body: formdata
      })
      .then(res=>res.json())
      .then(data=>{
        if(data['data']['key']){
          const res = S3_URL + data['data']['key']
          let newFormValues: Array<any> = [...props.quizMeta];
          // @ts-ignore: Object is possibly 'null'.
          newFormValues[index] = {...newFormValues[index], image: data['data']['key']};
          props.onChange(newFormValues);
          alert("Image uploaded successfully")
        }else{
          return  Promise.reject()
        }
      })
      .catch(err=>{
        console.log(err)
        alert("Failed to upload image. Please try again.")
      })
    }  
    
    const imageHandler = (e: any, index: number) => {
      const image = e.target.files[0]
      uploadFiles(image, index)
    };
    const formToRender = ()=>{
      switch(props.quizTypeName){
        case "riasec_quiz":
          return <RiasecQuizMetaForm {...props}/>
        case "dimensions_quiz":
          return (
            <DimensionsQuizMetaForm
            questions={props.questions}
            {...props}
             />
          )
        case "employability_quiz":
            return <EmployabilityQuizMetaForm
              {...props}
              imageHandler = {imageHandler}
              removeFormFields={removeFormFields}
              addFormFields={addFormFields}
            />
        case "universal_quiz":
          return <UniversalQuizMetaForm {...props} />
        default:
          return <RandomQuizMetaForm 
              {...props}
              imageHandler = {imageHandler}
              removeFormFields={removeFormFields}
              addFormFields={addFormFields}
          />
      }
    }
    return formToRender()
}

export default QuizMetaForm

  