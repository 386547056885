// // ? Report Response
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router';
import useTest from '../../hooks/useTest';
import DefaultReportResponse from './reportResponses/DefaultReportResponse';
import AptitudeReportResponse from './reportResponses/AptitudeReportResponse';

const ReportResponse = () => {

  const {testId} = useParams<{ testId: string }>(); 
  const {isLoading, data: test} = useTest(testId);
    const [component, setComponent] = useState<any>();
    useEffect(() => {
        switch (test?.test_page?.name ){
            case "Stride PCA Default" :
              default:
                setComponent(<DefaultReportResponse/>);
                break;
               
            case "Default Aptitude Test Page" :
                setComponent(<AptitudeReportResponse />);
                break;
            
        }
    }, [test]);

    return (

        <React.Fragment>
            {component}
        </React.Fragment>
    );
};
export default ReportResponse;
