// @ts-nocheck
import React, {FC, useEffect} from 'react';
import GoogleLogin, {
    GoogleLoginResponse,
    GoogleLoginResponseOffline,
} from 'react-google-login';
import styled from 'styled-components';
import GoogleIcon from '../../assets/svg/google.svg';
import {GOOGLE_CLIENT_ID} from '../../utils/constants';
import Button from './Button';
import Flex from './Flex';
import {Text2Css} from './Typography';

interface IProps {
    text: string;
    onSuccess: (
        reponse: GoogleLoginResponse | GoogleLoginResponseOffline
    ) => void;
    onFailure: (error: any) => void;
}

const GoogleAuth: FC<IProps> = ({text, onSuccess, onFailure}) => {
    return (
        <Container column>
            <OrContainer align="center">
                <span className="line"></span>
                <span style={{margin: '0 2rem'}}>Or</span>
                <span className="line"></span>
            </OrContainer>
            <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                buttonText={"Sign in With Google"}
                onSuccess={onSuccess}
                onFailure={onFailure}
                render={(renderProps) => (
                    <GoogleAuthButton
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                    >
                        <img src={GoogleIcon} alt="Google Icon" />
                        {text}
                    </GoogleAuthButton>
                )}
            />
        </Container>
    );
};

export default GoogleAuth;

const Container = styled(Flex)`
  margin-top: 3rem; 
`;

const OrContainer = styled(Flex)`
  ${Text2Css}
  width: 100%;
  color: ${(props) => props.theme.colors.grey[200]};

  .line {
    display: block;
    height: 2px;
    flex-grow: 1;
    background-color: ${(props) => props.theme.colors.grey[200]};
  }
`;

const GoogleAuthButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 0.2rem 0.8rem ${(props) => props.theme.colors.grey[200]};
  margin-top: 2rem;
  color: ${(props) => props.theme.colors.grey[500]};
  img {
    margin-right: 2rem;
  }
`;
