import React, { useState, useEffect } from 'react';
import MoneyIcon from '../../../assets/images/money.svg';
import TrueIcon from '../../../assets/images/true.svg';
import ModalContainer from '../../components/DetailModal/ModalContainer';
import PromocodeModal from '../../components/PromocodeModal/PromocodeModal';
import Modal from '../../components/Modal/Modal';
// import OfferModal from "../../components/OfferModal/OfferModal"
import {
	LOW_PROMOCODE,
	LOW_MEDIUM_PROMOCODE,
	HIGH_PROMOCODE,
	HIGH_MEDIUM_PROMOCODE,
	BOOKING_LINK,
} from '../../../utils/constants';

export default function PaymentSection(props: any) {
	const urlSearchParams = new URLSearchParams(window.location.search);
	const urlParams = Object.fromEntries(urlSearchParams.entries());
	const orderId = urlParams?.orderId;

	const { ApptitudeData, discount, setOpen, product } = props;
	const [showModalNo, setShowModalNo] = useState<any>(0);
	const [promoCodes, setPromoCodes] = useState<any>(product?.promo_codes);
	const [appliedPromoCode, setAppliedPromoCode] = useState<any>('');
	
	useEffect(() => {
		if (orderId){
			if (ApptitudeData?.report_metadata?.score < 30) {
				setAppliedPromoCode(LOW_PROMOCODE);
			} else if (ApptitudeData?.report_metadata?.score < 50) {
				setAppliedPromoCode(LOW_MEDIUM_PROMOCODE);
			} else if (ApptitudeData?.report_metadata?.score < 80) {
				setAppliedPromoCode(HIGH_MEDIUM_PROMOCODE);
			} else {
				setAppliedPromoCode(HIGH_PROMOCODE);
			}

			if (typeof product !== 'undefined') {
				setShowModalNo(1);
			} else {
				alert('Something Failed on Server');
			}
		}
	}, [])

	return (
		<div className='lorem-text-style-section' id='payment'>
			<div className='container-md'>
				<div className='page-title'>
					<h1>
						Get a better pay and a better job with the help of business masters
						by making sure you give just 5% of Degree course fee and 15% of time
					</h1>
				</div>
				<div className='lorem-grid'>
					<div className='lorem-grid-items'>
						<div className='lorem-header'>
							<div>
								<img src={MoneyIcon} alt='MoneyIcon' />
							</div>
							<div>
								<span>
									Scholarship discount upto 40% on the basis of Assessment
								</span>
							</div>
						</div>

						<div className='sec-text-style'>
							<p>
								Total Program Fees: INR <span>{product?.actual_price} /-</span>
							</p>
							<p>
								Organisation Funding Benefit: INR{' '}
								{Number(product?.actual_price) -
									Number(product?.discounted_price)}{' '}
							</p>
							<p>
								Cohort Price: INR <span>{product?.discounted_price}</span>(
								<span>
									{(
										((Number(product?.actual_price) -
											Number(product?.discounted_price)) /
											Number(product?.actual_price)) *
										100
									).toFixed(0)}
									% Discounted
								</span>
								)
							</p>
							<p>Your Result show if you are eligible to enrol.</p>
						</div>
						<div className='child-box'>
							{/* <h4>Final Program Fee after scholarship: INR {discount}</h4> */}
							<div className='all-true-icon-text-alignment'>
								<div className='icon-text-grid'>
									<div className='icon-text-grid-items'>
										<img src={TrueIcon} alt='TrueIcon' />
									</div>
									<div className='icon-text-grid-items'>
										<p>Get certified by ISB, XLRI & DTU Alumni</p>
									</div>
								</div>
								<div className='icon-text-grid'>
									<div className='icon-text-grid-items'>
										<img src={TrueIcon} alt='TrueIcon' />
									</div>
									<div className='icon-text-grid-items'>
										<p>Get complete Dashboard to track record progress</p>
									</div>
								</div>
								<div className='icon-text-grid'>
									<div className='icon-text-grid-items'>
										<img src={TrueIcon} alt='TrueIcon' />
									</div>
									<div className='icon-text-grid-items'>
										<p>
											Get access to Live sessions recordings for future
											learnings
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='lorem-grid-items'>
						<div className='lorem-header'>
							<div>
								<img src={MoneyIcon} alt='MoneyIcon' />
							</div>
							<div>
								<span>Additional Discount and EMI options</span>
							</div>
						</div>
						<h4>Affordable fee payment</h4>
						<div className='sec-text-style'>
							<p>Get Cashback for upfront fee payment</p>
							<p>Cashback upto Rs 5000</p>
						</div>
						<div className='child-box'>
							<h6>Get Instalments options</h6>
							<div className='all-true-icon-text-alignment'>
								<div className='icon-text-grid'>
									<div className='icon-text-grid-items'>
										<img src={TrueIcon} alt='TrueIcon' />
									</div>
									<div className='icon-text-grid-items'>
										<p>Choose EMI options in payment page</p>
									</div>
								</div>
								<div className='icon-text-grid'>
									<div className='icon-text-grid-items'>
										<img src={TrueIcon} alt='TrueIcon' />
									</div>
									<div className='icon-text-grid-items'>
										<p>Select your Debit/credit card</p>
									</div>
								</div>
								<div className='icon-text-grid'>
									<div className='icon-text-grid-items'>
										<img src={TrueIcon} alt='TrueIcon' />
									</div>
									<div className='icon-text-grid-items'>
										<p>Avail EMI as per your affordability</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='pay-now-button'>
					<div>
						<button
							style={{ marginLeft: 'auto' }}
							onClick={() => {
								if (ApptitudeData?.report_metadata?.score < 30) {
									setAppliedPromoCode(LOW_PROMOCODE);
								} else if (ApptitudeData?.report_metadata?.score < 50) {
									setAppliedPromoCode(LOW_MEDIUM_PROMOCODE);
								} else if (ApptitudeData?.report_metadata?.score < 80) {
									setAppliedPromoCode(HIGH_MEDIUM_PROMOCODE);
								} else {
									setAppliedPromoCode(HIGH_PROMOCODE);
								}

								if (typeof product !== 'undefined') {
									setShowModalNo(1);
								} else {
									alert('Something Failed on Server');
								}
							}}
						>
							Pay Now
						</button>
					</div>
					<div>
						<button onClick={() => window.open(BOOKING_LINK, '_blank')}>
							Book your Interview Call Now
						</button>
					</div>
				</div>
			</div>
			;
			{showModalNo > 0 && (
				<ModalContainer setOpen={setShowModalNo}>
					<>
						{showModalNo === 1 && (
							<PromocodeModal
								setOpen={setShowModalNo}
								setPromoCodes={setPromoCodes}
								promoCodes={promoCodes}
								product={product}
								aPromoCode={appliedPromoCode}
								userData={ApptitudeData}
								orderIdInUrl={orderId}
							/>
						)}
					</>
					<>{showModalNo === 4 && <Modal setOpen={setShowModalNo} />}</>
				</ModalContainer>
			)}
		</div>
	);
}
