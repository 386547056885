import { Button } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, {FC, useEffect, useState} from 'react';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import Flex from '../components/Flex';
import Sidebar from '../components/Sidebar';
import TestPageModal from "./popups/TestPageModal";
import ErrorFallback from '../pages/ErrorFallback';
import { useCurrentProfile } from '../../hooks/useProfile';
import { API_ENDPOINT } from '../../utils/constants';
import {
    Pagination,
    Table,
    TableContainer,
    TBody,
    TD,
    TH,
    THead,
    TR,
} from './Table';
import Toast from "./Toast";

 const TestPage = ()=> {
    const [rows, setRows] = useState<any>([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [testPageData, setTestPageData] = useState<any>(null)
    const [page, setPage] = useState<number>(1);
    const [pagination, setPagination] = useState<any>({});
    const [toastText,setToastText] = useState<string>("");
    const [showToast, setShowToast] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const setToast = (success: boolean) => {
         setSuccess(success);
         setShowToast(true);
     };
    const editTestPage = (id: any) => {
        fetch(`${API_ENDPOINT}test-pages/${id}`, {
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                setTestPageData(data)
            })
            .catch(err => {
                alert("Failed to get test page data. Please try again.")
            })
    };

    const deleteTestPage = (id: any) => {
        if (window.confirm("Are you sure you want to delete this?")) {
            fetch(`${API_ENDPOINT}test-pages/${id}`, {
                method: "DELETE",
                credentials: 'include'
            })
                .then(res => {
                    if (res.status == 200 || res.status == 204) {
                        setSuccess(true);
                        setShowToast(true);
                        setToastText("Deleted successfully") ;
                        setDataFetched(false);
                    } else {
                        return Promise.reject(res.status)
                    }
                })
                .catch(err => {
                    console.log(err)
                    setSuccess(false);
                    setShowToast(true);
                    setToastText("Failed to delete the test page. Please try again.") ;
                })
        }
    }

     const getList = () => {
        fetch(`${API_ENDPOINT}test-pages`, {
            credentials: 'include'
        })  .then(res => res.json())
            .then(data => {
                const newdata = data.data
                setRows(newdata['result']);
                setPagination(newdata);
                setDataFetched(true)
            })
            .catch(err => {
                alert("Failed to get test page list. Please try again.")
            })
    }

    useEffect(() => {
        getList();
    }, [])

    useEffect(() => {
        if (!dataFetched) {
            getList();
        }
    }, [dataFetched]);

    const {data: currentProfile} = useCurrentProfile();

    const renderUserList = () => {
        return rows?.map((user) => (
            <TR >
                <TD maxWidth='5%' centered>
                    {user.id}
                </TD>
                <TD maxWidth='15%' centered>
                    {user.name}
                </TD>
                <TD maxWidth='15%' centered>
                    {user.label}
                </TD>
                <TD maxWidth='10%' centered>
                    {user.test_type_code}
                </TD>
                <TD maxWidth='10%' centered>
                    <strong>
                        <Button
                            className="btn-stride-primary"
                            id={user.id}
                            variant="contained"
                            size="small"
                            style={{marginLeft: 16}}
                            onClick={() => {
                                editTestPage(user.id)
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            className="btn-stride-primary"
                            id={user.id}
                            size="small"
                            variant="contained"
                            style={{marginLeft: 16}}
                            onClick={() => {
                                deleteTestPage(user.id)
                            }}
                        >
                            Delete
                        </Button>
                    </strong>
                </TD>
            </TR>
        ));

    };

    switch (currentProfile?.role) {
        case 'superadmin':
        case 'admin':
        case 'client':
            return (
                <Container>
                    <Navbar authenticate={true}/>
                    <Sidebar/>
                    <Content>
                        <div id="quizListSection" style={{ height: 400, width: '100%' }}>
                            <Button
                                onClick={()=>setTestPageData({})}
                                className="btn-stride-primary"
                                variant="contained"
                                size="small"
                                style={{ marginLeft: 0 , padding: 20, fontSize: 12 }}
                            >
                                Add Test Page
                            </Button>
                    <TableContainer style={{margin: '0rem'}}>
                        <Table style={{flexGrow: 1, backgroundColor: '#fff'}}>
                            <THead>
                                <TR>
                                    <TH centered>Test Page ID</TH>
                                    <TH centered>Test Page Name</TH>
                                    <TH centered>Test Page Label</TH>
                                    <TH centered>Test Type</TH>
                                    <TH centered>Actions</TH>
                                </TR>
                            </THead>
                            <TBody>{renderUserList()}</TBody>
                        </Table>
                    </TableContainer>
                    {
                        pagination?.pagination_data && (
                            <Pagination
                                currentPage={page}
                                prevPage={pagination?.pagination_data.page_prev}
                                nextPage={pagination?.pagination_data.page_next}
                                nextPageChangeHandler={
                                    pagination?.pagination_data.page_next
                                        ? function () {
                                            setPage((currentPage) => currentPage + 1);
                                        }
                                        : undefined
                                }
                                prevPageChangeHandler={
                                    pagination?.pagination_data.page_prev
                                        ? function () {
                                            setPage((currentPage) => currentPage - 1);
                                        }
                                        : undefined
                                }
                            />
                        )
                    }
                        </div>
                    </Content>
                    {
                        !!testPageData && <TestPageModal setToastText={setToastText} getList={getList} testPageData={testPageData} onSuccess={(success)=>setToast(success)} onClose={()=>setTestPageData(null)}  />
                    }

                    {
                        showToast && <Toast    onClose={(showToast) => setShowToast(showToast)}
                                               success={success}>
                            {toastText}
                        </Toast>
                    }
                </Container>
          );
        default:
            return <ErrorFallback error='401'/>
    }
}
export default TestPage;

const Content = styled(Flex)`
  width: 100%;
  margin-top: 6rem;
  margin-left: 22rem;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.grey[50]};
`;

const Container = styled(Flex)`
  min-height: 100vh;

  
`;
