import React from "react";
import "./oneworkpart.css";

function OneWorkPart(props) {
  return (
    <div className="one_work_container">
      <div className="tell_img_left_container">
        <img src={props.imgName} alt="tell_img" className="tell_img_self" />
      </div>
      <div className="tell_img_right_container">
        <h6 className="step_one_text">{props.stepName}</h6>
        <p className="tell_us_sub_text">{props.stepTitle}</p>
        <p className="tell_us_extra_content">{props.stepExtraText}</p>
      </div>
    </div>
  );
}

export default OneWorkPart;
