// @ts-nocheck
import { report } from "process";
import Lottie from "react-lottie";
import React, { useEffect, useRef, useState } from "react";
import LeadSquaredForm from "../../components/forms/LeadSquaredForm";
import DisappointedEmoji from "../../../assets/Disappointed-emoji.json";
import BigSmileyEmoji from "../../../assets/Big-smiley-emoji.json";
import BlackShadesEmoji from "../../../assets/Black-shades-emoji.json";
import HeartyEyesEmoji from "../../../assets/Heart-eyes-emoji.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import CustomLoader from "../../../assets/customLoader.gif";

let currentQuestion;
const sliderSettings = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 3,
  speed: 500,
  centerPadding: "60px",
  arrows: true,
  afterChange: function(index) {
    currentQuestion.current.innerHTML = index + 1;
  },
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export default function UniversalQuizReportPage(props: any) {
  const show_correct_answer = props.reportData.show_correct_answer
  const reportData = props.reportData.content?.result
  const totalQuestion = props.reportData.content?.total_attempted_questions
  const correctAnswers = props.reportData.content?.correct_answers_count
  const score = props.reportData.content?.score
  const quiz_name = props.reportData.quiz_name
  const slickRef = useRef<Slider>(null);
  currentQuestion = useRef(null);

  const lottieOptions = {
    loop: true,
    autoplay: true, 
    animationData: score >= 90 ? HeartyEyesEmoji : score < 90 && score > 60 ? BlackShadesEmoji : score <= 60 && score > 40 ? BigSmileyEmoji : DisappointedEmoji,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    } 
  }

  return (
    <div id="container">
      <div id="top-wave" style={{display: props.reportData.status === 200 ? "block" : "none"}}>
        {<img className="desktop" src={score > 60 ? "https://d2b560u3hlgvw0.cloudfront.net/B13C2E_Top-vector.png" :
        (score <= 60 && score > 40) ? "https://d2b560u3hlgvw0.cloudfront.net/NN8W7Y_Top-vector-2.png" : "https://d2b560u3hlgvw0.cloudfront.net/141XT6_Top-vector-1.png" } alt="top-wave"/>}
         {<img className="mobile" src={score > 60 ? "https://d2b560u3hlgvw0.cloudfront.net/T2LUFR_Top-vector (1).png" :
        (score <= 60 && score > 40) ? "https://d2b560u3hlgvw0.cloudfront.net/V60NXE_Top-vector-1 (1).png" : "https://d2b560u3hlgvw0.cloudfront.net/6AC1EF_Top-vector-2 (1).png" } alt="top-wave"/>}
        <div className="quiz-score">
          <div className="animation">
          <img src="https://d2b560u3hlgvw0.cloudfront.net/J2RCQG_Confetti-Right.png" alt="Graphics"/>
          <Lottie options={lottieOptions} />
          <img src="https://d2b560u3hlgvw0.cloudfront.net/TLVNQ7_Confetti-Left.png" alt="Graphics"/>
          </div>
          <div style={{color: score > 60 ? "#219653" : score <= 60 && score > 40 ? "#2994A" : "3E92E30"}}>{score}% Score</div>
          <div>{score > 40 ? "Congratulations!" : "You can do better!"}</div>
          <div style={{color: score > 60 ? "#219653" : score <= 60 && score > 40 ? "#2994A" : "3E92E30"}}>{score}% Score</div>
        </div>
      </div>
      {props.reportData.status === 200 || (props.reportData.status === 428 && props.isUserLoggedIn) ?
      <div id="universal-quiz-report-wrapper">
        <div className="universal-quiz-summary">
          You attempted <span className='stride-blue'>{totalQuestion} questions</span> and from that <span className='correct-answers-count'>{correctAnswers} answers</span> are correct
        </div>
        {show_correct_answer ? 
        <>
        <div className="universal-quiz-question-counter">
          Question <span className='current' ref={currentQuestion}>1</span>/{totalQuestion}
        </div>
        <div style={{display: "block"}} className="universal-quiz-report">
          <Slider ref={slickRef} {...sliderSettings}>
            {
              Object.keys(reportData).length > 0 ?
              Object.keys(reportData).map(key => 
                <div className="universal-quiz-question-wrapper">
                  <div className="universal-quiz-question" dangerouslySetInnerHTML={{__html: reportData[key].questions.en}} />
                  <div className="universal-quiz-answers">
                    {reportData[key].options.map((option: any)=>{
                      const radioInput = <div className={reportData[key].correct_answer == option.value ? "correct-answer" :
                                                        reportData[key].user_response == option.value ? "user-answer" : undefined}>
                                <input type="radio" disabled value={option.value}></input><label>{option.value}</label>
                                </div>
                      return radioInput
                    })}
                  </div>
                </div>
              ) : "None"
            }
          </Slider>
        </div>
        </>
        : null}
      </div>
: 
      props.reportData.status === 428 ? 
        <div id="lead-squared-form-wrapper" style={{margin: "0 auto", padding: "20px"}}>
          <LeadSquaredForm 
          source={`${quiz_name} quiz page`}
          product={quiz_name}
          />
        </div> 
      : 
      props.reportData.status === 202 ? 
        <div className="invalid-report-id">
          <img src={CustomLoader} />  
        </div>
      :
        <div className="invalid-report-id">
          <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" alt="loader_gif" />
        </div>
      }    
    </div>
  )
}