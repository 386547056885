import LogoImage from "../src/assets/mentors/mckinsey.svg";
import one from "../src/assets/PromocodeModal/one.png"; //@ts-ignore
import two from "../src/assets/PromocodeModal/two.png"; //@ts-ignore
import three from "../src/assets/PromocodeModal/three.png"; //@ts-ignore
import Icons1 from "./assets/redutechIcon/file.png";
import Icons2 from "./assets/redutechIcon/upUser.png";
import Icons3 from "./assets/redutechIcon/in.png";
import Icons4 from "./assets/redutechIcon/chain.png";
import Icons5 from "./assets/redutechIcon/bulb.png";
import Icons6 from "./assets/redutechIcon/smile.png";
import Icons7 from "./assets/redutechIcon/beg.png";
import Icons8 from "./assets/redutechIcon/watch.png";
import Icons9 from "./assets/redutechIcon/hart.png";


export const data = {
    modal2Images: [one, two, three],
    headerSection: {
        headerButton: "Masterclass From ",
        headerButtonSpan: "Real",
        headerButton2: " Masters",
        headerText1: ` Acquire Project Management skill with live case-study &`,
        headerText4: " attract High Salaried Reputed Job or Promotion ",
        headerText5: `even with less or no work experience`,
        headerText3: `Get `,
        headerText6: `Certificate signed by ISB alumni`,
        headerText7: ` & `,
        headerText8: `Live Project Management experience. `,
        headerText9: `Attach that into your Linkedin profile or Resume and Increase your chances of becoming NEXT Project Manager or getting JOB by 95%`,
        item: {
            item1: "4 hours  Value packed session",
            item2: "Live session on Zoom",
            item3: "25th September 2022",
            item4: "11:00 AM onwards",
            item5: "Full hands on Experience",
            item6: "Mini Project",
        },
        headerRegisterButton: " Register for LIVE Masterclass NOW",
        headerRegisterInfo: `(Only 200 people can join this masterclass. Hurry up if you want to upgrade yourself for better career)`,
    },
    discountSection: {
        title: " Figures over Assumptions",
        subtitle: `Statistics to Prove Why Management Should be a Top Career Choice`,
        card: [
            {
                number: "22",
                symbol: "million",
                description:
                    "expected Management job openings by 2027 (PMI Insititute)",
            },
            {
                number: "$59,680",
                symbol: "",
                description: "average entry level PM salary (glassdoor)",
            },
            {
                number: "89",
                symbol: "%",
                description:
                    "of companies have a Project Management Officer (monday blog)",
            },
            {
                number: "61",
                symbol: "%",
                description:
                    " of organizations are grooming existing employees for managing a project",
            },
            {
                number: "72",
                symbol: "%",
                description:
                    " of managers are projected to have an increase in scope and responsibilities, up from 61% in 2022 (wellington,2019)",
            },
            {
                number: "23",
                symbol: "%",
                description:
                    " of company’s initiatives are under a Project Manager (PMI’20)",
            },
            {
                number: "15025",
                symbol: "",
                description:
                    "  of jobs require skills in management tools like Jira, Asana & Trello",
            },
            {
                number: "30k",
                symbol: "+",
                description: "  of remote Project Management Jobs (Linkedin)",
            },
        ],
    },

    WhatYouWillGet: {
        heading: "Our placement cohort will make you stand out of the Crowd",
        willGet: [
            {
                icon: Icons1,
                text: "Job Readiness in 15 Days of enrollment"
            }, {
                icon: Icons2,
                text: "Skills and Placement Focused Training Program to bridge the gap"
            }, {
                icon: Icons3,
                text: "The digital profile created will be sheared with Stride Ahead partner companies and Recruiters"
            }, {
                icon: Icons4,
                text: "Exclusive community and hiring support for the next 1 year"
            }, {
                icon: Icons5,
                text: "Master the art of concise, compelling resumes that will attract top recruiters in your field to find you"
            }, {
                icon: Icons6,
                text: "Take advantage of a professional and attractive LinkedIn profile to open doors to opportunities"
            }, {
                icon: Icons7,
                text: "Join peer-mentorship network & hone skills with events."
            }, {
                icon: Icons8,
                text: "Partnered with 300+ recruiters for guaranteed placement assistance."
            }, {
                icon: Icons9,
                text: "Access recorded videos to upskill for future job prospects."
            },
        ]
    },

    whatWillYouLearn: {
        heading: `What you’ll Learn in this Masterclass
`,
        points: [
            `How a project manager manage complex projects using Visual board and
       <u>how you can become project manager</u> learning this `,
            `What is the benefit of using kanban chart in project management `,
            `How to qualify yourself to become project manager by understanding everything 
   about Kanban charts and its elements <i>(also you can add that into your resume 
   to increase chances of getting JOB or promotion)</i>`,
            `Live Case study explanation of India’s one of best Food Delivery App Zomato <i>(How 
    Zomato’s employees use one of famous Kanban tool to manage their day-to-day operation)</i>`,
            `How to be super confident to manage complex project workflow using Kanban charts 
  after becoming project manager`,
        ],
        button: `Save your Seat Now!`,
        desc: `( This masterclass is only for 200 members )`,
    },

    whyChooseThisProgram: {
        title: "Why Choose this Program?",
        subtitle: ` A real taste at what your life will look like in a management role. A
        centric management training program conducted by industry experts.`,
        content: [
            {
                icon: "/assets/whyChoose/caseStudies.png",
                description: "Case Studies",
                span: "Learn to implement management skills (even in personal life!)",
            },
            {
                icon: "/assets/whyChoose/quizzes.png",
                description: "Quizzes & Assessments",
                span: "Assess how groomed you are for the role",
            },
            {
                icon: "/assets/whyChoose/content.png",
                description: "Content",
                span: "Study assorted management topics ",
            },
            {
                icon: "/assets/whyChoose/linkedin.png",
                description: "Linkedin",
                span: "Creating a Professional Manager Profile ",
            },
            {
                icon: "/assets/whyChoose/resume.png",
                description: "Resume",
                span: "Reach out to active recruiters",
            },
            {
                icon: "/assets/whyChoose/referrals.png",
                description: "Referrals",
                span: "Earn referrals from Industry Expert",
            },
            {
                icon: "/assets/whyChoose/interviews.png",
                description: "Interviews",
                span: "Know how to answer common questions",
            },
            {
                icon: "/assets/whyChoose/secrets.png",
                description: "Secrets",
                span: "Access less-known valuable information",
            },
        ],
    },

    programForSection: {
        title: "Who is this Program for?",
        content: [
            {
                image: "https://management-101.strideahead.in/assets/ProgramFor/image1.png",
                heading: "Final Year Graduate student",
                description1: [
                    {normal: `Update yourself with essential skills that are missing in academics but `},
                    {bold: `are a`},
                    {normal: ` must to get`},
                    {bold: ` a high`},
                    {normal: ` paying JOB`}
                ],
                description2: [
                    {normal: `Be a fresher with Project management`},
                    {bold: ` level `},
                    {normal: `experience`},
                    {bold: ` which is `},
                    {normal: `HUGE in demand skill after surge in startups`},
                ],
            },
            {
                image: "https://management-101.strideahead.in/assets/ProgramFor/image2.png",
                heading: "JOB Seeker with little or no experience",
                description1: [
                    {bold: `Reduce the chances of rejection `},
                    {normal: `due to no or less work experience`},
                ],
                description2: [
                    {normal: `Finish the masterclass with a `},
                    {bold: `certificate signed by ISB alumni `},
                    {
                        normal: `and live project working experience in your resume and Linkedin profile (...that makes your resume 10X better than others)`,
                    },
                ],
            },
            {
                image: "https://management-101.strideahead.in/assets/ProgramFor/image3.png",
                heading: `JOB with less salary or non-popular role`,
                description1: [
                    {normal: `Don’t be a replaceable guy of your company, be an asset`},
                ],
                description2: [
                    {normal: `Upgrade your `},
                    {bold: `leadership skill, team management skill & confidence`},
                    {
                        normal: ` to handle a complex project and EARN manager role with less or no experience
`,
                    },
                ],
            },
        ],
    },

    dontJoinSection: {
        title: "Don't join if",
        content: [
            "If you think this is a quick job getting scheme",
            "If you can’t implement learning on yourself",
            "If you want to become manager just for fun",
        ],
        button: `Yes, I want to become a Project Manager`,
    },
    getStartedSection: {
        title1: "Meet your Mentors",
        title2: "",

        content: [
            {
                name: "Abhinav Mahadevan",
                role: "ISB Alumni Abhinav Mahadevan will mentor you through this masterclass",
                src: "/assets/getStarted/mentor1.png",
                sponsorImage: "/assets/getStarted/image1.png",
                descriptions: [
                    `He completed his MBA from Indian School of Business (ISB) and into management practise from last 14 
          years`,
                    `He already managed team of more than 100 people successfully and trained fortune 500 clients for 
          project management`,
                    `In this masterclass Abhinav will guide you all about Project Management using his years of experience 
          and help you learn more than MBA in very short time`,
                    `Also, you’ll get certificate signed by Abhinav that you can use any anywhere (price of this
             certificate way more than masterclass price)`,
                ],
            },

            {
                name: "Piyush Gupta",
                role: "Piyush Gupta is the Co-founder of Strideahead",
                src: "/assets/getStarted/mentor2.png",
                sponsorImage: "/assets/getStarted/image2.png",
                descriptions: [
                    `He’ll co-host this masterclass along with Abhinav and will teach you Project management`,
                    `He is into startup space since his college days and also founder of many startups that are global 
          level companies now`,
                    `By working with Startups from the ground level, he has the experience and awareness on how 
            Projects are managed in any Organisation and how to make it successful by effectively communicating
            with team members, investors and other stakeholders.`,
                    `He will share this masterclass with Abhinav to guide you how to become project manager and make project 
          successful`,
                ],
            },
        ],
    },

    skillsSection: {
        button: "Save my Seat Please",
        desc: "( I don’t want to lose this )",
        title: "Become a 4x better professional",
        subtitle:
            "Earn a conglomeration of hard & soft skills, You can add this in your Resume and LinkedIn :)",

        withOut: {
            header: `Without the  Masterclass You are`,
            points: [
                {
                    image: "/assets/beforeAndAfter/Group 34471.png",
                    text: `Customer Support`
                },
                {
                    image: "/assets/beforeAndAfter/Group 34472.png",
                    text: `System Admin`
                },
                {
                    image: "/assets/beforeAndAfter/Group 34473.png",
                    text: `Marketing Executive`,
                },
                {
                    image: "/assets/beforeAndAfter/Group 34474.png",
                    text: `Financial Executive`,
                },
                {
                    image: "/assets/beforeAndAfter/Group 34475.png",
                    text: `Final Year Graduate `,
                },
                {
                    image: "/assets/beforeAndAfter/Group 34476.png",
                    text: `Desperate Job Seeker`,
                },
                {
                    image: "/assets/beforeAndAfter/Group 34477.png",
                    text: `Struggling Entrepreneur`,
                }
            ],
        },
        after: {
            header: `After the Masterclass
You become
`,
            points: [
                {
                    image: "/assets/beforeAndAfter/Group 34478.png",
                    text: `Sales manager`,
                },
                {
                    image: "/assets/beforeAndAfter/Group 34479.png",
                    text: `It Manager`,
                },
                {
                    image: "/assets/beforeAndAfter/Group 34480.png",
                    text: `Marketing Manager`,
                },
                {
                    image: "/assets/beforeAndAfter/Group 34481.png",
                    text: `Financial Manager`,
                },
                {
                    image: "/assets/beforeAndAfter/Group 34482.png",
                    text: `Graduate with management skills`,
                },
                {
                    image: "/assets/beforeAndAfter/Group 34483.png",
                    text: `Sought - after job seeker`,
                },
                {
                    image: "/assets/beforeAndAfter/Group 34484.png",
                    text: `Entrepreneur with team management skill`,
                }
            ],
        },
    },

    registrationDesktop: {
        title: "Mega Bonus",
        subtitle: `Register for this workshop now and take benefit of our “Exclusive” Mobile Application to reach to Recruiters `,
        content: [
            {
                image: "/assets/register/interest.png",
                title: "Select your Interest Areas and Fill in basic Details",
                text: "This will help our AI to personalize the dashboard for your growth and upskilling,  our Recruitment team always hunts down relevant options for you ",
            },
            {
                image: "/assets/register/persona.png",
                title: " Create your AI Powered Stride Profile",
                text: "Most resumes are Biased and ineffective, our Digital profile gives you a true picture of yourself and is loved by mentors and recruiters",
            },
            {
                image: "/assets/register/results.png",
                title: "Know your Best fit Job families",
                text: "Confused about a career path, worry no more, get ahead in this Industry 4.0 revolution",
            },
            {
                image: "/assets/register/digitalprofile.png",
                title: "Get Personalised Opportunities and Recruiter visibility",
                text: "Fill up your profile with relevant content and Stride Ahead will feed you practice cases and job opportunities to grow your career.      ",
            },
        ],
    },

    collaboratorsSection: {
        title: "Our Collaborators, Recruiters and Mentors come from",
        Content: [
            "https://management-101.strideahead.in/assets/collaborator/amazon.png",
            "https://management-101.strideahead.in/assets/collaborator/americanexpress.png",
            "https://management-101.strideahead.in/assets/collaborator/dell.png",
            "https://management-101.strideahead.in/assets/collaborator/gartner.png",
            "https://management-101.strideahead.in/assets/collaborator/gojek.png",
            "https://management-101.strideahead.in/assets/collaborator/google.png",
            LogoImage,
            "https://management-101.strideahead.in/assets/collaborator/microsoft.png",
            "https://management-101.strideahead.in/assets/collaborator/p&g.png",
            "https://management-101.strideahead.in/assets/collaborator/accenture.png",
            "https://management-101.strideahead.in/assets/collaborator/uber.png",

        ],
    },

    testimonialsSection: [
        {
            id: 10,
            rating: 4.5,
            src: "/assets/testimonial/Suyeesh.jpeg",
            name: "Suyeesh Reddy",
            role: "UI/UX Designer, PicBuddy",
            desc: "I am Suyeesh Reddy UI/UX designer, I started with my career journey and wanted expert guidance in UI/UX field leading me to Career igniter offered Stride Ahead. This package helped me in clearing all my career related doubts, helped me in personalising my Resume and Linkedin as per the field. I also got my first Internship from the platform. It was great working with the team, I got to learn many things about how the UI/UX Industry works . After getting hands-on experience in the Industry, I got various Interview opportunities from Stride Ahead’s platform. I would like to thank the whole team for the support. Looking forward the team again for helping me my profile building upskilling",
        },
        {
            id: 9,
            rating: 4.6,
            desc: "I'm very thankful to the team at Stride Ahead and my mentors for their guidance throughout the mentorship period. It has been a very rewarding experience and it has better prepared me for a career as a content creator. The Stride Ahead team has provided me with valuable hands-on work experience and skills that will help me in my further studies. The platform also helped me in Interview preparation, Gd with required tips and tricks. Further, I got my first opportunity to apply my skills in a professional environment.",
            src: "/assets/testimonial/Prachi.jpeg",
            name: "Prachi Thombare",
            role: "Operations manager at Campus trail, SNDT Women’s University, Mumbai",
        },
        {
            id: 7,
            rating: 4.8,
            desc: "Being a fresher from an Engineering College I had no clue about starting as a Digital Marketing Professional. Stride Ahead Explore helped me prepare for the journey with personalized learning content while their network helped me start off with a decent package. I am currently leading a team of 10 people",
            src: "/assets/testimonial/DypaliDogra.jpeg",
            name: "Dypali Dogra",
            role: "Creative lead at ONGC",
        },
        {
            id: 6,
            rating: 5,
            desc: "I aspired to be a Business Analyst but didn’t know where to start. Especially since I was from a tier-2 college. Stride Ahead not only helped me prepare for the job role but acquire the required skills and get my first job as a Business Analyst.",
            src: "/assets/testimonial/HimanshuSingh.jpeg",
            name: "Himanshu Singh",
            role: "Senior software  engineer at NOICE",
        },
        {
            id: 8,
            rating: 4.8,
            desc: "As a B-School student, I always wanted to apply my learnings and positively impact the people around. Stride Ahead showed me the path to it. I got Mentorship from Top notch mentors in the area of my Interest including reviewing my resume and Linkedin profile, which got me an edge over others. My mentor Piyush Gupta was always there for me whenever I wanted. I got placed at Micrographics India through an Internship opportunity provided by Stride Ahead. During this internship I have gained a lot of experience which will help in my career building. I also had an opportunity to learn the new tool  which comes in handy to keep track of contacts and easy to schedule follow up calls, mails and progresses. ",
            src: "/assets/testimonial/Kishore.jpg",
            name: "Kishore S",
            role: "MBA IIM, Indian Institute of Management Visakhapatnam",
        },
        {
            id: 11,
            rating: 4.7,
            desc: "I had many doubts about my skills and my career before I got guidance from the mentors at Stride Ahead. It started with me getting to know more about myself and what I can do with the help of Stride Ahead's personality assessment tests. And after that, the mentors took charge by telling me what to do next based on my interests and goals. I also got to know so much more about the fields which are going to grow in the future and hence decided to pursue my career in one of them. I am grateful that I got the mentorship by such dedicated individuals at Stride Ahead and I feel less scared now that they are going to guide me in my future endeavours as well.",
            src: "/assets/testimonial/DivyaMalviya.jpg",
            name: "Divya Malviya",
            role: "Social media manager at Locofast",
        },
        {
            id: 12,
            rating: 4.8,
            desc: "It was a good experience. Including the elaborate assessment as well the one on one session. I was always interested to get into management level roles and Stride Ahead is the right platform to assess yourself and develop these skills",
            src: "/assets/testimonial/Akshita.jpeg",
            name: "Akshita shrivastava",
            role: "Marketing Specialist at Triedge solutions",
        },
        {
            id: 3,
            rating: 4.9,
            desc: "",
            src: "https://d2b560u3hlgvw0.cloudfront.net/Screenshot_20220822_134335.png",
            name: "Shiva manoj",
            role: "Process lead, amazon",
            url: "https://d2b560u3hlgvw0.cloudfront.net/Shiva%20Manoj%20Edited%20Testimonial.mp4",
        },
        {
            id: 13,
            rating: 5,
            desc: "",
            src: "https://d2b560u3hlgvw0.cloudfront.net/Screenshot_20220822_135121.png",
            name: "Manoj kumar",
            role: "BI analyst at Polestar",
            url: "https://d2b560u3hlgvw0.cloudfront.net/Manoj%20Kumar%20Edited%20testimonial.mp4",
        },
        {
            id: 4,
            rating: 5,
            desc: "",
            src: "https://d2b560u3hlgvw0.cloudfront.net/Screenshot_20220822_135527.png",
            name: "Snigdha Saikia",
            role: "Marketing manager at Wish a design",
            url: "https://d2b560u3hlgvw0.cloudfront.net/Snigdha%20Edited%20Testimonial.mp4",
        },
        {
            id: 5,
            rating: 5,
            desc: "",
            src: "/assets/testimonial/Tanay.jpeg",
            name: "Tanay dangaich",
            role: "Associate Software engineer at Ugam",
            url: "https://d2b560u3hlgvw0.cloudfront.net/Tanay Edited Testimonial.mp4",
        },
        {
            id: 2,
            rating: 4.9,
            desc: `Fresher to leading team of 10 people Being a fresher from an Engineering
      College I had no clue about starting as a Digital Marketing Professional.
      Stride Ahead Explore helped me prepare for the journey with personalized
      learning content while their network helped me start off with a decent package.
      I am currently leading a team of 10 people Dypali Dogra Creative lead at ONGC`,
            src: "/assets/testimonial/Hitendrasingh.jpeg",
            name: "Hitendra singh",
            role: "Product manager at Clovia",
        },
        {
            id: 1,
            rating: 5,
            desc: "To grow in any field, you need to get Management skills to showcase in your Resume, Stride Ahead provided me a platform to develop these skills through the programs they are offering due to which I got the managerial level position in my field",
            src: "/assets/testimonial/IMG-20200531-WA0043.jpg",
            name: "Shubhangi mishra",
            role: "DevOps Engineer at Ericson",
        },
    ],

    bonusSection: {
        title: "Unlock Bonuses worth of ₹8,999",
        subTitle: `For FREE`,
        descriptions: [
            {
                icon: "/assets/bonus/one.png",
                title: "Industrial case studies & project management templates ",
            },
            {
                icon: "/assets/bonus/two.png",
                title:
                    "Interview prep & Profile building material curated by Industry experts",
            },
            {
                icon: "/assets/bonus/three.png",
                title:
                    "Hacks to crack entry level management role interviews in various domains",
            },
            {
                icon: "/assets/bonus/four.png",
                title: "Team management cheat sheet ",
            },
            {
                icon: "/assets/bonus/five.png",
                title: "Private Discord Community ",
            },
            {
                icon: "/assets/bonus/six.png",
                title:
                    "Make connections and get LinkedIn recommendations from highly revered industry leaders",
            },
        ],
    },

    getCertificateSection: {
        title: "Get Certificate Signed by ISB Alumni",
        button: `Yes, I want certificate`,
        desc: `( Book your seat now & receive Certificate after masterclass )`,
        content: [
            {
                title: "Official and verified",
                description:
                    "Receive an instructor and signed certificate with the institution's logo to verify your achievement and increase your job prospects",
            },
            {
                title: "Easily Shareable",
                description:
                    "Add the certificate on your CV or your resume or post it directly on LinkedIn. You can even post it in Twitter or Instagram",
            },
            {
                title: "Enhances Credibility",
                description:
                    "Use your certificate to enchance your professional credibility and stand out among your peers!",
            },
            {
                title: "LOR's",
                description:
                    "Shine high with letter Recommendations from top Industry Mentors",
            },
        ],
    },

    faqsSection: [
        {
            question: "Who is this program for?",
            answer: `This MAP is created for anyone who is Final year Graduate/Post-Graduate students,Job
      seekers with little or no experience,having job with less salary or non-popular role and wish to
      upscale in their career ladder . StrideAhead will be with you every step of the way, preparing,
      coaching and supporting you from planning to execution to new beginnings.`,
        },
        {
            question: "At what designations the alumni from this programs are placed",
            answer: `This is a domain independent program ie someone working in marketing, sales, finance, or any
      other executive role can join this program to get manager level role in their domain. Many of our
      students are working as Marketing Manager, Sales manager, Operations Manager project
      managers, supervisors, or project coordinators.
      `,
        },
        {
            question: "How will assessment help in getting scholarships",
            answer: `We will analyse you on various managerial parameters and on the basis of your score in the assessment,
      you will be infomed about eligibility into the program. The good performers in the assessment will get
      amazing discounts and Scholarships.
      `,
        },
        {
            question:
                "How will I get a job through this program",
            answer: `You have work on your skills through this program, We will connect you with Hiring managers from our
      300+ Partner companies`,
        },
        {
            question:
                "How much time do the session take?",
            answer: `This cohort is of 4 months and student need to spend 2-3 hours on weekend.`,
        },
        {
            question: "Are the session in person/online?",
            answer: `StrideAhead experience is completely online and Live. We receive applications from all over the
      World. Every experience is designed for someone who has a laptop & an internet connection.`,
        },
        {
            question: "Who are the mentors in the program",
            answer: `Our world-class 16 weeks program has accelerated the leadership and management performance
      of thousands of young inspiring minds. Facilitated by experienced business coaches, our
      in-person programs take leaders through a personalized approach that teaches each participant
      how to capitalize on their unique strengths, and also addresses their challenges in a caring,
      non-threatening manner. We will be with you every step of the way, preparing, coaching and
      supporting you from planning to execution.`,
        },
        // {
        //   question: "What are the prerequisites to join this masterclass.",
        //   answer: `Just a Laptop ,Good internet connection , lot of motivation & Energy to take your career to the next level.`,
        // },
    ],

    faqsSection2: [
        {
            question: "How can I enhance my career prospects with Big Data Analytics?",
            answer: "By learning Big Data Analytics, you can improve your decision-making skills, gain in-depth knowledge of data management, and become a valuable asset to organizations in need of data experts."
        },{
            question: "How can Cloud Solutions Architect training help me stay relevant in the job market?",
            answer: "By gaining the skills to design and implement cloud-based solutions, you can become a valuable asset to organizations seeking to modernize their IT infrastructure and stay competitive in the digital age"
        },{
            question: "How can Cyber Security training help me protect myself and my company from online threats?",
            answer: "By learning the latest techniques in cyber security, you can safeguard sensitive information, prevent data breaches, and ensure the safety of your personal and professional online presence."
        },{
            question: "How do I know which course is right for me?",
            answer: "Our data-driven recommendation will help you choose the course that aligns with your career goals and interests"
        },{
            question: "How do I know if these courses are in demand in the industry?",
            answer: "Our team has researched thousands of job opportunities and in-demand skill sets to provide you the best courses on the most in-demand skills in the industry."
        },{
            question: "How long do these courses take to complete?",
            answer: "The duration of each course 30 days, our programs are designed to provide you with the necessary skills and knowledge in an efficient manner."
        },{
            question: "Will I receive a certification upon completion of the course?",
            answer: "Yes, upon successful completion of the course, you will be certified by NASSCOM & MeitY to demonstrate your new skills and knowledge."
        },{
            question: "Can I access the course material after completing the program?",
            answer: "Yes, you will have lifetime access to the course material, allowing you to refresh your knowledge at any time"
        },{
            question: "How will these courses benefit me in my current job?",
            answer: "By gaining new skills and knowledge, you can improve your job performance, enhance your resume, and increase your career chances."
        },{
            question: "Can I take the courses while working full-time?",
            answer: "Yes, our courses are designed to be flexible, allowing you to learn while balancing your other commitments."
        },{
            question: "Will I have access to job placement services after completing the course?",
            answer: "Yes, we offer job placement assistance to help you find opportunities that align with your new skills and knowledge."
        },{
            question: "How do I access the course material?",
            answer: "Our courses are accessible online, allowing you to log in from anywhere with an internet connection."
        },{
            question: "Can I get help with my resume and job search?",
            answer: "Yes, we offer resume review and placement training to help you present yourself better in any situation."
        },{
            question: "What do I do if I face any issues during the assessment process?",
            answer: "We offer all-around support to the participants. Contact us at team@strideahead.in and we will get back to you."
        },
    ],


    successModal: {
        text: `Hey, I am excited to share that I just enrolled for a Masterclass  
   *"How Zomato manages its customer service using Trello"* powered by Stride Ahead .This Masterclass will help me becoming a Productivity ninja 
  😎and will be adding new case study in my profile. Have a look `,
        href: "http://management-101.strideahead.in/",
    },

    investment: {
        title: `What's the Investment`,
        benifits: {
            title: `What You’ll get Inside this Masterclass`,
            points: [
                `How to become project manager understating Visual Boards `,
                `Benefit of using kanban chart in project management`,
                `How to qualify yourself to become project manager `,
                `Live Case study explanation`,
                `How to be super confident to manage complex project `,
            ],
        },
        bonus: {
            title: "Plus",
            points: [
                `6 Valuable Bonuses to clear interview, build network, and become better manager`,
            ],
        },
        benifitsInfo: ` (worth more than ₹8,999 but today For FREE) `,
        info: {
            title: `LESS Expensive than a Growthless Career`,
            price: `Only ₹99/-`,
            info: `(For First 200 Members)`,
            button: `Yes, I want to Earn Project Manager Position`,
        },
    },
};
