import {data} from "../../../data";
import styles from "../../../styles/collaborators.module.css";
import React from "react";


const {Content, title} = data.collaboratorsSection;
const Collaborator = () => {
    return (
        <div className="container-md">
            <div className={styles.mainContainer}>
                <h1>{title}</h1>
                <hr/>
                <div className={styles.cardContainer}>
                    {Content.map((item, index) => (
                        <div key={index} className={styles.card}>
                            <img src={item}/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default Collaborator;
