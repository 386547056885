import { Button } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import QuizPage from '../pages/QuizPage';
import ReportPage from '../pages/ReportPage';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import Flex from '../components/Flex';
import Sidebar from '../components/Sidebar';
import MultiStepForm from './forms/MultiStepForm';
import ErrorFallback from '../pages/ErrorFallback';
import { useCurrentProfile } from '../../hooks/useProfile';
import { LOCALHOST, PROCESSOR_URL } from '../../utils/constants';
import QuizzContext from '../../QuizzContext';


export default function QuizList(props: any) {
  const [formType, setFormType] = useState("add");
  const [quizId, setQuizId] = useState();
  const [modalVisiblity, setModalVisiblity] = useState(false);
  const [rows, setRows] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [quizData, setQuizData] = useState({})

  const openQuiz = (id: any) => {
    const quizUrl = `${LOCALHOST}quiz/${id}`;
    window.open(quizUrl)
  }

  useEffect(() => {
    getList();
  }, [])

  useEffect(() => {
    if (!dataFetched) {
      getList();
    }
  }, [dataFetched]);



  const editQuiz = (id: any) => {
    setFormType("edit")
    setQuizId(id)
    fetch(`${PROCESSOR_URL}quiz/${id}`, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(data => {
        setModalVisiblity(true)
        setQuizData(data)
      })
      .catch(err => {
        alert("Failed to get quiz data. Please try again.")
      })
  }


  const deleteQuiz = (id: any) => {
    if (window.confirm("Are you sure you want to delete this?")) {
      fetch(`${PROCESSOR_URL}quiz/${id}`, {
        method: "DELETE",
        credentials: 'include'
      })
        .then(res => {
          if (res.status == 200 || res.status == 204) {
            setDataFetched(false);
            return "Deleted successfully"
          }
          else {
            return Promise.reject(res.status)
          }
        })
        .catch(err => {
          console.log(err)
          alert("Failed to delete the quiz. Please try again.")
        })
    }
  }

  const renderActionButtons = (params: any) => {
    return (
      <strong>
        <Button
          className="btn-stride-primary"
          id={params.id}
          variant="contained"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => { openQuiz(params.id) }}
        >
          Open
        </Button>
        <Button
          className="btn-stride-primary"
          id={params.id}
          variant="contained"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => { editQuiz(params.id) }}
        >
          Edit
        </Button>
        <Button
          className="btn-stride-primary"
          id={params.id}
          size="small"
          variant="contained"
          style={{ marginLeft: 16 }}
          onClick={() => { deleteQuiz(params.id) }}
        >
          Delete
        </Button>
      </strong>
    )
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Quiz name',
      width: 150,
      editable: false,
    },
    {
      field: 'question',
      headerName: 'Quiz Question',
      width: 300,
      editable: false,
    },
    {
      field: 'quiz_type',
      headerName: 'Quiz Type',
      width: 150,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 300,
      editable: false,
      disableClickEventBubbling: true,
      renderCell: renderActionButtons
    }

  ];

  const getList = () => {
    fetch(`${PROCESSOR_URL}quiz/`, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(data => {
        const list = data['results'].map((x: any) => ({ id: x.id, name: x.name, quiz_type: x.quiz_type, question: JSON.stringify(x.question_list) }));
        setRows(list)
        setDataFetched(true)
      })
      .catch(err => {
        alert("Failed to get quiz list. Please try again.")
      })
  }

  const { data: currentProfile } = useCurrentProfile();

  switch (currentProfile?.role) {
    case 'superadmin':
    case 'admin':
    case 'client':
      return (
        <Container>
          <Navbar authenticate={true} />
          <Sidebar />
          <Content>
            <div id="quizListSection" style={{ height: 400, width: '100%' }}>
              <Button
                onClick={() => {
                  setModalVisiblity(true);
                  setFormType("add")
                }}
                className="btn-stride-primary"
                variant="contained"
              >
                Add
              </ Button>
              <DataGrid
                className="quizListTable"
                paginationMode={'server'}
                rows={rows}
                columns={columns}
                pageSize={50}
                autoHeight={true}
                disableSelectionOnClick
              />
              {modalVisiblity
                ?
                <MultiStepForm
                  modalVisiblity={modalVisiblity}
                  setModalVisiblity={setModalVisiblity}
                  formdata={quizData}
                  resetQuizData={() => { setQuizData({}) }}
                  quizId={quizId}
                  setDataFetched={setDataFetched}
                  formType={formType}
                />
                : null
              }
            </div>
          </Content>
        </Container>
      );
    default:
      return <ErrorFallback error='401' />
  }
}


const Content = styled(Flex)`
  width: 100%;
  margin-top: 6rem;
  margin-left: 22rem;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.grey[50]};
`;

const Container = styled(Flex)`
  min-height: 100vh;
`;