import styles from "../../../styles/redutechFooter.module.css";
import LoGo from "../../../assets/redutechImg/logo.svg";
import LocationGrayIcon from "../../../../src/assets/images/gray-location.svg";
import LoGo2 from "../../../assets/redutechImg/redutech_lg.png";
const RedutechFooter = () => {
    return (
        <>
            <div className={styles.footerDesign}>
                <div className="container-md">
                    <div className={styles.footerContentAlignment}>
                        <div className={styles.footerLogo}>
                            <img src={LoGo2} alt="LoGo2" className="me-3" />
                            <img src={LoGo} alt="LoGo" />
                        </div>
                        <div className={`${styles.locationContentAlignment}`} >
                            <div>
                                <img src={LocationGrayIcon} alt="LocationGrayIcon" />
                            </div>
                            <div>
                                <p>
                                    Address: H.N. 317, Sector 8, Indira Nagar, Lucknow 226016
                                </p>
                            </div>
                        </div>
                        {/*<div className={styles.grayLocationAlignment}>*/}
                        {/*    <div>*/}
                        {/*        <img src={LocationGrayIcon} alt="LocationGrayIcon" />*/}
                        {/*    </div>*/}
                        {/*    /!*<div>*!/*/}
                        {/*    /!*    <p>Fumes co-working NSP, New Delhi - 110034</p>*!/*/}
                        {/*    /!*</div>*!/*/}
                        {/*</div>*/}
                        <div className={styles.footerLinkTextAlignment}>
                            <a href="#faq">FAQs</a>
                            <a href=" https://www.railedutech.com/privacy.html">
                                Privacy policy
                            </a>
                            <a href="https://www.railedutech.com/refund.html">
                                Refund policy
                            </a>
                            <a href="https://www.railedutech.com/terms.html">
                                Terms & conditions
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.footerCopyrightContent}>
                    <p>
                        © Copyright 2023-2023 | Redutech. All rights reserved.
                    </p>
                </div>
            </div>
        </>
    );
};
export default RedutechFooter;
