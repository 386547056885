import React, { useEffect, useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { data } from "../../../data";
import styles from "../../../styles/faq.module.css";

export const faqs = data.faqsSection;

const AccordionItem = ({ faq, showIndex, setIndex, index }: any) => {
  return (
    <section
      id="faq"
      className={showIndex == index ? styles.faqCardOpen : styles.faqCard}
    >
      <div className={styles.question} onClick={() => setIndex(index)}>
        <p>
          <span>Q{index + 1}. </span>
        </p>
        <h5>{faq.question}</h5>
        {showIndex !== index ? (
          <IoIosArrowDown
            className={styles.iconDown}
            onClick={() => setIndex(index)}
          />
        ) : (
          <IoIosArrowUp
            className={styles.iconUp}
            onClick={() => setIndex(index)}
          />
        )}
      </div>
      {showIndex == index && (
        <div className={styles.answer}>
          <hr />
          <p>{faq.answer}</p>
        </div>
      )}
    </section>
  );
};
export const FAQ = () => {
  const [currentIndex, setCurrentIndex] = useState<any>();
  const [viewMore, setViewMore] = useState(Math.round(faqs.length / 2));
  const toggle = () => {
    if (viewMore < faqs.length - 1) setViewMore(faqs.length);
    else setViewMore(Math.round(faqs.length / 2));
  };

  const handleAddIndex = (index: number) => {
    setCurrentIndex(index);
  };

  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);
  return (
    <div id="faq" className={styles.newFaqContainer}>
      <div className={styles.faqContainer}>
        <p>Frequently asked questions</p>
        <hr />
        {width > 768 ? (
          <div className={styles.faqContentAlignment}>
            <div className={styles.faqCardContainer}>
              {faqs.slice(0, faqs.length / 2).map((faq, index) => (
                <AccordionItem
                  faq={faq}
                  key={index}
                  index={index}
                  showIndex={currentIndex}
                  setIndex={handleAddIndex}
                />
              ))}
            </div>
            <div className={styles.faqCardContainer}>
              {faqs.slice(faqs.length / 2).map((faq, index) => (
                <AccordionItem
                  faq={faq}
                  key={index + faqs.length / 2}
                  index={Math.floor(index + faqs.length / 2)}
                  showIndex={currentIndex}
                  setIndex={handleAddIndex}
                />
              ))}
            </div>
          </div>
        ) : (
          <section className={styles.faqCardContainer}>
            {faqs.slice(0, viewMore).map((faq, index) => (
              <AccordionItem
                faq={faq}
                key={index}
                index={index}
                showIndex={currentIndex}
                setIndex={handleAddIndex}
              />
            ))}
            <h5 onClick={toggle}>
              View {viewMore < faqs.length ? "More" : "Less"}
            </h5>
          </section>
        )}
      </div>
    </div>
  );
};
