import React, {FC, useEffect, useState} from "react";
import Modal, {CloseButton} from "../Modal";
import Flex from "../Flex";
import {ModalHeading} from "../Typography";
import Select from "../Select";
import Input from "../Input";
import styled from "styled-components";
import {slideDown} from "../../../styles/animations";
import Button from "../Button";
import useForm from "../../../hooks/useForm";
import {ADD_REPORT_PAGE_VALIDATION_SCHEMA, API_ENDPOINT} from "../../../utils/constants";
import {APIError, FetchResponse, FormFields, IOption} from "../../../utils/types";
import axios from "axios";
import useTestTypes from "../../../hooks/useTestTypes";

const TestPageModal: FC<{ onClose: any, reportPageData: any, onSuccess: any,  getList: any, setToastText: any }> = ({onClose, reportPageData , getList, setToastText, onSuccess}: any)=>{
    const [isLoading, setLoading] = useState(false);
    const { data: types } = useTestTypes();

    const actionOnSubmit = async (
        data: { [key in FormFields]?: string | string[] }
    ): Promise<APIError[] | void> => {
        setLoading(true);
        try {
            let postdata;
            if (data.report_type)
              postdata = {
                test_type_id: data.report_type,
                name: data.report_name,
                label: data.report_display_label
            };

            else
                postdata = {
                    test_type_id: null,
                    name: data.report_name,
                    label: data.report_display_label
                };

            if(reportPageData?.data){
                await axios.put<FetchResponse<{ message: string }>>(
                    `${API_ENDPOINT}report-pages/${reportPageData?.data?.id}`,
                    postdata
                );
                getList()
                onClose()
                setToastText('Your report page has been update successfully')
                onSuccess(true);
            }else {
                await axios.post<FetchResponse<{ message: string }>>(
                    `${API_ENDPOINT}report-pages`,
                    postdata
                );
                getList()
                onClose()
                setToastText('Your report page has been created successfully')
                onSuccess(true);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            const errors = error.response.data.error as APIError[];
            onSuccess(false);
            // @ts-ignore
            setToastText(errors[0]?.name)
            return errors;
        }
    };

    const { formData, setFormData, handleChange, handleBlur, handleSubmit } =
        useForm({
            actions: { onSubmit: actionOnSubmit },
            validatorSchema: ADD_REPORT_PAGE_VALIDATION_SCHEMA,
            handleValidationOnBlur: true,
        });

    console.log(formData);

    const handleTestTypeChange = async (e) => {
        const {textContent, value  } = e.target;
        const test = await types?.find(s=>s.id ===  Number(value));
        // @ts-ignore
        setFormData({
            ...formData,
            report_name: { value: test? test?.test_name + " Report Page" : "", error: '' },
            report_type: { value:value? value: null, error: '' },

        });
    };

    const isButtonDisabled = () => {
        return !(
            formData.report_name?.value !== '' &&
            formData.report_display_label?.error === '' &&
            !isLoading
        );
    };
    useEffect(()=>{
        if(reportPageData?.data)
            setFormData({
                ...formData,
                report_name: { value: reportPageData?.data?.name , error: '' },
                report_type: { value:reportPageData?.data?.test_type_id, error: '' },
                report_display_label: { value:reportPageData?.data?.label, error: '' },

            });
    },[reportPageData])

    return(
        <div>
            {
                <Modal onClose={() => onClose(false)}>
                    <Container2  column onClick={(e) => e.stopPropagation()}>
                        <Flex justify='center' align='center'>
                            <ModalHeading>{reportPageData?.data?.id? "Update": "Add"} Report Page</ModalHeading>
                            <CloseButton onClick={() => onClose(false)} />
                        </Flex>
                        <form
                            onSubmit={handleSubmit}
                        >
                            <Select
                                label='Test Type'
                                name='report_page_type'
                                value={formData?.report_type?.value ?? ""}
                                onChange={handleTestTypeChange}
                                onBlur={handleBlur}
                                autoFocus
                                tabIndex={2}
                                options={types?.map(
                                    (type: any) => ({ value: +type.id , label: type.test_name, name:type.test_name } as IOption)
                                )}
                            />
                            <Input
                                label='Report Page Name'
                                name='report_name'
                                type='text'
                                value={formData?.report_name?.value ?? ""}
                                error={formData.report_name?.error}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoFocus
                                required
                                tabIndex={1}
                            />
                            <Input
                                label='Report Page Label'
                                name='report_display_label'
                                type='text'
                                value={formData.report_display_label?.value}
                                error={formData.report_display_label?.error}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoFocus
                                required
                                tabIndex={1}
                            />
                            <Flex>
                                <AddButton
                                    type='submit'
                                    isLoading={isLoading}
                                    disabled={isButtonDisabled()}
                                >
                                    {reportPageData?.data?.id? "Update" : "Add"} Test
                                </AddButton>
                            </Flex>
                        </form>
                    </Container2>
                </Modal>
            }
        </div>
    )
}

export default TestPageModal;


const Container2 = styled(Flex)`
  width: 40rem;
  padding: 3rem;
  background-color: #fff;
  border-radius: 4px;
  animation: ${slideDown} 0.4s ease-out;
  overflow-y: auto;
  max-height: 90%;
  form {
    margin-top: 2rem;
  }
  input[type=file]{
    color:transparent;
  }
  label{
  font-size: 14px;
  }
`;

var AddButton = styled(Button)`
  width: 100%;
  height: 5rem;
  background-color: ${(props) => props.theme.colors.blue[500]};
`;