import styles from "../../../styles/getCertificate.module.css";
import { IoMdCube } from "react-icons/io";
import React from "react";
import { data } from "../../../data";
import DotsIcon from "../../../assets/getCertified/dots.png";
import BottomIcon from "../../../assets/getCertified/bottom.png";
import TopIcon from "../../../assets/getCertified/top.png";
import CertificateImage from "../../../assets/images/redutechCertificate.png";
import VectoreImage from "../../../assets/images/new-vectore.svg";
import certificate from "../../../assets/getCertified/certificate.png";
import styled from "styled-components";

const { content, title, desc, button } = data.getCertificateSection;

const RedutechGetCertificate = ({ setOpen }: any) => {
    return (
        <>
            <div className={styles.certificateAllContentAlignment2}>
                <div className="container-md">
                    <div className={styles.sectionTitle2}>
                        <h1 style={{color:"#8B292A"}}>Get certified from NASSCOM, Future Skills Prime and Meity, Government of India</h1>
                    </div>
                    <Divider>
                        <hr/>
                    </Divider>
                    <div className={styles.cerGrid}>
                        <div className={styles.cerGridItems}>
                            <div className={styles.allTextIconContentAlignment}>
                                <div className={styles.iconTextGrid}>
                                    {content.map((row, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className={styles.logo}>
                                                    <IoMdCube style={{color:"#8B292A"}} className={styles.IoMdCube_image} />
                                                </div>
                                                <div className={styles.iconTextGridItems}>
                                                    <p style={{color:"#8B292A"}}>{row.title}</p>
                                                    <span style={{color:"#3D3636"}}>{row.description}</span>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className={styles.cerGridItems}>
                            <div className={styles.CertificateImageStyle}>
                                <img src={CertificateImage} alt="CertificateImage" />
                            </div>
                        </div>
                    </div>
                    <StyledButton className="d-flex justify-content-center">
                     <a href="#payment">
                         Yes, I want certificate
                     </a>
                    </StyledButton>
                    <Text className="mt-4">
                        ( Book your seat now & receive Certificate after masterclass )
                    </Text>
                </div>
            </div>
        </>
    );
};
export default RedutechGetCertificate;


const StyledButton = styled.div`
 a{
   background: linear-gradient(90.67deg, #8B292A 24.23%, #CB5657 127.76%);
   border-radius: 10px;
   padding: 25px 75px;
   color: #FFFFFF;
   border:none;
   font-weight: 600;
   font-size: 32px;
   letter-spacing: 0.02em;
   color: #F2EFFB !important;
   text-decoration: none;
   text-align:center;
   
   @media(max-width:768px){
     padding: 22px 30px;
     font-size:20px;
     width:100%;
   }
 }
`;

const Text = styled.p`
    font-weight: 500;
    font-size: 24px;
    line-height: 160%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #281E42
    margin-top: 20px;
    @media(max-width:768px){
     font-size:16px;
   }
`;

const Divider =  styled.div`
   width:100%;
   display:flex;
   justify-content:center;
   align-items: center;
   
   hr{
    width: 150px;
    height: 4px;
    border-radius: 50%;
    background: #8B292A;
    opacity: 1;
    border: none;
    margin-top:20px;
    margin-bottom:40px;
   }
`;