import React, { FC, FocusEvent, InputHTMLAttributes, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { IoIosSearch } from 'react-icons/io';
import styled, { css } from 'styled-components';
import { fadeIn, shake } from '../../styles/animations';
import Flex from './Flex';
import { Text1Css } from './Typography';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	error?: string;
	showSuccessIndicator?: boolean;
	showSearchIndicator?: boolean;
	inputHeight?: string;
	icon?: JSX.Element;
}

const Input: FC<IProps> = ({
	type,
	label,
	name,
	placeholder,
	required,
	autoFocus,
	error,
	value,
	onChange,
	className,
	style,
	onBlur,
	showSuccessIndicator = false,
	showSearchIndicator = false,
	inputHeight,
	icon,
	...rest
}) => {
	const [focused, setFocused] = useState(autoFocus || false);

	const handleOnBlurEvent = (event: FocusEvent<HTMLInputElement>) => {
		setFocused(false);
		if (onBlur) {
			onBlur(event);
		}
	};

	return (
		<Container
			focused={focused}
			error={error}
			className={className}
			style={style}
			showSearchIndicator={showSearchIndicator}
			inputHeight={inputHeight}
		>
			{label && (
				<label htmlFor={name}>
					{label}
					{required && '*'}
				</label>
			)}
			{showSearchIndicator && <StyledSearch />}
			<input
				type={type}
				name={name}
				id={name}
				required={required}
				placeholder={placeholder}
				value={value || ''}
				autoFocus={autoFocus}
				formNoValidate
				onChange={onChange}
				onFocus={() => setFocused(true)}
				onBlur={handleOnBlurEvent}
				className={`${error && 'error'} ${focused && 'focused'}`}
				{...rest}
			/>

			{icon}

			{/* {showSuccessIndicator && !focused && value && value !== '' && !error && (
				<StyledCheck />
			)} */}
			{error && <span className='error'>{error}</span>}
		</Container>
	);
};

export default Input;

const Container = styled.div<{
	focused: boolean;
	error?: string;
	showSearchIndicator?: boolean;
	inputHeight?: string;
	className?: string;
}>`
	display: flex;
	position: relative;
	flex-direction: column;
	margin-bottom: 1rem;
	align-items: center;

	${({ showSearchIndicator }) =>
		showSearchIndicator &&
		css`
			margin-bottom: 0rem;
		`}
	${Text1Css}

  /* Shake if there is an error */
  ${({ error }) =>
		error &&
		css`
			animation: ${shake} 0.7s ease-out;
		`}

  label {
		color: ${({ theme }) => theme.colors.grey[500]};
		font-weight: normal;
		width: 100%;
	}

	input {
		width: 100%;
		${({ inputHeight }) =>
			css`
				height: ${inputHeight ? inputHeight : '5rem'};
			`}

		padding: 1.6rem;
		margin-top: 0.5rem;
		outline: none;
		font-size: inherit;
		border: 1px solid ${({ theme }) => theme.colors.grey[500]};
		/* border: 0px solid; */

		${({ showSearchIndicator }) =>
			showSearchIndicator &&
			css`
				padding: 0rem;
				padding-left: 5.2rem;

				border-color: ${({ theme }) => theme.colors.grey[200]};
			`}

		/* Focused state styles */
    &.focused {
			box-shadow: 0 0 2px ${({ theme }) => theme.colors.blue[500]};
			border-color: ${({ theme }) => theme.colors.blue[500]};
		}

		/* Error state styles */
		&.error {
			box-shadow: 0 0 2px ${({ theme }) => theme.colors.red};
			border-color: ${({ theme }) => theme.colors.red[500]};
		}

		border-radius: 10px;
		color: ${({ theme }) => theme.colors.grey[500]};
		transition: 0.1s;

		::placeholder {
			font-size: 1.6rem;
		}
	}

	&.test-form {
		label {
			font-size: 1.6rem;
		}
		input {
			height: 4rem;
			border: 1px solid ${({ theme }) => theme.colors.grey[200]};
			box-sizing: border-box;
			border-radius: 10px;
			font-size: 1.6rem;
			padding-left: 1rem;
			margin-top: 0.8rem;
			&.error {
				font-size: 1.6rem;
			}
		}
	}
	span.error {
		margin-left: 0.4rem;
		color: ${({ theme }) => theme.colors.red[500]};
	}

	@media only screen and (max-width: 1024px) {
		&.test-form {
			flex: 1;
			label {
				font-family: Roboto;
				font-size: 1.2rem;
			}
			input {
				font-family: Roboto;
				border: 1px solid ${({ theme }) => theme.colors.grey[200]};
				box-sizing: border-box;
				border-radius: 10px;
				font-size: 1.2rem;
				padding-left: 1rem;
				margin-top: 0.8rem;
				&.error {
					font-size: 1.2rem;
				}
			}
		}
	}
`;

const StyledCheck = styled(FaCheckCircle)`
	position: absolute;
	font-size: 2rem;
	right: 1.5rem;
	top: 4.9rem;
	color: ${({ theme }) => theme.colors.blue[500]};
	animation: ${fadeIn} 0.5s;
`;

const StyledSearch = styled(IoIosSearch)`
	position: absolute;
	font-size: 3rem;
	left: 1.4rem;
	top: 1rem;
	color: ${({ theme }) => theme.colors.grey[400]};
	animation: ${fadeIn} 0.5s;
`;

export const InputGroup = styled(Flex)`
	flex-firection: row;
`;
