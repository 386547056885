import styled from "styled-components";
import StarRatings from "react-star-ratings";
import React, { useEffect, useState } from "react";
import CalendarIcon from "../../../assets/redutechIcon/calandar.png"
import BrowserIcon from "../../../assets/redutechIcon/browser.png"
import ClockIcon from "../../../assets/redutechIcon/clock.png"
import TimerIcon from "../../../assets/redutechIcon/timer.png"
import Cyber from "../../../assets/redutechImg/cyber.png"
import Cloud from "../../../assets/redutechImg/cloud.png"
import BigData from "../../../assets/redutechImg/bigData.png"
import BigDataWithRem from "../../../assets/redutechImg/bigDataWithRem.png"
import { DropdownItem } from "../DropdownMenu";
import Recommended from '../../../assets/recommended.svg'

const ProgramFor = ({ setCourseType, reportMeta }: any) => {

    const points = [
        {
            point1: "Hadoop"
        }, {
            point1: " HDFS and YARN"
        }, {
            point1: "MapReduce framework and pig"
        }, {
            point1: "Apache Hive"
        }, {
            point1: "NoSQL"
        }, {
            point1: " MongoDB"
        }, {
            point1: "Table"
        }, {
            point1: "Spark"
        }, {
            point1: "Scala Operations"
        }, {
            point1: "Python"
        }, {
            point1: " Data Science"
        }, {
            point1: "Data Analytics using Python"
        },
    ]
    const points2 = [
        {
            point1: "Basics of Linux & networking"
        }, {
            point1: "Virtualization"
        }, {
            point1: " Cloud Computing"
        }, {
            point1: "Setting up a cloud using open source tools"
        }, {
            point1: " Cloud Networking"
        }, {
            point1: " Cloud Storage"
        }, {
            point1: "Public Cloud service providers"
        }, {
            point1: "Containers"
        }, {
            point1: "Standards and Regulations"
        }, {
            point1: " Cloud Security"
        }
    ]
    const points3 = [
        {
            point1: "Linux Environment"
        }, {
            point1: "TCP/IP Cyber Security Perspective"
        }, {
            point1: "Security Threats & Vulnerabilities"
        }, {
            point1: " Cryptography and Network Security"
        }, {
            point1: " Overview of End System Security"
        }, {
            point1: " Overview of Network Defence"
        }, {
            point1: "Application Security"
        }, {
            point1: "Threat Modeling"
        }, {
            point1: " Malware Analysis"
        }
    ]

    const [active, setActive] = useState<any>(1);
    const [recommended, setRecommended] = useState<number>();
    useEffect(() => {
        if (reportMeta?.report_metadata?.recommended_course === "CC") {
            setRecommended(2)
            setActive(2)
        } else if (reportMeta?.report_metadata?.recommended_course === "CS") {
            setRecommended(3)
            setActive(3)
        } else if (reportMeta?.report_metadata?.recommended_course === "BDA") {
            setRecommended(1)
            setActive(1)
        }
    }, [reportMeta?.report_metadata?.recommended_course])

    return (
        <div className="container-md">
            <StyleDiv>
                <div className="row gap-lg-0 gap-4">
                    <div className="col-lg-4 col-12">
                        <Card className={`${active === 1 && "redutech_Is_Active"}`} onClick={() => {
                            setCourseType("Big Data Analytics");
                            setActive(1);
                        }}>
                            <ImageDiv><img src={BigDataWithRem} /></ImageDiv>
                            <RecommendedImage>
                                {recommended === 1 && <img src={Recommended} />}
                            </RecommendedImage>
                            <PaddingDiv>
                                <RatingDiv className="d-flex justify-content-between align-items-center">
                                    <StarRatings
                                        starCount={5}
                                        rating={4.7}
                                        starSpacing="0.1rem"
                                        starDimension="13px"
                                        starRatedColor={"#FFB800"}
                                    />
                                    <div>
                                        <ClipsDiv className="mb-1">Big Data</ClipsDiv>
                                        <ClipsDiv>Hadoop</ClipsDiv>
                                    </div>
                                    <div>
                                        <ClipsDiv className="mb-1">NoSQL databases</ClipsDiv>
                                        <ClipsDiv>Spark</ClipsDiv>
                                    </div>

                                </RatingDiv>
                                <ContentDiv className="mt-3">
                                    <h4 className="text-start">Big Data Analytics</h4>
                                    <p className="text-start">This course is designed for industry professionals seeking
                                        to transition to big data and B.Tech/MCA students. It offers blended
                                        learning...<span data-bs-toggle="modal" data-bs-target="#exampleModal1"
                                            style={{ color: "#8B292A" }}>Read More</span>
                                    </p>
                                </ContentDiv>
                                <InfoDiv className="mt-3 mb-3">
                                    <div className="row ">
                                        <div className="col-6 d-flex align-items-center mb-3"><IconDiv><img
                                            src={BrowserIcon} /></IconDiv><span>Live Online</span>
                                        </div>
                                        <div className="col-6 d-flex align-items-center mb-3"><IconDiv><img
                                            src={ClockIcon} /></IconDiv><span>15th Mar 2023</span></div>
                                        <div className="col-6 d-flex align-items-center"><IconDiv><img
                                            src={TimerIcon} /></IconDiv><span>30 Days</span>
                                        </div>
                                        <div className="col-6 d-flex align-items-center"><IconDiv><img
                                            src={CalendarIcon} /></IconDiv><span>Masterclass</span>
                                        </div>
                                    </div>
                                </InfoDiv>
                                {/*<UserDiv className="text-start">*/}
                                {/*    <h5 className="text-start m-0">Led by</h5>*/}
                                {/*    <h4 className="m-0">Ashutosh Sharma</h4>*/}
                                {/*    <p className="m-0">Mentor Health Coach</p>*/}
                                {/*</UserDiv>*/}
                            </PaddingDiv>
                            <div className="modal fade" id="exampleModal1"
                                aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                <ModalDiv className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <p>This course is designed for industry professionals seeking to transition
                                                to big data and B.Tech/MCA students. It offers blended learning, 24/7
                                                e-lab access, a co-branded certificate, and potential government
                                                incentives.
                                            </p>
                                            <div>
                                                <Row className="row">
                                                    {points?.map((value, index) => {
                                                        return (
                                                            <h6 className="col-4">{index + 1} {value?.point1}</h6>
                                                        )
                                                    })}

                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </ModalDiv>
                            </div>
                        </Card>
                    </div>
                    <div className="col-lg-4 col-12">
                        <Card className={`${active === 2 && "redutech_Is_Active"}`} onClick={() => {
                            setCourseType("Cloud solution architect");
                            setActive(2);
                        }}>
                            <ImageDiv><img src={Cloud} /></ImageDiv>
                            <RecommendedImage>
                                {recommended === 2 && <img src={Recommended} />}
                            </RecommendedImage>
                            <PaddingDiv>
                                <RatingDiv className="d-flex justify-content-between align-items-center">
                                    <StarRatings
                                        starCount={5}
                                        rating={4.8}
                                        starSpacing="0.1rem"
                                        starDimension="13px"
                                        starRatedColor={"#FFB800"}
                                    />
                                    <div>
                                        <ClipsDiv className="mb-1">Cloud Computing</ClipsDiv>
                                        <ClipsDiv>Virtualization</ClipsDiv>
                                    </div>
                                    <div>
                                        <ClipsDiv className="mb-1">AWS</ClipsDiv>
                                        <ClipsDiv>Cloud Security</ClipsDiv>
                                    </div>
                                </RatingDiv>
                                <ContentDiv className="mt-3">
                                    <h4 className="text-start">Cloud Solutions Architect</h4>
                                    <p className="text-start">This course provides an introduction to cloud computing,
                                        covering both open source and proprietary
                                        tools...<span data-bs-toggle="modal" data-bs-target="#exampleModal2"
                                            style={{ color: "#8B292A" }}>Read More</span>
                                    </p>
                                </ContentDiv>
                                <InfoDiv className="mt-3 mb-3">
                                    <div className="row ">
                                        <div className="col-6 d-flex align-items-center mb-3"><IconDiv><img
                                            src={BrowserIcon} /></IconDiv><span>Live Online</span>
                                        </div>
                                        <div className="col-6 d-flex align-items-center mb-3"><IconDiv><img
                                            src={ClockIcon} /></IconDiv><span>15th Mar 2023</span></div>
                                        <div className="col-6 d-flex align-items-center"><IconDiv><img
                                            src={TimerIcon} /></IconDiv><span>30 Days</span>
                                        </div>
                                        <div className="col-6 d-flex align-items-center"><IconDiv><img
                                            src={CalendarIcon} /></IconDiv><span>Masterclass</span>
                                        </div>
                                    </div>
                                </InfoDiv>
                                {/*<UserDiv className="text-start">*/}
                                {/*    <h5 className="text-start m-0">Led by</h5>*/}
                                {/*    <h4 className="m-0">Ashutosh Sharma</h4>*/}
                                {/*    <p className="m-0">Mentor Health Coach</p>*/}
                                {/*</UserDiv>*/}
                            </PaddingDiv>
                            <div className="modal fade" id="exampleModal2"
                                aria-labelledby="exampleModalLabel2" aria-hidden="true">
                                <ModalDiv className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <p>This course provides an introduction to cloud computing, covering both
                                                open source and proprietary tools. It includes hands-on experience and
                                                provides a joint co-branded participation certificate. Participants may
                                                be eligible for government incentives.</p>
                                            <div>
                                                <Row className="row">
                                                    {points2?.map((value, index) => {
                                                        return (
                                                            <h6 className="col-4">{index + 1} {value?.point1}</h6>
                                                        )
                                                    })}

                                                </Row>
                                            </div>

                                        </div>
                                    </div>
                                </ModalDiv>
                            </div>
                        </Card>
                    </div>
                    <div className="col-lg-4 col-12">
                        <Card className={`${active === 3 && "redutech_Is_Active"}`} onClick={() => {
                            setCourseType("Cyber Security");
                            setActive(3);
                        }}>
                            <ImageDiv><img src={Cyber} /></ImageDiv>
                            <RecommendedImage>
                                {recommended === 3 && <img src={Recommended} />}
                            </RecommendedImage>
                            <PaddingDiv>
                                <RatingDiv className="d-flex justify-content-between align-items-center">
                                    <StarRatings
                                        starCount={5}
                                        rating={4.7}
                                        starSpacing="0.1rem"
                                        starDimension="12px"
                                        starRatedColor={"#FFB800"}
                                    />
                                    <div>
                                        <ClipsDiv className="mb-1">Cyber Security</ClipsDiv>
                                        <ClipsDiv>Cryptography</ClipsDiv>
                                    </div>
                                    <div>
                                        <ClipsDiv className="mb-1">Cryptography</ClipsDiv>
                                        <ClipsDiv>Malware and Cyber Attacks</ClipsDiv>
                                    </div>

                                </RatingDiv>
                                <ContentDiv className="mt-3">
                                    <h4 className="text-start">Pragmatic Approach to Cyber Security</h4>
                                    <p className="text-start">This course bridges the gap betwe...<span
                                        data-bs-toggle="modal" data-bs-target="#exampleModal3"
                                        style={{ color: "#8B292A" }}>Read More</span>
                                    </p>
                                </ContentDiv>
                                <InfoDiv className="mt-3 mb-3">
                                    <div className="row ">
                                        <div className="col-6 d-flex align-items-center mb-3"><IconDiv><img
                                            src={BrowserIcon} /></IconDiv><span>Live Online</span>
                                        </div>
                                        <div className="col-6 d-flex align-items-center mb-3"><IconDiv><img
                                            src={ClockIcon} /></IconDiv><span>15th Mar 2023</span></div>
                                        <div className="col-6 d-flex align-items-center"><IconDiv><img
                                            src={TimerIcon} /></IconDiv><span>30 Days</span>
                                        </div>
                                        <div className="col-6 d-flex align-items-center"><IconDiv><img
                                            src={CalendarIcon} /></IconDiv><span>Masterclass</span>
                                        </div>
                                    </div>
                                </InfoDiv>
                                {/*<UserDiv className="text-start">*/}
                                {/*    <h5 className="text-start m-0">Led by</h5>*/}
                                {/*    <h4 className="m-0">Ashutosh Sharma</h4>*/}
                                {/*    <p className="m-0">Mentor Health Coach</p>*/}
                                {/*</UserDiv>*/}
                            </PaddingDiv>
                            <div className="modal fade" id="exampleModal3"
                                aria-labelledby="exampleModalLabel3" aria-hidden="true">
                                <ModalDiv className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close">
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <p>This course bridges the gap between basic and advanced cyber security
                                                courses, providing hands-on training, clear objectives, and a co-branded
                                                certificate. It prepares students for several job roles, including
                                                network security and endpoint security.
                                            </p>
                                            <div>
                                                <Row className="row">
                                                    {points3?.map((value, index) => {
                                                        return (
                                                            <h6 className=" col-4">{index + 1} {value?.point1}</h6>
                                                        )
                                                    })}
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </ModalDiv>
                            </div>
                        </Card>
                    </div>
                </div>
            </StyleDiv>
        </div>
    )
}

export default ProgramFor;


const ModalDiv = styled.div`
   max-width: 45%;
   
   p{
       font-size:20px;
     }
   
   @media(max-width:768px){
     max-width: 100%;
     p{
       font-size:14px;
     }
   }
`;

const StyleDiv = styled.div`
   padding: 120px 0px;
`;

const Card = styled.div`
   background: #FFFFFF;
   box-shadow: 0px 15px 56px rgba(139, 41, 42, 0.12);
   border-radius: 20px;
   cursor: pointer;
   position:relative;
   border: 2px solid transparent;
   min-height: 600px !important;
   @media(max-width:768px){
    min-height: auto !important;
   }
`;

const RecommendedImage = styled.div`
    position: absolute;
    top:10px;
    right:10px;
`;

const PaddingDiv = styled.div`
    padding: 20px 30px 30px 30px;
    @media(max-width:768px){
      padding: 15px 20px 20px 20px;
    
     }
 `;

const ImageDiv = styled.div`
     img{
        width:100%
     }
`;

const RatingDiv = styled.div`

`;
const Row = styled.div`
     h6{
        font-size:12px;
        font-weight:400px;
        color:#000000;
        @media(max-width:768px){
          font-size:10px;
          font-weight:400px;
          color:#000000;
        }
     }
`;

const ClipsDiv = styled.div`
     background: #F2F2F2;
     border-radius: 6px;
     padding: 5px 6px;
     font-size: 12px;
     @media(max-width:768px){
     padding: 5px;
     font-size: 8px;
     }
`;

const ContentDiv = styled.div`
     h4{
     font-weight: 600;
     font-size: 24px;
     line-height: 160%;
     color: #3D3636;
     @media(max-width:768px){
     font-size: 20px;
     }
     }
     p{
     font-weight: 400;
     font-size: 16px;
     line-height: 160%;
     color: #4F4F4F;
     @media(max-width:768px){
     font-size: 14px;
     }
     }
`;

const InfoDiv = styled.div`
      span{
      font-weight: 400;
      font-size: 14px;
      line-height: 200%;
      color: #4F4F4F;
      margin-left:10px;
    @media(max-width:768px){
     font-size: 10px;
     margin-left:6px;
     }
      }
`;
const IconDiv = styled.div`
   width: 30px;
   height: 30px;
    @media(max-width:768px){
      width: 22px;
      height: 22px;
     }
   
`;
const UserDiv = styled.div`
     h5{
       font-weight: 700;
       font-size: 16px;
       line-height: 200%;
       color: #4F4F4F;
     @media(max-width:768px){
     font-size: 14px;
     }
     }
     h4{
       font-weight: 600;
       font-size: 18px;
       line-height: 200%;
       color: #4F4F4F;
     @media(max-width:768px){
     font-size: 16px;
     }
     }
     p{
       font-weight: 400;
       font-size: 14px;
       line-height: 200%;
       color: #4F4F4F;
     @media(max-width:768px){
     font-size: 12px;
     }
     }
`;

