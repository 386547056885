import { FC } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import styled from 'styled-components';
import Flex from './Flex';
import { Text1Css } from './Typography';

interface IProps {
  align?: string;
  justify?: string;
  className?: string;
  style?: any;
}

const FormStatus: FC<IProps> = ({
  align,
  justify,
  className,
  style,
  children,
}) => {
  return (
    <Container
      className={className}
      align={align}
      justify={justify}
      style={style}
    >
      <InfoIcon />
      {children}
    </Container>
  );
};

const InfoIcon = styled(FaInfoCircle)`
  margin-right: 0.6rem;
`;

const Container = styled(Flex)`
  border: 1px solid;
  border-radius: 5px;
  padding: 0.8rem 1rem;
  ${Text1Css}

  &.success {
    background-color: ${({ theme }) => theme.colors.green[300]};
    border-color: ${({ theme }) => theme.colors.green[500]};
    color: ${({ theme }) => theme.colors.green[500]};

    ${InfoIcon} {
      color: ${({ theme }) => theme.colors.green[500]};
    }
  }

  &.fail {
    background-color: ${({ theme }) => theme.colors.red[300]};
    border-color: ${({ theme }) => theme.colors.red[500]};
    color: ${({ theme }) => theme.colors.red[500]};

    ${InfoIcon} {
      color: ${({ theme }) => theme.colors.red[500]};
    }
  }

  a {
    color: ${({ theme }) => theme.colors.green[500]};
  }
`;

export default FormStatus;
