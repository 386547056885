// @ts-nocheck
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import BrainwaveList from '../components/BrainwaveList';
import Flex from '../components/Flex';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import AdminHome from './AdminHome';
import AdminsList from './AdminList';
import ReportList from './ReportList';
import ReportResponse from './ReportResponse';
import TestList from './TestList';

const AdminDashboard = () => {
  return (
    <Container>
      <Navbar />
      <Sidebar />
      <Content>
        <Switch>
          <Route exact path='/' component={AdminHome} />
          <Route exact path='/admin' component={AdminsList} />
          <Route path='/admin/:userId/test' component={TestList} />
          <Route path='/report/:testId' exact component={ReportList} />
          <Route
            path='/report/:testId/:reportId'
            exact
            component={ReportResponse}
          />
          <Route path='/brainwave' component={BrainwaveList} />
          <Route path='*'>
            <Redirect to='/404' />
          </Route>
        </Switch>
      </Content>
    </Container>
  );
};

export default AdminDashboard;

const Container = styled(Flex)`
  height: 100vh;
`;

const Content = styled(Flex)`
  width: calc(100% - 26rem);
  margin-top: 6rem;
  margin-left: 22rem;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.grey[50]};
`;
