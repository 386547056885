import React, { useEffect, useState } from "react";
import CloseIcon from "../../../../assets/cancel.png";

const UniversalQuizMetaForm = (props: any) => {
  const [s, setS] = useState(props.quizMeta.strengths || []);
  const [sCurr, setSCurr] = useState("");
  const [w, setW] = useState(props.quizMeta.weaknesses || []);
  const [wCurr, setWCurr] = useState("");
  const [p, setP] = useState(props.quizMeta.potential_strengths || []);
  const [pCurr, setPCurr] = useState("");
  const [t, setT] = useState(props.quizMeta.tips_to_improve || []);
  const [tCurr, setTCurr] = useState("");

  const universalMetaFormHeading = [
    {
      name: "Strengths",
      state: s,
      setState: setS,
      currV: sCurr,
      setCurrV: setSCurr,
    },
    {
      name: "Weakness",
      state: w,
      setState: setW,
      currV: wCurr,
      setCurrV: setWCurr,
    },
    {
      name: "Potential Strength",
      state: p,
      setState: setP,
      currV: pCurr,
      setCurrV: setPCurr,
    },
    {
      name: "Tips to Improve",
      state: t,
      setState: setT,
      currV: tCurr,
      setCurrV: setTCurr,
    },
  ];

  useEffect(() => {
    let newFormValues = { ...props.quizMeta };
    // @ts-ignore: Object is possibly 'null'.
    newFormValues['strengths'] = s;
    newFormValues['weaknesses'] = w;
    newFormValues['potential_strengths'] = p;
    newFormValues['tips_to_improve'] = t;

    props.onChange(newFormValues);
  }, [s, w, p, t]);

  return (
    <div>
      <label>Quiz Meta</label>
      {universalMetaFormHeading.map((element: any, index: any) => (
        <div className="form-inline" key={index}>
          <br />
          <div>{element.name}</div>
          <div style={{ border: "3px solid black", padding: "10px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: "15px",
                alignSelf: "center",
              }}
            >
              <input
                type="text"
                name={element.name}
                value={element.currV}
                onChange={(e) => element.setCurrV(e.target.value)}
              />
              <div
                onClick={() => {
                  element.setState((prev) => [...prev, element.currV]);
                  element.setCurrV("");
                }}
                style={{
                  cursor: "pointer",
                  backgroundColor: "grey",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                Add
              </div>
            </div>
            {element.state.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "inline-block",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "lightgrey",
                    padding: "5px 10px 5px 10px",
                    margin: "5px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    justifyContent: "space-between",
                    borderRadius: "10px",
                    alignItems: "center",
                  }}
                >
                  <div>{item}</div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      element.setState(
                        element.state.filter((v: string) => v !== item)
                      )
                    }
                  >
                    <img
                      src={CloseIcon}
                      alt="Add New Test"
                      style={{ width: "15px", height: "15px" }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default UniversalQuizMetaForm;
