import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT } from '../utils/constants';
import { APIError, PaginationData, Question } from '../utils/types';

const useTestQuestions = (testId: string, page = 1) => {
  const fetchTestQuestions = async (page: number, testId: string) => {
    try {
      const currentUrlParams = new URLSearchParams(window.location.search);
      let  refresh_token = currentUrlParams.get("refresh_token")
      const {
        data: { data },
      } = await axios.get(`${API_ENDPOINT}test/${testId}/question`, {
        headers:{"refresh_token":refresh_token||''},
        params: { page },
      });
      return data;
    } catch (error) {
      throw error.response.data;
    }
  };

  return useQuery<
    { questions: Question[]; pagination_data: PaginationData },
    AxiosResponse<APIError>
  >(['testQuestions', testId, page], () => fetchTestQuestions(page, testId), {
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export default useTestQuestions;
