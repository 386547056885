import React, { useEffect, useState } from 'react'
import { PieChart } from 'react-minimal-pie-chart';
import styled from "styled-components";
import './redutechchart.css'
import styles from "../../../styles/collaborators.module.css";
import { CountContainer } from '../CountCard';
import { number } from '@amcharts/amcharts4/core';

export default function RedutechChart({ reportMeta }: any) {

    const [score1, setScore1] = useState(0);
    const [score2, setScore2] = useState(0);
    const [score3, setScore3] = useState(0);

    const field = [
        { title: `${score1}%`, value: score1, color: '#E38627', },
        { title: `${score2}%`, value: score2, color: '#C13C37', },
        { title: `${score3}%`, value: score3, color: '#6A2135', },
    ]

    const data = {
        data1: [
            {
                title: "Examine large data sets!",
                content: "Understand the process of examining large and complex data sets to uncover hidden patterns!"
            },
            {
                title: "Become an Irreplaceable Resource!",
                content: "Through a thorough understanding of correlations, market trends, and customer preferences, become an irreplaceable resource for organizations!"
            },
            {
                title: "Discover End Usage!",
                content: "Identify new revenue opportunities, reduce costs, improve risk management, and increase productivity, the possibilities are endless!"
            },
        ],
        data2: [
            {
                title: "Create Computing Services!",
                content: "Learn to create resources that allow users to access computing services across the internet!"
            }, {
                title: "Become an Irreplaceable Resource!",
                content: "Enable individuals, businesses, and organizations to deploy and scale their IT infrastructure and applications quickly and easily without investing in expensive hardware and software and become an irreplaceable resource!"
            }, {
                title: "Discover End Usage!",
                content: "Increase automation, AI integration, IoT connectivity, information storage, and remote access, the possibilities are endless!"
            },
        ],
        data3: [
            {
                title: "Safeguard Information!",
                content: "Learn the process of protecting computer systems, networks, and sensitive information from unauthorized access, use, disclosure, disruption, modification, or destruction!"
            }, {
                title: "Become an Irreplaceable Resource!",
                content: "Master the skill of continuous monitoring, updating, and improvement to stay ahead of evolving cyber threats and become an irreplaceable resource!"
            }, {
                title: "Discover End Usage!",
                content: "Help organizations prevent cyber threats, malware, phishing attacks, ransomware, and cyber espionage, the possibilities are endless!"
            },
        ]
    }
    const [dataType, setDataType] = useState<any>(data?.data1);

    useEffect(() => {
        if (reportMeta?.report_metadata?.recommended_course === "CC") {
            setDataType(data?.data2)
        } else if (reportMeta?.report_metadata?.recommended_course === "CS") {
            setDataType(data?.data3)
        } else if (reportMeta?.report_metadata?.recommended_course === "BDA") {
            setDataType(data?.data1)
        }
    }, [reportMeta?.report_metadata?.recommended_course])

    useEffect(() => {
        const sum = reportMeta?.report_metadata?.score['BDA'] + reportMeta?.report_metadata?.score['CC'] + reportMeta?.report_metadata?.score['CS']
        const score1 = (reportMeta?.report_metadata?.score['BDA'] / sum) * 100
        const finalScore1 = Number(score1.toFixed(2))
        // const d = Number(finalScore1.toFixed(2))
        const score2 = (reportMeta?.report_metadata?.score['CC'] / sum) * 100
        const finalScore2 = Number(score2.toFixed(2))
        const score3 = (reportMeta?.report_metadata?.score['CS'] / sum) * 100
        const finalScore3 = Number(score3.toFixed(2))
        console.log(finalScore1, finalScore2, finalScore3)
        setScore1(finalScore1)
        setScore2(finalScore2)
        setScore3(finalScore3)
    }
        , [reportMeta?.report_metadata?.score])


    return (

        <div className="three-month-section">
            <div className="container-md">
                <div className="page-title2 pb-5">
                    <h3 style={{ color: "#8B292A" }}>Get Certificate Signed By ISB Alumi</h3>
                </div>
                <Divider>
                    <hr />
                </Divider>
                <div className="three-month-grid">
                    <div className="three-month-grid-items">
                        <Small>
                            <PieChart
                                data={field}
                                radius={50}

                                lineWidth={50}
                                label={(field) => field.dataEntry.title}
                                labelPosition={70}
                                labelStyle={{
                                    fontSize: "8px",
                                    // fontStyle:"GrayText",
                                    fontWeight: "800",
                                }}
                            />

                        </Small>
                        <div className="label_for_pie_chart_container">
                            <div className='rect_color_title_container'>
                                <div className='rectangle_color_box' style={{ backgroundColor: "#E38627" }} />
                                <p>Cloud computing </p>
                            </div>
                            <div className='rect_color_title_container'>
                                <div className='rectangle_color_box' style={{ backgroundColor: "#C13C37" }} />
                                <p>Big data analysis</p>
                            </div>
                            <div className='rect_color_title_container'>
                                <div className='rectangle_color_box' style={{ backgroundColor: "#6A2135" }} />
                                <p>Cyber security</p>
                            </div>
                        </div>

                    </div>
                    <div className="three-month-grid-items">
                        <div className="all-text-alignment">
                            {dataType?.map((value, index) => {
                                return (
                                    <div>
                                        <h4>{value?.title}</h4>
                                        <h6>
                                            {value?.content}
                                        </h6>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Small = styled.div`
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    width: 400px;

    @media(max-width:767px){
        width:90%;
        diplay:flex;
        justify-content: center;
    }
  `
const CourseCointainer = styled.div`
display:flex;
flex-direction:column;
`
const SubHeading = styled.h4`
     color: #3D3636;
     font-weight: 600;
     font-size: 24px;
     text-align:center;
     @media (max-width: 768px){
       font-size:14px;
     }
`;
const PaddingDiv = styled.div`
   padding: 0px 0px 120px;
`;

const Divider = styled.div`
   width:100%;
   display:flex;
   justify-content:center;
   align-items: center;
   
   hr{
    width: 150px;
    height: 4px;
    border-radius: 50%;
    background: #8B292A;
    opacity: 1;
    border: none;
    margin-top:20px;
    margin-bottom:40px;
   }
`;
