import React, { useEffect, useState } from "react";
import HeaderMain from "../../components/Header section/HeaderMain";
import AboutSection from "../../components/About section/AboutSection";
import WorkMain from "../../components/Work section/WorkMain";
import LeadSquaredForm from "../../components/forms/LeadSquaredForm";
import CustomLoader from "../../../assets/customLoader.gif";
import { useLocation } from "react-router-dom";
import {
  IGNITER_REDIRECT_URL_0,
  IGNITER_REDIRECT_URL_1,
  IGNITER_REDIRECT_URL_2,
  IGNITER_REPORT_CTA_0,
  IGNITER_REPORT_CTA_1,
  IGNITER_REPORT_CTA_2,
} from "../../../utils/constants";

function Home(props: any) {
  const [reportData, setReportData] = useState({});
  const quiz_name = props.reportData.quiz_name;

  useEffect(() => {
    if (props?.reportData?.content) {
      setReportData(props.reportData.content);
    }
  }, [props]);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const [CTADetails, setCTADetails] = useState({});
  const query = useQuery();

  useEffect(() => {
    setCTADetails(URLProvider(query?.get("cta_type")));
  }, []);

  const URLProvider = (cta_type) => {
    switch (cta_type) {
      case "0":
        return {
          url: IGNITER_REDIRECT_URL_0,
          text: IGNITER_REPORT_CTA_0,
        };
      case "1":
        return {
          url: IGNITER_REDIRECT_URL_1,
          text: IGNITER_REPORT_CTA_1,
        };
      case "2":
        return {
          url: IGNITER_REDIRECT_URL_2,
          text: IGNITER_REPORT_CTA_2,
        };
      default:
        return {
          url: IGNITER_REDIRECT_URL_0,
          text: IGNITER_REPORT_CTA_0,
        };
    }
  };

  return (
    <div>
      {props.reportData.status === 200 || (props.reportData.status === 428 && props.isUserLoggedIn) ?
        <>
        <HeaderMain />

        <AboutSection reportData={reportData} CTADetails={CTADetails} />

        <WorkMain CTADetails={CTADetails} />
        </>
      : props.reportData.status === 428 ? 
        <div id="lead-squared-form-wrapper" style={{margin: "0 auto", padding: "20px"}}>
          <LeadSquaredForm 
          source={`${quiz_name} quiz page`}
          product={quiz_name}
          />
        </div> 
      : 
      props.reportData.status === 202 ? 
        <div className="invalid-report-id">
          <img src={CustomLoader} />  
        </div>
      :
        <div className="invalid-report-id">
          <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" alt="loader_gif" />
        </div>
      }
    </div>   
  );
}

export default Home;
