// @ts-nocheck
import React, { useEffect, useState } from "react";
import StrideImage from "../../../assets/images/stride.svg";
import ReactApexChart from "react-apexcharts";
import { object } from "@amcharts/amcharts4/core";
export default function HeroSection(props: any) {
  const { ApptitudeData } = props;
  const [apptitudeDetails, setApptitudeDetails] = useState([]);

  useEffect(() => {
    let item = Object.entries(ApptitudeData?.dimensions);
    let orderData: any = item
      ?.map((obj: any) => {
        return { ...obj[1], header: obj[0] };
      })
      .sort((a, b) => (a.percentage < b.percentage ? 1 : -1));
    setApptitudeDetails(orderData);
    console.log("orderData", orderData);
  }, []);

  var series: any = apptitudeDetails?.map((object: any) => object.relative_percentage);
  var options: any = {
    chart: {
      type: "polarArea",
    },
    labels: apptitudeDetails?.map((object: any) => object?.header.charAt(0).toUpperCase() + object?.header.substring(1)),
    dataLabels: {
      enabled: false,
      textAnchor: "start",
    },
   
    yaxis: {
      show: false,
    },
    title: {
      text: `${ApptitudeData?.percentage.toFixed(0)}%`,
      align: 'right',
      margin: 0,
      offsetX: -100,
      offsetY: 270,
      floating: false,
      style: {
        fontSize:  '40px',
        fontWeight:  700,
        fontFamily:  'Poppins',
        color:  '#352958'
      },
    },
    subtitle: {
      text: "SCORE" ,
      align: 'right',
      margin: 0,
      offsetX: -80,
      offsetY: 330,
      floating: false,
      style: {
        fontSize:  '40px',
        fontWeight:  400,
        fontFamily:  "Poppins",
        color:  '#A3A2B4'
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
    },
    stroke: {
      colors: ["#fff"],
    },
    fill: {
      type: "solid",
      opacity: 0.9,
      
    },
    legend: {
      show: true,
      fontSize: "16px",
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "#352958",
      width: 250,
      itemMargin: {
        horizontal: -10,
        vertical: 5,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 320,
            
          },
          legend: {
            position: "bottom",
            horizontalAlign: 'left', 
            itemMargin: {
              horizontal: -5,
              vertical: 3,
            },
            markers: {
              offsetX: -2,
            }
          },
          title: {
            text:  `${ApptitudeData?.percentage.toFixed(0)}%`,
            align: 'right',
            margin: 0,
            offsetX: -30,
            offsetY: 380,
            floating: false,
            style: {
              fontSize:  '20px',
              fontWeight:  700,
              fontFamily:  'Poppins',
              color:  '#352958'
            },
          },
          subtitle: {
            text: "SCORE" ,
            align: 'right',
            margin: 0,
            offsetX: -20,
            offsetY: 410,
            floating: false,
            style: {
              fontSize:  '20px',
              fontWeight:  400,
              fontFamily:  "Poppins",
              color:  '#A3A2B4'
            },
          },
          dataLabels: {
            enabled: true,
          }        
        },
      },
    ],
  };

  return (
    <div className="report-hero-section-alignment">
      <div className="image-center-alignment">
        <img src={StrideImage} alt="StrideImage" />
      </div>
      <div className="container-md">
        <div className="report-hero-grid">
          <div className="report-hero-grid-items">
            <h1>Benchmark yourself with Management Skills</h1>
            <p>
              MAP Assessment is a comprehensive aptitude test that is used to evaluate and hire qualified people with managerial potential. Using this
              managerial aptitude assessment, you may assess your talents and personality to see if whether you are a good fit for the managerial
              positions you are applying for or not.
            </p>
            
          </div>
          <div className="report-hero-grid-items">
            <div className="chart-image">
              <ReactApexChart options={options} series={series} type="polarArea" width="650px" height="700px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
