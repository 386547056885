import React from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../styles/animations';
import { LOCALHOST, STRIDE_ID } from '../../utils/constants';
import Button from '../components/Button';
import { HeadingSeparator } from '../components/Separator';
import { Heading1, Text1Css } from '../components/Typography';

const LoginPage = () => {

  const redirectToLogin = () => {
    window.open(`${STRIDE_ID}login?redirect_to=${window.location.href}`, '_self');
  };

  return (
    <Container>
      <Heading1>Welcome to Stride Ahead Assessment Platform!</Heading1>
      <HeadingSeparator />
      <Description>Sign in using StrideID to access</Description>
      <Description>Stride Ahead Assessment platform.</Description>

      <StyledButton type='submit' onClick={redirectToLogin}>
        Sign in using StrideID
      </StyledButton>
    </Container>
  );
};

export default LoginPage;

const Container = styled.div`
  width: 45rem;
  animation: ${fadeIn} 0.5s;
`;

const Description = styled.h2`
  ${Text1Css}
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.grey[500]};
`;

const StyledButton = styled(Button)`
  margin-top: 2rem;
`;
