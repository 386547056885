import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Device from '../../../assets/analysis/device.png';
import DeviceFull from '../../../assets/analysis/device-full.png';
import useTest from '../../../hooks/useTest';
import Button from '../../components/Button';
import Flex from '../../components/Flex';
import { useMedia } from '../../../hooks/useMedia';

const TestDetails: FC<{ testId: string }> = ({ testId }) => {
	const history = useHistory();

	const { data: test } = useTest(testId);

	const renderDeviceImage = useMedia(
		['(max-width: 1024px)'],
		[<img src={DeviceFull} alt='Device' />],
		<img src={Device} alt='Device' />,
	);
	return (
		<TestConatiner>
			<DetailContainer column flex={1} justify='space-between'>
				<h2>
					{test?.test_display_label
						? test?.test_display_label
						: test?.test_name}
				</h2>
				<span>{test?.description}</span>
				<StyledButton onClick={() => history.push(`/test/${testId}`)}>
					Take the test
				</StyledButton>
			</DetailContainer>
			<Image column flex={1}>
				{renderDeviceImage}
			</Image>
		</TestConatiner>
	);
};

export default TestDetails;

const TestConatiner = styled(Flex)`
	width: 100%;
	background: ${({ theme }) => theme.colors.blue[500]};
	padding: 0 6.6rem;
	margin: 2rem 0;
	border-radius: 1rem;
	color: ${({ theme }) => theme.colors.white};
	line-height: 150%;
	font-weight: normal;
	font-size: 1.8rem;
	h2 {
		font-weight: 600;
		font-size: 3.6rem;
		margin-top: 4.6rem !important;
		line-height: 150%;
		margin-bottom: 0;
		color: #ffffff;
	}
	span {
		margin: 2.4rem 0;
	}
	img {
		float: right;
	}

	@media only screen and (max-width: 1024px) {
		flex-direction: column-reverse;
		padding: 1rem;
	}
`;

const DetailContainer = styled(Flex)`
	@media only screen and (max-width: 1024px) {
		padding: 0 1rem 3rem 1rem;
		h2 {
			margin-top: 0;
			line-height: 150%;
		}
	}
`;

const Image = styled(Flex)`
	@media only screen and (max-width: 1024px) {
		width: 70%;
		margin: auto;
	}
`;

const StyledButton = styled(Button)`
	background: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.blue[500]};
	margin-bottom: 4.6rem;
	width: max-content;
	padding: 1.6rem 4.6rem;
	@media only screen and (max-width: 1024px) {
		margin: auto;
	}
`;
