import React, { useEffect, useMemo, useState } from "react";
import Footer from ".././Footer";
import styled from "styled-components";
import Flex from "../../components/Flex";
import Navbar from "../../components/Navbar";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import ErrorFallback from ".././ErrorFallback";
import { useAnalysisReport } from "../../../hooks/useAnalysisReport";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './subjectwise.report.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CalculatingPage from '../CalculatingPage';
import "./askiitians.report.css";
import graphIcon from '../../../assets/images/aptitute_icon/GraphUp.svg'
import LowMeterIcon from '../../../assets/images/aptitute_icon/meterLow.svg'
import MediumMeterIcon from '../../../assets/images/aptitute_icon/meterMedium.png'
import HighMeterIcon from '../../../assets/images/aptitute_icon/meterHigh.png'
import percentageIcon from '../../../assets/images/aptitute_icon/percent.svg'
import cupIcon from '../../../assets/images/aptitute_icon/cup.svg'
import { RED_COLOR, YELLOW_COLOR, GREEN_COLOR } from "../../../utils/constants";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { number } from "@amcharts/amcharts4/core";


const AskIITiansReportPage = ({ reportData2, test, testId, isFetched, isSuccess2, isLoading2 }: any) => {
    const { token } = useParams<{ token: string }>();
    const customLogo = test?.logo_image;

    console.log(test)
    const [auth, setAuth] = useState<boolean>(false);
    const configs = {
        red: RED_COLOR,
        yellow: YELLOW_COLOR,
        green: GREEN_COLOR
    }
    // console.log(configs)
    useEffect(() => {
        if (!reportData2) {
            setAuth(true);
        }
    }, [])

    const [active, setActive] = useState(0);
    const first = 1
    const second = 2
    const third = 3
    const fourth = 4

    const { data: reportMeta, isSuccess, isLoading } = useAnalysisReport(token, false, 5, auth);
    const reportData = reportData2 ? reportData2 : reportMeta?.report_metadata;
    const overall: any = useMemo(() => {
        if (reportData?.score) {
            return reportData?.score > 75 ? "High" : reportData?.score > 50 ? "Moderate" : "Low";
        }
        return "Low";
    }, [reportData]);
    const aptitude: string = useMemo(() => {
        if (reportData?.aptitude_score) {
            let a_score = reportData?.subjects.a.percentage;
            return a_score;
        }
        return 0..toString();
    }, [reportData]);
    const subjects = useMemo(() => {
        // @ts-ignore
        if (reportData?.subjects) {
            // @ts-ignore
            return reportData?.subjects;
        }
        return {};
    }, [reportData]);

    const subjectwise_percent = useMemo(() => {
        // @ts-ignore
        if (reportData?.subjects) {
            // @ts-ignore
            return reportData?.subjects;
        }
        return {};
    }, [reportData]);

    const userSub = useMemo(()=>{
        const sub = reportData?.subjects
        let val:any = {};
        Object.values(sub).map((sub_obj:any,index)=>{
            console.log(sub_obj)
            if (sub_obj?.total_count === 0){
                val = sub_obj
            }
        }
        )
        return val.title
    },[reportData])




    const handleClick = (data:number) => {
        if (active) {
        setActive(0);
          return;
        }
        setActive(data);
      };


    return (
        <Container column>
            {!isLoading2 || !isLoading ? (
                isSuccess2 || isSuccess ? (
                    reportData ? (
                        <React.Fragment>
                            {
                                reportData2 ? null :
                                    <Navbar
                                        authenticate={false}
                                        homepage="https://strideahead.in"
                                        customLogo={customLogo}
                                    />
                            }
                            <nav className="navbar navbar-expand-lg"
                                style={{ marginTop: 55, background: "rgba(26, 100, 214, 0.08)" }}>
                                <div className="container-fluid justify-content-center mt-4 mb-3">
                                    <div className="d-block text-center">
                                        <h3 className="fs-1 fw-bolder">{test?.test_display_label ? test?.test_display_label : test?.test_name}</h3>
                                    </div>
                                </div>
                            </nav>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 student-block">
                                        <div className="container">
                                            <div className="row flex-column">
                                                <div className="col-lg-6 col-12 student-info">
                                                    <h3>
                                                        <i className="fas fa-graduation-cap"></i> Student Info.
                                                    </h3>
                                                    <p className="name">{test?.name ? test?.name : reportMeta?.name}</p>
                                                    <p className="email">{test?.email ? test?.email : reportMeta?.email}</p>
                                                </div>
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-6">
                                                            <div className="card ">
                                                                <div className="card-body">
                                                                    <p className="head">Overall</p>
                                                                    <div className="icon">

                                                                        <div className="show_desc"><BsFillQuestionCircleFill onMouseEnter={() => handleClick(first)} onMouseLeave={() => handleClick(first)}  onClick={() => handleClick(first)}  className="info-icon" /></div>
                                                                        {active === 1?<p style={{color:"#fff"}} >This is indicative of the overall performance of the student as ‘Low’, ‘Moderate’ or ‘High’</p>:
                                                                        <div>
                                                                                        <p className="value">{overall}</p>
                                                                                        <img src={reportData?.score
                                                                                            < 50 ? LowMeterIcon : reportData?.score
                                                                                                < 75 ? MediumMeterIcon : HighMeterIcon} /></div>
                                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-6">
                                                            <div className="card ">
                                                                <div className="card-body">
                                                                    <p className="head">Score</p>
                                                                    <div className="icon">
                                                                        <div className="show_desc"><BsFillQuestionCircleFill  onMouseEnter={() => handleClick(second)} onMouseLeave={() => handleClick(second)}  onClick={() => handleClick(second)}  className="info-icon" /></div>
                                                                        {active === 2?<p style={{color:"#fff"}} >
                                                                            {userSub==="Mathematics"?<>The percentage score of the student in Physics, Chemistry and Biology</>:userSub==="Biology"?<>The percentage score of the student in Physics, Chemistry and Mathematics</>:<>The percentage score of the student in Physics, Chemistry, Mathematics and Biology</>}
                                                                            </p>:
                                                                        <div>
                                                                    <p className="value">{reportData?.score}%</p>
                                                                        <img src={graphIcon} />
                                                                    </div>
                                                                    }


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-6">
                                                            <div className="card ">
                                                                <div className="card-body">
                                                                    <p className="head">Potential Score</p>
                                                                    <div className="icon">
                                                                        <div className="show_desc"><BsFillQuestionCircleFill  onMouseEnter={() => handleClick(third)}  onMouseLeave={() => handleClick(third)} className="info-icon" /></div>
                                                                        {active === 3?<p style={{color:"#fff"}} >The achievable score by the student as per the aptitude.</p>:
                                                                        <div>
                                                                    <p className="value">{reportData?.potential_score}%</p>
                                                                    <img src={percentageIcon} />

                                                                    </div>
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-6">
                                                            <div className="card aptitude ">
                                                                <div className="card-body">
                                                                    <p className="head">Aptitude</p>
                                                                    <div className="icon">
                                                                        <div className="show_desc"><BsFillQuestionCircleFill  onMouseEnter={() => handleClick(fourth)} onMouseLeave={() => handleClick(fourth)}  onClick={() => handleClick(fourth)}  className="info-icon" /></div>
                                                                        {active === 4?<p style={{color:"#fff"}} >Percentage score in aptitude and logical reasoning section of the test. Indicates how easily a student can grasp new concepts.</p>:
                                                                        <div>
                                                                    <p className="value">{aptitude} %</p>
                                                                    <img src={cupIcon} />

                                                                    </div>
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 report-block">
                                <div className="container">
                                    <div className="col-lg-12 col-6 mb-5">
                                        <div className="card ">
                                            <div className="card-body">
                                                <h3 className="text-center">Score Card</h3>
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        {Object.keys(subjects)?.map((sk, index) => {
                                                            const subject = subjects[sk];
                                                            const percent = subjectwise_percent[sk];
                                                            console.log(percent)
                                                            if (percent?.total_count === 0||percent?.title === "Aptitude" ){
                                                                return
                                                            }
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="subject">{subject.title}</td>
                                                                    <td className="score">
                                                                        <div className="progress">
                                                                            <div
                                                                                className="progress-bar"
                                                                                role="progressbar"
                                                                                style={{
                                                                                    width: `${percent?.percentage === 0 ? 1 : percent?.percentage}%`,
                                                                                    backgroundColor:
                                                                                        percent?.percentage < 50 ?
                                                                                            configs?.red : percent?.percentage < 75 ?
                                                                                                configs?.yellow : configs?.green
                                                                                }}
                                                                            ></div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="remarks">
                                                                        <span className="badge"
                                                                            style={{
                                                                                backgroundColor: percent?.percentage
                                                                                    < 50 ? configs?.red : percent?.percentage
                                                                                        < 75 ? configs?.yellow : configs?.green
                                                                            }}>
                                                                            {percent?.percentage
                                                                                < 50 ? 'Low' : percent?.percentage
                                                                                    < 75 ? 'Moderate' : 'High'}</span>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h3 className="text-center">Score Card</h3>
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            {Object.keys(subjects)?.map((sk, index) => {
                                                                const subject = subjects[sk];
                                                                const percent = subjectwise_percent[sk];
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className="subject">{subject.title}</td>
                                                                        <td className="score">
                                                                            <div className="progress">
                                                                                <div
                                                                                    className="progress-bar"
                                                                                    role="progressbar"
                                                                                    style={{
                                                                                        width: `${percent?.percentage}%`,
                                                                                        backgroundColor:
                                                                                            percent?.percentage < 50 ?
                                                                                                configs?.red : percent?.percentage < 75 ?
                                                                                                    configs?.yellow : configs?.green
                                                                                    }}
                                                                                ></div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="remarks">
                                                                            <span className="badge"
                                                                                style={{
                                                                                    backgroundColor: percent?.percentage
                                                                                        < 50 ? configs?.red : percent?.percentage
                                                                                            < 75 ? configs?.yellow : configs?.green
                                                                                }}>
                                                                                {percent?.percentage
                                                                                    < 50 ? 'Low' : percent?.percentage
                                                                                        < 75 ? 'Moderate' : 'High'}</span>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {/* </div>
                            </div> */}
                            {reportData2 ? null : <Footer />}
                        </React.Fragment>
                    ) : // null)
                        isFetched ? (
                            // <Loading/>
                            <CalculatingPage />
                        ) : null
                ) : (
                    <ErrorFallback
                        error="500"
                        showNavbar={false}
                        url={`/test/${testId}`}
                    />
                )
            ) : // null)}
                isFetched ? (
                    <Loading />
                ) : null}
        </Container>
    );
};
export default AskIITiansReportPage;

const Container = styled(Flex)`
  width: 100%;
  // background: ${({ theme }) => theme.colors.blue[50]};
  font-family: Poppins;
  @media only screen and (max-width: 1024px) {
    // background: ${({ theme }) => theme.colors.grey[50]};
    padding: 1.2rem;
  }
`;

