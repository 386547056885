import React from "react";

export default function() {
  return (
    <svg
      viewBox="64 64 896 896"
      width="1em"
      height="1em"
      fill="currentColor"
    >
      <defs>
        <style type="text/css"></style>
      </defs>
      <path
        d="M506.892 81.849c-241.603 0-439.276 197.674-439.276 439.276 0 241.598 197.674 439.271 439.276 439.271 241.598 0 439.269-197.674 439.269-439.271 0-241.603-197.674-439.276-439.269-439.276z m0 834.618c-219.637 0-395.348-175.709-395.348-395.342 0-219.637 175.709-395.348 395.348-395.348 219.633 0 395.342 175.709 395.342 395.348-0.001 219.633-175.711 395.342-395.342 395.342z m0 0z"
        p-id="2146"
        fill=""
      ></path>
      <path
        d="M287.253 521.124c0 28.751 5.714 57.488 16.719 84.049 11.002 26.562 27.28 50.929 47.611 71.255 20.331 20.331 44.69 36.609 71.255 47.612 26.559 11.006 55.301 16.718 84.053 16.718 28.751 0 57.488-5.714 84.049-16.718 26.562-11.003 50.929-27.282 71.255-47.612 20.331-20.327 36.609-44.69 47.612-71.255 11.006-26.56 16.718-55.299 16.718-84.049 0-28.752-5.714-57.494-16.718-84.053-11.003-26.563-27.282-50.925-47.612-71.255-20.327-20.331-44.69-36.609-71.255-47.611-26.56-11.007-55.299-16.719-84.049-16.719-28.752 0-57.494 5.714-84.053 16.719-26.563 11.002-50.925 27.28-71.255 47.611-20.331 20.331-36.609 44.69-47.611 71.255-11.007 26.559-16.719 55.301-16.719 84.053z m0 0z"
        p-id="2147"
        fill=""
      ></path>
    </svg>
  );
}
