import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useOutsideClick from '../../hooks/useOutsideClick';
import { useCurrentProfile } from '../../hooks/useProfile';
import { API_ENDPOINT, DICEBAR_URL, STRIDE_ID } from '../../utils/constants';
import { DropdownItem, DropdownMenu } from './DropdownMenu';

const Avatar = () => {
  const queryClient = useQueryClient();

  const history = useHistory();

  const [showDropdown, setShowDropdown] = useState(false);
  const avatarRef = useRef(null);

  const { data: currentProfile } = useCurrentProfile();

  useOutsideClick(avatarRef, () => setShowDropdown(false));

  const redirectToAccount = () => {
    window.open(`${STRIDE_ID}account`);
  };

  const defaultImage = () => {
    return currentProfile && DICEBAR_URL + currentProfile?.name + '.svg';
  };

  const handleLogout = async () => {
    try {
      await axios.get(API_ENDPOINT + 'logout');
      queryClient.invalidateQueries(['profile', 'current']);
      history.go(0) 
    } catch (error) {
      alert('Something went wrong');
    }
  };

  return (
    <Container ref={avatarRef}>
      <img
        src={currentProfile?.profile_pic || defaultImage()}
        alt={currentProfile?.name}
        onClick={() => setShowDropdown(!showDropdown)}
      />
      {showDropdown && (
        <DropdownMenu column right='0' top='5rem'>
          <DropdownItem
            onClick={() => {
              redirectToAccount();
              setShowDropdown(false);
            }}
          >
            Account
          </DropdownItem>
          <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
        </DropdownMenu>
      )}
    </Container>
  );
};

export default Avatar;

const Container = styled.div`
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  margin-left: auto;
  margin-right: 2rem;
  cursor: pointer;
  margin-top:1rem;

  img {
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
  }
`;
