import react, {useEffect, useState} from 'react';
import styled from "styled-components";
import React from "react";
import User from '../../../assets/user.png'

const RecommendSection = ({reportMeta}: any) => {
    const [dataType, setDataType] = useState<any>();
    const data = {
        data1: [
            {
                title: "Pattern Recognition",
                content: "Seeking meaningful patterns and connections in data to inform decisions."
            }, {
                title: "Technical proficiency",
                content: " Comfortable with coding, data manipulation, and statistical analysis tools."
            }, {
                title: "Analytical Mindset",
                content: "Ability to identify business challenges and use data to develop solutions."
            },
        ],
        data2: [
            {
                title: "Learning Agility",
                content: "Embracing the ability to quickly adapt and scale to changing business needs."
            }, {
                title: "Technological acumen",
                content: " Familiarity with cloud platforms, infrastructure, and services to support operations."
            }, {
                title: "Innovation and Novelty",
                content: " Pursuing new and efficient ways to leverage cloud technology for competitive advantage."
            },
        ],
        data3: [
            {
                title: "Attention to detail",
                content: "A keen eye for identifying vulnerabilities and developing robust security measures."
            }, {
                title: "Problem-solving mindset",
                content: "Ability to identify and solve complex security challenges through software."
            }, {
                title: "Technical proficiency",
                content: "Strong knowledge of programming languages and network security protocols to develop effective solutions."
            },
        ]
    }

    useEffect(() => {
        if (reportMeta?.report_metadata?.recommended_course === "CC") {
            setDataType(data?.data2)
        } else if (reportMeta?.report_metadata?.recommended_course === "CS") {
            setDataType(data?.data3)
        } else if (reportMeta?.report_metadata?.recommended_course === "BDA") {
            setDataType(data?.data1)
        }
    }, [reportMeta?.report_metadata?.recommended_course])


    return (
        <MainDiv className="container-md">
            <Heading1 className="d-flex justify-content-center">
                Based on the analysis of your preferences, the following features of your personality steal the
                limelight
            </Heading1>
            {/*<SubHeading className="d-flex justify-content-center">*/}
            {/*    Based on your answers, we see that you are*/}
            {/*</SubHeading>*/}
            <Divider>
                <hr/>
            </Divider>
            <div className="row gap-lg-0 gap-4">
                {dataType?.map((value, index) => {
                    return (
                        <div className="col-lg-4 col-12">
                            <Adaptive className="d-flex align-items-center">
                                <Img className="d-flex justify-content-center align-items-center">
                                    <img src={User}/>
                                </Img>
                                <Content>
                                    <h5>{value?.title}</h5>
                                    <p>
                                        {value?.content}
                                    </p>
                                </Content>
                            </Adaptive>
                        </div>
                    )
                })}
            </div>
        </MainDiv>
    )
}

export default RecommendSection;

const MainDiv = styled.div`
   margin-bottom: 5rem !important;
`;

const Heading1 = styled.h4`
     color: #8B292A;
     font-weight: 700;
     font-size: 48px;
     line-height:160%;
     margin-top:100px;
     margin-bottom:30px;
     text-align:center;
     @media (max-width: 768px){
       font-size:26px;
     }
`;

const SubHeading = styled.h4`
     color: #3D3636;
     font-weight: 600;
     font-size: 24px;
     text-align:center;
     @media (max-width: 768px){
       font-size:14px;
     }
`;

const Divider = styled.div`
   width:100%;
   display:flex;
   justify-content:center;
   align-items: center;
   
   hr{
    width: 150px;
    height: 4px;
    border-radius: 50%;
    background: #8B292A;
    opacity: 1;
    border: none;
    margin-top:20px;
    margin-bottom:40px;
   }
`;

const Adaptive = styled.div`
     background: #FFFFFF;
     box-shadow: 0px 15px 56px rgba(139, 41, 42, 0.12);
     border-radius: 20px;
     padding:20px;
     min-height:185px;
      @media (max-width: 768px){    
       padding:20px;
     }
`;

const Img = styled.div`
    background: linear-gradient(90.67deg, #8B292A 24.23%, #CB5657 127.76%);
    border-radius: 100%;
    width: 100px;
    height: 100px;
    @media (max-width: 768px){
       width: 80px;
       height: 80px;
     }
`;

const Content = styled.div`
    width: 72%;
    text-align: start;
    margin-left:15px;
    @media(max-width:768px){
      width:67%;
      margin-left:12px;
    }
    h5{
     font-weight: 600;
     font-size: 22px;
     color: #3D3636;
     margin:0px;
     @media (max-width: 768px){
      font-size: 18px;
     }
    }
    p{
    margin-top:6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #281E42;
    @media (max-width: 768px){
      font-size: 12px;
     }
    }
    
`;