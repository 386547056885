import React, { FC } from 'react';
import styled from 'styled-components';
import { slideDown } from '../../../styles/animations';
import { getRatingIcon } from '../../../utils/helperFunctions';
import { Feedback } from '../../../utils/types';
import Flex from '../Flex';
import Modal, { CloseButton } from '../Modal';

const FeedbackPopup: FC<{ onClose: any; feedback: Feedback }> = ({
  onClose,
  feedback,
}) => {
  return (
    <Modal width='60rem' onClose={() => onClose(false)}>
      <Container column onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={() => onClose(false)} />
        <Message>Overall experience :</Message>
        <img
          src={`${process.env.PUBLIC_URL}/feedback/${getRatingIcon(
            feedback?.rating
          )}.svg`}
          alt={getRatingIcon(feedback?.rating)}
        />
        <Message>Feedback :</Message>
        <Text>{feedback.comment}</Text>
      </Container>
    </Modal>
  );
};

export default FeedbackPopup;

const Container = styled(Flex)`
  min-width: 37rem;
  max-width: 75%;
  max-height: 90%;
  overflow: auto;
  height: fit-content;
  padding: 2rem;
  background-color: #fff;
  border-radius: 4px;
  animation: ${slideDown} 0.4s ease-out;
  img {
    margin-bottom: 1.2rem;
    margin-left: 0;
    width: 5rem;
  }

  @media only screen and (max-width: 1024px) {
    width: 80%;
  }
`;

const Message = styled(Flex)`
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  color: ${(props) => props.theme.colors.grey[500]};
  margin-bottom: 1rem;
`;

const Text = styled(Flex)`
  font-weight: normal;
  font-size: 2rem;
  line-height: 3.2rem;
  color: ${(props) => props.theme.colors.grey[500]};
  margin-bottom: 2rem;
`;
