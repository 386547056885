// @ts-nocheck
import React, { useRef, useState } from "react";
import Footer from ".././Footer";
import styled from "styled-components";
import Flex from "../../components/Flex";
import Navbar from "../../components/Navbar";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import ErrorFallback from ".././ErrorFallback";
import { useAnalysisReport } from "../../../hooks/useAnalysisReport";
import "./stridepac.css";
import Slider from "react-slick";
import { Button, Modal } from "antd";
import Lottie from "react-lottie";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeartyEyesEmoji from "../../../assets/Heart-eyes-emoji.json";
import BlackShadesEmoji from "../../../assets/Black-shades-emoji.json";
import BigSmileyEmoji from "../../../assets/Big-smiley-emoji.json";
import DisappointedEmoji from "../../../assets/Disappointed-emoji.json";


let currentQuestion;
const sliderSettings = {
    className: "center",
    centerMode: true,
    infinite: true,
    speed: 500,
    centerPadding: "60px",
    arrows: true,
    slidesToShow: 1,
    afterChange: function (index) {
        currentQuestion.current.innerHTML = index + 1;
    },
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const AptitudeReportPage = ({ test, testId, isFetched }) => {
    const { token } = useParams<{ token: string }>();
    const [modalVisiblity, setModalVisility] = useState(false);
    const modalQuestion = useRef({});
    const customLogo = test?.logo_image;
    const { data: reportMeta, isSuccess, isLoading } = useAnalysisReport(token);
    currentQuestion = useRef(null);
    const slickRef = useRef<Slider>(null);
    const hasImage = htmlString => {
        var div = document.createElement('div');
        div.innerHTML = htmlString

        return Boolean(div.querySelector('img'));
    }
    const getModalData = () => {
        if (modalQuestion.current['questions']) {
            return (
                <>
                    <div className="aptitude-quiz-question-wrapper">
                        <div className="aptitude-quiz-question" dangerouslySetInnerHTML={{ __html: modalQuestion.current['questions'].en }} />
                        <div className="universal-quiz-answers"
                            style={{
                                padding: "20px"
                            }}
                        >
                            {modalQuestion.current['options'].map((option: any) => {
                                const radioInput = <div className={modalQuestion.current['correct_answer_count'] == option.value ? "correct-answer_count" :
                                    modalQuestion.current['user_response'] == option.value ? "user-answer" : undefined}>
                                    <input type="radio" disabled value={option.value} />
                                    <label>{option.value}</label>
                                </div>
                                return radioInput
                            })}
                        </div>
                    </div>
                </>
            )
        } else {
            return undefined
        }
    }
    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: reportMeta?.report_metadata?.score >= 90 ? HeartyEyesEmoji : reportMeta?.report_metadata?.score < 90 && reportMeta?.report_metadata?.score > 60 ? BlackShadesEmoji : reportMeta?.report_metadata?.score <= 60 && reportMeta?.report_metadata?.score > 40 ? BigSmileyEmoji : DisappointedEmoji,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    const getHeaderStyles = () => {
        const baseStyle = {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "48px",
            lineHeight: "48px",
        }
        if (reportMeta?.report_metadata?.score > 60) {
            return { ...baseStyle, color: "#00C853" }
        }
        else if (reportMeta?.report_metadata?.score <= 60 && reportMeta?.report_metadata?.score > 40) {
            return { ...baseStyle, color: "#FFD600" }
        }
        else {
            return { ...baseStyle, color: "#E92E30" }
        }
    }
    return (
        <Container column>
            {!isLoading ? (
                isSuccess ? (
                    reportMeta?.report_metadata ? (
                        <React.Fragment>
                            <Navbar
                                homepage="https://strideahead.in"
                                customLogo={customLogo}
                            />

                            <div id="aptitude-quiz-report-wrapper" className="aptitude-report-page-wrapper"
                                style={{
                                    paddingTop: "150px",
                                }}
                            >
                                <div
                                    id="aptitude-quiz-content"
                                    className="aptitude-quiz-summary aptitude-quiz-report-summary"
                                    style={{
                                        position: "relative",
                                        width: "810px",
                                        height: "fit-content",
                                        maxWidth: "60%",
                                        background: "#fff",
                                        margin: "0 auto",
                                        textAlign: "center",
                                        boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.16)",
                                        borderRadius: "20px",
                                        padding: "83px 0"
                                    }}
                                >
                                    <div className="aptitude-quiz-emoji"
                                        style={{
                                            background: "#ffffff",
                                            borderRadius: "50%",
                                            position: "absolute",
                                            top: "0",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "100px",
                                            padding: "10px",
                                            boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12);",
                                        }}
                                    >
                                        <Lottie options={lottieOptions} />
                                    </div>
                                    <div className="aptitude-quiz-summary-header"
                                        style={{
                                            paddingBottom: "32px",
                                        }}
                                    >
                                        <div
                                            style={getHeaderStyles()}
                                        >
                                            Your Score: {reportMeta?.report_metadata?.score}%
                                        </div>
                                        <div
                                            style={{
                                                fontFamily: "Poppins",
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                                fontSize: "24px",
                                                lineHeight: "24px",
                                                color: "#4F4F4F"
                                            }}
                                        >
                                            {reportMeta?.report_metadata?.score > 40 ? "Congratulations!" : "You can do better!"}
                                        </div>
                                    </div>
                                    <div className="aptitude-quiz-report-summary"
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: "150px 1px 150px",
                                            justifyContent: "center",
                                            //  columnGap: "20%",
                                            //  columnGap: "80px",
                                            //  rowGap: "48px",
                                            // rowGap: "1%",
                                            columnGap: "5rem",
                                            rowGap: "2rem",
                                            justifyItems: "center",
                                            alignItems: "center",
                                        }}>
                                        <div className="universal-quiz-instruction-wrapper">
                                            <div className="universal-quiz-instruction">
                                                <div className="universal-quiz-instruction-title">{reportMeta?.report_metadata?.correct_answer_count}</div>
                                            </div>
                                            <div className="universal-quiz-instruction-info">Correct Answers</div>
                                        </div>

                                        <div className="vertical-line-150"></div>
                                        <div className="vertical-line-100"></div>

                                        <div className="universal-quiz-instruction-wrapper">
                                            <div className="universal-quiz-instruction">
                                                <div className="universal-quiz-instruction-title">{reportMeta?.report_metadata?.incorrect_answer_count}</div>
                                            </div>
                                            <div className="universal-quiz-instruction-info">Incorrect Answers</div>
                                        </div>

                                        <div className="horizontal-line-150"></div>
                                        <div className="spacer-desktop"></div>
                                        <div className="horizontal-line-150"></div>
                                        <div className="horizontal-line-100"></div>
                                        <div className="spacer-mobile"></div>
                                        <div className="horizontal-line-100"></div>

                                        <div className="universal-quiz-instruction-wrapper">
                                            <div className="universal-quiz-instruction">
                                                <div className="universal-quiz-instruction-title">{reportMeta?.report_metadata?.submitted_questions_count}</div>
                                            </div>
                                            <div className="universal-quiz-instruction-info">Questions Attempted</div>
                                        </div>

                                        <div className="vertical-line-150"></div>
                                        <div className="vertical-line-100"></div>

                                        <div className="universal-quiz-instruction-wrapper">
                                            <div className="universal-quiz-instruction">
                                                <div className="universal-quiz-instruction-title">
                                                    {reportMeta.report_metadata.total_questions_count}
                                                </div>
                                            </div>
                                            <div className="universal-quiz-instruction-info">Total Questions Count</div>
                                        </div>

                                    </div>
                                    <div>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                                {/*{Object.keys(reportMeta).length > 0 ? <div className="aptitude-quiz-report-question-counter">*/}
                                {/*    Question <span className='current' ref={currentQuestion} style={{fontSize: "16px", fontWeight: 600}}>1</span>/{reportMeta?.report_metadata?.total_questions_count}*/}
                                {/*</div> : null}*/}
                                <div style={{ display: "block" }} className="aptitude-quiz-report-slider">
                                    <Slider ref={slickRef} {...sliderSettings} className="slider-wrapper">
                                        {/*{*/}
                                        {/*    Object.keys(reportMeta).length > 0 ?*/}
                                        {/*        Object.keys(reportMeta).map(key =>*/}
                                        {/*            <>*/}
                                        {/*                <div className="aptitude-quiz-question-wrapper">*/}
                                        {/*                    <div className="aptitude-quiz-question" dangerouslySetInnerHTML={{__html: reportMeta[key]?.questions.en}} />*/}
                                        {/*                    <div className={*/}
                                        {/*                        hasImage(reportMeta[key]?.questions.en) ?*/}
                                        {/*                            "aptitude-quiz-answers-image universal-quiz-answers" : "aptitude-quiz-answers-text universal-quiz-answers"*/}
                                        {/*                    }>*/}
                                        {/*                        {reportMeta[key]?.options.map((option: any)=>{*/}
                                        {/*                            const radioInput = <div className={reportMeta[key]?.correct_answer == option.value ? "correct-answer" :*/}
                                        {/*                                reportMeta[key]?.user_response == option.value ? "user-answer" : undefined}>*/}
                                        {/*                                <input type="radio" disabled value={option.value} />*/}
                                        {/*                                <label>{option.value}</label>*/}
                                        {/*                            </div>*/}
                                        {/*                            return radioInput*/}
                                        {/*                        })}*/}
                                        {/*                    </div>*/}
                                        {/*                    {*/}
                                        {/*                        hasImage(reportMeta[key].questions.en) ?*/}
                                        {/*                            <div className="aptitude-quiz-reveal-answer-button">*/}
                                        {/*                                <Button*/}
                                        {/*                                    style={{*/}
                                        {/*                                        color: "#fff",*/}
                                        {/*                                        background: "#1273B7",*/}
                                        {/*                                        width: "100%",*/}
                                        {/*                                        marginRight: "",*/}
                                        {/*                                    }}*/}
                                        {/*                                    onClick={(e) => {*/}
                                        {/*                                        setModalVisility(true);*/}
                                        {/*                                        modalQuestion.current = reportMeta[key]*/}
                                        {/*                                    }}*/}
                                        {/*                                >*/}
                                        {/*                                    Click to review*/}
                                        {/*                                </Button>*/}
                                        {/*                            </div> :*/}
                                        {/*                            null*/}
                                        {/*                    }*/}
                                        {/*                </div>*/}
                                        {/*            </>*/}
                                        {/*        ) : <>*/}

                                        {/*        </>*/}
                                        {/*}*/}
                                    </Slider>
                                </div>
                                <Modal
                                    closable={false}
                                    width={"80%"}
                                    style={{ top: 20 }}
                                    visible={modalVisiblity}
                                    onCancel={() => {
                                        setModalVisility(false)
                                    }
                                    }
                                    destroyOnClose={true}
                                    footer={null}
                                >
                                    {getModalData()}
                                </Modal>
                            </div>



                            <Footer />
                        </React.Fragment>
                    ) : // null)
                        isFetched ? (
                            <Loading />
                        ) : null
                ) : (
                    <ErrorFallback
                        error="500"
                        showNavbar={false}
                        url={`/test/${testId}`}
                    />
                )
            ) : // null)}
                isFetched ? (
                    <Loading />
                ) : null}
        </Container>
    );
};

export default AptitudeReportPage;

const Container = styled(Flex)`
  width: 100%;
  background: ${({ theme }) => theme.colors.blue[50]};
  font-family: Poppins;
  @media only screen and (max-width: 1024px) {
    background: ${({ theme }) => theme.colors.grey[50]};
    padding: 1.2rem;
  }
  @media only screen and (max-width: 700px) {
    background: ${({ theme }) => theme.colors.grey[50]};
    padding: 1.2rem;
    // width:20%;
    // height: 100px;

  }
`;

const Content = styled(Flex)`
  max-width: 1250px;
  margin: 1.5rem auto;
  margin-top: 6rem;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const DetailAndSummaryContainer = styled(Flex)`
  width: 100%;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
